import React, { useEffect } from "react";
import close from "../../../../assets/images/close.svg";
import { MainContainer, Header, Heading, ResendHeader } from "./styled";
import { Dialog, Grid, FormControl, TextField } from "@mui/material";
import ErrorHandle from "../../../../shared/commonComponents/errorHandle/errorHandle";
import BottomNavigation from "../../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import Button from "../../../../shared/commonComponents/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  resendInvite,
  handleError,
  resendInviteClient,
  sendInvites,
  CloseResendDialogForClient,
  CloseResendDialogForShop,
} from "../../../../store/userManagment/action";
import SendInvite from "../../../userManagment/users/create/sendInvite";
import SessionStorageService from "../../../../services/functional/accounts/login/storage";

const ResendInvite = (props) => {
  const dispatch = useDispatch();

  const ClientInviteResponse = useSelector(
    (state) => state.userManagmentReducer.resendInviteClient
  );
  const ShopInviteResponse = useSelector(
    (state) => state.userManagmentReducer.resendInvite
  );

  const sendInvitesUser = useSelector(
    (state) => state.userManagmentReducer?.sendInvites
  );

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const onSave = () => {
    const data = {
      user_id: props.values._id,
      admin_id: props.values.updated_by.user_id,
      store_id: props.values.current_store_id,
    };

    const clientData = {
      admin_id: userDetails._id,
      client_id: props.values.client_id,
      user_id: props.values._id,
    };
    if (props.state === "client") {
      dispatch(resendInviteClient(clientData));
    } else if (props.state === "shop") {
      dispatch(resendInvite(data));
    }

    dispatch(sendInvites(true));
  };

  useEffect(() => {
    if (
      ClientInviteResponse === "COMPLETE" ||
      ShopInviteResponse === "COMPLETE"
    ) {
      if (props.clientData && props.shopData) {
        dispatch(CloseResendDialogForShop(null));
      } else if (props.clientData && !props.shopData) {
        dispatch(CloseResendDialogForClient(null));
      }
      props.close(true);
    }
  }, [ClientInviteResponse, ShopInviteResponse]);

  const isError = useSelector((state) => state.userManagmentReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.userManagmentReducer?.error?.message
  );

  const handleErrorState = () => {
    dispatch(handleError());
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.close;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              backgroundColor: "transparent !important",
              boxShadow: "none",
              width: "445px",
              minHeight: "449px",
            },
          },
        }}
      >
        <MainContainer>
          <Header>
            <img
              src={close}
              alt="close"
              onClick={props.close}
              style={{ cursor: "pointer" }}
            />
          </Header>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            padding={"30px"}
            direction="row"
            style={{
              display: "block",
              backgroundColor: "#fff",
            }}
          >
            <ResendHeader>Resend Invite for {props.values.name} </ResendHeader>
            <FormControl style={{ width: "100%", margin: "27px 0 150px 0" }}>
              <TextField
                fullWidth={true}
                type={"name"}
                placeholder={"Email"}
                variant="standard"
                id="roles"
                label="Permission Level"
                value={
                  props.values.roles === "INSPECTOR"
                    ? "GENERAL"
                    : props.values.roles
                }
              />
              <Heading className="choose">
                {props.values.roles === "ADMIN" &&
                  "Request and review inspections, access the Admin dashboard and settings, invite and manage users, etc."}
                {(props.values.roles === "INSPECTOR" ||
                  props.values.roles === "GENERAL") &&
                  ""}
              </Heading>

              <div>
                <TextField
                  fullWidth={true}
                  type={"email"}
                  placeholder={"Email"}
                  variant="standard"
                  value={props.values.user_email}
                />
              </div>
            </FormControl>
            <BottomNavigation
              opacity={"1"}
              position={"sticky"}
              padding={"17px 0px 0 0px"}
              justifyContent={"space-between"}
              moblieJustifyContent={"space-around"}
              borderRadius={"0px 0px 4px 4px"}
              minHeight={"55px"}
              bottom={"0"}
              left={"0"}
            >
              <Button
                show={true}
                width={"115px"}
                height={"34px"}
                clicked={props.close}
                secondary={true}
                fontSize={"16px"}
              >
                <div>Cancel</div>
              </Button>

              <Button
                show={true}
                width={"118px"}
                height={"34px"}
                clicked={onSave}
                primary={true}
                fontSize={"16px"}
              >
                Send
              </Button>
            </BottomNavigation>
          </Grid>
        </MainContainer>
      </Dialog>
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
      {sendInvitesUser && (
        <SendInvite
          fromResendInvite={"resendInvite"}
          clientData={props.clientData}
          shopData={props.shopData}
        />
      )}
    </>
  );
};

export default ResendInvite;
