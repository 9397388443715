import React from "react";
import "./login.css";

const InternalServerError = (props) => {
  return (
    <div className="server-error-main">
      <div className="server-error-screen">
        <h1>Network Error!</h1>
        <div>
          <p>
            Sorry for the inconvenience. We&rsquo;ll be back online shortly!
          </p>
        </div>
      </div>
      <div
        className="bottom-footer"
        style={{
          position: "fixed",
          bottom: "10px",
          width: "100%",
        }}
      >
        {" "}
        © 2022 Assurant, Inc. All Rights Reserved
      </div>
    </div>
  );
};

export default InternalServerError;
