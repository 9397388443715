import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Login from "./pages/accounts/login/login";
import InternalServerError from "./pages/accounts/login/internalServer";
import { Security, LoginCallback } from "@okta/okta-react";
import { toRelativeUrl, OktaAuth } from "@okta/okta-auth-js";
import config from "./services/functional/accounts/login/config-okta";
import MainAdministration from "./pages/admin/index";
import UserManagmentScreen from "./pages/userManagment";
import ReportingScreen from "./pages/reporting";
import ReminderScreen from "./pages/reminder/index";
import MainDasboardScreen from "./pages/inspection/dashboard";
import CreateInspection from "./pages/inspection/create";
import EditInspection from "./pages/inspection/edit";
import ReviewInspection from "./pages/inspection/review";
import RequestMoreInspectionDetails from "./pages/inspection/request-details";
import ErrorHandle from "./shared/commonComponents/errorHandle/errorHandle";
import PrivateRoutes from "./PrivateRoutes";
import ActivateUser from "./pages/accounts/login/activateUser";
import ResetPassword from "./pages/accounts/login/resetPassword";
import SessionStorageService from "./services/functional/accounts/login/storage";
import { useCallback } from "react";

const oktaAuth = new OktaAuth(config.okta.oidc);

const PgaRoutes = () => {
  const navigate = useNavigate();

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const restoreOriginalUri = useCallback((_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  }, []);

  const customAuthHandler = () => {
    navigate("/login");
  };
  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/activate/:activationToken" element={<ActivateUser />} />
          <Route
            path="/resetPassword/:userId"
            element={<ResetPassword />}
          />
          <Route path="/loginCallback" component={LoginCallback} />
          <Route element={<PrivateRoutes />}>
            {userDetails?.roles.toLowerCase().includes("super") ? (
              <>
                <Route path="/setup" element={<UserManagmentScreen />} />
                <Route path="/reporting" element={<ReportingScreen />} />
                <Route path="*" element={<Navigate to="/setup" />} />
              </>
            ) : (
              <>
                <Route path="/admin" element={<MainAdministration />} />

                <Route path="/dashboard" element={<MainDasboardScreen />} />
                <Route path="/reporting" element={<ReportingScreen />} />

                <Route path="/setup" element={<UserManagmentScreen />} />

                <Route
                  path="/reminder"
                  element={<ReminderScreen navigate={navigate} />}
                />
                <Route
                  path="/inspection/:InspectionId/create/:adjudicatorId"
                  element={<CreateInspection />}
                />
                <Route
                  path="inspection/edit/:InspectionId"
                  element={<EditInspection />}
                />
                <Route
                  exact
                  path="inspection/review/:InspectionId"
                  element={<ReviewInspection />}
                />
                <Route
                  exact
                  path="inspection/completed/:InspectionId"
                  element={<ReviewInspection />}
                />
                <Route
                  path="inspection/review/"
                  element={<Navigate to="/dashboard" replace />}
                />
                <Route
                  path="inspection/edit/"
                  element={<Navigate to="/dashboard" replace />}
                />

                <Route
                  path="/inspection/request-more/:InspectionId"
                  element={<RequestMoreInspectionDetails />}
                />
                <Route path="*" element={<ErrorHandle />} />
              </>
            )}
          </Route>
          <Route path="/servererror" element={<InternalServerError />} />
        </Routes>
      </>
    </Security>
  );
};

export default PgaRoutes;
