import React, { useState, useEffect } from "react";
import {
  Dialog,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Button from "../../../shared/commonComponents/button/button";
import { setInspectionValue } from "../../../store/claimInspection/action";
import { useDispatch, useSelector } from "react-redux";
import DialogInspectionClosedSent from "./dialogInspectionClosedSent";
import { Header, Heading } from "./closeInspectionStyled";
import close from "../../../assets/images/close.svg";
import BottomNavigation from "../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import SessionStorageService from "../../../services/functional/accounts/login/storage";

export default function CloseInspectionDialog(props) {
  const dispatch = useDispatch();
  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);
  const [enableTextField, setEnableTextField] = useState(false);
  const [value, setValue] = useState("");
  const [isComment, setComment] = useState("");
  const [isOpen, setOpen] = useState(true);
  const [openInspectionCheck, setOpenInspectionCheck] = useState(false);

  const closedInspectionSuccess = useSelector(
    (state) => state.claimInspectionReducer.closeInspectionSuccess
  );

  const claimInspectionId = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );

  const CloseInspectionOpen = () => {
    setOpenInspectionCheck(true);
  };

  const CloseInspectionClosed = () => {
    setOpenInspectionCheck(false);
  };

  const handleInputField = (e) => {
    setValue(e.target.value);
    setComment("Technical or other issue: " + e.target.value);
  };
  const onClosed = () => {
    dispatch(
      setInspectionValue({
        user_id: userDetails._id,
        comment: isComment,
        claimId: claimInspectionId._id,
      })
    );
  };
  const handleRadioBoxChange = (e, name) => {
    if (name === "Technical or other issue") {
      setEnableTextField(true);
      setComment("");
    } else if (name === "Shop said they’d complete, but didn’t") {
      setEnableTextField(false);
      setComment(e.target.value);
    } else if (name === "Shop refused virtual inspection") {
      setEnableTextField(false);
      setComment(e.target.value);
    }
  };
  useEffect(() => {
    if (closedInspectionSuccess?.status?.code === 200) {
      setOpen(false);
      setOpenInspectionCheck(true);
    }
  }, [closedInspectionSuccess]);
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            isOpen;
          }
        }}
        fullWidth
        maxWidth={"lg"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              backgroundColor: "transparent !important",
              boxShadow: "none",
              borderRadius: ".5",
              width: "445px",
              minHeight: "449px",
            },
          },
        }}
      >
        <Header>
          <img
            src={close}
            onClick={props.close}
            style={{
              cursor: "pointer",
            }}
          />
        </Header>
        <div
          style={{
            padding: "20px 30px 30px 30px",
            backgroundColor: "#ffffff",
            borderRadius: "2px",
          }}
        >
          <Heading>Close Inspection</Heading>
          <FormControl>
            <RadioGroup name="radio-buttons-group">
              <FormControlLabel
                value="Shop refused virtual inspection"
                control={<Radio />}
                label="Shop refused virtual inspection"
                onChange={(e) =>
                  handleRadioBoxChange(e, "Shop refused virtual inspection")
                }
              />
              <FormControlLabel
                value="Shop said they’d complete, but didn’t"
                control={<Radio />}
                label="Shop said they’d complete, but didn’t"
                onChange={(e) =>
                  handleRadioBoxChange(
                    e,
                    "Shop said they’d complete, but didn’t"
                  )
                }
              />
              <FormControlLabel
                value="Technical or other issue"
                control={<Radio />}
                label="Technical or other issue"
                onChange={(e) =>
                  handleRadioBoxChange(e, "Technical or other issue")
                }
              />
            </RadioGroup>
          </FormControl>
          {enableTextField ? (
            <TextField
              multiline
              fullWidth
              label="Please explain the issue"
              placeholder="Please explain the issue"
              variant="standard"
              value={value}
              onChange={handleInputField}
            />
          ) : null}

          <BottomNavigation
            opacity={"1"}
            position={"sticky"}
            padding={"17px 0px 0 0px"}
            justifyContent={"space-between"}
            moblieJustifyContent={"space-around"}
            borderRadius={"0px 0px 4px 4px"}
            minHeight={"55px"}
            bottom={"0"}
            left={"0"}
          >
            <Button
              show={true}
              width={"115px"}
              height={"34px"}
              clicked={props.close}
              secondary={true}
              fontSize={"16px"}
            >
              Cancel
            </Button>

            <Button
              show={true}
              width={"118px"}
              height={"34px"}
              clicked={onClosed}
              disabled={isComment.length === 0 ? true : false}
              primary={isComment.length > 0 ? true : false}
              fontSize={"16px"}
            >
              Close
            </Button>
          </BottomNavigation>
        </div>
      </Dialog>
      {openInspectionCheck && (
        <DialogInspectionClosedSent
          open={CloseInspectionOpen}
          close={CloseInspectionClosed}
        />
      )}
    </>
  );
}
