import React, { useState, useEffect } from "react";
import {
  Dialog,
  Grid,
  Pagination,
  Autocomplete,
  TextField,
  Stack,
  InputAdornment,
  endAdornment,
  createFilterOptions,
} from "@mui/material";
import {
  MainContainer,
  Heading,
  Table,
  ClaimDesk,
  PaginationContainer,
  PageText,
  TableRight,
  BodyTextContainer,
} from "./styled";
import AddClientUser from "../users/create/clientUser";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import AddShopUser from "../users/create/shopUser";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import viewIcon from "../../../assets/images/view.svg";
import check from "../../../assets/images/check.svg";
import send from "../../../assets/images/send.svg";
import edit from "../../../assets/images/edit.svg";
import sortImg from "../../../assets/images/sort.svg";
import { useDispatch, useSelector } from "react-redux";
import { headerType } from "../../../store/sharedComponents/header/action";
import { isFooterReq } from "../../../store/sharedComponents/footer/action";
import {
  getShopUsers,
  searchClientFilter,
  searchShopFilter,
  getClientList,
  getClientUsers,
  getShopListOfClient,
  fileFetchHeader,
  data,
  handleError,
  clientValues,
} from "../../../store/userManagment/action";
import PaginationItem from "@mui/material/PaginationItem";
import SessionStorageService from "../../../services/functional/accounts/login/storage";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";
import EditUser from "../users/edit/editUsers";
import Delete from "../../../shared/commonComponents/confirm/delete";
import ResendInvite from "../users/resendInvite/resendInviteUsers";
import searchIcon from "../../../assets/images/search.svg";
import Shops from "../shop/shop";
import Logo from "../logo/logo";
import EditShop from "../shop/editShop";
import UserShops from "../users/shops/userShops";

const Setup = () => {
  const dispatch = useDispatch();
  const [showClientUser, setShowClientUser] = useState(false);
  const [page, setPage] = useState(1);
  const size = 10;
  const [isClientRotate, setClientRotate] = useState(false);
  const [isShopRotate, setShopRotate] = useState(false);
  const [clientId, setClientId] = useState("");
  const [showShopUser, setShowShopUser] = useState(false);
  const [showShop, setShowShop] = useState(false);
  const [value, setValue] = useState(true);
  const [client, setClient] = useState(null);
  const [changeClientText, setChangeClientText] = useState(true);
  const [changeClientShopText, setChangeClientShopText] = useState(true);
  const [shopValue, setShopValue] = useState(true);
  const [shopId, setShopId] = useState("");
  const [shop, setShop] = useState(null);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isResendInvite, setResendInvite] = useState(false);
  const [editUserValues, setEditUserValues] = useState("");
  const [deleteUserDetails, setDeleteUserDetails] = useState("");
  const [resendInviteDetails, setResendInviteDetails] = useState("");
  const [editLogo, setEditLogo] = useState("");
  const [editClient, setEditClient] = useState("");
  const [deleteState, setDeleteState] = useState("");
  const [resendState, setResendState] = useState("");
  const [editState, setEditState] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [isSetSelectedShop, setSelectedShop] = useState();
  const [initialRow, setInitialRow] = useState(1);
  const [finalRow, setFinalRow] = useState(10);
  const [PageCount, setPageCount] = useState(1);
  const [editShop, setEditShop] = useState(false);
  const [showUserStores, setShowUserStores] = useState(false);
  const [userStoreDetails, setUserStoreDetails] = useState("");
  const [userStoreState, setUserStoresState] = useState("");

  const shopUsers = useSelector(
    (state) => state.userManagmentReducer?.shopAllUsers
  );

  const searchKeyword = useSelector(
    (state) => state.userManagmentReducer?.searchKeyword
  );

  const totalCount = useSelector(
    (state) => state.userManagmentReducer?.totalCount
  );

  const clientUser = useSelector(
    (state) => state.userManagmentReducer?.clientUsers
  );

  const ClientList = useSelector(
    (state) => state.userManagmentReducer?.clientList
  );

  const ShopListofClient = useSelector(
    (state) => state.userManagmentReducer?.shopListOfClient
  );

  const updateShopList = useSelector(
    (state) => state.userManagmentReducer?.updateShopUser
  );

  const updateClientList = useSelector(
    (state) => state.userManagmentReducer?.updateClientUser
  );

  const isError = useSelector((state) => state.userManagmentReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.userManagmentReducer?.error?.message
  );

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const jwtTokenClientDetails = SessionStorageService.get("CLIENT_DETAILS");
  const clientdetails = JSON.parse(jwtTokenClientDetails);

  const defaultProps = {
    options: ClientList,
    getOptionLabel: (option) => option.name,
  };

  const shopProps = {
    options: ShopListofClient,
    getOptionLabel: (option) => option.name,
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
    limit: 500,
  });

  useEffect(() => {
    if (client) {
      dispatch(clientValues(client));
    }
  }, [client]);

  useEffect(() => {
    if (userDetails?.roles === "ADMIN") {
      setClient(clientdetails);
      setClientId(clientdetails?._id);
      if (clientdetails) {
        dispatch(
          getClientUsers({ client_id: clientdetails._id, page, size, sortType })
        );
        dispatch(getShopListOfClient(clientdetails._id));
      }
    } else if (userDetails?.roles.toLowerCase().includes("super")) {
      dispatch(fileFetchHeader({ link: userDetails?.image_url }));
    }
  }, []);

  useEffect(() => {
    if (clientId) {
      const cvb = ClientList.find((data) => data._id === clientId);
      setClient(cvb);
    }
    if (shopId) {
      const updateShop = ShopListofClient.find((data) => data._id === shopId);

      setShop(updateShop);
    }
  }, [ClientList, ShopListofClient]);

  const handleErrorState = () => {
    dispatch(handleError());
  };

  const onClickClients = (event, value) => {
    setValue(false);
    setClient(value);
    setClientId(value?._id);
    if (value) {
      dispatch(getClientUsers({ client_id: value._id, page, size, sortType }));
      dispatch(getShopListOfClient(value._id));
    }
  };

  const onClickShop = (event, value) => {
    setShopValue(false);
    setShop(value);
    setShopId(value?._id);

    if (value) {
      const selectedShop = ShopListofClient.filter(
        (data) => data._id === value._id
      );

      const viTypeValue = selectedShop[0].vi_type;

      setSelectedShop(viTypeValue);
      dispatch(
        getShopUsers({
          client_id: value.client_id,
          shop_id: value._id,
          page: 1,
          size,
          sortType,
        })
      );
    }
    if (!value) {
      dispatch(getClientUsers({ client_id: clientId, page, size, sortType }));
    }
  };

  const openAddClientUser = () => {
    setShowClientUser(true);
  };

  const closeClientUser = () => {
    setShowClientUser(false);
  };

  const openAddShopUser = () => {
    setShowShopUser(true);
  };

  const closeShopUser = () => {
    setShowShopUser(false);
  };

  const OpenShop = () => {
    setShowShop(true);
  };

  const CloseShop = () => {
    setShowShop(false);
  };

  const openEditLogo = (params, editing) => {
    setEditClient(editing);
    setEditLogo(true);
  };
  const closeEditLogo = () => {
    setEditLogo(false);
  };

  const openEditShop = (params, editing) => {
    setEditClient(editing);
    setEditShop(true);
  };
  const closeEditShop = () => {
    setEditShop(false);
  };

  useEffect(() => {
    dispatch(
      headerType({
        isSearch: true,
        isHeaderVi: true,
        isHamburger: true,
        isActiveClose: false,
        isLogo: true,
      })
    );
    dispatch(isFooterReq({ isFooterReq: true }));
  }, []);

  useEffect(() => {
    dispatch(getClientList());
  }, []);

  const openEditUser = (data, param) => {
    setShowEditUser(true);
    setEditUserValues(data);
    setEditState(param);
  };

  const closeEditUser = () => {
    setShowEditUser(false);
  };

  const openDelete = (data, param) => {
    setShowDelete(true);
    setDeleteUserDetails(data);
    setDeleteState(param);
  };

  const closeDelete = () => {
    setShowDelete(false);
  };

  const resendInvite = (data, param) => {
    setResendInvite(true);
    setResendInviteDetails(data);
    setResendState(param);
  };
  const closeResendInvite = () => {
    setResendInvite(false);
  };

  useEffect(() => {
    if (clientId && shopId && updateShopList) {
      dispatch(
        getShopUsers({
          client_id: clientId,
          shop_id: shopId,
          page,
          size,
          sortType,
        })
      );
    }
  }, [updateShopList]);

  useEffect(() => {
    if (client?._id && updateClientList && !shopId) {
      dispatch(
        getClientUsers({ client_id: client?._id, page, size, sortType })
      );
    }
  }, [updateClientList]);

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / size));
    setPage(1);
    updateRows(1);
  }, [totalCount]);

  const updateRows = (value) => {
    setInitialRow(10 * value - 9);
    if (totalCount > 10 * value) {
      setFinalRow(10 * value);
    } else {
      setFinalRow(totalCount);
    }
  };

  const pageChange = (event, value) => {
    setPage(value);
    updateRows(value);
    if (searchKeyword) {
      shopId
        ? dispatch(
            searchShopFilter({
              search: searchKeyword,
              shop_id: shopId,
              page: value,
              size,
              client_id: clientId,
              sortType,
            })
          )
        : dispatch(
            searchClientFilter({
              search: searchKeyword,
              client_id: clientId,
              page: value,
              size,
              sortType,
            })
          );
    } else {
      shopId
        ? dispatch(
            getShopUsers({
              client_id: clientId,
              shop_id: shopId,
              page: value,
              size,
              sortType,
            })
          )
        : dispatch(
            getClientUsers({ client_id: clientId, page: value, size, sortType })
          );
    }
  };

  useEffect(() => {
    dispatch(
      data({
        cli: clientId,
        sho: shopId,
        isPage: page,
        isSize: size,
        isSort: sortType,
      })
    );
  }, [clientId, shopId, page, size, sortType]);

  const onCLickSortIcon = () => {
    setClientRotate(!isClientRotate);
    if (sortType === "asc") {
      setSortType("desc");
      dispatch(
        getClientUsers({
          client_id: clientdetails._id,
          page,
          size,
          sortType: "desc",
        })
      );
    } else {
      setSortType("asc");
      dispatch(
        getClientUsers({
          client_id: clientdetails._id,
          page,
          size,
          sortType: "asc",
        })
      );
    }
  };
  const onClickSortShopIcon = () => {
    setShopRotate(!isShopRotate);
    if (sortType === "asc") {
      setSortType("desc");
      dispatch(
        getShopUsers({
          client_id: clientId,
          shop_id: shopId,
          page,
          size,
          sortType: "desc",
        })
      );
    } else {
      setSortType("asc");
      dispatch(
        getShopUsers({
          client_id: clientId,
          shop_id: shopId,
          page,
          size,
          sortType: "asc",
        })
      );
    }
  };
  // Show Stores linked with User - start //
  const openUserStores = (data, param) => {
    setShowUserStores(true);
    setUserStoreDetails(data);
    setUserStoresState(param);
  };
  const closeUserStores = () => {
    setShowUserStores(false);
  };
  // Show Stores linked with User - end //

  return (
    <>
      <MainContainer>
        <Grid container flexDirection={"row"}>
          <Heading className="setup" item xs={12} sm={4} md={4} lg={4} xl={4}>
            Setup & User Management
          </Heading>
        </Grid>
        <Grid container>
          <ClaimDesk item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Heading className="label">Claims Admin</Heading>

            {userDetails?.roles === "ADMIN" ? (
              <>
                <Stack spacing={1} sx={{ width: "70%", display: "flex" }}>
                  <TextField
                    width={"100%"}
                    type={"name"}
                    variant="standard"
                    value={clientdetails?.name}
                    InputProps={{ readOnly: true }}
                    /* start updated by pratikasha */
                    aria-label="claims admin"
                    /* end */
                    sx={{ cursor: "pointer" }}
                  />
                </Stack>
              </>
            ) : (
              <>
                <Stack spacing={1} sx={{ width: "70%", display: "flex" }}>
                  <div>
                    <Autocomplete
                      forcePopupIcon={false}
                      noOptionsText={"No results found"}
                      {...defaultProps}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            variant="standard"
                            onChange={(event) => setChangeClientText(false)}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={searchIcon}
                                    alt="search"
                                    width={"16px"}
                                    height={"16px"}
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  {changeClientText && client ? (
                                    <img
                                      src={edit}
                                      alt="edit"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        openEditLogo(params, "client");
                                      }}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                      sx={{
                        "& .MuiButtonBase-root": {
                          visibility: "visible",
                        },
                      }}
                      onChange={(event, value) => {
                        onClickClients(event, value);
                        setChangeClientText(true);
                      }}
                      onInputChange={(event, value) => {
                        if (event.target.value === 0) {
                          setChangeClientText(true);
                        }
                      }}
                    />
                  </div>
                </Stack>
              </>
            )}

            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "150px",
              }}
            >
              <Heading
                className="coloredVar clients"
                opacity={client && client.status === "ACTIVE" ? "1" : "0.4"}
                onClick={
                  client && client.status === "ACTIVE"
                    ? openAddClientUser
                    : null
                }
              >
                + User
              </Heading>
            </div>

            <Heading className="label">Shop</Heading>
            <Stack spacing={1} sx={{ width: "70%", display: "flex" }}>
              <div>
                <Autocomplete
                  forcePopupIcon={false}
                  noOptionsText={"No results found"}
                  {...shopProps}
                  filterOptions={filterOptions}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.shop_id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        /* start updated by pratikasha */
                        aria-label="shop search"
                        /* end */
                        label=""
                        variant="standard"
                        onChange={(event) => setChangeClientShopText(false)}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={searchIcon}
                                alt="search"
                                width={"16px"}
                                height={"16px"}
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {changeClientShopText && shop && client ? (
                                <img
                                  src={edit}
                                  alt="edit"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    (userDetails?.roles
                                      .toLowerCase()
                                      .includes("super") &&
                                      !client.name
                                        .toLowerCase()
                                        .includes("assurant")) ||
                                    !userDetails?.user_email
                                      .toLowerCase()
                                      .includes("assurant")
                                      ? openEditShop(params, "shop")
                                      : openEditLogo(params, "shop");
                                  }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                  sx={{
                    "& .MuiButtonBase-root": {
                      visibility: "visible",
                    },
                  }}
                  disabled={!client}
                  onChange={(event, value) => {
                    onClickShop(event, value);
                    setChangeClientShopText(true);
                  }}
                  onInputChange={(event, value) => {
                    if (event.target.value === 0) {
                      setChangeClientShopText(true);
                    }
                  }}
                />
              </div>
            </Stack>

            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "150px",
              }}
            >
              <Heading
                className="coloredVar shop"
                onClick={
                  client && client.status === "ACTIVE"
                    ? client.name.toLowerCase().includes("assurant")
                      ? null
                      : OpenShop
                    : null
                }
                opacity={
                  client && client.status === "ACTIVE"
                    ? client.name.toLowerCase().includes("assurant")
                      ? "0.4"
                      : "1"
                    : "0.4"
                }
              >
                + shop
              </Heading>

              <Heading
                className="coloredVar"
                onClick={
                  shop &&
                  client &&
                  client.status === "ACTIVE" &&
                  shop.vi_type === 1
                    ? openAddShopUser
                    : null
                }
                opacity={
                  shop &&
                  shop.vi_type === 1 &&
                  client &&
                  client.status === "ACTIVE"
                    ? "1"
                    : "0.4"
                }
              >
                + users
              </Heading>
            </div>
          </ClaimDesk>
          <TableRight item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Table
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent={"spaceEvenly"}
            >
              {clientUser && clientUser.length > 0 && !shop ? (
                <>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div
                      style={{
                        display: "inline-flex",
                      }}
                    >
                      USER{" "}
                      <div style={{ margin: "0 0 0 12px" }}>
                        <img
                          src={sortImg}
                          alt={"sort"}
                          onClick={onCLickSortIcon}
                          style={
                            isClientRotate
                              ? { transform: "rotate(180deg)" }
                              : { transform: "rotate(0deg)" }
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{ paddingLeft: "5px" }}
                  >
                    LEVEL
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{ paddingLeft: "10px" }}
                  >
                    STATUS
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
                </>
              ) : (
                <>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div
                      style={{
                        display: "inline-flex",
                      }}
                    >
                      USER{" "}
                      <div style={{ margin: "0 0 0 12px" }}>
                        <img
                          src={sortImg}
                          alt={"sort"}
                          onClick={onClickSortShopIcon}
                          style={
                            isShopRotate
                              ? { transform: "rotate(180deg)" }
                              : { transform: "rotate(0deg)" }
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{ paddingLeft: "5px" }}
                  >
                    LEVEL
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{ paddingLeft: "7px" }}
                  >
                    STATUS
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    MOBILE APP
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
                </>
              )}
            </Table>
            {client && clientUser && clientUser.length > 0 && !shop ? (
              clientUser.map((user, index) => {
                return (
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    key={index}
                  >
                    <BodyTextContainer
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      key={index}
                    >
                      {user.name}
                    </BodyTextContainer>
                    <BodyTextContainer item xs={4} sm={4} md={4} lg={4} xl={4}>
                      {user.roles.toLowerCase().includes("inspector")
                        ? "GENERAL"
                        : user.roles.toUpperCase()}
                    </BodyTextContainer>
                    <BodyTextContainer item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {user.status}
                    </BodyTextContainer>
                    <BodyTextContainer item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {user.name ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {user.status === "INVITED" ? (
                            <img
                              src={send}
                              alt="send"
                              onClick={() => {
                                resendInvite(user, "client");
                              }}
                            />
                          ) : user.roles.toLowerCase().includes("super") ||
                            userDetails?.user_email ===
                              user.user_email ? null : (
                            <img
                              src={edit}
                              alt="edit"
                              onClick={() => {
                                openEditUser(user, "client");
                              }}
                            />
                          )}
                          {user.roles.toLowerCase().includes("super") ||
                          userDetails.user_email === user.user_email ? (
                            <span style={{ paddingRight: "20px" }}>{""}</span>
                          ) : (
                            <span style={{ paddingLeft: "5px" }}>
                              <img
                                src={deleteIcon}
                                alt="delete"
                                onClick={() => {
                                  openDelete(user, "client");
                                }}
                              />
                            </span>
                          )}
                        </div>
                      ) : (
                        <div style={{ width: "28px", height: "28px" }} />
                      )}
                    </BodyTextContainer>
                  </Grid>
                );
              })
            ) : shop && shopUsers && shopUsers.length ? (
              shopUsers.map((user, index) => {
                return (
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    key={index}
                  >
                    <BodyTextContainer
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      key={index}
                    >
                      {user.name}
                    </BodyTextContainer>
                    <BodyTextContainer item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {user.roles.toLowerCase().includes("inspector")
                        ? "GENERAL"
                        : user.roles.toUpperCase()}
                    </BodyTextContainer>
                    <BodyTextContainer item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {user.status}
                    </BodyTextContainer>
                    <BodyTextContainer item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {isSetSelectedShop === 1 ? (
                        <img src={check} alt="check" />
                      ) : (
                        <div style={{ width: "28px", height: "28px" }}></div>
                      )}
                    </BodyTextContainer>
                    <BodyTextContainer item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {user.name ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {shop.vi_type === 1 &&
                            (user.status === "INVITED" ? (
                              <img
                                src={send}
                                alt="send"
                                onClick={() => {
                                  resendInvite(user, "shop");
                                }}
                              />
                            ) : user.roles
                                .toLowerCase()
                                .includes("super") ? null : (
                              <img
                                src={edit}
                                alt="edit"
                                onClick={() => {
                                  openEditUser(user, "shop");
                                }}
                              />
                            ))}

                          <span style={{ paddingLeft: "5px" }}>
                            {user.roles.toLowerCase().includes("super") ? (
                              <span style={{ paddingRight: "20px" }}>{""}</span>
                            ) : user.stores.length > 1 ? (
                              <img
                                src={viewIcon}
                                alt="view"
                                onClick={() => {
                                  openUserStores(user, "shop");
                                }}
                              />
                            ) : (
                              <img
                                src={deleteIcon}
                                alt="delete"
                                onClick={() => {
                                  openDelete(user, "shop");
                                }}
                              />
                            )}
                          </span>
                        </div>
                      ) : (
                        <div style={{ width: "28px", height: "28px" }} />
                      )}
                    </BodyTextContainer>
                  </Grid>
                );
              })
            ) : (
              <Heading className="shopUser">
                {client && shop && searchKeyword
                  ? "No inspector found"
                  : client && searchKeyword
                  ? "No adjudicator found"
                  : client && shop
                  ? "No inspector found"
                  : client
                  ? "No adjudicator found"
                  : "Select a client to view user details"}
              </Heading>
            )}
          </TableRight>
        </Grid>

        {PageCount && (client || shop) ? (
          <>
            <PaginationContainer
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mobile={12}
            >
              <PageText>
                Showing results {initialRow} - {finalRow} from {totalCount}
              </PageText>
              <Pagination
                count={PageCount}
                page={page}
                showFirstButton
                showLastButton
                defaultPage
                onChange={pageChange}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      first: KeyboardDoubleArrowLeftIcon,
                      last: KeyboardDoubleArrowRightIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </PaginationContainer>
          </>
        ) : null}
      </MainContainer>
      {showClientUser && (
        <AddClientUser
          close={closeClientUser}
          addClient={client}
          open={showClientUser}
          page={page}
          size={size}
          sort={sortType}
        />
      )}
      {showShopUser && (
        <AddShopUser
          close={closeShopUser}
          addShop={shop}
          open={showShopUser}
          shopNameTpa={shop?.name}
          page={page}
          size={size}
          sort={sortType}
          fromUserManagementScreen={"AddShopUser"}
        />
      )}

      {showShop && (
        <Dialog
          open={OpenShop}
          fullWidth
          minWidth={"lg"}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              CloseShop;
            }
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "875px",
                maxHeight: "600px",
                overflow: "hidden",
                backgroundColor: "transparent !important",
                boxShadow: "none",
              },
            },
          }}
        >
          <Shops
            onClose={CloseShop}
            clientData={client}
            page={page}
            size={size}
            sort={sortType}
          />
        </Dialog>
      )}
      {showEditUser && (
        <EditUser
          open={openEditUser}
          close={closeEditUser}
          values={editUserValues}
          state={editState}
          page={page}
          size={size}
          sort={sortType}
        />
      )}
      {showDelete && (
        <Delete
          open={openDelete}
          close={closeDelete}
          values={deleteUserDetails}
          state={deleteState}
          page={page}
          size={size}
          sort={sortType}
        />
      )}
      {showUserStores && (
        <UserShops
          open={showUserStores}
          close={closeUserStores}
          values={userStoreDetails}
          state={userStoreState}
          page={page}
          size={size}
          sort={sortType}
        />
      )}
      {isResendInvite && (
        <ResendInvite
          open={resendInvite}
          close={closeResendInvite}
          values={resendInviteDetails}
          state={resendState}
          clientData={client}
          shopData={shop}
          page={page}
          size={size}
          sort={sortType}
        />
      )}
      {editLogo && (
        <Dialog
          open={openEditLogo}
          fullWidth
          minWidth={"lg"}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              closeEditLogo;
            }
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                backgroundColor: "transparent !important",
                boxShadow: "none",
              },
            },
          }}
        >
          <Logo
            onClose={closeEditLogo}
            onClient={client}
            onShop={shop}
            editing={editClient}
            page={page}
            size={size}
            sort={sortType}
          />
        </Dialog>
      )}
      {editShop && (
        <Dialog
          open={openEditShop}
          fullWidth
          minWidth={"lg"}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              closeEditShop;
            }
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                backgroundColor: "transparent !important",
                boxShadow: "none",
                width: "100%",
                maxWidth: "875px",
                maxHeight: "600px",
              },
            },
          }}
        >
          <EditShop
            onClose={closeEditShop}
            onClient={client}
            onShop={shop}
            editing={editClient}
          />
        </Dialog>
      )}
      {isError && (
        <ErrorHandle
          message={errorMessage}
          errorState={handleErrorState}
          isErrorValue={isError?.response?.data?.status?.message?.status}
          isClientErrorValue={isError?.response?.data?.status}
        />
      )}
    </>
  );
};

export default Setup;
