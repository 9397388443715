import * as types from "./types";

export const getApiKeyData = (payload) => ({
  type: types.GET_API_KEY,
  payload,
});

export const getPdfData = (payload) => ({
  type: types.GET_PDF,
  payload,
});
