import React, { useEffect, useState } from "react";
import {
  HeaderContainer,
  HeaderTextContainer,
  BodyGridContainer,
  BodyTextContainer,
  MainContainer,
  SubCardGrid,
  MainGrid,
  CardGrid1,
  LeftGrid,
  RightGrid,
  CenterGrid,
  CardGrid,
  Circle,
  NameCard,
  NumberCard,
  BannerAlert,
} from "./styled";
import Man1 from "../../../../assets/images/mechanic.svg";
import Man2 from "../../../../assets/images/customerCare.svg";
import infoImg from "../../../../assets/images/info.svg";
import closeIcon from "../../../../assets/images/close-banneralert.svg";
import redWarningIcon from "../../../../assets/images/red_warning_icon.svg";
import orangeWarningIcon from "../../../../assets/images/orange_warning_icon.svg";
import {
  getActiveClaims,
  getTokenValidity,
} from "../../../../store/inspection/dashboard/getClaimsData/action";
import {
  showLoader,
  hideLoader,
} from "../../../../store/sharedComponents/loader/action";
import { useDispatch, useSelector } from "react-redux";
import { headerType } from "../../../../store/sharedComponents/header/action";
import { isFooterReq } from "../../../../store/sharedComponents/footer/action";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../shared/commonComponents/loader/loader";
import {
  getClaimInspectionDetails,
  resetClaimDetails,
  setNavigate,
  setUpdate,
} from "../../../../store/claimInspection/action";
import ErrorHandle from "../../../../shared/commonComponents/errorHandle/errorHandle";
import SessionStorageService from "../../../../services/functional/accounts/login/storage";
import { BANNER_ALERT_LABELS } from "../../../../shared/constants/constants";

const ActiveScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adjucdicatorClaimsList, setAdjucdicatorClaimsList] = useState(null);
  const [shopClaimsList, setshopClaimsList] = useState(null);
  const [noResultShop, setNoResultShop] = useState(false);
  const [noResultadjudicator, setNoResultAdjudicator] = useState(false);
  const [inspectionId, setInspectionId] = useState("");
  const [inspectionStatus, setInspectionStatus] = useState("");
  const isError = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.isError
  );

  const errorMessage = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.error?.message
  );

  const searchData = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.claimsSearchData
  );
  const claimsData = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.claimsData
  );
  const claimInspectionDetails = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );
  const navigation = useSelector(
    (state) => state.claimInspectionReducer?.navigate
  );
  const adjudicator = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.adjudicator
  );
  const shop = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.shop
  );
  const tokenExpiresIn = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.expiresIn
  );
  const expiryAlert = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.expiryAlert
  );

  const isLoading = useSelector((state) => state?.loader?.isLoading);

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const [showBannerAlert, setShowBannerAlert] = useState(false);

  useEffect(() => {
    dispatch(showLoader());
    const data = {
      clientId: userDetails.client_id,
      role: userDetails.roles,
      name: userDetails.name,
      email: userDetails.user_email,
    };
    dispatch(getActiveClaims(data));
    if (data.role === "ADMIN") {
      dispatch(getTokenValidity(data.clientId));
    }
    dispatch(setUpdate(false));
  }, []);
  useEffect(() => {
    setShowBannerAlert(expiryAlert);
  }, [expiryAlert]);
  useEffect(() => {
    if (searchData) {
      const filteredadjudicatorData = adjudicator.filter((data) => {
        return (
          data.adjudicatorName
            .toLocaleLowerCase()
            .includes(searchData.toLocaleLowerCase()) ||
          data.claimNumber.toLowerCase().includes(searchData.toLowerCase())
        );
      });
      if (filteredadjudicatorData.length > 0) {
        setAdjucdicatorClaimsList(filteredadjudicatorData);
        setNoResultAdjudicator(false);
      } else {
        setNoResultAdjudicator(true);
      }
      const filteredShopData = shop.filter((data) => {
        return (
          data.shopName.toLowerCase().includes(searchData.toLowerCase()) ||
          data.claimNumber.toLowerCase().includes(searchData.toLowerCase())
        );
      });
      if (filteredShopData.length > 0) {
        setshopClaimsList(filteredShopData);
        setNoResultShop(false);
      } else {
        setNoResultShop(true);
      }
    } else {
      setNoResultShop(false);
      setNoResultAdjudicator(false);
      setshopClaimsList(null);
      setAdjucdicatorClaimsList(null);
    }
  }, [searchData]);
  useEffect(() => {
    dispatch(
      headerType({
        isSearch: true,
        isHeaderVi: false,
        isHamburger: true,
        isActiveClose: true,
        isLogo: true,
      })
    );
    dispatch(isFooterReq({ isFooterReq: false }));
  }, []);

  const claimHandler = (inspectionId, inspectionStatus) => {
    setInspectionId(inspectionId);
    setInspectionStatus(inspectionStatus);
    dispatch(setNavigate(true));
    // dispatch(getClaimInspectionDetails(inspectionId));
  };
  const claimHandlerShop = (inspectionId, inspectionStatus) => {
    setInspectionId(inspectionId);
    setInspectionStatus(inspectionStatus);
    dispatch(setNavigate(true));
    // dispatch(getClaimInspectionDetails(inspectionId));
  };

  const closeBannerAlert = () => {
    setShowBannerAlert(false);
  };

  const getBannerColor = () => {
    let color = BANNER_ALERT_LABELS.BLUE_COLOR;
    if (tokenExpiresIn < 16 && tokenExpiresIn > 7) {
      color = BANNER_ALERT_LABELS.BLUE_COLOR;
    } else if (tokenExpiresIn < 8 && tokenExpiresIn > 1) {
      color = BANNER_ALERT_LABELS.ORANGE_COLOR;
    } else if (tokenExpiresIn < 2) {
      color = BANNER_ALERT_LABELS.RED_COLOR;
    }
    return color;
  };

  const getBannerIcon = () => {
    let icon = infoImg;
    if (tokenExpiresIn < 16 && tokenExpiresIn > 7) {
      icon = infoImg;
    } else if (tokenExpiresIn < 8 && tokenExpiresIn > 1) {
      icon = orangeWarningIcon;
    } else if (tokenExpiresIn < 2) {
      icon = redWarningIcon;
    }
    return icon;
  };

  const getBannerText = () => {
    let text = BANNER_ALERT_LABELS.MSG_15DAYS;
    if (tokenExpiresIn < 16 && tokenExpiresIn > 7) {
      text = BANNER_ALERT_LABELS.MSG_15DAYS;
    } else if (tokenExpiresIn < 8 && tokenExpiresIn > 1) {
      text = BANNER_ALERT_LABELS.MSG_7DAYS;
    } else if (tokenExpiresIn < 2) {
      text = BANNER_ALERT_LABELS.MSG_1DAY;
    }
    return text;
  };

  useEffect(() => {
    dispatch(setNavigate(false));
  }, []);
  useEffect(() => {
    dispatch(resetClaimDetails(false));
  }, []);
  useEffect(() => {
    if (navigation && inspectionStatus) {
      if (inspectionStatus === "CREATED") {
        navigate(`/inspection/${inspectionId}/create`, { state: inspectionId });
      }
      if (inspectionStatus === "SUBMITTED" || inspectionStatus === "UPDATED") {
        navigate(`/inspection/review/${inspectionId}`, { state: inspectionId });
      }
      if (inspectionStatus === "REQUESTED" || inspectionStatus === "STARTED") {
        navigate(`/inspection/edit/${inspectionId}`, { state: inspectionId });
      }
    }
  }, [navigation]);

  const navigateToApiKey = () => {
    navigate("/admin", { state: { message: "RedirectFromBannerAlert" } });
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <MainContainer>
          {showBannerAlert && (
            <BannerAlert color={getBannerColor()}>
              <img
                style={{ marginRight: "20px" }}
                src={getBannerIcon()}
                alt="info-warning"
              />
              {getBannerText()}
              <span
                onClick={navigateToApiKey}
                style={{
                  fontWeight: 400,
                  marginLeft: "8px",
                  cursor: "pointer",
                }}
              >
                {BANNER_ALERT_LABELS.TAP_TO_UPDATE}
              </span>
              <img
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  cursor: "pointer",
                }}
                onClick={closeBannerAlert}
                src={closeIcon}
                alt="close"
              />
            </BannerAlert>
          )}
          <CardGrid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mobile={12}
          >
            <CardGrid1
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              mobile={2.4}
              className="urgentMechanic"
            >
              <SubCardGrid>
                <NumberCard>{claimsData?.urgentMechanic}</NumberCard>
                <NameCard>
                  Urgent <br /> Mechanic
                </NameCard>
              </SubCardGrid>
            </CardGrid1>
            <CardGrid1
              item
              xs={0.6}
              sm={0.6}
              md={0.6}
              lg={0.6}
              xl={0.6}
              mobile={0.6}
            >
              <img style={{ marginTop: "4rem" }} src={Man1} alt="mechanic" />
            </CardGrid1>

            <CardGrid1
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              mobile={2.4}
              className="pendingMechanic"
            >
              <SubCardGrid>
                <NumberCard>{claimsData?.pendingMechanic}</NumberCard>
                <NameCard style={{ color: "#3D515A" }}>
                  Pending <br /> Mechanic
                </NameCard>
              </SubCardGrid>
            </CardGrid1>

            <Circle
              item
              xs={1.2}
              sm={1.2}
              md={1.2}
              lg={1.2}
              xl={1.2}
              mobile={1.2}
              className="display"
            >
              <NameCard className="totalActive">Total Active</NameCard>
              <NumberCard className="totalActiveNumber">
                {claimsData?.totalActiveClaims}
              </NumberCard>
            </Circle>

            <CardGrid1
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              mobile={2.4}
            >
              <SubCardGrid>
                <NumberCard>{claimsData?.urgentReview}</NumberCard>
                <NameCard>
                  Urgent <br /> Review
                </NameCard>
              </SubCardGrid>
            </CardGrid1>

            <CardGrid1
              item
              xs={0.6}
              sm={0.6}
              md={0.6}
              lg={0.6}
              xl={0.6}
              mobile={0.6}
              className="customerCare"
            >
              <img
                style={{ marginTop: "4rem" }}
                src={Man2}
                alt="customerCare"
              />
            </CardGrid1>
            <CardGrid1
              item
              xs={2.4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={2.4}
              mobile={2.4}
              className="pendingReview"
            >
              <SubCardGrid>
                <NumberCard>{claimsData?.pendingReview}</NumberCard>
                <NameCard style={{ color: "#3D515A" }}>
                  Pending <br /> Review
                </NameCard>
              </SubCardGrid>
            </CardGrid1>
          </CardGrid>

          <MainGrid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mobile={12}
          >
            <LeftGrid item xs={5} sm={5} md={5} lg={5} xl={5} mobile={5}>
              <HeaderContainer container direction="row">
                <HeaderTextContainer
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  mobile={3}
                  className="headerClaim"
                >
                  Claim #
                </HeaderTextContainer>
                <HeaderTextContainer
                  item
                  xs={2.5}
                  sm={2.5}
                  md={2.5}
                  lg={2.5}
                  xl={2.5}
                  mobile={2.5}
                >
                  Total
                </HeaderTextContainer>
                <HeaderTextContainer
                  item
                  xs={2.5}
                  sm={2.5}
                  md={2.5}
                  lg={2.5}
                  xl={2.5}
                  mobile={2.5}
                >
                  In-status
                </HeaderTextContainer>
                <HeaderTextContainer
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  mobile={4}
                >
                  Shop
                </HeaderTextContainer>
              </HeaderContainer>
              {noResultShop ? (
                <BodyTextContainer
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  mobile={12}
                  className="noClaim"
                >
                  No result found
                </BodyTextContainer>
              ) : (
                <BodyGridContainer container direction="row">
                  {shop?.length > 0 ? (
                    (shopClaimsList || shop)?.map((data, index) => (
                      <React.Fragment key={index}>
                        <BodyTextContainer
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          mobile={12}
                          className="bodyClaim"
                        >
                          <span
                            style={{
                              color: data.pendingMechanicstatus
                                ? "#3D515A"
                                : data.urgentMechanicstatus
                                ? "#E18801"
                                : data.shopLate
                                ? "#B90000"
                                : "#3D515A",
                              fontWeight: "600",
                              cursor: "pointer",
                              lineBreak: "anywhere",
                              wordBreak: "break-word",
                              width: "100px",
                            }}
                            onClick={() =>
                              claimHandlerShop(data.inspection_id, data.status)
                            }
                          >
                            {data.claimNumber.replace(/CLAIM-/, "")}
                          </span>
                        </BodyTextContainer>
                        <BodyTextContainer
                          className="bodyTotal"
                          item
                          xs={2.5}
                          sm={2.5}
                          md={2.5}
                          lg={2.5}
                          xl={2.5}
                          mobile={2.5}
                        >
                          {data.totalDays}
                        </BodyTextContainer>
                        <BodyTextContainer
                          item
                          xs={2.5}
                          sm={2.5}
                          md={2.5}
                          lg={2.5}
                          xl={2.5}
                          mobile={2.5}
                        >
                          {data.inStatus}
                        </BodyTextContainer>
                        <BodyTextContainer
                          className="bodyShop"
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          mobile={12}
                        >
                          {data.shopName}
                        </BodyTextContainer>
                      </React.Fragment>
                    ))
                  ) : (
                    <BodyTextContainer
                      className="noClaim"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      mobile={12}
                    >
                      No active inspections.
                    </BodyTextContainer>
                  )}
                </BodyGridContainer>
              )}
            </LeftGrid>
            <RightGrid item xs={5} sm={5} md={5} lg={5} xl={5} mobile={5}>
              <HeaderContainer container direction="row">
                <HeaderTextContainer
                  item
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  mobile={12}
                  className="headerClaim"
                >
                  Claim #
                </HeaderTextContainer>
                <HeaderTextContainer
                  item
                  xs={2.5}
                  sm={2.5}
                  md={2.5}
                  lg={2.5}
                  xl={2.5}
                  mobile={2.5}
                >
                  Total
                </HeaderTextContainer>
                <HeaderTextContainer
                  item
                  xs={2.5}
                  sm={2.5}
                  md={2.5}
                  lg={2.5}
                  xl={2.5}
                  mobile={2.5}
                >
                  In-status
                </HeaderTextContainer>
                <HeaderTextContainer
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  mobile={12}
                >
                  Adjudicator
                </HeaderTextContainer>
              </HeaderContainer>
              {noResultadjudicator ? (
                <BodyTextContainer
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  mobile={12}
                  className="noClaim"
                >
                  No result found
                </BodyTextContainer>
              ) : (
                <BodyGridContainer container direction="row">
                  {adjudicator?.length > 0 ? (
                    (adjucdicatorClaimsList || adjudicator)?.map(
                      (data, index) => (
                        <React.Fragment key={index}>
                          <BodyTextContainer
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            mobile={3}
                            className="bodyClaim"
                            key={index}
                          >
                            <span
                              style={{
                                color: data.pendingadjudicatorstatus
                                  ? "#3D515A"
                                  : data.urgentadjudicatorstatus
                                  ? "#E18801"
                                  : "#3D515A",
                                fontWeight: "600",
                                cursor: "pointer",
                                lineBreak: "anywhere",
                                wordBreak: "break-word",
                                width: "100px",
                              }}
                              onClick={() =>
                                claimHandler(data.inspection_id, data.status)
                              }
                            >
                              {data.claimNumber.replace(/CLAIM-/, "")}
                            </span>
                          </BodyTextContainer>
                          <BodyTextContainer
                            item
                            xs={2.5}
                            sm={2.5}
                            md={2.5}
                            lg={2.5}
                            xl={2.5}
                            mobile={2.5}
                          >
                            {data.totalDays}
                          </BodyTextContainer>
                          <BodyTextContainer
                            item
                            xs={2.5}
                            sm={2.5}
                            md={2.5}
                            lg={2.5}
                            xl={2.5}
                            mobile={2.5}
                          >
                            {data.inStatus}
                          </BodyTextContainer>
                          <BodyTextContainer
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            mobile={12}
                          >
                            {data.adjudicatorName}
                          </BodyTextContainer>
                        </React.Fragment>
                      )
                    )
                  ) : (
                    <BodyTextContainer
                      className="noClaim"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      mobile={12}
                    >
                      All caught up!
                    </BodyTextContainer>
                  )}
                </BodyGridContainer>
              )}
            </RightGrid>
          </MainGrid>
        </MainContainer>
      )}
      {isError && <ErrorHandle message={errorMessage} />}
    </>
  );
};
export default ActiveScreen;
