import {
  GET_REMINDER_DETAILS,
  GET_REMINDER_DETAILS_SUCCESS,
  GET_REMINDER_DETAILS_FAILURE,
  UPDATE_REMINDER_DETAILS,
  UPDATE_REMINDER_DETAILS_SUCCESS,
  UPDATE_REMINDER_DETAILS_FAILURE,
  RESTORE_REMINDER_DETAILS,
  REMINDER_DATA_CHANGE,
  OPEN_REMINDER_POPUP,
} from "./types";

const initialState = {
  remindersdata: [],
  isUpdateRemindersData: false,
  reminderError: "",
  isDataChanged: false,
  openReminderPopUp: false,
  error: null,
  isError: false,
};

export const ReminderReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_REMINDER_DETAILS:
      return {
        ...state,
        remindersdata: [],
      };

    case GET_REMINDER_DETAILS_SUCCESS:
      return {
        ...state,
        remindersdata: action.payload,
      };
    case GET_REMINDER_DETAILS_FAILURE:
      return {
        ...state,
        remindersdata: action.error,
        isError: action.payload,
        error: action.payload,
      };

    case REMINDER_DATA_CHANGE:
      return {
        ...state,
        isDataChanged: action.payload,
      };

    case UPDATE_REMINDER_DETAILS:
      return {
        ...state,
        remindersdata: action.payload,
      };
    case UPDATE_REMINDER_DETAILS_SUCCESS:
      return {
        ...state,
        remindersdata: action.payload,
      };
    case UPDATE_REMINDER_DETAILS_FAILURE:
      return {
        ...state,
        reminderError: action.error,
        isError: action.payload,
        error: action.payload,
      };
    case RESTORE_REMINDER_DETAILS:
      return {
        ...state,
        remindersdata: action.payload,
      };
    case OPEN_REMINDER_POPUP:
      return {
        ...state,
        openReminderPopUp: action.payload,
      };
    default:
      return state;
  }
};
