import { put, call, takeEvery } from "redux-saga/effects";
import { dashboardService } from "../../../../services/functional";
import * as types from "./types";
import {
  HIDE_LOADER,
  HIDE_CLOSED_LOADER,
} from "../../../sharedComponents/loader/types";

function* getClaimsData({ payload }) {
  try {
    const response = yield call(dashboardService.getActiveClaims, payload);
    yield put({ type: types.GET_CLAIMS_DATA_SUCCESS, data: response.data });
    yield put({ type: HIDE_LOADER });
  } catch (error) {
    yield put({ type: types.GET_CLAIMS_DATA_FAILURE, payload: error });
  }
}

function* getClosedClaimsData({ payload }) {
  try {
    const response = yield call(dashboardService.getClosedClaims, payload);
    yield put({
      type: types.GET_CLOSED_CLAIMS_DATA_SUCCESS,
      data: response.data,
    });
    yield put({ type: HIDE_CLOSED_LOADER });
  } catch (error) {
    yield put({ type: types.GET_CLOSED_CLAIMS_DATA_FAILURE, payload: error });
  }
}
function* getClosedDropdownData() {
  try {
    const response = yield call(dashboardService.getClosedDropdownDataService);
    yield put({
      type: types.GET_CLOSED_DROPDOWN_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_CLOSED_DROPDOWN_DATA_FAILURE, error });
  }
}
function* getTokenValidity({ payload }) {
  try {
    const response = yield call(dashboardService.getTokenValidity, payload);
    yield put({ type: types.GET_TOKEN_VALIDITY_SUCCESS, data: response.data });
  } catch (error) {
    yield put({ type: types.GET_TOKEN_VALIDITY_FAILURE, payload: error });
  }
}
export function* watchGetClaims() {
  yield takeEvery(types.GET_CLAIMS_DATA, getClaimsData);
}

export function* watchGetClosedClaims() {
  yield takeEvery(types.GET_CLOSED_CLAIMS_DATA, getClosedClaimsData);
}
export function* watchGetClosedDropDownData() {
  yield takeEvery(types.GET_CLOSED_DROPDOWN_DATA, getClosedDropdownData);
}
export function* watchGetTokenValidity() {
  yield takeEvery(types.GET_TOKEN_VALIDITY, getTokenValidity);
}
