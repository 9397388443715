import styled from "styled-components";
import { Grid } from "@mui/material";

export const Heading = styled(Grid)`
  cursor: pointer;
  &.setup {
    font-size: 18px;
    color: #13223c;
    font-family: "OpenSans SemiBold";
    margin-bottom: 20px;
  }
  &.coloredVar {
    opacity: ${(props) => props.opacity};
    color: #0066cc;
    font-size: 16px;
    font-family: "OpenSans Regular";
    text-transform: uppercase;
    &.clients {
      margin: 10px 0 30px 0px;
    }
    &.shop {
      margin: 18px 0 0 0;
    }
  }
  &.label {
    font-size: 12px;
    height: 18px;
    font-family: "OpenSans SemiBold";
    color: #68758b;
  }
  &.shopUser {
    font-family: "OpenSans Regular";
    margin: 16px;
    font-size: 16px;
    line-height: 28px;
    color: #68758b;
  }
`;

export const Table = styled(Grid)`
  cursor: default;
  font-size: 14px;
  font-family: "OpenSans SemiBold";
  background: #fbfbfb;
  align-items: center;
  height: 56px;
  padding: 16px 0 16px 16px;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  display: flex;
`;

export const MainContainer = styled.div`
  padding: 5px 24px 30px 24px;
`;

export const SearchBox = styled.div``;
export const ClaimDesk = styled(Grid)``;

export const TableRight = styled(Grid)`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 1920px) and (max-height: 1024px) {
    height: 550px;
  }
  @media only screen and (max-width: 1680px) and (max-height: 850px) {
    height: 500px;
  }
  @media only screen and (max-width: 1530px) and (max-height: 768px) {
    height: 450px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 680px) {
    height: 380px;
  }
  @media only screen and (max-width: 1280px) and (max-height: 570px) {
    height: 290px;
  }
`;

export const BodyTextContainer = styled(Grid)`
  font-family: "OpenSans Regular";
  padding: 9px 0 0 15px;
  font-size: 14px;
  line-height: 24px;
  color: #3d515a;
  background: #ffffff;
  height: 50px;
  box-shadow: inset 0px -1px 0px rgba(20, 20, 20, 0.2);
`;

export const PaginationContainer = styled(Grid)`
  display: flex;
  justify-content: end;
  padding-top: 20px;
  margin-bottom: 20px !important;
`;

export const PageText = styled.div`
  padding-top: 4px;
  color: #3d515a;
  font-family: "OpenSans Regular";
  font-size: 16px;
`;
