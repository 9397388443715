import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../shared/commonComponents/header/header";
import ActiveScreen from "./active/active";
import ClosedScreen from "./closed/closed";
import Footer from "../../../shared/commonComponents/footer/footer";
import { setShowClosedScreen } from "../../../store/inspection/dashboard/getClaimsData/action";

const MainDasboardScreen = (props) => {
  const dispatch = useDispatch();
  const [showActiveScreen, setShowActiveScreen] = useState(true);
  const [showClosedScreen, setShowClosedScreenn] = useState(false);
  const showCloseScreen = useSelector(
    (state) => state?.dashboardReducer?.claimsReducer?.closedScreenShow
  );
  useEffect(() => {
    if (showCloseScreen) {
      setShowActiveScreen(false);
      setShowClosedScreenn(true);
    }
  }, [showCloseScreen]);
  const togglingActive = () => {
    dispatch(setShowClosedScreen(false));
    setShowActiveScreen(true);
    setShowClosedScreenn(false);
  };
  const togglingClosed = () => {
    setShowActiveScreen(false);
    setShowClosedScreenn(true);
  };
  return (
    <>
      <Header
        style={{ zIndex: "1000" }}
        togglingActive={togglingActive}
        togglingClosed={togglingClosed}
        showActiveScreen={showActiveScreen}
        showClosedScreen={showClosedScreen}
      />
      {showActiveScreen && <ActiveScreen />}
      {showClosedScreen && <ClosedScreen />}
      <Footer />
    </>
  );
};

export default MainDasboardScreen;
