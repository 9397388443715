export const GET_CLAIM_INSPECTION_DETAILS = "GET_CLAIM_INSPECTION_DETAILS";
export const GET_CLAIM_INSPECTION_DETAILS_SUCCESS =
  "GET_CLAIM_INSPECTION_DETAILS_SUCCESS";
export const GET_CLAIM_INSPECTION_DETAILS_FAILURE =
  "GET_CLAIM_INSPECTION_DETAILSS_FAILURE";
export const CREATE_NEW_INPECTION_REQUEST = "CREATE_NEW_REQUEST";
export const CREATE_NEW_INPECTION_REQUEST_SUCCESS =
  "CREATE_NEW_REQUEST_SUCCESS";
export const CREATE_NEW_INPECTION_REQUEST_FAILURE =
  "CREATE_NEW_REQUEST_FAILURE";
export const RESEND_INSPECTION = "RESEND_INSPECTION";
export const RESEND_INSPECTION_SUCCESS = "RESEND_INSPECTION_SUCCESS";
export const RESEND_INSPECTION_FAILURE = "RESEND_INSPECTION_FAILURE";
export const SET_CURRENT_CLAIM = "SET_CURRENT_CLAIM";
export const GET_SEARCH_PART_LIST = "GET_SEARCH_PART_LIST";
export const GET_SEARCH_PART_LIST_SUCCESS = "GET_SEARCH_PART_LIST_SUCCESS";
export const GET_SEARCH_PART_LIST_FAILURE = "GET_SEARCH_PART_LIST_FAILURE";
export const SET_PART = "SET_PART";
export const SET_REQUESTED_IMAGE_COUNT = "SET_REQUESTED_IMAGE_COUNT";
export const SET_REQUESTED_VIDEO_COUNT = "SET_REQUESTED_VIDEO_COUNT";
export const SET_REQUESTED_NOISE = "SET_REQUESTED_NOISE";
export const SET_REQUESTED_LEAK = "SET_REQUESTED_LEAK";
export const SET_REQUESTED_OTHER = "SET_REQUESTED_OTHER";
export const SET_REQUESTED_DESCRIPTION = "SET_REQUESTED_DESCRIPTION";
export const DELETE_REQUESTED_PART = "DELETE_REQUESTED_PART";
export const SET_VIDEO_PHOTO_FLAG = "SET_VIDEO_PHOTO_FLAG";
export const ENABLE_REQUEST_NEW_INSPECTION = "ENABLE_REQUEST_NEW_INSPECTION";
export const ENABLE_REQUEST_NEW_INSPECTION_SUCCESS =
  "ENABLE_REQUEST_NEW_INSPECTION_SUCCESS";
export const ENABLE_EDIT = "ENABLE_EDIT";
export const GET_ATTACHMENT_REQUEST_DETAILS = "GET_ATTACHMENT_REQUEST_DETAILS";
export const GET_ATTACHEMENT_REQUEST_DETAILS_SUCCESS =
  "GET_ATTACHEMENT_REQUEST_DETAILS_SUCCESS";
export const GET_ATTACHEMENT_REQUEST_DETAILS_FAILURE =
  "GET_ATTACHEMENT_REQUEST_DETAILS_FAILURE";
export const DOWNLOAD_CLAIMS_FILES = "DOWNLOAD_CLAIMS_FILES";
export const DOWNLOAD_CLAIMS_FILES_SUCCESS = "DOWNLOAD_CLAIMS_FILES_SUCCESS";
export const DOWNLOAD_CLAIMS_FILES_FAILURE = "DOWNLOAD_CLAIMS_FILES_FAILURE";
export const SET_REQUESTED = "SET_REQUESTED";
export const EDIT_REQUEST_ATTACHMENT = "EDIT_REQUEST_ATTACHMENT";
export const EDIT_REQUEST_ATTACHMENT_SUCCESSFUL =
  "EDIT_REQUEST_ATTACHMENT_SUCCESSFUL";
export const EDIT_REQUEST_ATTACHMENT_FAILURE =
  "EDIT_REQUEST_ATTACHMENT_FAILURE";
export const ACCEPT_INSPECTION = "ACCEPT_INSPECTION";
export const ACCEPT_INSPECTION_SUCCCESS = "ACCEPT_INSPECTION_SUCCCESS";
export const ACCEPT_INSPECTION_FAILURE = "ACCEPT_INSPECTION_FAILURE";
export const RETAKE_REQUEST = "RETAKE_REQUEST";
export const RETAKE_REQUEST_SUCCESS = "RETAKE_REQUEST_SUCCESS";
export const RETAKE_REQUEST_FAILURE = "RETAKE_REQUEST_FAILURE";
export const GET_RETAKE_REQUEST_COMMENT = "GET_RETAKE_REQUEST_COMMENT";
export const GET_RETAKE_REQUEST_COMMENT_SUCCESS =
  "GET_RETAKE_REQUEST_COMMENT_SUCCESS";
export const GET_RETAKE_REQUEST_COMMENT_FAILURE =
  "GET_RETAKE_REQUEST_COMMENT_FAILURE";
export const SET_REQUESTED_FAILURE = "SET_REQUEST_FAILURE";
export const SET_MORE_DETAILS = "SET_MORE_DETAILS";
export const SET_NAVIGATE = "SET_NAVIGATE";
export const SET_ACCEPT = "SET_ACCEPT";
export const RESET_CLAIM_DETAILS = "RSET_CLAIM_DETAILS";
export const UPDATE_ALL_PREVIEW_IMAGES = "UPDATE_ALL_PREVIEW_IMAGES";
export const UPDATE_IS_COMPLETED = "UPDATE_IS_COMPLETED";
export const SET_RETAKE_REQUEST = "SET_RETAKE_REQUEST";
export const RETAKE_REQUEST_SEND = "RETAKE_REQUEST_SEND";
export const SET_UPDATE_COMPELTE = "SET_UPDATE_COMPELTE";
export const HANDLE_ERROR = "HANDLE_ERROR";
export const SET_INSPECTION_STARTED = "SET_INSPECTION_STARTED";
export const RESET_REQUEST_MORE_INSPECTION = "RESET_REQUEST_MORE_INSPECTION";
export const ENABLE_ACCEPT_INSPECTION = "ENABLE_ACCEPT_INSPECTION";
export const CLOSE_INSPECTION_VALUES = "CLOSE_INSPECTION_VALUES";
export const CLOSE_INSPECTION_VALUES_SUCCESS =
  "CLOSE_INSPECTION_VALUES_SUCCESS";
export const CLOSE_INSPECTION_VALUES_FAILURE =
  "CLOSE_INSPECTION_VALUES_FAILURE";
