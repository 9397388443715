import React from "react";
import Reporting from "./reporting";
import Header from "../../shared/commonComponents/header/header";

const ReportingScreen = (props) => {
  return (
    <>
      <Header reporting={true} />
      <Reporting />
    </>
  );
};

export default ReportingScreen;
