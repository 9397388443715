import React from "react";
import { useSelector } from "react-redux";
import { Bottom, FootText } from "./styled";

const Footer = () => {
  const isFooterReq = useSelector(
    (state) => state.footerReducer.condition.isFooterReq
  );

  return (
    <>
      <div
        style={{
          bottom: "0",
          width: "100%",
          zIndex: 1000,
        }}
      >
        {isFooterReq && (
          <Bottom>
            <FootText>
              © 2022. Powered by Assurant. All rights reserved.
            </FootText>
          </Bottom>
        )}
      </div>
    </>
  );
};
export default Footer;
