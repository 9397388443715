import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, PaginationItem } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  HeaderContainer,
  HeaderTextContainer,
  PaginationContainer,
  PageText,
  BodyGridContainer,
  BodyTextContainer,
  ImageContainer,
  MainContainer,
  ClaimContainer,
  Header,
  DropdownGrid,
} from "./styled";
import ErrorHandle from "../../../../shared/commonComponents/errorHandle/errorHandle";
import Sort from "../../../../assets/images/keyboard-arrow-down.svg";
import {
  showClosedLoader,
  showLoader,
} from "../../../../store/sharedComponents/loader/action";
import { headerType } from "../../../../store/sharedComponents/header/action";
import { isFooterReq } from "../../../../store/sharedComponents/footer/action";
import {
  getClosedClaims,
  getClosedDropdownData,
} from "../../../../store/inspection/dashboard/getClaimsData/action";
import Loader from "../../../../shared/commonComponents/loader/loader";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SessionStorageService from "../../../../services/functional/accounts/login/storage";
import {
  getClaimInspectionDetails,
  setNavigate,
} from "../../../../store/claimInspection/action";
import view from "../../../../assets/images/view.svg";
import { useNavigate } from "react-router-dom";

const ClosedScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shop, setShop] = useState("");
  const [requestor, setRequestor] = useState("");
  const [status, setStatus] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [initialRow, setInitialRow] = useState(1);
  const [finalRow, setFinalRow] = useState(10);
  const [reverse, setReverse] = useState(false);
  const [inspectionId, setInspectionId] = useState("");
  const [inspectionStatus, setInspectionStatus] = useState("");

  const navigation = useSelector(
    (state) => state.claimInspectionReducer?.navigate
  );
  const STATUS_DATA = [
    { value: "", name: "ALL" },
    { value: "COMPLETED", name: "Completed" },
    { value: "CLOSED", name: "Closed" },
  ];
  const fetchedClosedClaimData = useSelector(
    (state) =>
      state.dashboardReducer?.claimsReducer?.closedClaimsData?.closedClaims
  );
  const totalCount = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.closedClaimsData?.count
  );
  const list = useSelector(
    (state) => state.dashboardReducer.claimsReducer.closedDropDownData
  );
  const isError = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.isError
  );
  const searchText = useSelector(
    (state) => state.dashboardReducer.claimsReducer?.closedSearchData
  );
  const errorMessage = useSelector(
    (state) => state.dashboardReducer?.claimsReducer?.error?.message
  );
  const claimInspectionDetails = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );
  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);
  useEffect(() => {
    if (searchText) {
      setPageNo(1);
    }
  }, [searchText]);
  useEffect(() => {
    dispatch(showLoader());
    dispatch(showClosedLoader());
    dispatch(
      getClosedClaims({
        Adjudicator: requestor,
        Shop: shop,
        Status: status,
        search: searchText,
        pageNo,
        size: 10,
        reverse,
        clientId: userDetails.client_id,
        role: userDetails.roles,
        name: userDetails.name,
        email: userDetails.user_email,
      })
    );
  }, [requestor, shop, status, pageNo, searchText, reverse]);
  useEffect(() => {
    dispatch(getClosedDropdownData());
  }, []);
  const handlePageChange = (e, value) => {
    setPageNo(value);
    updateRows(value);
  };
  const handleSortChange = () => {
    setReverse(!reverse);
  };
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const isLoadingComplete = useSelector(
    (state) => state?.loader?.isLoadingComplete
  );
  useEffect(() => {
    dispatch(
      headerType({
        isSearch: true,
        isHeaderVi: false,
        isHamburger: true,
        isActiveClose: true,
        isLogo: true,
      })
    );
    dispatch(isFooterReq({ isFooterReq: false }));
  }, []);

  const handleAdjudicatorMenu = (e) => {
    setRequestor(e.target.value);
    setPageNo(1);
  };
  const handleShopMenu = (e) => {
    setShop(e.target.value);
    setPageNo(1);
  };
  const handleStatusMenu = (e) => {
    setStatus(e.target.dataset.value);
    setPageNo(1);
  };
  useEffect(() => {
    if (totalCount === 0) {
      updateRows(0);
    } else if (searchText) {
      updateRows(1);
      setPageNo(1);
    } else {
      updateRows(pageNo);
    }
  }, [totalCount]);

  const updateRows = (value) => {
    if (value === 0) {
      setInitialRow(0);
      setFinalRow(0);
    } else {
      setInitialRow(10 * value - 9);
      if (totalCount > 10 * value) {
        setFinalRow(10 * value);
      } else {
        setFinalRow(totalCount);
      }
    }
  };
  const claimHandler = (inspectionId, inspectionStatus) => {
    setInspectionId(inspectionId);
    setInspectionStatus(inspectionStatus);
    dispatch(setNavigate(true));
    // dispatch(getClaimInspectionDetails(inspectionId));
  };
  useEffect(() => {
    dispatch(setNavigate(false));
  }, []);

  useEffect(() => {
    if (navigation && inspectionStatus) {
      if (inspectionStatus === "COMPLETED") {
        navigate(`/inspection/completed/${inspectionId}`, {
          state: inspectionId,
        });
      }
    }
  }, [navigation]);
  return (
    <>
      {isLoading || !isLoadingComplete ? (
        <Loader />
      ) : (
        <MainContainer>
          <Header container xs={12} sm={12} md={12} lg={12} xl={12} mobile={12}>
            <ClaimContainer item xs={6} sm={6} md={6} lg={6} xl={6} mobile={12}>
              Closed Claims
            </ClaimContainer>
            <DropdownGrid item xs={6} sm={6} md={6} lg={6} xl={6} mobile={12}>
              <FormControl>
                <InputLabel>
                  <span
                    style={{ fontFamily: "OpenSans Regular", fontSize: "10px" }}
                  >
                    Status{" "}
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  value={status}
                  style={{ width: "130px", marginRight: "33px" }}
                >
                  {STATUS_DATA.map(({ value, name }, index) => (
                    <MenuItem
                      key={index}
                      value={value}
                      onClick={(e) => handleStatusMenu(e)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>
                  <span
                    style={{ fontFamily: "OpenSans Regular", fontSize: "10px" }}
                  >
                    Requestor{" "}
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  value={requestor}
                  style={{ width: "130px", marginRight: "33px" }}
                  onChange={(e) => handleAdjudicatorMenu(e)}
                >
                  <MenuItem value="">{"ALL"}</MenuItem>
                  {list?.adjudicatorList?.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>
                  <span
                    style={{ fontFamily: "OpenSans Regular", fontSize: "10px" }}
                  >
                    Shop
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  value={shop}
                  style={{ width: "130px" }}
                  onChange={(e) => handleShopMenu(e)}
                >
                  <MenuItem value="">{"ALL"}</MenuItem>
                  {list?.shopList?.map((data, index) => (
                    <MenuItem
                      key={index}
                      value={data}
                      onClick={(e) => handleShopMenu(e)}
                    >
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DropdownGrid>
          </Header>
          <HeaderContainer container direction="row">
            <HeaderTextContainer
              className="headerStatus"
              item
              xs={12}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              mobile={12}
            >
              Status
              <ImageContainer className="sortImage">
                {" "}
                {reverse ? (
                  <img
                    src={Sort}
                    style={{ transform: "scale(1)" }}
                    onClick={handleSortChange}
                  />
                ) : (
                  <img
                    src={Sort}
                    style={{ transform: "scale(-1)" }}
                    onClick={handleSortChange}
                  />
                )}
              </ImageContainer>
            </HeaderTextContainer>
            <HeaderTextContainer
              className="totaltime"
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              mobile={12}
            >
              Total Time
            </HeaderTextContainer>
            <HeaderTextContainer
              item
              xs={12}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              mobile={12}
            >
              Time in status
            </HeaderTextContainer>
            <HeaderTextContainer
              className="headerClaim"
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              mobile={12}
            >
              Claim #
            </HeaderTextContainer>
            <HeaderTextContainer
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              mobile={12}
              style={{ paddingLeft: "3%" }}
            >
              RO #
            </HeaderTextContainer>
            <HeaderTextContainer
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              mobile={12}
            >
              Adjudicator
            </HeaderTextContainer>
            <HeaderTextContainer
              item
              xs={12}
              sm={1.5}
              md={1.5}
              lg={1.5}
              xl={1.5}
              mobile={12}
            >
              Shop
            </HeaderTextContainer>
            <HeaderTextContainer
              item
              xs={12}
              sm={0.5}
              md={0.5}
              lg={0.5}
              xl={0.5}
              mobile={12}
            >
              Action
            </HeaderTextContainer>
          </HeaderContainer>
          <BodyGridContainer container direction="row">
            {fetchedClosedClaimData?.length > 0 ? (
              fetchedClosedClaimData?.map((data, index) => (
                <>
                  <BodyTextContainer
                    className="bodyStatus"
                    item
                    xs={12}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    mobile={12}
                    key={index}
                  >
                    {data.status}
                  </BodyTextContainer>
                  <BodyTextContainer
                    className="bodyTime"
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    mobile={12}
                  >
                    {data.totalDays}
                  </BodyTextContainer>
                  <BodyTextContainer
                    item
                    xs={12}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    mobile={12}
                  >
                    {data.inStatus}
                  </BodyTextContainer>
                  <BodyTextContainer
                    className="bodyClaim"
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    mobile={12}
                  >
                    {data.claimNo.replace(/CLAIM-/, "")}
                  </BodyTextContainer>
                  <BodyTextContainer
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    mobile={12}
                    style={{ paddingLeft: "3%" }}
                  >
                    {data.req_number}
                  </BodyTextContainer>
                  <BodyTextContainer
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    mobile={12}
                  >
                    {data.adjudicator}
                  </BodyTextContainer>
                  <BodyTextContainer
                    item
                    xs={12}
                    sm={1.5}
                    md={1.5}
                    lg={1.5}
                    xl={1.5}
                    mobile={12}
                  >
                    {data.shop}
                  </BodyTextContainer>
                  <BodyTextContainer
                    item
                    xs={12}
                    sm={0.5}
                    md={0.5}
                    lg={0.5}
                    xl={0.5}
                    mobile={12}
                    key={index}
                    onClick={() =>
                      claimHandler(data.inspection_id, data.status)
                    }
                  >
                    {data.status === "COMPLETED" && data.actionSign === true ? (
                      <img
                        style={{
                          marginLeft: "17px",
                          width: "24px",
                        }}
                        src={view}
                        alt="view"
                      />
                    ) : (
                      ""
                    )}
                  </BodyTextContainer>
                </>
              ))
            ) : (
              <BodyTextContainer
                className="bodyStatus"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mobile={12}
                style={{ boxShadow: "0px -1px 0px rgb(20 20 20 / 20%)" }}
              >
                No closed inspections found.
              </BodyTextContainer>
            )}
          </BodyGridContainer>

          {totalCount > 0 ? (
            <PaginationContainer
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mobile={12}
            >
              <PageText>
                Showing results {initialRow} - {finalRow} from {totalCount}
              </PageText>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={pageNo}
                showFirstButton
                showLastButton
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      first: KeyboardDoubleArrowLeftIcon,
                      last: KeyboardDoubleArrowRightIcon,
                    }}
                    {...item}
                  />
                )}
                onChange={(e, value) => handlePageChange(e, value)}
              ></Pagination>
            </PaginationContainer>
          ) : null}
        </MainContainer>
      )}
      {isError && <ErrorHandle message={errorMessage} />}
    </>
  );
};
export default ClosedScreen;
