import { ISFOOTEREQ } from "./types";

const initialSate = {
  condition: {
    isFooterReq: true,
  },
};

const FooterReducer = (state = initialSate, action) => {
  switch (action.type) {
    case ISFOOTEREQ:
      return {
        ...state,
        condition: action.payload,
      };

    default:
      return state;
  }
};
export default FooterReducer;
