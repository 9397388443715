import * as types from "./types";

const initialState = {
  claimInspectionDetails: [],
  resendInspection: [],
  currentClaim: [],
  searchPartList: [],
  error: null,
  enableAdd: false,
  inspectionRequest: [],
  enableNewInspection: false,
  enableEdit: false,
  downloadFiles: null,
  editComplete: false,
  acceptInspection: null,
  retakeRequest: null,
  retakeComment: [],
  change: false,
  requestMoreDetails: [],
  navigate: false,
  accept: false,
  reset: false,
  allDownloadedFiles: [{ name: "", asset: "" }],
  isUpdateComplete: false,
  retake: false,
  retakeSuccess: false,
  searchlistFailure: false,
  isError: false,
  retakeError: null,
  inspectionStarted: false,
  enableAcceptInspection: false,
  closeInspection: "",
  closeInspectionSuccess: {},
  closeInspectionFailure: {},
};

const updatePreviewImageHandler = (prevState, i, j, readerData) => {
  const updateState = [...prevState];
  const updateStateIndex = { ...updateState[i] };

  const updateAsset = { ...updateStateIndex.asset[j] };
  updateAsset.preview = readerData;
  updateState[i].asset[j] = updateAsset;
  updateStateIndex.asset[j] = updateAsset;
  updateState[i] = updateStateIndex;
  return updateState;
};

export const ClaimInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLAIM_INSPECTION_DETAILS:
      return {
        ...state,
        claimInspectionDetails: [],
      };

    case types.GET_CLAIM_INSPECTION_DETAILS_SUCCESS:
      return {
        ...state,
        claimInspectionDetails: action.payload,
      };
    case types.GET_CLAIM_INSPECTION_DETAILS_FAILURE:
      return {
        ...state,
        claimInspectionDetails: action.error,
        error: action.payload,
        isError: action.payload,
      };
    case types.CREATE_NEW_INPECTION_REQUEST_SUCCESS:
      return {
        ...state,
        claimInspectionDetails: [],
      };
    case types.CREATE_NEW_INPECTION_REQUEST_FAILURE:
      return {
        ...state,
        claimInspectionDetails: action.error,
        error: action.payload,
        isError: action.payload,
      };

    case types.RESEND_INSPECTION:
      return {
        ...state,
        resendInspection: [],
      };
    case types.RESEND_INSPECTION_SUCCESS:
      return {
        ...state,
        resendInspection: action.payload,
        claimInspectionDetails: [],
      };
    case types.RESEND_INSPECTION_FAILURE:
      return {
        ...state,
        resendInspection: action.error,
        error: action.payload,
        isError: action.payload,
      };
    case types.SET_CURRENT_CLAIM:
      return {
        ...state,
        currentClaim: action.payload,
      };
    case types.GET_SEARCH_PART_LIST_SUCCESS:
      return {
        ...state,
        searchPartList: action.payload,
        searchlistFailure: false,
      };
    case types.GET_SEARCH_PART_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        enableAdd: true,
        isError: action.payload,
        searchlistFailure: true,
      };
    case types.SET_PART:
      return {
        ...state,
        inspectionRequest: [action.payload, ...state.inspectionRequest],
        enableNewInspection: false,
      };
    case types.SET_REQUESTED_IMAGE_COUNT:
      return {
        ...state,
        inspectionRequest: state.inspectionRequest.map((data) =>
          data.name === action.payload.name
            ? { ...data, min_image: parseInt(action.payload.image_Count) }
            : data
        ),
        requestMoreDetails: state.requestMoreDetails.map((data) =>
          data.name === action.payload.name
            ? { ...data, min_image: parseInt(action.payload.image_Count) }
            : data
        ),
      };
    case types.SET_REQUESTED_VIDEO_COUNT:
      return {
        ...state,
        inspectionRequest: state.inspectionRequest.map((data) =>
          data.name === action.payload.name
            ? { ...data, min_video: parseInt(action.payload.video_Count) }
            : data
        ),
        requestMoreDetails: state.requestMoreDetails.map((data) =>
          data.name === action.payload.name
            ? { ...data, min_video: parseInt(action.payload.video_Count) }
            : data
        ),
      };
    case types.SET_REQUESTED_LEAK:
      return {
        ...state,

        inspectionRequest: state.inspectionRequest.map((data) =>
          data.name === action.payload.name
            ? { ...data, leak: action.payload.leak }
            : data
        ),
        requestMoreDetails: state.requestMoreDetails.map((data) =>
          data.name === action.payload.name
            ? { ...data, leak: action.payload.leak }
            : data
        ),
      };
    case types.SET_REQUESTED_NOISE:
      return {
        ...state,

        inspectionRequest: state.inspectionRequest.map((data) =>
          data.name === action.payload.name
            ? { ...data, noise: action.payload.noise }
            : data
        ),
        requestMoreDetails: state.requestMoreDetails.map((data) =>
          data.name === action.payload.name
            ? { ...data, noise: action.payload.noise }
            : data
        ),
      };
    case types.SET_REQUESTED_OTHER:
      return {
        ...state,

        inspectionRequest: state.inspectionRequest.map((data) =>
          data.name === action.payload.name
            ? { ...data, other_failure: action.payload.other }
            : data
        ),
        requestMoreDetails: state.requestMoreDetails.map((data) =>
          data.name === action.payload.name
            ? { ...data, other_failure: action.payload.other }
            : data
        ),
      };
    case types.SET_REQUESTED_DESCRIPTION:
      return {
        ...state,

        inspectionRequest: state.inspectionRequest.map((data) =>
          data.name === action.payload.name
            ? { ...data, instructions: action.payload.description }
            : data
        ),
        requestMoreDetails: state.requestMoreDetails.map((data) =>
          data.name === action.payload.name
            ? { ...data, instructions: action.payload.description }
            : data
        ),
      };
    case types.SET_VIDEO_PHOTO_FLAG:
      return {
        ...state,
        inspectionRequest: state.inspectionRequest.map((data) =>
          data.name === action.payload.name
            ? { ...data, attachment_type: action.payload.attachment }
            : data
        ),
        requestMoreDetails: state.requestMoreDetails.map((data) =>
          data.name === action.payload.name
            ? { ...data, attachment_type: action.payload.attachment }
            : data
        ),
      };
    case types.DELETE_REQUESTED_PART:
      return {
        ...state,
        inspectionRequest: state.inspectionRequest.filter(
          (data) => data.name !== action.payload
        ),
        requestMoreDetails: state.requestMoreDetails.filter(
          (data) => data.name !== action.payload
        ),
      };
    case types.ENABLE_REQUEST_NEW_INSPECTION_SUCCESS:
      return {
        ...state,
        enableNewInspection: action.payload,
      };
    case types.ENABLE_EDIT:
      return {
        ...state,
        enableEdit: action.payload,
      };
    case types.DOWNLOAD_CLAIMS_FILES_SUCCESS:
      return {
        ...state,
        downloadFiles: action.payload,
      };
    case types.DOWNLOAD_CLAIMS_FILES_FAILURE:
      return {
        ...state,
        downloadFiles: action.error,
        error: action.payload,
        isError: action.payload,
      };
    case types.SET_REQUESTED:
      return {
        ...state,
        inspectionRequest: action.payload,
      };
    case types.EDIT_REQUEST_ATTACHMENT_SUCCESSFUL:
      return {
        ...state,
        enableEdit: false,
        editComplete: action.payload,
        claimInspectionDetails: [],
      };
    case types.EDIT_REQUEST_ATTACHMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.ACCEPT_INSPECTION_SUCCCESS:
      return {
        ...state,
        acceptInspection: action.payload,
        accept: false,
      };
    case types.ACCEPT_INSPECTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.RETAKE_REQUEST_SUCCESS:
      return {
        ...state,
        retakeRequest: action.payload,
        retake: false,
        retakeSuccess: true,
        retakeError: null,
        retakeComment: [],
        enableAcceptInspection: true,
      };
    case types.RETAKE_REQUEST_FAILURE:
      return {
        ...state,
        retakeError: action.payload,
      };
    case types.GET_RETAKE_REQUEST_COMMENT_SUCCESS:
      return {
        ...state,
        retakeComment: action.payload,
      };
    case types.GET_RETAKE_REQUEST_COMMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
        retakeComment: {},
      };
    case types.SET_MORE_DETAILS:
      return {
        ...state,
        requestMoreDetails: [action.payload, ...state.requestMoreDetails],
        enableNewInspection: false,
      };
    case types.SET_NAVIGATE:
      return {
        ...state,
        navigate: action.payload,
      };
    case types.SET_ACCEPT:
      return {
        ...state,
        accept: action.payload,
      };
    case types.RESET_CLAIM_DETAILS:
      return {
        ...state,
        reset: action.payload,
        inspectionRequest: [],
        enableNewInspection: false,
        claimInspectionDetails: [],
        currentClaim: [],
        isUpdateComplete: false,
        downloadFiles: null,
      };
    case types.UPDATE_IS_COMPLETED:
      return {
        ...state,
        isUpdateComplete: action.payload,
      };
    case types.UPDATE_ALL_PREVIEW_IMAGES: {
      return {
        ...state,
        claimInspectionDetails: {
          ...state.claimInspectionDetails,
          attachment_requests_attributes: action.payload,
        },
        isUpdateComplete: true,
      };
    }

    case types.SET_RETAKE_REQUEST:
      return {
        ...state,
        retake: action.payload,
        retakeError: null,
        retakeComment: [],
      };
    case types.RETAKE_REQUEST_SEND:
      return {
        ...state,
        retakeSuccess: action.payload,
      };
    case types.SET_UPDATE_COMPELTE:
      return {
        ...state,
        isUpdateComplete: action.payload,
      };
    case types.HANDLE_ERROR:
      return {
        ...state,
        isError: false,
        error: null,
      };
    case types.SET_INSPECTION_STARTED:
      return {
        ...state,
        inspectionStarted: action.payload,
      };
    case types.RESET_REQUEST_MORE_INSPECTION:
      return {
        ...state,
        requestMoreDetails: [],
      };
    case types.ENABLE_ACCEPT_INSPECTION:
      return {
        ...state,
        enableAcceptInspection: action.payload,
      };
    case types.CLOSE_INSPECTION_VALUES:
      return {
        ...state,
        closeInspection: action.payload,
      };
    case types.CLOSE_INSPECTION_VALUES_SUCCESS:
      return {
        ...state,
        closeInspectionSuccess: action.payload,
      };
    case types.CLOSE_INSPECTION_VALUES_FAILURE:
      return {
        ...state,
        closeInspectionFailure: action.payload,
      };
    default:
      return state;
  }
};
