import React, { useEffect, useState } from "react";
import {
  HeaderText,
  Description,
  Shop,
  Adjudicator,
  ReminderContainer,
  Remindertext,
  Input,
  IntervalContainer,
  Interval,
  Hours,
  IntervalText,
  MainHeaderText,
  ReminderHeader,
  ToggleButton,
  ToggleText,
  Line,
  MainContainer,
  SaveButton,
  SaveText,
  ModalContainer,
  ButtonContainer,
  SaveHeader,
  SaveDescription,
  Header,
} from "./styled";
import ErrorHandle from "../../shared/commonComponents/errorHandle/errorHandle";
import Toggle from "../../shared/commonComponents/toggle/toggle";
import { headerType } from "../../store/sharedComponents/header/action";
import { isFooterReq } from "../../store/sharedComponents/footer/action";
import { Dialog } from "@mui/material";
import Loader from "../../shared/commonComponents/loader/loader";
import SessionStorageService from "../../services/functional/accounts/login/storage";
import {
  showLoader,
  hideLoader,
} from "../../store/sharedComponents/loader/action";
import {
  getRemindersDetails,
  updateReminderDetails,
  isRemainderUpdate,
  checkDataSaved,
  restoreReminderDetails,
  openSaveReminderPopUp,
} from "../../store/reminders/actions";
import { useDispatch, useSelector } from "react-redux";
import close from "../../../src/assets/images/close.svg";
import { data } from "../../store/reminders/reminder.js";
import { CoPresentOutlined } from "@mui/icons-material";

const Reminder = (props) => {
  const dispatch = useDispatch();
  const remindersdata = useSelector(
    (state) => state.reminderReducers.remindersdata
  );
  const openReminderPopUp = useSelector(
    (state) => state.reminderReducers.openReminderPopUp
  );
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const [value, setValue] = useState({});
  const [showSave, setShowSave] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isError = useSelector((state) => state.reminderReducers?.isError);
  const errorMessage = useSelector(
    (state) => state.reminderReducers?.error?.message
  );
  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);
  const isDataChanged = useSelector(
    (state) => state.reminderReducers?.isDataChanged
  );

  useEffect(() => {
    dispatch(
      headerType({
        isSearch: false,
        isHeaderVi: false,
        isHamburger: true,
        isActiveClose: false,
        isLogo: true,
      })
    );
    dispatch(showLoader());
    dispatch(getRemindersDetails(userDetails.client_id));
    dispatch(isFooterReq({ isFooterReq: true }));
    const timer = setTimeout(() => dispatch(hideLoader()), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(hideLoader());
  }, [remindersdata]);

  const restoreRemnider = () => {
    dispatch(restoreReminderDetails(remindersdata));
    setValue(remindersdata);
    CloseSave();
  };
  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(remindersdata, "clientId")) {
      setValue(remindersdata);
    } else if (
      remindersdata?.data === "No Data found for the given Client ID."
    ) {
      data.clientId = userDetails.client_id;
      data.name = userDetails.name;
      setValue(data);
      dispatch(updateReminderDetails(data, "post", userDetails.client_id));
    }
  }, [remindersdata]);

  useEffect(() => {
    return () => {
      setShowSave(true);
    };
  }, [openReminderPopUp]);

  const OpenSave = () => {
    setShowSave(true);
  };

  const CloseSave = () => {
    setShowSave(false);
    dispatch(checkDataSaved(false));
    dispatch(openSaveReminderPopUp(false));
    dispatch(
      headerType({
        isSearch: false,
        isHeaderVi: false,
        isHamburger: true,
        isActiveClose: false,
        isLogo: true,
      })
    );
  };

  const updateReminder = () => {
    if (
      Object.prototype.hasOwnProperty.call(remindersdata, "clientId") ||
      remindersdata?.status === true ||
      remindersdata === "Reminder Updated Succesfully!!"
    ) {
      dispatch(showLoader());
      dispatch(updateReminderDetails(value, "put", userDetails.client_id));
      const timer = setTimeout(() => dispatch(hideLoader()), 1000);
      CloseSave();
      setAnchorEl(null);
      return () => clearTimeout(timer);
    } else {
      dispatch(showLoader());
      dispatch(updateReminderDetails(value, "post", userDetails.client_id));
    }
    CloseSave();
    setAnchorEl(null);
  };

  const handleChange = (e, name) => {
    setDataSaved(true);
    let dropdownValue;
    let checkedValues;
    let checked;
    if (name === "dropdownValues") {
      dropdownValue = e.target.value;
    } else if (name === "time") {
      if (e.target.value > 0 && e.target.value < 25) {
        checkedValues = parseInt(e.target.value);
      }
    } else if (name === "firstReminder") {
      checked = e.target.checked;
      if (!checked) {
        checkedValues = 0;
      }
    }
    setValue((prevState) => ({
      ...prevState,
      shop: {
        final_reminder: prevState?.shop?.final_reminder,
        first_reminder: {
          time: checkedValues || prevState?.shop?.first_reminder.time,
          type: dropdownValue || prevState?.shop?.first_reminder.type,
          toggleValue:
            checked === false
              ? checked
              : checked || prevState?.shop?.first_reminder.toggleValue,
        },
      },
    }));
    dispatch(checkDataSaved(true));
    console.log("First reninder changed");
  };

  const handleChangeFinal = (e, name) => {
    let dropdownFinal;
    let checkedValuesFinal;
    let checkedFinal;
    if (name === "dropdownFinal") {
      dropdownFinal = e.target.value;
    } else if (name === "timeFinal") {
      if (e.target.value > 0 && e.target.value < 25) {
        checkedValuesFinal = parseInt(e.target.value);
      }
    } else if (name === "finalReminder") {
      checkedFinal = e.target.checked;
      if (!checkedFinal) {
        checkedValuesFinal = 0;
      }
    }
    setValue((prevState) => ({
      ...prevState,
      shop: {
        first_reminder: prevState?.shop?.first_reminder,
        final_reminder: {
          time: checkedValuesFinal || prevState?.shop?.final_reminder.time,
          type: dropdownFinal || prevState?.shop?.final_reminder?.type,
          toggleValue:
            checkedFinal === false
              ? checkedFinal
              : checkedFinal || prevState?.shop?.final_reminder?.toggleValue,
        },
      },
    }));
    dispatch(checkDataSaved(true));
  };

  const handleChangeReview = (e, name) => {
    let checkedReview;
    if (name === "readyForReviewAdjudicator") {
      checkedReview = e.target.checked;
    }
    setValue((prevState) => ({
      ...prevState,
      adjudicator: {
        shop_late: prevState?.adjudicator?.shop_late,
        first_reminder: prevState?.adjudicator?.first_reminder,
        second_reminder: prevState?.adjudicator?.second_reminder,

        readyForReview:
          checkedReview === false
            ? checkedReview
            : checkedReview || prevState?.adjudicator?.readyForReview,
      },
    }));
    dispatch(checkDataSaved(true));
  };

  const handleChangeAdjFirst = (e, name) => {
    let checkedAdjFirst;
    let dropdownAdjFirst;
    let checkedValuesAdjFirst;
    if (name === "dropdownAdjFirst") {
      dropdownAdjFirst = e.target.value;
    } else if (name === "typeAdjFirst") {
      if (e.target.value > 0 && e.target.value < 25) {
        checkedValuesAdjFirst = parseInt(e.target.value);
      }
    } else if (name === "reminderAdjFirst") {
      checkedAdjFirst = e.target.checked;
      if (!checkedAdjFirst) {
        checkedValuesAdjFirst = 0;
      }
    }
    setValue((prevState) => ({
      ...prevState,
      adjudicator: {
        readyForReview: prevState?.adjudicator?.readyForReview,
        second_reminder: prevState?.adjudicator?.second_reminder,
        shop_late: prevState?.adjudicator?.shop_late,
        first_reminder: {
          time:
            checkedValuesAdjFirst ||
            prevState?.adjudicator?.first_reminder?.time,
          type:
            dropdownAdjFirst || prevState?.adjudicator?.first_reminder?.type,
          toggleValue:
            checkedAdjFirst === false
              ? checkedAdjFirst
              : checkedAdjFirst ||
                prevState?.adjudicator?.first_reminder?.toggleValue,
        },
      },
    }));
    dispatch(checkDataSaved(true));
  };

  const handleChangeSecond = (e, name) => {
    let checkedSecond;
    let dropdownSecond;
    let checkedValuesSecond;
    if (name === "dropdownSecond") {
      dropdownSecond = e.target.value;
    } else if (name === "timeSecond") {
      if (e.target.value > 0 && e.target.value < 25) {
        checkedValuesSecond = parseInt(e.target.value);
      }
    } else if (name === "reminderSecond") {
      checkedSecond = e.target.checked;
      if (!checkedSecond) {
        checkedValuesSecond = 0;
      }
    }
    setValue((prevState) => ({
      ...prevState,
      adjudicator: {
        readyForReview: prevState?.adjudicator?.readyForReview,
        first_reminder: prevState?.adjudicator?.first_reminder,
        shop_late: prevState?.adjudicator?.shop_late,
        second_reminder: {
          time:
            checkedValuesSecond ||
            prevState?.adjudicator?.second_reminder?.time,
          type: dropdownSecond || prevState?.adjudicator?.second_reminder?.type,
          toggleValue:
            checkedSecond === false
              ? checkedSecond
              : checkedSecond ||
                prevState?.adjudicator?.second_reminder?.toggleValue,
        },
      },
    }));
    dispatch(checkDataSaved(true));
  };

  const handleChangeShopLate = (e, name) => {
    let checkedShopLate;
    let dropdownShopLate;
    let checkedValuesShopLate;
    if (name === "dropdownLate") {
      dropdownShopLate = e.target.value;
    } else if (name === "timeLate") {
      if (e.target.value > 0 && e.target.value < 25) {
        checkedValuesShopLate = parseInt(e.target.value);
      }
    } else if (name === "reminderLate") {
      checkedShopLate = e.target.checked;
      if (!checkedShopLate) {
        checkedValuesShopLate = 0;
      }
    }

    setValue((prevState) => ({
      ...prevState,
      adjudicator: {
        readyForReview: prevState?.adjudicator?.readyForReview,
        first_reminder: prevState?.adjudicator?.first_reminder,
        second_reminder: prevState?.adjudicator?.second_reminder,
        shop_late: {
          time:
            checkedValuesShopLate || prevState?.adjudicator?.shop_late?.time,
          type: dropdownShopLate || prevState?.adjudicator?.shop_late?.type,
          toggleValue:
            checkedShopLate === false
              ? checkedShopLate
              : checkedShopLate ||
                prevState?.adjudicator?.shop_late?.toggleValue,
        },
      },
    }));
    dispatch(checkDataSaved(true));
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <MainContainer>
          <ReminderHeader>
            <MainHeaderText>Reminders</MainHeaderText>
            <Description>
              Time intervals are based from the time of initial request
              {isDataChanged && (
                <SaveButton
                  marginLeft="20px"
                  onClick={updateReminder}
                  className="mainSave"
                >
                  <SaveText>Save</SaveText>
                </SaveButton>
              )}
              {showSave && (
                <Dialog
                  onClose={CloseSave}
                  open={openReminderPopUp}
                  minWidth={"sm"}
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        overflow: "hidden",
                        backgroundColor: "transparent !important",
                        boxShadow: "none",
                      },
                    },
                  }}
                >
                  <Header>
                    <img
                      src={close}
                      onClick={restoreRemnider}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Header>
                  <div
                    style={{
                      marginLeft: "20px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <ModalContainer>
                      <SaveHeader>Save Your Changes ?</SaveHeader>
                      <SaveDescription>
                        The changes you made have not been saved.
                      </SaveDescription>
                      <ButtonContainer
                        container
                        direction="row"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        mobile={12}
                        justifyContent={"space-between"}
                      >
                        <SaveButton
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          mobile={6}
                          onClick={updateReminder}
                          onClose={CloseSave}
                        >
                          <SaveText>Save</SaveText>
                        </SaveButton>
                        <SaveButton
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          mobile={6}
                          onClick={restoreRemnider}
                          onClose={CloseSave}
                          background={"#FFFFFF"}
                          marginLeft={"40%"}
                        >
                          <SaveText color={"#252525"}>Dont Save</SaveText>
                        </SaveButton>
                      </ButtonContainer>
                    </ModalContainer>
                  </div>
                </Dialog>
              )}
            </Description>
          </ReminderHeader>
          <ReminderContainer
            container
            justifyContent={"space-around"}
            direction="row"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mobile={12}
          >
            <Shop item xs={12} sm={4} md={4} lg={4} xl={4} mobile={12}>
              <div>
                <HeaderText
                  style={{
                    fontFamily: "OpenSans Bold",
                    color: "#3D515A",
                    fontSize: "14px",
                  }}
                >
                  Shop
                </HeaderText>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Remindertext>First Reminder</Remindertext>
                  <ToggleButton>
                    <Toggle
                      checked={value?.shop?.first_reminder?.toggleValue}
                      name={"firstReminder"}
                      value={value?.shop?.first_reminder?.toggleValue}
                      onChange={(event) => handleChange(event, "firstReminder")}
                    />
                    <ToggleText style={{ marginTop: "0px", marginLeft: "5px" }}>
                      {value?.shop?.first_reminder?.toggleValue ? "on" : "off"}
                    </ToggleText>
                  </ToggleButton>
                </div>
                <div style={{ display: "flex" }}>
                  <IntervalContainer>
                    <Input
                      type="number"
                      id="quantity"
                      name="timeFirst"
                      min="1"
                      max="24"
                      value={
                        value?.shop?.first_reminder?.toggleValue
                          ? value?.shop?.first_reminder?.time
                          : "--"
                      }
                      disabled={
                        value?.shop?.first_reminder?.toggleValue ? false : true
                      }
                      onChange={(event) => handleChange(event, "time")}
                    ></Input>
                  </IntervalContainer>
                  <IntervalContainer marginLeft="19px">
                    <IntervalText>Interval</IntervalText>
                    <Hours
                      name="dropdownValues"
                      id="cars"
                      onChange={(event) =>
                        handleChange(event, "dropdownValues")
                      }
                      value={value?.shop?.first_reminder?.type}
                      disabled={
                        value?.shop?.first_reminder?.toggleValue ? false : true
                      }
                    >
                      <option value="hours">hours</option>
                      <option value="days">days</option>
                    </Hours>
                  </IntervalContainer>
                </div>
                <Line></Line>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Remindertext>Final Reminder</Remindertext>
                <ToggleButton>
                  <Toggle
                    checked={value?.shop?.final_reminder?.toggleValue}
                    name={"finalReminder"}
                    value={value?.shop?.final_reminder?.toggleValue}
                    onChange={(event) =>
                      handleChangeFinal(event, "finalReminder")
                    }
                  />
                  <ToggleText>
                    {value?.shop?.final_reminder?.toggleValue ? "on" : "off"}
                  </ToggleText>
                </ToggleButton>
              </div>
              <div style={{ display: "flex" }}>
                <IntervalContainer>
                  <Input
                    type="number"
                    id="quantity"
                    /* start updated by pratikasha */
                    aria-label="final reminder label"
                    /* end */
                    name="timeFinal"
                    min="1"
                    max="24"
                    value={
                      value?.shop?.final_reminder?.toggleValue
                        ? value?.shop?.final_reminder?.time
                        : "--"
                    }
                    onChange={(event) => handleChangeFinal(event, "timeFinal")}
                  ></Input>
                </IntervalContainer>
                <IntervalContainer marginLeft={"19px"}>
                  <IntervalText>Interval</IntervalText>
                  <Hours
                    name="dropdownFinal"
                    id="cars"
                    onChange={(event) =>
                      handleChangeFinal(event, "dropdownFinal")
                    }
                    value={value?.shop?.final_reminder?.type}
                    disabled={
                      value?.shop?.final_reminder?.toggleValue ? false : true
                    }
                  >
                    <option value="hours">hours</option>
                    <option value="days">days</option>
                  </Hours>
                </IntervalContainer>
              </div>
              <Line></Line>
            </Shop>

            <Shop item xs={12} sm={1} md={1} lg={1} xl={1} mobile={12}></Shop>

            <Adjudicator item xs={12} sm={4} md={4} lg={4} xl={4} mobile={12}>
              <HeaderText
                style={{
                  fontFamily: "OpenSans Bold",
                  color: "#3D515A",
                  fontSize: "14px",
                  marginTop: "12px",
                }}
              >
                Adjudicator
              </HeaderText>

              <div style={{}}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Remindertext>Ready for Review</Remindertext>
                  <ToggleButton>
                    <Toggle
                      checked={value?.adjudicator?.readyForReview}
                      name={"readyForReviewAdjudicator"}
                      value={value?.adjudicator?.readyForReview}
                      onChange={(event) =>
                        handleChangeReview(event, "readyForReviewAdjudicator")
                      }
                    />
                    <ToggleText style={{ marginTop: "0px", marginLeft: "5px" }}>
                      {value?.adjudicator?.readyForReview ? "on" : "off"}
                    </ToggleText>
                  </ToggleButton>
                </div>
                <Line></Line>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Remindertext>First Reminder</Remindertext>
                  <ToggleButton>
                    <Toggle
                      checked={value?.adjudicator?.first_reminder?.toggleValue}
                      name={"reminderAdjFirst"}
                      value={value?.adjudicator?.first_reminder?.toggleValue}
                      onChange={(event) =>
                        handleChangeAdjFirst(event, "reminderAdjFirst")
                      }
                    />
                    <ToggleText style={{ marginTop: "0px", marginLeft: "5px" }}>
                      {value?.adjudicator?.first_reminder?.toggleValue
                        ? "on"
                        : "off"}
                    </ToggleText>
                  </ToggleButton>
                </div>
                <div style={{ display: "flex" }}>
                  <IntervalContainer>
                    <Input
                      type="number"
                      id="quantity"
                      /* start updated by pratikasha */
                      aria-label="first reminder label"
                      /* end */
                      name="typeAdjFirst"
                      min="1"
                      max="24"
                      value={
                        value?.adjudicator?.first_reminder?.toggleValue
                          ? value?.adjudicator?.first_reminder?.time
                          : "--"
                      }
                      onChange={(event) =>
                        handleChangeAdjFirst(event, "typeAdjFirst")
                      }
                    ></Input>
                  </IntervalContainer>
                  <IntervalContainer marginLeft={"19px"}>
                    <IntervalText>Interval</IntervalText>
                    <Hours
                      name="dropdownAdjFirst"
                      id="cars"
                      onChange={(event) =>
                        handleChangeAdjFirst(event, "dropdownAdjFirst")
                      }
                      value={value?.adjudicator?.first_reminder?.type}
                      disabled={
                        value?.adjudicator?.first_reminder?.toggleValue
                          ? false
                          : true
                      }
                    >
                      <option value="hours">hours</option>
                      <option value="days">days</option>
                    </Hours>
                  </IntervalContainer>
                </div>
                <Line></Line>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Remindertext>Second Reminder</Remindertext>
                  <ToggleButton>
                    <Toggle
                      checked={value?.adjudicator?.second_reminder?.toggleValue}
                      name={"reminderSecond"}
                      value={value?.adjudicator?.second_reminder?.toggleValue}
                      onChange={(event) =>
                        handleChangeSecond(event, "reminderSecond")
                      }
                    />
                    <ToggleText style={{ marginTop: "0px", marginLeft: "5px" }}>
                      {value?.adjudicator?.second_reminder?.toggleValue
                        ? "on"
                        : "off"}
                    </ToggleText>
                  </ToggleButton>
                </div>
                <div style={{ display: "flex" }}>
                  <IntervalContainer>
                    <Input
                      type="number"
                      id="quantity"
                      /* start updated by pratikasha */
                      aria-label="second reminder label"
                      /* end */
                      name="timeSecond"
                      min="1"
                      max="24"
                      value={
                        value?.adjudicator?.second_reminder?.toggleValue
                          ? value?.adjudicator?.second_reminder?.time
                          : "--"
                      }
                      onChange={(event) =>
                        handleChangeSecond(event, "timeSecond")
                      }
                    ></Input>
                  </IntervalContainer>
                  <IntervalContainer marginLeft={"19px"}>
                    <IntervalText>Interval</IntervalText>
                    <Hours
                      name="dropdownSecond"
                      id="cars"
                      onChange={(event) =>
                        handleChangeSecond(event, "dropdownSecond")
                      }
                      value={value?.adjudicator?.second_reminder?.type}
                      disabled={
                        value?.adjudicator?.second_reminder?.toggleValue
                          ? false
                          : true
                      }
                    >
                      <option value="hours">hours</option>
                      <option value="days">days</option>
                    </Hours>
                  </IntervalContainer>
                </div>
                <Line></Line>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Remindertext>Shop Late</Remindertext>
                  <ToggleButton>
                    <Toggle
                      checked={value?.adjudicator?.shop_late?.toggleValue}
                      name={"reminderLate"}
                      value={value?.adjudicator?.shop_late?.toggleValue}
                      onChange={(event) =>
                        handleChangeShopLate(event, "reminderLate")
                      }
                    />
                    <ToggleText style={{ marginTop: "0px", marginLeft: "5px" }}>
                      {value?.adjudicator?.shop_late?.toggleValue
                        ? "on"
                        : "off"}
                    </ToggleText>
                  </ToggleButton>
                </div>
                <div style={{ display: "flex" }}>
                  <IntervalContainer>
                    <Input
                      type="number"
                      id="quantity"
                      /* start updated by pratikasha */
                      aria-label="shop late reminder"
                      /* end */
                      name="timeLate"
                      min="1"
                      max="24"
                      value={
                        value?.adjudicator?.shop_late?.toggleValue
                          ? value?.adjudicator?.shop_late?.time
                          : "--"
                      }
                      onChange={(event) =>
                        handleChangeShopLate(event, "timeLate")
                      }
                    ></Input>
                  </IntervalContainer>
                  <IntervalContainer>
                    <IntervalText>Interval</IntervalText>
                    <Hours
                      name="dropdownLate"
                      id="cars"
                      onChange={(event) =>
                        handleChangeShopLate(event, "dropdownLate")
                      }
                      value={value?.adjudicator?.shop_late?.type}
                      disabled={
                        value?.adjudicator?.shop_late?.toggleValue
                          ? false
                          : true
                      }
                    >
                      <option value="hours">hours</option>
                      <option value="days">days</option>
                    </Hours>
                  </IntervalContainer>
                </div>
                <Line></Line>
              </div>
            </Adjudicator>
            <Shop item xs={12} sm={3} md={3} lg={3} xl={3} mobile={12}></Shop>
          </ReminderContainer>
        </MainContainer>
      )}
      {isError && <ErrorHandle message={errorMessage} />}
    </>
  );
};

export default Reminder;
