import styled from "styled-components";

export const Heading = styled.div`
  font-family: "OpenSans SemiBold";
  font-size: 18px;
  line-height: 27px;
  color: #13223c;
`;

export const SubHeading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 20px;
  padding-top: 5px;
  color: #68758b;
`;

export const Header = styled.div`
  direction: rtl;
  background-color: transparent !important;
  color: white !important;
  width: 100%;
`;

export const SubHeader = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 28px;
  color: #2972c8;

  &.heading {
    font-family: "OpenSans Regular";
    font-size: 14px;
    line-height: 24px;
    color: #68758b;
    padding-top: 10px;
  }
`;

export const Text = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 22px;
`;
