import styled from "styled-components";

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 18px;
  line-height: 25px;
  color: #13223c;
  &.errorMessage {
    color: #fe4a4a;
    text-align: center;
    margin: 10px 0 20px 0;
  }
`;

export const Content = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 26px;
  color: #3d515a;
  padding-top: 10px;
  padding-bottom: 15px;
`;

export const Text = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 22px;
`;

export const Header = styled.div`
  direction: rtl;
  background-color: transparent !important;
  color: white !important;
  width: 100%;
`;
export const Comment = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 18px;
  color: #68758b;
  padding-top: 10px;
  padding-bottom: 15px;
`;
export const CommentTime = styled.div`
  font-family: "OpenSans Normal";
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #68758b;
`;
