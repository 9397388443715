import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Stack,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  Heading,
  Line,
  Cancel,
  Box,
  PlusLogo,
  ShopName,
  Message,
} from "./styled";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";
import successImg from "../../../assets/images/success.svg";
import removeUpload from "../../../assets/images/removeUpload.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import refresh from "../../../assets/images/refresh.svg";
import Button from "../../../shared/commonComponents/button/button";
import BottomNavigation from "../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import close from "../../../assets/images/close.svg";
import { Header } from "../users/create/styled";
import add from "../../../assets/images/add.svg";
import {
  addNewShop,
  getClientList,
  fileUpload,
  clearUserStoreID,
  fileFetch,
  handleError,
  closeLogoImg,
} from "../../../store/userManagment/action";
import {
  showLoader,
  hideLoader,
} from "../../../store/sharedComponents/loader/action";
import SessionStorageService from "../../../services/functional/accounts/login/storage";

const EditShop = (props) => {
  const dispatch = useDispatch();
  const [usernameError, setUsernameError] = useState(false);
  const [shopName, setShopName] = useState(props.onShop.name);
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [checked, setChecked] = useState(props.onShop.vi_type);
  const [isUploading, setUploading] = useState(false);
  const [anyChange, setAnyChange] = useState(false);

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const fetchedUrl = useSelector(
    (state) => state.userManagmentReducer?.fileFetch?.data
  );

  const userStoreId = useSelector(
    (state) => state.userManagmentReducer?.addNewShop?.response?.data?.store_id
  );

  const handleUserName = (event, value) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    setTimeout(() => {
      if (nameRegex.test(event.target.value)) {
        setUsernameError(false);
      } else {
        setUsernameError(true);
      }
    }, 2000);
    setShopName(event.target.value);
  };

  const isError = useSelector((state) => state.userManagmentReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.userManagmentReducer?.error
  );
  const fileUploaded = useSelector(
    (state) => state.userManagmentReducer?.fileUpload
  );

  const handleErrorState = () => {
    dispatch(handleError());
  };

  const fileInput = useRef();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    if (userStoreId) {
      props.onClose(true);
    }
    return () => {
      if (props.editing === "shop") {
        dispatch(clearUserStoreID(null));
      }
    };
  }, [userStoreId]);

  useEffect(() => {
    if (fetchedUrl?.asset) {
      if (props.editing === "shop") {
        const byteCharacters = atob(fetchedUrl?.asset);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/*" });
        const myFile = URL.createObjectURL(blob);
        const file = new File([blob], "shop-logo", {
          type: "image/*",
          lastModified: Date.now(),
        });
        setImageName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
          dispatch(hideLoader());
        };
        reader.readAsDataURL(blob);
      } else {
        setPreview(null);
      }
    } else {
      setPreview(null);
    }
  }, [fetchedUrl]);

  useEffect(() => {
    dispatch(getClientList());
  }, []);

  useEffect(() => {
    if (props.onShop?.image_url) {
      dispatch(showLoader());
      dispatch(fileFetch({ link: props.onShop?.image_url }));
    }
    return () => {
      dispatch(closeLogoImg(null));
    };
  }, []);

  const uploadImageProgress = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setMessage("Uploaded");
        }
        return Math.min(oldProgress + 15, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProgress(0);
        setUploading(true);
        uploadImageProgress();
        setPreview(reader.result);
        const formData = new FormData();
        formData.append("contas", "shop-logo");
        formData.append("filetype", "images/*");
        formData.append("image", image);

        dispatch(fileUpload(formData));
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const onContinue = () => {
    const shopdata = {
      store_name: shopName.trim(),
      image_url: fileUploaded.data?.path
        ? fileUploaded.data?.path
        : props.onShop.image_url,
      vi_type: checked ? 1 : 0,
      client_id: props.onShop.client_id,
      shop_id: props.onShop?.shop_id,
      admin_id: userDetails._id,
    };
    dispatch(addNewShop(shopdata));
  };

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  const onDelete = () => {
    setPreview(null);
  };

  const onAnyChange = () => {
    if (
      props.onShop.name !== shopName ||
      fileUploaded.data?.path ||
      props.onShop.vi_type !== checked
    ) {
      setAnyChange(true);
    }
  };

  useEffect(() => {
    onAnyChange();
  }, [shopName, checked, fileUploaded.data?.path]);

  return (
    <>
      <MainContainer container>
        <Header>
          <img
            src={close}
            alt="close"
            onClick={props.onClose}
            style={{
              cursor: "pointer",
            }}
          />
        </Header>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"30px"}
          direction="row"
          style={{ backgroundColor: "#fff", justifyContent: "space-evenly" }}
        >
          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <div>
              <Heading className="new">Edit Shop</Heading>

              <div style={{ margin: "15px 0 25px 0" }}>
                <Heading className="client-shop">Shop</Heading>
                <>
                  <Stack>
                    <TextField
                      freeSolo={true}
                      error={usernameError}
                      variant="standard"
                      fullWidth
                      onChange={handleUserName}
                      value={shopName}
                      helperText={
                        usernameError ? "Please Enter Valid Name" : ""
                      }
                    />
                  </Stack>
                  <>
                    <Heading
                      style={{ marginTop: "28px" }}
                      className="client-shop"
                    >
                      Shop ID
                    </Heading>
                    <TextField
                      variant="standard"
                      fullWidth
                      value={props.onShop.shop_id}
                      InputProps={{ readOnly: true }}
                    />
                  </>
                </>

                <Heading className="mobileApp">
                  <Checkbox checked={checked} onChange={handleCheckBox} />
                  Enable Mobile App Inspections
                </Heading>
              </div>
            </div>
          </Grid>
          <Line item xs={12} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <div style={{ padding: "0 0 0 15px" }}>
              <Heading className="details">Branding Details</Heading>
              <Heading className="logo-preview">Logo Preview</Heading>

              <div style={{ display: "grid" }}>
                <div>
                  <Box className="img">
                    {preview ? (
                      <img
                        src={preview}
                        alt="img"
                        style={{
                          width: "300px",
                          height: "100px",
                        }}
                      />
                    ) : null}
                  </Box>
                </div>
                <div style={{ display: "block" }}>
                  {preview ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ShopName>{imageName} </ShopName>
                        {fileUploaded?.data?.path ? (
                          <>
                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                              }}
                            >
                              {message === "Uploaded" ? (
                                <>
                                  <div>
                                    <img
                                      src={successImg}
                                      alt="success"
                                      style={{ marginRight: "13px" }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={removeUpload}
                                      alt="delete"
                                      onClick={() => {
                                        setPreview(null);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                            }}
                          >
                            <div>
                              <img
                                src={refresh}
                                alt="refresh"
                                style={{ marginRight: "13px" }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  fileInput.current.click();
                                }}
                              />
                              <input
                                type="file"
                                style={{ display: "none" }}
                                ref={fileInput}
                                accept="image/*"
                                onChange={(event) => {
                                  const file = event.target.files[0];
                                  if (
                                    file &&
                                    file.type.substring(0, 5) === "image"
                                  ) {
                                    setImage(file);
                                  } else {
                                    setImage(null);
                                  }
                                }}
                              />
                            </div>
                            <div>
                              <img
                                src={deleteIcon}
                                alt="delete"
                                style={{ padding: "2px" }}
                                onClick={onDelete}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {preview && isUploading && (
                        <div style={{ width: "100%" }}>
                          <div>
                            <LinearProgress
                              variant="determinate"
                              color="success"
                              value={progress}
                            />
                          </div>
                          <Message>{message}</Message>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <PlusLogo>
                        <img
                          src={add}
                          alt="+logo"
                          style={{ padding: "2px" }}
                          onClick={(event) => {
                            event.preventDefault();
                            fileInput.current.click();
                          }}
                        />
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInput}
                          accept="image/*"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file && file.type.substring(0, 5) === "image") {
                              setImage(file);
                            } else {
                              setImage(null);
                            }
                          }}
                        />
                        <Heading className="logo">Logo</Heading>
                      </PlusLogo>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <BottomNavigation
          opacity={"1"}
          position={"sticky"}
          padding={"17px 30px 0 30px"}
          justifyContent={"space-between"}
          moblieJustifyContent={"space-around"}
          borderRadius={"0px 0px 4px 4px"}
          minHeight={"55px"}
          bottom={"0"}
          left={"0"}
        >
          <Cancel onClick={props.onClose}>Cancel</Cancel>

          <Button
            show={true}
            width={"120px"}
            height={"32px"}
            clicked={onContinue}
            disabled={!anyChange}
            primary={anyChange && preview ? true : false}
          >
            Save
          </Button>
        </BottomNavigation>
      </MainContainer>
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
    </>
  );
};

export default EditShop;
