import { request } from "../../../services/core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getClientServices = () => {
  return request(METHOD_TYPES.GET, ENDPOINTS.CLIENTS);
};

export const getShopOfClientServices = (payload) => {
  const params = `v1/api/stores/client/${payload.payload}`;
  return request(METHOD_TYPES.GET, params);
};

export const getShopServices = () => {
  return request(METHOD_TYPES.GET, ENDPOINTS.SHOP);
};

export const getShopListAssurantServices = () => {
  return request(METHOD_TYPES.GET, ENDPOINTS.ASSURANTSHOP);
};

export const postClientUserServices = (payload) => {
  const params = `v1/api/adjudicators/client/${payload.payload.client_id}/user`;

  return request(METHOD_TYPES.POST, params, payload.payload);
};

export const postShopServices = (data) => {
  return request(METHOD_TYPES.POST, ENDPOINTS.ADDSHOP, data.payload);
};
export const postShopUserServices = (payload) => {
  const params = `v1/api/accounts/client/${payload.payload.client_id}/shop/${payload.payload.shop_id}/user`;
  return request(METHOD_TYPES.POST, params, payload.payload);
};
export const getClientUsersServices = (payload) => {
  const params = `v1/api/adjudicators/client/${payload.payload.client_id}/users?pageNo=${payload.payload.page}&size=${payload.payload.size}&sort=${payload.payload.sortType}`;
  return request(METHOD_TYPES.GET, params);
};
export const getShopUsersServices = (payload) => {
  const params = `v1/api/accounts/client/${payload.payload.client_id}/shop/${payload.payload.shop_id}/users?pageNo=${payload.payload.page}&size=${payload.payload.size}&sort=${payload.payload.sortType}`;
  return request(METHOD_TYPES.GET, params);
};

export const sentInviteShopServices = (payload) => {
  const params = "v1/api/accounts/invite-users";
  const transcation = request(METHOD_TYPES.POST, params, {
    inviteUsersArray: payload.payload.inviteUsersArray,
  });
  return transcation;
};

export const sentInviteClientServices = (payload) => {
  const params = "v1/api/adjudicators/invite-client-users";
  const transcation = request(METHOD_TYPES.POST, params, {
    inviteUsersArray: payload.payload.inviteUsersArray,
  });
  return transcation;
};

export const deleteUsersServices = ({ payload }) => {
  const params = `v1/api/accounts/shop/delete/${payload._id}`;
  return request(METHOD_TYPES.DELETE, params, payload);
};

export const deleteClientUsersServices = ({ payload }) => {
  const params = `v1/api/adjudicators/client/delete/${payload._id}`;
  return request(METHOD_TYPES.DELETE, params, payload);
};

export const resendInviteServices = ({ payload }) => {
  const params = "v1/api/accounts/invite-users/resend";
  return request(METHOD_TYPES.POST, params, payload);
};

export const resendInviteClientServices = ({ payload }) => {
  const params = "v1/api/adjudicators/resend-invite";
  return request(METHOD_TYPES.POST, params, payload);
};

export const editUsersServices = ({ payload }) => {
  const params = `v1/api/accounts/shop/${payload.shop_id}/user/${payload.user_id}`;
  return request(METHOD_TYPES.PUT, params, payload);
};

export const editClientUsersServices = ({ payload }) => {
  const params = `v1/api/adjudicators/client/${payload.client_id}/user/${payload.user_id}`;
  return request(METHOD_TYPES.PUT, params, payload);
};

export const editClientLogoServices = ({ payload }) => {
  const params = "v1/api/clients/logo";
  return request(METHOD_TYPES.PUT, params, payload);
};

export const editShopLogoServices = ({ payload }) => {
  const params = "v1/api/stores/logo";
  return request(METHOD_TYPES.PUT, params, payload);
};

export const fileUploadServices = ({ payload }) => {
  const params = "/v1/api/files/uploadIntegration";
  return request(METHOD_TYPES.POST, params, payload);
};

export const fileFetchServices = ({ payload }) => {
  const params = "v1/api/files/downloadIntegration";
  return request(METHOD_TYPES.POST, params, payload);
};

export const shopUserMailServices = ({ payload }) => {
  const params = "v1/api/accounts/checkUserEmail";
  return request(METHOD_TYPES.POST, params, payload);
};
export const clientUserMailServices = ({ payload }) => {
  const params = "v1/api/adjudicators/checkUserEmail";
  return request(METHOD_TYPES.POST, params, payload);
};

export const searchShopFilterServices = ({ payload }) => {
  const params = `v1/api/accounts/shop/${payload.shop_id}/search?pageNo=${payload.page}&size=${payload.size}&sort=${payload.sort}&filter=${payload.search}`;
  return request(METHOD_TYPES.GET, params, payload);
};

export const searchClientFilterServices = ({ payload }) => {
  const params = `v1/api/adjudicators/client/${payload.client_id}/search?pageNo=${payload.page}&size=${payload.size}&sort=${payload.sort}&filter=${payload.search}`;
  return request(METHOD_TYPES.GET, params, payload);
};

export const deleteShopUsersServices = (payload, shopName) => {
  const params = "v1/api/accounts/remove/store";
  const data = {
    user_email: payload.user_email,
    store_name: shopName,
  };
  return request(METHOD_TYPES.POST, params, data);
};
