import { put, call, takeEvery, all } from "redux-saga/effects";
import { userManagmentServices } from "../../services/functional";
import * as types from "./types";
import * as loaderType from "../../store/sharedComponents/loader/types";

function* getClientList() {
  try {
    const response = yield call(userManagmentServices.getClientServices);

    yield put({ type: types.GET_CLIENT_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.GET_CLIENT_LIST_FAILURE, payload: error });
  }
}

function* getShopListOfClientsss(payload) {
  try {
    const response = yield call(
      userManagmentServices.getShopOfClientServices,
      payload
    );

    yield put({
      type: types.GET_SHOP_LIST_CLIENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_SHOP_LIST_CLIENT_FAILURE, payload: error });
  }
}

function* getShopList() {
  try {
    const response = yield call(userManagmentServices.getShopServices);

    yield put({ type: types.GET_SHOP_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.GET_SHOP_LIST_FAILURE, error });
  }
}

function* getShopListAssurant() {
  try {
    const response = yield call(
      userManagmentServices.getShopListAssurantServices
    );

    yield put({
      type: types.GET_SHOP_LIST_ASSURANT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_SHOP_LIST_ASSURANT_FAILURE, error });
  }
}

function* postNewUserToClient(payload) {
  try {
    const response = yield call(
      userManagmentServices.postClientUserServices,
      payload
    );

    yield put({
      type: types.ADD_NEW_USERS_TO_CLIENT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: types.ADD_NEW_USERS_TO_CLIENT_FAILURE, payload: error });
  }
}

function* postNewShop(payload) {
  try {
    const response = yield call(
      userManagmentServices.postShopServices,
      payload
    );
    const data = {
      response: response.data,
      store_name: payload.payload.store_name,
    };
    yield call(getShopListOfClientsss, { payload: payload.payload.client_id });
    yield put({ type: types.ADD_NEW_SHOP_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.ADD_NEW_SHOP_FAILURE, payload: error });
  }
}

function* postNewUserToShop(payload) {
  try {
    const response = yield call(
      userManagmentServices.postShopUserServices,
      payload
    );
    yield put({ type: types.SET_SHOPUSERS, payload: payload.payload });

    yield put({
      type: types.ADD_NEW_USERS_TO_SHOP_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: types.ADD_NEW_USERS_TO_SHOP_FAILURE, payload: error });
  }
}

function* getClientUsers(payload) {
  try {
    const response = yield call(
      userManagmentServices.getClientUsersServices,
      payload
    );

    const count = response.data.count;

    const sorted = response.data.data;

    yield put({
      type: types.GET_CLIENT_USERS_SUCCESS,
      payload: { sorted, count },
    });
  } catch (error) {
    yield put({ type: types.GET_CLIENT_USERS_FAILURE, payload: error });
  }
}

function* getShopUsers(payload) {
  try {
    const response = yield call(
      userManagmentServices.getShopUsersServices,
      payload
    );
    const sortShopUser = response.data.data;
    const count = response.data.count;
    yield put({
      type: types.GET_SHOP_USERS_SUCCESS,
      payload: { sortShopUser, count },
    });
  } catch (error) {
    yield put({ type: types.GET_SHOP_USERS_FAILURE, payload: error });
  }
}

function* sentInvitesShop(payload) {
  try {
    const response = yield call(
      userManagmentServices.sentInviteShopServices,
      payload
    );
    yield put({
      type: types.UPDATE_SHOP_USER,
      payload: true,
    });

    yield put({ type: types.SENT_INVITE_SHOP_SUCCESS, payload: "SUCCESS" });
  } catch (error) {
    yield put({ type: types.SENT_INVITE_SHOP_FAILURE, payload: error });
  }
}

function* sentInvitesClient(payload) {
  try {
    const response = yield call(
      userManagmentServices.sentInviteClientServices,
      payload
    );
    yield put({
      type: types.UPDATE_CLIENT_USER,
      payload: true,
    });
    yield put({ type: types.SENT_INVITE_CLIENT_SUCCESS, payload: "SUCCESS" });
  } catch (error) {
    yield put({ type: types.SENT_INVITE_CLIENT_FAILURE, payload: error });
  }
}

function* deleteUsers(payload) {
  try {
    const response = yield call(
      userManagmentServices.deleteUsersServices,
      payload
    );

    yield put({
      type: types.UPDATE_SHOP_USER,
      payload: true,
    });

    yield put({ type: types.DELETE_USER_SUCCESS, payload: payload.payload });
  } catch (error) {
    yield put({ type: types.DELETE_USER_FAILURE, payload: error });
  }
}

function* deleteClientUsers(payload) {
  try {
    const response = yield call(
      userManagmentServices.deleteClientUsersServices,
      payload
    );

    yield put({
      type: types.UPDATE_CLIENT_USER,
      payload: true,
    });

    yield put({
      type: types.DELETE_CLIENT_USER_SUCCESS,
      payload: payload.payload,
    });
  } catch (error) {
    yield put({ type: types.DELETE_CLIENT_USER_FAILURE, payload: error });
  }
}

function* resendInvites(payload) {
  try {
    const response = yield call(
      userManagmentServices.resendInviteServices,
      payload
    );

    yield put({ type: types.RESEND_INVITE_SUCCESS, payload: "SUCCESS" });
  } catch (error) {
    yield put({ type: types.RESEND_INVITE_FAILURE, payload: error });
  }
}

function* resendInvitesClient(payload) {
  try {
    const response = yield call(
      userManagmentServices.resendInviteClientServices,
      payload
    );

    yield put({
      type: types.RESEND_INVITE_CLIENT_SUCCESS,
      payload: "SUCCESS",
    });
  } catch (error) {
    yield put({ type: types.RESEND_INVITE_CLIENT_FAILURE, payload: error });
  }
}

function* editUsers(payload) {
  try {
    const response = yield call(
      userManagmentServices.editUsersServices,
      payload
    );
    yield put({
      type: types.UPDATE_SHOP_USER,
      payload: true,
    });
    yield put({ type: types.EDIT_USER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.EDIT_USER_FAILURE, payload: error });
  }
}

function* editClientUser(payload) {
  try {
    const response = yield call(
      userManagmentServices.editClientUsersServices,
      payload
    );
    yield put({
      type: types.UPDATE_CLIENT_USER,
      payload: true,
    });
    yield put({ type: types.EDIT_CLIENT_USER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.EDIT_CLIENT_USER_FAILURE, payload: error });
  }
}

function* editClientLogo(payload) {
  try {
    const response = yield call(
      userManagmentServices.editClientLogoServices,
      payload
    );
    yield put({ type: types.CLIENT_LOGO_SUCCESS, payload: response.data });
    yield call(getClientList);
  } catch (error) {
    yield put({ type: types.CLIENT_LOGO_FAILURE, payload: error });
  }
}

function* editShopLogo(payload) {
  try {
    const response = yield call(
      userManagmentServices.editShopLogoServices,
      payload
    );
    yield put({ type: types.SHOP_LOGO_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.SHOP_LOGO_FAILURE, payload: error });
  }
}

function* fileUploaded(payload) {
  try {
    const response = yield call(
      userManagmentServices.fileUploadServices,
      payload
    );

    yield put({ type: types.FILE_UPLOAD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.FILE_UPLOAD_FAILURE, payload: error });
  }
}

function* fileFetched(payload) {
  try {
    const response = yield call(
      userManagmentServices.fileFetchServices,
      payload
    );

    yield put({ type: types.FILE_FETCH_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.FILE_FETCH_FAILURE, payload: error });
    yield put({ type: loaderType.HIDE_LOADER, payload: false });
  }
}

function* fileFetchHeader(payload) {
  try {
    const response = yield call(
      userManagmentServices.fileFetchServices,
      payload
    );
    yield put({
      type: types.FILE_FETCH_HEADER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.FILE_FETCH_HEADER_FAILURE, payload: error });
  }
}

function* checkShopUserMail(payload) {
  try {
    const response = yield call(
      userManagmentServices.shopUserMailServices,
      payload
    );
    yield put({
      type: types.CHECK_SHOP_USER_MAIL_SUCCESS,
      payload: response.data,
    });

    if (payload.payload.level === "GENERAL") {
      yield put({
        type: types.SET_GENERAL_USERNAME_MAIL,
        payload: {
          name: payload.payload.name,
          user_email: payload.payload.user_email,
          inspectorExists: response.data.status.inspectorExists,
        },
      });
    } else {
      yield put({
        type: types.SET_ADMIN_USERNAME_MAIL,
        payload: {
          name: payload.payload.name,
          user_email: payload.payload.user_email,
          inspectorExists: response.data.status.inspectorExists,
        },
      });
    }
    yield put({
      type: types.CHECK_SHOP_USER_MAIL_FAILURE,
      payload: response.data.status.message,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_SHOP_USER_MAIL_FAILURE,
      payload: error.response.data.status.message,
    });
  }
}

function* checkClientUserMail(payload) {
  try {
    const response = yield call(
      userManagmentServices.clientUserMailServices,
      payload
    );
    yield put({
      type: types.CHECK_CLIENT_USER_MAIL_SUCCESS,
      payload: response.data,
    });

    if (payload.payload.level === "GENERAL") {
      yield put({
        type: types.SET_GENERAL_USERNAME_MAIL,
        payload: {
          name: payload.payload.name,
          user_email: payload.payload.user_email,
        },
      });
    } else {
      yield put({
        type: types.SET_ADMIN_USERNAME_MAIL,
        payload: {
          name: payload.payload.name,
          user_email: payload.payload.user_email,
        },
      });
    }
    yield put({
      type: types.CHECK_CLIENT_USER_MAIL_FAILURE,
      payload: response.data.status.message,
    });
  } catch (error) {
    yield put({
      type: types.CHECK_CLIENT_USER_MAIL_FAILURE,
      payload: error.response.data.status.message,
    });
  }
}

function* searchShopFilter(payload) {
  try {
    const response = yield call(
      userManagmentServices.searchShopFilterServices,
      payload
    );

    const res = response.data.data;
    const count = response.data.count;

    yield put({
      type: types.SEARCH_SHOP_FILTER_SUCCESS,
      payload: { res, count },
    });
  } catch (error) {
    yield put({ type: types.SEARCH_SHOP_FILTER_FAILURE, payload: error });
  }
}

function* searchClientFilter(payload) {
  try {
    const response = yield call(
      userManagmentServices.searchClientFilterServices,
      payload
    );

    const res = response.data.data;
    const count = response.data.count;
    yield put({
      type: types.SEARCH_CLIENT_FILTER_SUCCESS,
      payload: { res, count },
    });
  } catch (error) {
    yield put({ type: types.SEARCH_CLIENT_FILTER_FAILURE, payload: error });
  }
}

function* deleteShopUsers(action) {
  try {
    const response = yield call(
      userManagmentServices.deleteShopUsersServices,
      action.payload,
      action.shopName
    );

    yield put({
      type: types.UPDATE_SHOP_USER,
      payload: true,
    });

    yield put({
      type: types.DELETE_SHOP_USER_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({ type: types.DELETE_SHOP_USER_FAILURE, payload: error });
  }
}

export function* watchGetClientUsers() {
  yield takeEvery(types.GET_CLIENT_USERS, getClientUsers);
}
export function* watchGetShopUsers() {
  yield takeEvery(types.GET_SHOP_USERS, getShopUsers);
}

export function* watchAddUserToClient() {
  yield takeEvery(types.ADD_NEW_USERS_TO_CLIENT, postNewUserToClient);
}

export function* watchAddNewShop() {
  yield takeEvery(types.ADD_NEW_SHOP, postNewShop);
}

export function* watchAddUserToShop() {
  yield takeEvery(types.ADD_NEW_USERS_TO_SHOP, postNewUserToShop);
}

export function* watchGetClient() {
  yield takeEvery(types.GET_CLIENT_LIST, getClientList);
}

export function* watchGetShop() {
  yield takeEvery(types.GET_SHOP_LIST, getShopList);
}

export function* watchGetShopListAssurant() {
  yield takeEvery(types.GET_SHOP_LIST_ASSURANT, getShopListAssurant);
}

export function* watchGetShopOfClient() {
  yield takeEvery(types.GET_SHOP_LIST_CLIENT, getShopListOfClientsss);
}

export function* watchSentInvitesShop() {
  yield takeEvery(types.SENT_INVITE_SHOP, sentInvitesShop);
}
export function* watchSentInvitesClient() {
  yield takeEvery(types.SENT_INVITE_CLIENT, sentInvitesClient);
}

export function* watchDeleteUsers() {
  yield takeEvery(types.DELETE_USER, deleteUsers);
}

export function* watchDeleteClientUsers() {
  yield takeEvery(types.DELETE_CLIENT_USER, deleteClientUsers);
}

export function* watchResendInvites() {
  yield takeEvery(types.RESEND_INVITE, resendInvites);
}

export function* watchResendInvitesClient() {
  yield takeEvery(types.RESEND_INVITE_CLIENT, resendInvitesClient);
}

export function* watchEditUsers() {
  yield takeEvery(types.EDIT_USER, editUsers);
}

export function* watchEditClientUsers() {
  yield takeEvery(types.EDIT_CLIENT_USER, editClientUser);
}

export function* watchClientLogo() {
  yield takeEvery(types.CLIENT_LOGO, editClientLogo);
}

export function* watchShopLogo() {
  yield takeEvery(types.SHOP_LOGO, editShopLogo);
}

export function* watchFileUpload() {
  yield takeEvery(types.FILE_UPLOAD, fileUploaded);
}

export function* watchFileFetched() {
  yield takeEvery(types.FILE_FETCH, fileFetched);
}

export function* watchFileFetchHeader() {
  yield takeEvery(types.FILE_FETCH_HEADER, fileFetchHeader);
}

export function* watchCheckShopUserMail() {
  yield takeEvery(types.CHECK_SHOP_USER_MAIL, checkShopUserMail);
}
export function* watchCheckClientUserMail() {
  yield takeEvery(types.CHECK_CLIENT_USER_MAIL, checkClientUserMail);
}

export function* watchSearchShopFilter() {
  yield takeEvery(types.SEARCH_SHOP_FILTER, searchShopFilter);
}

export function* watchSearchClientFilter() {
  yield takeEvery(types.SEARCH_CLIENT_FILTER, searchClientFilter);
}

export function* watchDeleteShopUsers() {
  yield takeEvery(types.DELETE_SHOP_USER, deleteShopUsers);
}

export function* mainUserManagmentSaga() {
  yield all([
    watchGetClient(),
    watchGetShop(),
    watchGetShopListAssurant(),
    watchAddUserToClient(),
    watchAddNewShop(),
    watchAddUserToShop(),
    watchGetShopOfClient(),
    watchGetClientUsers(),
    watchGetShopUsers(),
    watchSentInvitesShop(),
    watchSentInvitesClient(),
    watchResendInvites(),
    watchResendInvitesClient(),
    watchDeleteUsers(),
    watchDeleteClientUsers(),
    watchEditUsers(),
    watchEditClientUsers(),
    watchClientLogo(),
    watchShopLogo(),
    watchFileUpload(),
    watchFileFetched(),
    watchFileFetchHeader(),
    watchCheckShopUserMail(),
    watchCheckClientUserMail(),
    watchSearchShopFilter(),
    watchSearchClientFilter(),
    watchDeleteShopUsers(),
  ]);
}
