import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  ClaimContainer,
  ClaimHeader,
  ClaimText,
  ClaimDetails,
  SearchBoxContainer,
  StyledInput,
  IconContainer,
  ClaimCommentsRequestMore,
  Line,
  ClaimInspectionDetails,
  ClaimRequest,
  UploadContentRequestMore,
  UploadHeaderRequestMore,
  UploadTextRequestMore,
  UploadImageRequestMore,
  UploadCountRequestMore,
  UploadContainerRequestMore,
} from "./requestMoreInspectionRequest.styled";
import Grid from "@mui/material/Grid";
import { Button } from "../../../styles/inspectionHeader.styled";
import {
  Box,
  UploadHeaderCount,
  UploadTotalCount,
  UploadContainer,
  UploadCount,
  UploadHeader,
  UploadImage,
  UploadText,
  UploadContent,
  UploadStatus,
} from "../review/reviewInspectionRequest.styled";
import { useDispatch, useSelector } from "react-redux";
import { headerType } from "../../../store/sharedComponents/header/action";
import Search from "../../../assets/images/search.svg";
import downloadLogo from "../../../assets/images/download-bottom 1.svg";
import camera from "../../../assets/images/camera-1.svg";
import video from "../../../assets/images/video.svg";
import leftSwiperControl from "../../../assets/images/leftSwiper.svg";
import rightSwiperControl from "../../../assets/images/rightSwiper.svg";
import {
  getAttachmentRequestDetails,
  searchPart,
  setMoreDetails,
  setPart,
  getClaimInspectionDetails,
  handleError,
  resetRequestMore,
} from "../../../store/claimInspection/action";
import useDebounce from "../dashboard/closed/useDebounce";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../edit/swipert.css";
import { List, ListItemText, Popover, ListItemButton } from "@mui/material";
import AttachementRequestCard from "../../../shared/functionalComponents/atttachement-request/attachmentRequestCard";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";
import crossIcon from "../../../assets/images/remove-circle.svg";
import { isFooterReq } from "../../../store/sharedComponents/footer/action";
import playButton from "../../../assets/images/playButton.png";

import JSZip from "jszip";

const RequestMoreDetails = (props) => {
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [indexOverlay, setIndexOverlay] = useState(2);
  const dispatch = useDispatch();
  const location = useLocation();
  const swiperRef = useRef(null);
  const userAgentString = navigator.userAgent;
  let safariAgent = userAgentString.indexOf("Safari") > -1;
  const chromeAgent = userAgentString.indexOf("Chrome") > -1;
  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }

  const claimInspectionDetails = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );
  const addedParts = useSelector(
    (state) => state?.claimInspectionReducer.inspectionRequest
  );
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );
  const partList = useSelector(
    (state) => state?.claimInspectionReducer?.searchPartList
  );
  const isUpdateComplete = useSelector(
    (state) => state.claimInspectionReducer.isUpdateComplete
  );

  const downloadFiles = useSelector(
    (state) => state.claimInspectionReducer.downloadFiles
  );
  const debounceSearch = useDebounce(searchText, 1000);
  useEffect(() => {
    dispatch(getAttachmentRequestDetails(currentClaim));
    dispatch(isFooterReq({ isFooterReq: true }));
    dispatch(resetRequestMore());
  }, []);
  useEffect(() => {
    if (debounceSearch) {
      dispatch(searchPart(debounceSearch));
    }
  }, [debounceSearch]);
  const handlePartSearch = (event) => {
    if (event.target.value.length === 0) {
      setAnchorEl(event.currentTarget);
      setSearchText(event.target.value);
    } else {
      if (event.target.value.split(" ").join("").length > 0) {
        setAnchorEl(event.currentTarget);
        setSearchText(event.target.value);
      }
    }
  };
  const isError = useSelector((state) => state.claimInspectionReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.claimInspectionReducer?.error?.message
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const RequestMoreDetails = useSelector(
    (state) => state.claimInspectionReducer?.requestMoreDetails
  );
  const handleErrorState = () => {
    dispatch(handleError());
  };
  const handlePartSeleceted = (event, data) => {
    setAnchorEl(null);
    const listVal = addedParts.findIndex((element) => {
      return (
        element.name.split(" ").join("").toLowerCase() ===
        data.split(" ").join("").toLowerCase()
      );
    });
    const RequestVal = RequestMoreDetails.findIndex((element) => {
      return (
        element.name.split(" ").join("").toLowerCase() ===
        data.split(" ").join("").toLowerCase()
      );
    });
    if (listVal === -1 && RequestVal === -1) {
      const newRequest = {
        name: data,
        noise: false,
        leak: false,
        other_failure: false,
        min_image: 0,
        min_video: 0,
        instructions: "",
        attachment_type: "",
      };
      dispatch(setMoreDetails(newRequest));
    }
  };
  const handleAdd = () => {
    const val = addedParts.findIndex((element) => {
      return (
        element.name.split(" ").join("").toLowerCase() ===
        searchText.split(" ").join("").toLowerCase()
      );
    });
    const requestnew = RequestMoreDetails.findIndex((element) => {
      return (
        element.name.split(" ").join("").toLowerCase() ===
        searchText.split(" ").join("").toLowerCase()
      );
    });
    if (val === -1 && requestnew === -1) {
      const newRequest = {
        name: searchText,
        noise: false,
        leak: false,
        other_failure: false,
        min_image: 0,
        min_video: 0,
        image: false,
        video: false,
        instructions: "",
        attachment_type: "",
      };
      dispatch(setMoreDetails(newRequest));
    }
  };

  const onDownloadClick = () => {
    const zip = new JSZip();
    if (downloadFiles) {
      let i = 0;
      while (i < downloadFiles.length > 0) {
        let j = 0;
        while (j < downloadFiles[i].assets.length > 0) {
          const byteCharacters = atob(downloadFiles[i].assets[j].asset);

          const byteNumbers = new Array(byteCharacters.length);
          let k = 0;
          while (k < byteCharacters.length) {
            byteNumbers[k] = byteCharacters.charCodeAt(k);
            k++;
          }

          const byteArray = new Uint8Array(byteNumbers);

          zip.file(downloadFiles[i].assets[j].filename, byteArray);
          j++;
        }
        i++;
      }
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${claimInspectionDetails.claimNumber}.zip`);
    });
  };
  const handleSlideNext = () => {
    swiperRef.current.swiper.slideNext();
    if (
      swiperRef.current.swiper.activeIndex + 2 ===
      swiperRef.current.swiper.slides.length
    ) {
      setIndexOverlay(swiperRef.current.swiper.slides.length);
    } else {
      setIndexOverlay(indexOverlay + 1);
    }
  };
  const handleSlidePrev = () => {
    swiperRef.current.swiper.slidePrev();
    if (swiperRef.current.swiper.activeIndex === 0) {
      setIndexOverlay(2);
    } else {
      setIndexOverlay(indexOverlay - 1);
    }
  };
  return (
    <>
      <ClaimContainer
        container
        justifyContent={"space-around"}
        direction="row"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        mobile={12}
      >
        <div
          style={{
            height: "840px",
            display: "flex",
            width: "100%",
          }}
        >
          <ClaimHeader item xs={12} sm={3} md={3} lg={3} xl={3} mobile={12}>
            <div
              style={{
                width: "95%",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ClaimDetails style={{ marginTop: "0px" }} ipadWidth={"280px"}>
                <ClaimText
                  fontFamily={"OpenSans Bold"}
                  fontSize={"18px"}
                  lineHeight={"33px"}
                >
                  Claim #{" "}
                  <span>
                    {claimInspectionDetails.claimNumber &&
                      claimInspectionDetails.claimNumber.replace(/CLAIM-/, "")}
                  </span>
                </ClaimText>
              </ClaimDetails>
              <ClaimDetails>
                RO: <span>{claimInspectionDetails.RO}</span>
              </ClaimDetails>
              <ClaimDetails>
                VIN:{" "}
                <span>
                  {"***"}
                  {claimInspectionDetails?.vin?.slice(
                    claimInspectionDetails.vin.length - 6
                  )}
                </span>
              </ClaimDetails>
              <ClaimDetails>
                Shop: <span>{claimInspectionDetails.shop}</span>
              </ClaimDetails>
              <ClaimDetails>
                Inspector: <span>{claimInspectionDetails.Inspector}</span>
              </ClaimDetails>
              <ClaimDetails>
                Request type: <span>{claimInspectionDetails.RequestType}</span>
              </ClaimDetails>
              <ClaimDetails>
                Customer: <span>{claimInspectionDetails.Customer}</span>
              </ClaimDetails>
              <ClaimDetails>
                Vehicle:{" "}
                <span>
                  {claimInspectionDetails?.vehicle_year +
                    " " +
                    claimInspectionDetails?.vehicle_make +
                    " " +
                    claimInspectionDetails?.Vehicle}
                </span>
              </ClaimDetails>
              <ClaimDetails>
                VI Completion:{" "}
                <span>{claimInspectionDetails.VICompletion}</span>
              </ClaimDetails>
              <ClaimCommentsRequestMore>
                Inspector Comments
                <ClaimDetails style={{ marginTop: "0px", color: "#68758b" }}>
                  {claimInspectionDetails?.additional_doc?.comments
                    ? claimInspectionDetails.additional_doc?.comments
                    : "NONE"}
                </ClaimDetails>
              </ClaimCommentsRequestMore>
              <div style={{ marginTop: "21px" }}>
                <UploadTotalCount>
                  Uploads ({claimInspectionDetails?.totalAssets})
                  <img
                    style={{
                      marginLeft: "10px",
                      marginTop: "3px",
                      width: "16px",
                    }}
                    onClick={onDownloadClick}
                    src={downloadLogo}
                    alt="downloadLogo"
                  />
                </UploadTotalCount>
                <UploadContainerRequestMore>
                  {claimInspectionDetails?.attachment_requests_attributes &&
                  claimInspectionDetails.attachment_requests_attributes.length >
                    0
                    ? claimInspectionDetails.attachment_requests_attributes.map(
                        (data) => {
                          return (
                            <>
                              <UploadContent>
                                <UploadHeader>
                                  <UploadText>{data.name}</UploadText>
                                  {data?.min_video > 0 && (
                                    <UploadImage>
                                      {" "}
                                      <img
                                        src={video}
                                        alt="video"
                                        style={{
                                          width: "20px",
                                          marginRight: "6px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                      <UploadCount>
                                        {data?.min_video}
                                      </UploadCount>
                                    </UploadImage>
                                  )}
                                  {data?.min_image > 0 && (
                                    <UploadImage>
                                      {" "}
                                      <img
                                        src={camera}
                                        alt="camera"
                                        style={{
                                          width: "20px",
                                          marginRight: "6px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                      <UploadCount>
                                        {data?.min_image}
                                      </UploadCount>
                                    </UploadImage>
                                  )}
                                </UploadHeader>
                              </UploadContent>
                              <UploadStatus container spacing={1}>
                                {isUpdateComplete &&
                                  data.assets.map((y, index) => {
                                    return (
                                      <Grid
                                        item
                                        xs={4}
                                        key={index}
                                        id={index + 1232}
                                        style={{ overflowX: scroll }}
                                      >
                                        {y.type === "image/jpg" ||
                                        y.type === "image/jpeg" ||
                                        y.type === "image/png" ? (
                                          <img
                                            className="react-player fixed-bottom"
                                            src={
                                              "data:image/*;base64," + y.asset
                                            }
                                            width="90px"
                                            height="90px"
                                            key={index}
                                            onClick={(e) =>
                                              handleThumb(e, y.slideIndex)
                                            }
                                            style={{
                                              objectFit: "fill",
                                              marginRight: "11px",
                                            }}
                                          />
                                        ) : (
                                          <>
                                            <video
                                              className="react-player fixed-bottom"
                                              poster={
                                                safariAgent ? playButton : null
                                              }
                                              src={
                                                "data:video/mp4;base64," +
                                                y.asset
                                              }
                                              width="90px"
                                              height="90px"
                                              key={index}
                                              type="video/mp4"
                                              style={{
                                                objectFit: "fill",
                                                marginRight: "11px",
                                              }}
                                              onClick={(e) =>
                                                handleThumb(e, y.slideIndex)
                                              }
                                            />
                                          </>
                                        )}
                                      </Grid>
                                    );
                                  })}
                                {isUpdateComplete && data.oldassets
                                  ? data?.oldassets.map((y, index) => {
                                      return (
                                        <Grid key={index}>
                                          {y.type === "image/jpg" ||
                                          y.type === "image/jpeg" ||
                                          y.type === "image/png" ? (
                                            <div
                                              style={{
                                                position: "relative",
                                                filter: "grayscale(100%)",
                                                marginTop: "8px",
                                                marginLeft: "8px",
                                              }}
                                            >
                                              <img
                                                src={
                                                  "data:image/*;base64," +
                                                  y.asset
                                                }
                                                controls={true}
                                                key={index}
                                                width={"90px"}
                                                height={"90px"}
                                                style={{
                                                  objectFit: "fill",
                                                  marginRight: "11px",
                                                }}
                                              />
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  position: "absolute",
                                                  width: "100%",
                                                  top: "12px",
                                                  left: "0px",
                                                  opacity: "1",
                                                }}
                                              >
                                                <div>
                                                  <img
                                                    src={crossIcon}
                                                    alt="crossIcon"
                                                    style={{
                                                      marginTop: "15px",
                                                      width: "37px",
                                                      marginRight: "11px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  position: "relative",
                                                  filter: "grayscale(100%)",
                                                  marginTop: "8px",
                                                  marginLeft: "8px",
                                                }}
                                              >
                                                <video
                                                  className="react-player fixed-bottom"
                                                  controls={false}
                                                  controlsList="nodownload noremoteplayback noplaybackrate"
                                                  playsInline={false}
                                                  poster={
                                                    safariAgent
                                                      ? playButton
                                                      : null
                                                  }
                                                  src={
                                                    "data:video/mp4;base64," +
                                                    y.asset
                                                  }
                                                  width="90px"
                                                  height="90px"
                                                  key={index}
                                                  type="video/mp4"
                                                  style={{
                                                    objectFit: "fill",
                                                    marginRight: "11px",
                                                  }}
                                                />
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    position: "absolute",
                                                    width: "100%",
                                                    top: "12px",
                                                    left: "0px",
                                                    opacity: "1",
                                                  }}
                                                >
                                                  <div>
                                                    <img
                                                      src={crossIcon}
                                                      alt="crossIcon"
                                                      style={{
                                                        marginTop: "15px",
                                                        width: "37px",
                                                        marginRight: "11px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </Grid>
                                      );
                                    })
                                  : null}
                              </UploadStatus>
                            </>
                          );
                        }
                      )
                    : null}
                </UploadContainerRequestMore>
              </div>
            </div>
          </ClaimHeader>
          <ClaimHeader item xs={12} sm={9} md={9} lg={9} xl={9} mobile={12}>
            <ClaimRequest marginLeft={"5rem"}>
              <ClaimText>Request More Details</ClaimText>
              <div style={{ display: "flex" }}>
                <SearchBoxContainer width={"240px"}>
                  <IconContainer
                    width={"19px"}
                    height={"19px"}
                    mobileWidth={"19px"}
                    mobileHeight={"19px"}
                  >
                    <img
                      src={Search}
                      alt="Search Icon"
                      width={"22px"}
                      height={"22px"}
                    />
                  </IconContainer>
                  <StyledInput
                    id="search"
                    type="text"
                    searchImg={Search}
                    placeholder={"Search..."}
                    autoComplete="off"
                    value={searchText}
                    onChange={(e) => handlePartSearch(e)}
                  />
                  {partList.length > 0 ? (
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      disableAutoFocus={true}
                      disableEnforceFocus={true}
                      sx={{ height: "200px" }}
                    >
                      <List>
                        {partList?.map((data, index) => {
                          return (
                            <ListItemButton
                              key={index}
                              onClick={(e) => handlePartSeleceted(e, data)}
                            >
                              <ListItemText primary={`${data}`}></ListItemText>
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </Popover>
                  ) : null}
                </SearchBoxContainer>

                <Button
                  marginTop={"0px"}
                  style={{ marginLeft: "18px" }}
                  width={"16%"}
                  padding={"6px 24px"}
                  backgroundColor={
                    partList.length > 0 || !searchText.length > 0
                      ? "#FFFFFF"
                      : "black"
                  }
                  color={
                    partList.length > 0 || !searchText.length > 0
                      ? "black"
                      : "white"
                  }
                  onClick={handleAdd}
                  disabled={searchText.length > 0 ? false : true}
                >
                  Add
                </Button>
              </div>
              <div
                style={{
                  marginLeft: "25px",
                  fontSize: "11px",
                  fontFamily: "OpenSans Regular",
                  color: "#3D515A",
                  marginTop: "4px",
                  lineHeight: "12px",
                  fontWeight: "400",
                }}
              >
                {searchText.length > 0 && !partList.length > 0
                  ? "Component not found. Try another term or click Add to continue."
                  : "Use common terms for parts or components"}
              </div>
              <ClaimInspectionDetails>
                <div
                  style={{
                    width: "139%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>Requested:</div>
                  <div
                    style={{
                      float: "right",
                      marginBottom: "-14px",
                      display:
                        RequestMoreDetails?.length > 2 || addedParts.length > 2
                          ? "flex"
                          : "none",
                    }}
                  >
                    <img
                      src={leftSwiperControl}
                      style={{ cursor: "pointer" }}
                      onClick={handleSlidePrev}
                    ></img>
                    <img
                      src={rightSwiperControl}
                      style={{ cursor: "pointer" }}
                      onClick={handleSlideNext}
                    ></img>
                  </div>
                </div>
                <Line></Line>
                <Swiper
                  ref={swiperRef}
                  modules={[Navigation, Pagination]}
                  spaceBetween={10}
                  slidesPerView={2.35}
                  style={{ width: "139%" }}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 34,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 34,
                    },
                    1280: {
                      slidesPerView: 2.3,
                      spaceBetween: 34,
                    },
                  }}
                >
                  {RequestMoreDetails.length > 0
                    ? RequestMoreDetails.map((data, index) => {
                        return (
                          <SwiperSlide key={data.name}>
                            <AttachementRequestCard
                              header={data.name}
                              imageCountValue={data.min_image}
                              videoCountValue={data.min_video}
                              description={data.instructions}
                              leak={data.leak}
                              noise={data.noise}
                              other={data.other_failure}
                              image={
                                data.attachment_type === "photo" ||
                                data.attachment_type === "video/photo"
                                  ? true
                                  : false
                              }
                              video={
                                data.attachment_type === "video" ||
                                data.attachment_type === "video/photo"
                                  ? true
                                  : false
                              }
                              blur={
                                index >= 2 && index === indexOverlay
                                  ? true
                                  : false
                              }
                              loc="RequestMore"
                            />
                          </SwiperSlide>
                        );
                      })
                    : null}
                  {addedParts.length > 0
                    ? addedParts.map((data) => {
                        return (
                          <SwiperSlide key={data.name}>
                            <AttachementRequestCard
                              header={data.name}
                              imageCountValue={data.min_image}
                              videoCountValue={data.min_video}
                              description={data.instructions}
                              leak={data.leak}
                              noise={data.noise}
                              other={data.other_failure}
                              image={data.min_image > 0 ? true : false}
                              video={data.min_video > 0 ? true : false}
                              color={"rgba(241, 241, 241, 0.6)"}
                              disable={true}
                            />
                          </SwiperSlide>
                        );
                      })
                    : null}
                </Swiper>
              </ClaimInspectionDetails>
              <ClaimInspectionDetails
                style={{ padding: "0px" }}
                marginTop={"0rem"}
              >
                Required:
                <Line></Line>
                <div style={{ display: "flex" }}>
                  <UploadContentRequestMore>
                    <UploadHeaderRequestMore height={"35px"}>
                      <UploadTextRequestMore marginTop={"5px"}>
                        VIN
                      </UploadTextRequestMore>
                      <UploadImageRequestMore>
                        {" "}
                        <img
                          src={camera}
                          alt="camera"
                          style={{
                            width: "20px",
                            marginRight: "6px",
                          }}
                        />
                        <UploadCountRequestMore>1</UploadCountRequestMore>
                      </UploadImageRequestMore>
                    </UploadHeaderRequestMore>
                  </UploadContentRequestMore>
                  <UploadContentRequestMore>
                    <UploadHeaderRequestMore
                      height={"35px"}
                      marginLeft={"14px"}
                    >
                      <UploadTextRequestMore marginTop={"5px"}>
                        Odometer
                      </UploadTextRequestMore>
                      <UploadImageRequestMore>
                        {" "}
                        <img
                          src={camera}
                          alt="camera"
                          style={{
                            width: "20px",
                            marginRight: "6px",
                          }}
                        />
                        <UploadCountRequestMore>1</UploadCountRequestMore>
                      </UploadImageRequestMore>
                    </UploadHeaderRequestMore>
                  </UploadContentRequestMore>
                </div>
              </ClaimInspectionDetails>
            </ClaimRequest>
          </ClaimHeader>
        </div>
      </ClaimContainer>
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
    </>
  );
};

export default RequestMoreDetails;
