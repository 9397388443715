import React, { useState } from "react";
import {
  TextField,
  Button,
  Collapse,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import "./login.css";
import {
  resetPassword,
  setDefault,
  setForgotPassword,
  setSupport,
} from "../../../store/accounts/login/action";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import lockLogo from "../../../assets/images/lock.svg";
import assurantLogo from "../../../assets/images/assurant-logo.svg";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { passwordRequirements } from "./passwordRequirements";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [open, setOpen] = useState(true);
  const [successOpen, setSuccessOpen] = useState(true);
  const [enable, setEnable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const resetSuccess = useSelector(
    (state) => state?.loginReducer?.resetPassword
  );

  const handleAlertBox = (isOpen) => {
    isOpen ? setOpen(false) : setOpen(true);
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (id === "password") {
      setPassword(value);
      validatePassword(value);
    } else {
      setConfirmPassword(value);
      validateConfirmPassword(value, password);
    }
  };

  const validatePassword = (data) => {
    const validationRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (validationRegex.test(data)) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }

    setEnable(false);
    handleAlertBox(false);
    if (confirmPassword.length > 0) {
      validateConfirmPassword(confirmPassword, data);
    }
  };
  const validateConfirmPassword = (data, data1) => {
    if (data === data1) {
      setConfirmPasswordError(false);
      if (!passwordError) {
        setEnable(true);
        handleAlertBox(true);
      } else {
        setEnable(false);
        handleAlertBox(false);
      }
    } else {
      setConfirmPasswordError(true);
      setEnable(false);
      handleAlertBox(false);
    }
  };
  const changePassword = () => {
    const token = (new URLSearchParams(window.location.search)).get("token");
    dispatch(
      resetPassword({
        password: { password },
        token: { token },
        id: userId,
      })
    );
  };
  const backToSign = () => {
    dispatch(setDefault(true));
    dispatch(setForgotPassword(false));
    dispatch(setSupport(false));
    navigate(window.location.replace("/login"));
  };
  const handleClickShowPassword = (value) => {
    if (value === "password") {
      setShowPassword(!showPassword);
    }
    if (value === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="login-main">
      <div className="activate-screen">
        <img className="assurant-logo" src={assurantLogo} alt="logo" />
        <p className="title-name">Change Your Password</p>
        <div className="reset-error">
          {passwordError || confirmPasswordError ? (
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
                sx={{
                  alignItems: "center",
                  height: "30px",
                  width: "80%",
                  mr: 3,
                  ml: 3,
                  fontSize: "12px",
                }}
                variant="outlined"
                severity="error"
              >
                {passwordError ? "Password Requirements not met" : null}
                {passwordError && confirmPasswordError ? <br /> : null}
                {confirmPasswordError ? "Passwords did not match" : null}
              </Alert>
            </Collapse>
          ) : null}
        </div>

        <div className="MainDiv">
          <div className="pass-req">
            Password requirements:
            <ul className="pwd-ul">
              {passwordRequirements.map((data) => {
                return <li className="pass-req ul">{data.title}</li>;
              })}
            </ul>
          </div>

          <div className="password-icon forResetPass-icon">
            <img className="signin-password" src={lockLogo} alt="pwd" />
            <TextField
              fullWidth={true}
              id="password"
              label="New password"
              type={showPassword ? "text" : "password"}
              value={password}
              variant={"standard"}
              style={{
                fontFamily: "OpenSans Regular",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("password")}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
            />
          </div>
          <div className="password-icon">
            <img className="signin-password" src={lockLogo} alt="pwd" />
            <TextField
              fullWidth={true}
              id="confirmPassword"
              label="Verify new password"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              variant={"standard"}
              style={{
                fontFamily: "OpenSans Regular",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("confirmPassword")}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
            />
          </div>
          <div className="remember-me-sign-in">
            <Button
              variant="contained"
              style={{
                width: "201px",
                background: "#0047AD",
                opacity: enable ? 1 : 0.6,
                border: "0.5px solid #0047AD",
                borderRadius: "20px",
                textTransform: "none",
                fontSize: "16px",
                marginTop: "20px",
                color: "white",
              }}
              className="sign-in"
              onClick={changePassword}
              disabled={!enable}
            >
              Change Password
            </Button>
          </div>
          <div className="back-signin">
            <span className="back-to-signin" onClick={backToSign}>
              Back to Sign In
            </span>
          </div>
          <div className="reset-error" style={{ marginBottom: "10px" }}>
            {resetSuccess?.status?.message === "success" ? (
              <Collapse in={successOpen}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setSuccessOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                  severity="success"
                  variant="outlined"
                  sx={{ width: "90%" }}
                >
                  Password Reset Successfully! Go back to Sign In
                </Alert>
              </Collapse>
            ) : null}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          zIndex: 1000,
        }}
        className="bottom-footer"
      >
        © 2022 Assurant, Inc. All Rights Reserved
      </div>
    </div>
  );
}

export default ResetPassword;
