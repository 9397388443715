import { HEADER_TYPE } from "./types";

const initialSate = {
  condition: {
    isSearch: true,
    isHeaderVi: true,
    isHamburger: true,
    isActiveClose: true,
    isLogo: true,
    isSave: true,
    isEdit: true,
    isRequestEmpty: true,
    isRequestStarted: true,
    isRequestInProgress: true,
    isRequestCompleted: true,
    isRequestMoreDetail: true,
    requestStatus: "",
  },
};

const HeaderReducer = (state = initialSate, action) => {
  switch (action.type) {
    case HEADER_TYPE:
      return {
        ...state,
        condition: action.payload,
      };

    default:
      return state;
  }
};
export default HeaderReducer;
