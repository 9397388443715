import styled from "styled-components";
import { Grid } from "@mui/material";

export const Heading = styled.div`
  font-family: "OpenSans Bold";
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #13223c;
  padding-top: 20px;
`;

export const SubHeading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0px;
  color: #3d515a;
  padding-top: 20px;
`;

export const Header = styled.div`
  direction: rtl;
  background-color: transparent !important;
  color: white !important;
  width: 100%;
`;
