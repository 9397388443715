export const ENDPOINTS = {
  CLAIMS: "v1/api/inspections/claims/active",
  CLOSED_CLAIMS: "v1/api/inspections/claims/closed",
  GET_REMINDER_DETAILS: "v1/api/reminders/client",
  UPDATE_REMINDER_DETAILS: "v1/api/client/reminders",
  CREATE_REMINDER_DETAILS: "/v1/api/reminders/",
  GET_CLAIM_INSPECTION_DETAILS: "v1/api/inspections/",
  DOWNLOAD_ASSET_DETAILS: "v1/api/files/downloadIntegration",
  RESEND_INSPECTION: "v1/api/inspections/rerequest/",
  CLIENTS: "v1/api/clients",
  ADDCLIENT: "v1/api/clients",
  ADDSHOP: "v1/api/stores",
  ASSURANTSHOP: "v1/api/stores",
  INSPECTION: "v1/api/inspections",
  CREATE_NEW_INSPECTION: "v1/api/inspections",
  ACCEPT_INSPECTION: "v1/api/inspections/accept/",
  RETAKE_REQUEST: "v1/api/inspections/retake",
  RETAKE_REQUEST_COMMENT: "v1/api/inspections/checkretake/claimno",
  LOGIN_VERFIY: "v1/api/authentication/login-verify",
  REPORTING_DATA: "v1/api/inspections/report",
  ACTIVATE_USER: "v1/api/accounts/signup-user",
  DOWNLOAD_REPORT: "v1/api/reporting/report",
  RESET_EMAIL: "v1/api/accounts/forgot-password-link",
  RESET_PASSWORD: "v1/api/accounts/set-password",
  SUPPORT_REQUEST: "v1/api/accounts/support",
  API_KEY: "/v1/api/clients",
};
