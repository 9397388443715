import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { Heading, SubHeading, Header } from "./dialogSent.styled";
import sent from "../../../assets/images/sent-tick.svg";
import close from "../../../assets/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { retakeSuccess } from "../../../store/claimInspection/action";

const DialogSent = (props) => {
  const dispatch = useDispatch();
  const open = useSelector(
    (state) => state.claimInspectionReducer?.retakeSuccess
  );

  const [openDialog, setOpenDialog] = useState(open);

  const handleClose = () => {
    setOpenDialog(false);
    dispatch(retakeSuccess(false));
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);
  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth={"xs"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            backgroundColor: "transparent !important",
            boxShadow: "none",
            borderRadius: ".5",
          },
        },
      }}
    >
      <Header>
        <img
          src={close}
          onClick={handleClose}
          style={{
            cursor: "pointer",
          }}
        />
      </Header>
      <div
        style={{ padding: "10%", backgroundColor: "#fff", borderRadius: "2px" }}
      >
        <div style={{ marginLeft: "44%" }}>
          <img src={sent} width={"44px"} height={"30px"} />
        </div>
        <Heading>Request Sent</Heading>
        <SubHeading>
          The inspector has been notified of your request.
        </SubHeading>
      </div>
    </Dialog>
  );
};
export default DialogSent;
