import { put, call, takeEvery, all } from "redux-saga/effects";
import { apiKeyServices } from "../../services/functional";
import * as types from "./types";

function* getApiKeyData({ payload }) {
  try {
    const response = yield call(apiKeyServices.getApiKeyDataService, payload);
    yield put({
      type: types.GET_API_KEY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_API_KEY_FAILURE, error });
  }
}

function* getPdfData({ payload }) {
  try {
    const response = yield call(apiKeyServices.getPdfDataService, payload);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileURL = window.URL.createObjectURL(blob);
    window.open(fileURL);
    yield put({
      type: types.GET_PDF_SUCCESS,
      payload: fileURL,
    });
  } catch (error) {
    yield put({ type: types.GET_PDF_FAILURE, error });
  }
}

export function* watchGetPdfData() {
  yield takeEvery(types.GET_PDF, getPdfData);
}

export function* watchGetApiKeyData() {
  yield takeEvery(types.GET_API_KEY, getApiKeyData);
}

export function* mainApiKeySaga() {
  yield all([watchGetApiKeyData(), watchGetPdfData()]);
}
