import React from "react";
import Header from "../../shared/commonComponents/header/header";
import Setup from "./landing/userManagement";
import { Bottom, FootText } from "../../shared/commonComponents/footer/styled";

export default function UserManagmentScreen() {
  return (
    <>
      <Header userManagementScreen={true} />
      <Setup />
      <div
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Bottom>
          <FootText>© 2022. Powered by Assurant. All rights reserved.</FootText>
        </Bottom>
      </div>
    </>
  );
}
