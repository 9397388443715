import assurantlogo from "../../../../assets/images/assurant-logo.svg";

const config = {
  okta: {
    logo: assurantlogo,
    baseUrl: process.env.REACT_APP_OKTA_ISSUER,
    oidc: {
      pkce: true,
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
      redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
      issuer: process.env.REACT_APP_OKTA_ISSUER,
      scopes: ["openid", "profile", "email", "groups"],
      tokenManager: {
        storage: "sessionStorage",
      },
    },
  },
};
export default config;

function load() {
  const env = process.env.REACT_APP_STAGE;
  let filename = "development.json";
  if (env === "production") {
    filename = "production.json";
  } else if (env === "model") {
    filename = "model.json";
  } else if (env === "sandbox") {
    filename = "sandbox.json";
  }

  return fetch(filename)
    .then((result) => result.json())
    .then((newconfig) => {
      for (const prop in config) {
        delete config[prop];
      }
      for (const prop in newconfig) {
        config[prop] = newconfig[prop];
      }
      return config;
    });
}
export { load };
