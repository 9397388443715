import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { Heading, SubHeading, Header, SubHeader, Text } from "./apiKey.styled";
import close from "../../assets/images/close.svg";
import copy from "../../assets/images/copy.svg";
import pdf from "../../assets/images/pdf.svg";
import Button from "../../shared/commonComponents/button/button";
import BottomNavigation from "../../shared/commonComponents/bottomNavigation/bottomNavigation";
import Tooltip from "@mui/material/Tooltip";
import copyTexts from "copy-to-clipboard";
import { getApiKeyData, getPdfData } from "../../store/apiKey/action";
import SessionStorageService from "../../services/functional/accounts/login/storage";

const ApiKey = (props) => {
  const dispatch = useDispatch();
  const [copyStatus, setcopyStatus] = useState("Copy to clipboard");
  const isApiKey = useSelector((state) => state.apiKeyReducer.apiKeyData);
  const jwtTokenClientDetails = SessionStorageService.get("CLIENT_DETAILS");
  const clientdetails = JSON.parse(jwtTokenClientDetails);
  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);
  useEffect(() => {
    dispatch(getApiKeyData(clientdetails?.clientId));
  }, []);

  const copyToClipboard = () => {
    setcopyStatus("Copied");
    copyTexts(isApiKey?.secret_key);
  };
  const handleOpenPdf = () => {
    dispatch(getPdfData(userDetails.client_id));
  };

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth={"xs"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            backgroundColor: "transparent !important",
            boxShadow: "none",
            borderRadius: ".5",
          },
        },
      }}
    >
      <Header>
        <img
          src={close}
          onClick={props.onClose}
          style={{
            cursor: "pointer",
          }}
        />
      </Header>
      <div
        style={{ padding: "7%", backgroundColor: "#fff", borderRadius: "2px" }}
      >
        <Heading>API Key</Heading>
        <SubHeading>
          This is used to initiate an inspection from your claims system. Your
          claims system will pass over details about the claim that will be used
          in the inspection request.
        </SubHeading>
        <div style={{ paddingTop: "2.5rem" }}>
          <Tooltip title={copyStatus} arrow="true" placement="bottom-start">
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                width: "16rem",
                height: "20px",
              }}
              onClick={() => copyToClipboard()}
            >
              <img src={copy}></img>
              <div
                style={{
                  paddingLeft: "15px",
                  fontSize: "16px",
                  color: "#2972c8",
                  lineHeight: "28px",
                  fontFamily: "OpenSans Regular",
                }}
              >
                [Assurant Provided API Key]
              </div>
            </div>
          </Tooltip>
          <SubHeader className="heading">
            Click to copy the unique API key
          </SubHeader>
          <div
            style={{
              paddingTop: "2rem",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={handleOpenPdf}
          >
            <img src={pdf}></img>
            <SubHeader style={{ paddingLeft: "15px" }}>
              API Documentation
            </SubHeader>
          </div>
        </div>
        <BottomNavigation
          justifyContent={"end"}
          margin={"40px 0 0 0"}
          minHeight={"35px"}
        >
          <Button
            show={true}
            width={"148px"}
            height={"34px"}
            secondary={true}
            clicked={props.onClose}
          >
            <Text>Close</Text>
          </Button>
        </BottomNavigation>
      </div>
    </Dialog>
  );
};
export default ApiKey;
