import React, { useEffect, useState } from "react";
import {
  MainContainer,
  HeaderGrid,
  Grid3,
  Grid2,
  Button,
} from "../../../styles/inspectionHeader.styled";
import { fileFetchHeader } from "../../../store/userManagment/action";
import assurantLogo from "../../../assets/images/assurant-logo.svg";
import { useNavigate } from "react-router-dom";
import { createNewInpectionRequest } from "../../../store/claimInspection/action";
import SessionStorageService from "../../../services/functional/accounts/login/storage";

import { useDispatch, useSelector } from "react-redux";
const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [preview, setPreview] = useState();
  const [disabled, setDisabled] = useState(false);
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const clientdetails = SessionStorageService.get("CLIENT_DETAILS");
  const parseClientDetails = JSON.parse(clientdetails);

  const enbaleRequest = useSelector(
    (state) => state.claimInspectionReducer?.enableNewInspection
  );
  const RequestMoreDetails = useSelector(
    (state) => state.claimInspectionReducer?.requestMoreDetails
  );
  const isLogo = useSelector((state) => state.headerReducer.condition?.isLogo);

  const reset = useSelector((state) => state?.claimInspectionReducer?.reset);

  useEffect(() => {
    if (!userDetails.roles.toLowerCase().includes("super") && !fetchedUrl) {
      dispatch(fileFetchHeader({ link: parseClientDetails?.image_url }));
    }
  }, []);

  const fetchedUrl = useSelector(
    (state) => state.userManagmentReducer?.fileFetchHeader?.data
  );

  const displayImageUrl = () => {
    if (fetchedUrl) {
      const asset = fetchedUrl.asset;
      const assetValue = "data:image/png;base64," + asset;
      setPreview(assetValue);
    } else {
      setPreview(null);
    }
  };

  useEffect(() => {
    displayImageUrl();
  }, [fetchedUrl]);

  const handleSave = () => {
    if (disabled) {
      return null;
    } else {
      setDisabled(true);
      dispatch(
        createNewInpectionRequest({
          status: "REQUESTED",
          claimNo: currentClaim,
          support_doc_array: RequestMoreDetails,
          step: 2,
        })
      );
    }
  };
  useEffect(() => {
    if (reset) {
      navigate("/dashboard");
    }
  }, [reset]);
  return (
    <>
      <MainContainer
        style={{ position: "fixed", top: "0", width: "100%", zIndex: 3 }}
      >
        <HeaderGrid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} mobile={12}>
            {isLogo && (
              <img
                src={preview ? preview : assurantLogo}
                alt="ClientLogo"
                width={"130px"}
                height={"35px"}
                style={{ margin: "15px 0 16px 35px", cursor: "pointer" }}
                onClick={() =>
                  (window.location = window.location.origin + "/dashboard")
                }
              ></img>
            )}
          </Grid2>
          <Grid3
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            mobile={12}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <div style={{ marginTop: "8px" }}>
              <Button
                marginTop={"0px"}
                width={"30%"}
                padding={"6px 40px"}
                style={{ paddingRight: "70px" }}
                onClick={handleSave}
                disabled={enbaleRequest ? false : true}
                backgroundColor={enbaleRequest ? "black" : "#F1F1F1"}
                color={enbaleRequest ? "#ffffff" : "#666666"}
              >
                {"Save"}
              </Button>
            </div>
          </Grid3>
        </HeaderGrid>
      </MainContainer>
    </>
  );
};
export default Header;
