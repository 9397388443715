import * as types from "./types";

const initialSate = {
  Adjudicator: {},
  Shop: {},
  adjudicator: [],
  shop: [],
  closedClaimsData: [],
  totalActive: null,
  error: null,
  claimsSearchData: "",
  closedSearchData: "",
  isError: false,
  closedDropDownData: null,
  reportSearchData: "",
  closedScreenShow: false,
  expiresIn: 0,
  expiryAlert: false,
};
const ClaimsReducer = (state = initialSate, action) => {
  switch (action.type) {
    case types.GET_CLAIMS_DATA:
      return {
        ...state,
        claimsData: [],
        loader: false,
      };
    case types.GET_CLAIMS_DATA_SUCCESS:
      return {
        ...state,
        claimsData: action.data,
        Adjudicator: action.data.Adjudicator,
        Shop: action.data.Shop,
        adjudicator: action.data.adjudicator,
        shop: action.data.shop,
        totalActive: action.data.totalActive,
        loader: false,
      };
    case types.GET_CLAIMS_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loader: false,
        isError: action.payload,
      };
    case types.GET_TOKEN_VALIDITY:
      return {
        ...state,
      };
    case types.GET_TOKEN_VALIDITY_SUCCESS:
      return {
        ...state,
        expiresIn: action.data.data.expiresIn,
        expiryAlert: action.data.data.expiryAlert,
      };
    case types.GET_TOKEN_VALIDITY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.GET_CLOSED_CLAIMS_DATA:
      return {
        ...state,
        closedClaimsData: [],
        loader: false,
      };
    case types.GET_CLOSED_CLAIMS_DATA_SUCCESS:
      return {
        ...state,
        closedClaimsData: action.data,
        loader: false,
      };
    case types.GET_CLOSED_CLAIMS_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loader: false,
        isError: action.payload,
      };
    case types.GET_ACTIVE_SEARCHED_CLAIM_DATA:
      return {
        ...state,
        claimsSearchData: action.payload,
      };
    case types.GET_CLOSED_SEARCHED_CLAIM_DATA:
      return {
        ...state,
        closedClaimsData: action.payload,
      };
    case types.GET_CLOSED_DROPDOWN_DATA:
      return {
        ...state,
        closedClaimsDropdownData: action.payload,
      };
    case types.GET_CLOSED_MENU_SEARCH_DATA:
      return {
        ...state,
        closedSearchData: action.payload,
      };
    case types.GET_CLOSED_MENU_SEARCH_FAILURE:
      return {
        ...state,
        filteredClosedClaimsData: [],
        isError: action.payload,
        error: action.payload,
      };
    case types.GET_CLOSED_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        closedDropDownData: action.payload,
      };
    case types.GET_CLOSED_DROPDOWN_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        isError: action.error,
      };
    case types.GET_REPORT_SEARCH_DATA:
      return {
        ...state,
        reportSearchData: action.payload,
      };
    case types.SHOW_CLOSED_SCREEN:
      return {
        ...state,
        closedScreenShow: action.payload,
      };
    default:
      return state;
  }
};

export default ClaimsReducer;
