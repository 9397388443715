import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  Stack,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  MainContainer,
  Heading,
  Line,
  Cancel,
  Box,
  PlusLogo,
  ShopName,
  Message,
} from "./styled";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";
import successImg from "../../../assets/images/success.svg";
import removeUpload from "../../../assets/images/removeUpload.svg";
import Button from "../../../shared/commonComponents/button/button";
import BottomNavigation from "../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import progress1Shop from "../../../assets/images/progress1Shop.svg";
import close from "../../../assets/images/close.svg";
import { Header } from "../users/create/styled";
import add from "../../../assets/images/add.svg";
import {
  addNewShop,
  getClientList,
  fileUpload,
  handleError,
} from "../../../store/userManagment/action";
import AddShopUser from "../users/create/shopUser";
import SessionStorageService from "../../../services/functional/accounts/login/storage";

const Shops = (props) => {
  const dispatch = useDispatch();
  const [usernameError, setUsernameError] = useState(false);
  const [shop, setShop] = useState(null);
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [showUsers, setShowUsers] = useState(false);
  const [shopID, setShopID] = useState("");
  const [checked, setChecked] = useState(false);
  const [shopIdError, setShopIdError] = useState(false);
  const [shopAssurant, setShopAssurant] = useState("");

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const handleUserName = (event, value) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    setTimeout(() => {
      if (nameRegex.test(event.target.value)) {
        setUsernameError(false);
      } else {
        setUsernameError(true);
      }
    }, 2000);
    setShopAssurant(event.target.value);
  };

  const isError = useSelector((state) => state.userManagmentReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.userManagmentReducer?.error
  );

  const fileUploaded = useSelector(
    (state) => state.userManagmentReducer?.fileUpload
  );

  const handleErrorState = () => {
    dispatch(handleError());
  };

  const handleShopId = (event) => {
    const shopIdRegex = /^[0-9]*$/;
    setTimeout(() => {
      if (event.target.value.length === 6) {
        if (shopIdRegex.test(event.target.value)) {
          setShopIdError(false);
        } else {
          setShopIdError(true);
        }
      } else if (event.target.value.length === 0) {
        setShopIdError(false);
      } else {
        setShopIdError(true);
      }
    }, 2000);

    setShopID(event.target.value);
  };

  const fileInput = useRef();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    dispatch(getClientList());
  }, []);

  const uploadImageProgress = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setMessage("Uploaded");
        }
        return Math.min(oldProgress + 20, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProgress(0);
        uploadImageProgress();
        setPreview(reader.result);
        const formData = new FormData();
        formData.append("contas", "shop-logo");
        formData.append("filetype", "images/*");
        formData.append("image", image);

        dispatch(fileUpload(formData));
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const onContinue = () => {
    const shopdata = {
      store_name: props?.clientData?.name.toLowerCase().includes("assurant")
        ? shop.shop_name.trim()
        : shopAssurant.trim(),
      image_url: fileUploaded.data?.path,
      vi_type: checked ? 1 : 0,
      client_id: props?.clientData?._id,
      shop_id: shopID,
      admin_id: userDetails._id,
    };
    setShowUsers(true);
    dispatch(addNewShop(shopdata));
  };

  const closeUsers = () => {
    setShowUsers(false);
  };

  const closeShop = () => {
    props.onClose();
  };

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <MainContainer container>
        <Header>
          <img
            src={close}
            alt="close"
            onClick={props.onClose}
            style={{
              cursor: "pointer",
            }}
          />
        </Header>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          padding={"30px"}
          direction="row"
          style={{ backgroundColor: "#fff", justifyContent: "space-evenly" }}
        >
          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <div>
              <Heading className="new">New Shop</Heading>
              <div>
                <img src={progress1Shop} alt="progress1Shop" />
              </div>

              <div style={{ marginTop: "15px" }}>
                <Heading className="client-shop">Shop</Heading>
                <>
                  <Stack>
                    <TextField
                      freeSolo={true}
                      error={usernameError}
                      variant="standard"
                      fullWidth
                      onChange={handleUserName}
                      helperText={
                        usernameError ? "Please Enter Valid Name" : ""
                      }
                    />
                  </Stack>
                  <>
                    <Heading
                      style={{ marginTop: "28px" }}
                      className="client-shop"
                    >
                      Shop ID
                    </Heading>
                    <TextField
                      variant="standard"
                      fullWidth
                      error={shopIdError}
                      inputProps={{
                        maxLength: 6,
                      }}
                      onChange={handleShopId}
                      helperText={
                        shopIdError
                          ? "Contains Only 6 Digit Numeric Value"
                          : null
                      }
                    />
                  </>
                </>

                <Heading className="mobileApp">
                  <Checkbox checked={checked} onChange={handleCheckBox} />
                  Enable Mobile App Inspections
                </Heading>
              </div>
            </div>
          </Grid>
          <Line item xs={12} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <div style={{ padding: "0 0 0 15px" }}>
              <Heading className="details">Branding Details</Heading>
              <Heading className="logo-preview">Logo Preview</Heading>

              <div style={{ display: "grid" }}>
                <div>
                  <Box className="img">
                    {preview ? (
                      <img
                        src={preview}
                        alt="img"
                        style={{
                          width: "270px",
                          height: "80px",
                        }}
                      />
                    ) : null}
                  </Box>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  {preview ? (
                    <>
                      <ShopName>{fileUploaded?.data?.path}</ShopName>
                      <div>
                        <LinearProgress
                          variant="determinate"
                          color="success"
                          value={progress}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "space-evenly",
                          textAlign: "center",
                          display: "flex",
                        }}
                      >
                        {message === "Uploaded" ? (
                          <>
                            <div>
                              <img
                                src={successImg}
                                alt="success"
                                style={{ marginRight: "13px" }}
                              />
                            </div>
                            <div>
                              <img
                                src={removeUpload}
                                alt="delete"
                                onClick={() => {
                                  setPreview(null);
                                }}
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : shop || shopID ? (
                    <>
                      <PlusLogo>
                        <img
                          src={add}
                          alt="+logo"
                          style={{ padding: "2px" }}
                          onClick={(event) => {
                            event.preventDefault();
                            fileInput.current.click();
                          }}
                        />
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInput}
                          accept="image/*"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file && file.type.substring(0, 5) === "image") {
                              setImage(file);
                            } else {
                              setImage(null);
                            }
                          }}
                        />
                        <Heading className="logo">Logo</Heading>
                      </PlusLogo>
                    </>
                  ) : null}
                </div>
                {preview ? (
                  <>
                    <div>
                      <LinearProgress
                        variant="determinate"
                        color="success"
                        value={progress}
                      />
                    </div>
                    <Message>{message}</Message>
                  </>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
        <BottomNavigation
          opacity={"1"}
          position={"sticky"}
          padding={"17px 30px 0 30px"}
          justifyContent={"space-between"}
          moblieJustifyContent={"space-around"}
          borderRadius={"0px 0px 4px 4px"}
          minHeight={"55px"}
          bottom={"0"}
          left={"0"}
        >
          <Cancel onClick={props.onClose}>Cancel</Cancel>

          <Button
            show={true}
            width={"120px"}
            height={"32px"}
            clicked={onContinue}
            disabled={
              (shop || shopAssurant) &&
              shopID &&
              fileUploaded?.data?.path &&
              !usernameError &&
              !shopIdError &&
              preview
                ? false
                : true
            }
            primary={
              (shop || shopAssurant) &&
              shopID &&
              fileUploaded?.data?.path &&
              !usernameError &&
              !shopIdError &&
              preview
                ? true
                : false
            }
          >
            Continue
          </Button>
        </BottomNavigation>
      </MainContainer>
      {showUsers && (
        <AddShopUser
          close={closeUsers}
          addShop={shop}
          open={showUsers}
          shopId={shopID}
          clientData={props?.clientData}
          shopNameTpa={shopAssurant}
          page={props?.page}
          size={props?.size}
          sort={props?.sort}
          fromAddShop={"AddShop"}
          fromShop={closeShop}
        />
      )}
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
    </>
  );
};

export default Shops;
