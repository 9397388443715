export const SET_ADMIN_USERNAME_MAIL = "SET_ADMIN_USERNAME_MAIL";
export const SET_GENERAL_USERNAME_MAIL = "SET_GENERAL_USERNAME_MAIL";
export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAILURE = "GET_CLIENT_LIST_FAILURE";
export const GET_SHOP_LIST = "GET_SHOP_LIST";
export const GET_SHOP_LIST_SUCCESS = "GET_SHOP_LIST_SUCCESS";
export const GET_SHOP_LIST_FAILURE = "GET_SHOP_LIST_FAILURE";
export const GET_SHOP_LIST_ASSURANT = "GET_SHOP_LIST_ASSURANT";
export const GET_SHOP_LIST_ASSURANT_SUCCESS = "GET_SHOP_LIST_ASSURANT_SUCCESS";
export const GET_SHOP_LIST_ASSURANT_FAILURE = "GET_SHOP_LIST_ASSURANT_FAILURE";
export const ADD_NEW_USERS_TO_CLIENT = "ADD_NEW_USERS_TO_CLIENT";
export const ADD_NEW_USERS_TO_CLIENT_SUCCESS =
  "ADD_NEW_USERS_TO_CLIENT_SUCCESS";
export const ADD_NEW_USERS_TO_CLIENT_FAILURE =
  "ADD_NEW_USERS_TO_CLIENT_FAILURE";
export const ADD_NEW_SHOP = "ADD_NEW_SHOP";
export const ADD_NEW_USERS_TO_SHOP = "ADD_NEW_USERS_TO_SHOP";
export const ADD_NEW_USERS_TO_SHOP_SUCCESS = "ADD_NEW_USERS_TO_SHOP_SUCCESS";
export const ADD_NEW_USERS_TO_SHOP_FAILURE = "ADD_NEW_USERS_TO_SHOP_FAILURE";
export const ADD_NEW_SHOP_SUCCESS = "ADD_NEW_SHOP_SUCCESS";
export const ADD_NEW_SHOP_FAILURE = "ADD_NEW_SHOP_FAILURE";
export const GET_SHOP_LIST_CLIENT = "GET_SHOP_LIST_CLIENT";
export const GET_SHOP_LIST_CLIENT_SUCCESS = "GET_SHOP_LIST_CLIENT_SUCCESS";
export const GET_SHOP_LIST_CLIENT_FAILURE = "GET_SHOP_LIST_CLIENT_FAILURE";
export const GET_CLIENT_USERS = "GET_CLIENT_USERS";
export const GET_CLIENT_USERS_SUCCESS = "GET_CLIENT_USERS_SUCCESS";
export const GET_CLIENT_USERS_FAILURE = "GET_CLIENT_USERS_FAILURE";

export const GET_SHOP_USERS = "GET_SHOP_USERS";
export const GET_SHOP_USERS_SUCCESS = "GET_SHOP_USERS_SUCCESS";
export const GET_SHOP_USERS_FAILURE = "GET_SHOP_USERS_FAILURE";
export const SENT_INVITE_SHOP = "SENT_INVITE_SHOP";
export const SENT_INVITE_SHOP_SUCCESS = "SENT_INVITE_SHOP_SUCCESS";
export const SENT_INVITE_SHOP_FAILURE = "SENT_INVITE_SHOP_FAILURE";
export const SENT_INVITE_CLIENT = "SENT_INVITE_CLIENT";
export const SENT_INVITE_CLIENT_SUCCESS = "SENT_INVITE_CLIENT_SUCCESS";
export const SENT_INVITE_CLIENT_FAILURE = "SENT_INVITE_CLIENT_FAILURE";
export const SET_SHOPUSERS = "SET_SHOPUSERS";
export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_FAILURE = "RESEND_INVITE_FAILURE";
export const RESEND_INVITE_CLIENT = "RESEND_INVITE_CLIENT";
export const RESEND_INVITE_CLIENT_SUCCESS = "RESEND_INVITE_CLIENT_SUCCESS";
export const RESEND_INVITE_CLIENT_FAILURE = "RESEND_INVITE_CLIENT_FAILURE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const DELETE_CLIENT_USER = "DELETE_CLIENT_USER";
export const DELETE_CLIENT_USER_SUCCESS = "DELETE_CLIENT_USER_SUCCESS";
export const DELETE_CLIENT_USER_FAILURE = "DELETE_CLIENT_USER_FAILURE";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";
export const EDIT_CLIENT_USER = "EDIT_CLIENT_USER";
export const EDIT_CLIENT_USER_SUCCESS = "EDIT_CLIENT_USER_SUCCESS";
export const EDIT_CLIENT_USER_FAILURE = "EDIT_CLIENT_USER_FAILURE";
export const CLIENT_LOGO = "CLIENT_LOGO";
export const CLIENT_LOGO_SUCCESS = "CLIENT_LOGO_SUCCESS";
export const CLIENT_LOGO_FAILURE = "CLIENT_LOGO_FAILURE";
export const SHOP_LOGO = "SHOP_LOGO";
export const SHOP_LOGO_SUCCESS = "SHOP_LOGO_SUCCESS";
export const SHOP_LOGO_FAILURE = "SHOP_LOGO_FAILURE";
export const UPDATE_SHOP_USER = "UPDATE_SHOP_USER";
export const UPDATE_SHOP_USER_SUCCESS = "UPDATE_SHOP_USER_SUCCESS";
export const UPDATE_SHOP_USER_FAILURE = "UPDATE_SHOP_USER_FAILURE";
export const UPDATE_CLIENT_USER = "UPDATE_CLIENT_USER";
export const UPDATE_CLIENT_USER_SUCCESS = "UPDATE_CLIENT_USER_SUCCESS";
export const UPDATE_CLIENT_USER_FAILURE = "UPDATE_CLIENT_USER_FAILURE";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";
export const FILE_FETCH = "FILE_FETCH";
export const FILE_FETCH_SUCCESS = "FILE_FETCH_SUCCESS";
export const FILE_FETCH_FAILURE = "FILE_FETCH_FAILURE";
export const DISPLAY_IMAGE = "DISPLAY_IMAGE";
export const DELETE = "DELETE";
export const FILE_FETCH_HEADER = "FILE_FETCH_HEADER";
export const FILE_FETCH_HEADER_SUCCESS = "FILE_FETCH_HEADER_SUCCESS";
export const FILE_FETCH_HEADER_FAILURE = "FILE_FETCH_HEADER_FAILURE";
export const CHECK_SHOP_USER_MAIL = "CHECK_SHOP_USER_MAIL";
export const CHECK_SHOP_USER_MAIL_SUCCESS = "CHECK_SHOP_USER_MAIL_SUCCESS";
export const CHECK_SHOP_USER_MAIL_FAILURE = "CHECK_SHOP_USER_MAIL_FAILURE";
export const CHECK_CLIENT_USER_MAIL = "CHECK_CLIENT_USER_MAIL";
export const CHECK_CLIENT_USER_MAIL_SUCCESS = "CHECK_CLIENT_USER_MAIL_SUCCESS";
export const CHECK_CLIENT_USER_MAIL_FAILURE = "CHECK_CLIENT_USER_MAIL_FAILURE";
export const SET_CHECK_CLIENT_MAIL = "SET_CHECK_CLIENT_MAIL";
export const SET_CHECK_SHOP_MAIL = "SET_CHECK_SHOP_MAIL";
export const SEARCH_SHOP_FILTER = "SEARCH_SHOP_FILTER";
export const SEARCH_SHOP_FILTER_SUCCESS = "SEARCH_SHOP_FILTER_SUCCESS";
export const SEARCH_SHOP_FILTER_FAILURE = "SEARCH_SHOP_FILTER_FAILURE";
export const SEARCH_CLIENT_FILTER = "SEARCH_CLIENT_FILTER";
export const SEARCH_CLIENT_FILTER_SUCCESS = "SEARCH_CLIENT_FILTER_SUCCESS";
export const SEARCH_CLIENT_FILTER_FAILURE = "SEARCH_CLIENT_FILTER_FAILURE";
export const SORTING_USER = "SORTING_USER";
export const SORTING_SHOP_USER = "SORTING_SHOP_USER";
export const DATA = "DATA";
export const CLIENT_VALUES = "CLIENT_VALUES";
export const EMPTY_DATA = "EMPTY_DATA";
export const HANDLE_ERROR = "HANDLE_ERROR";
export const SEARCH_KEYWORD = "SEARCH_KEYWORD";
export const SEND_INVITES = "SEND_INVITES";
export const VI_TYPE_STATUS = "VI_TYPE_STATUS";
export const DELETE_SHOP_USER = "DELETE_SHOP_USER";
export const DELETE_SHOP_USER_SUCCESS = "DELETE_SHOP_USER_SUCCESS";
export const DELETE_SHOP_USER_FAILURE = "DELETE_SHOP_USER_FAILURE";
