import { put, call, takeEvery, all } from "redux-saga/effects";

import { reminderServices } from "../../services/functional";

import {
  GET_REMINDER_DETAILS,
  UPDATE_REMINDER_DETAILS,
  GET_REMINDER_DETAILS_SUCCESS,
  GET_REMINDER_DETAILS_FAILURE,
  UPDATE_REMINDER_DETAILS_SUCCESS,
  UPDATE_REMINDER_DETAILS_FAILURE,
  IS_REMAINDER_UPDATE,
} from "./types";

function* getReminderDetails({ payload }) {
  try {
    const response = yield call(reminderServices.getReminderServices, payload);

    yield put({ type: GET_REMINDER_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GET_REMINDER_DETAILS_FAILURE, payload: error });
  }
}

function* updateReminderDetails(action) {
  try {
    const response = yield call(
      reminderServices.updateReminderServices,
      action.payload,
      action.call,
      action?.clientid
    );

    yield call(getReminderDetails, { payload: action.clientid });
  } catch (error) {
    yield put({ type: UPDATE_REMINDER_DETAILS_FAILURE, payload: error });
  }
}

export function* watchGetReminderdetails() {
  yield takeEvery(GET_REMINDER_DETAILS, getReminderDetails);
}

export function* watchUpdateReminderdetails() {
  yield takeEvery(UPDATE_REMINDER_DETAILS, updateReminderDetails);
}

export function* mainReminderSaga() {
  yield all([watchGetReminderdetails(), watchUpdateReminderdetails()]);
}
