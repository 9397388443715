import React, { useState } from "react";
import { Dialog, Button } from "@mui/material";
import { Heading, Content, Header, Text } from "./errorHandle.styled";
import close from "../../../assets/images/close.svg";
import BottomNavigation from "../bottomNavigation/bottomNavigation";
import { useNavigate } from "react-router-dom";
import SessionStorageService from "../../../services/functional/accounts/login/storage";

const ErrorHandle = (props) => {
  const [showDialog, setDialog] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    if (props.acceptValue) {
      setDialog(false);
      props.errorState();
      SessionStorageService.clearAll();
      navigate("/");
    }
    setDialog(false);
    props.errorState();
  };
  return (
    <Dialog
      onClose={handleClose}
      open={showDialog}
      minWidth={"sm"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            overflow: "hidden",
            backgroundColor: "transparent !important",
            boxShadow: "none",
            minWidth: "445px",
            minHeight: "200px",
          },
        },
      }}
    >
      <Header>
        <img
          src={close}
          onClick={handleClose}
          style={{
            cursor: "pointer",
          }}
        />
      </Header>
      <div
        style={{
          marginLeft: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Heading style={{ padding: "1.5rem" }}>
          {props?.isErrorValue?.type === "Delete Error" ||
          props?.isClientErrorValue?.type === "Delete Error"
            ? "Cannot Delete User"
            : props.acceptValue
            ? null
            : "Something went wrong"}
        </Heading>
        <Content
          style={{
            padding: "1.5rem",
          }}
        >
          {props?.isErrorValue?.type === "Delete Error"
            ? props.isErrorValue.message
            : props?.isClientErrorValue?.type === "Delete Error"
            ? props.isClientErrorValue.message
            : props.acceptValue
            ? props?.acceptValue
            : props.message}
        </Content>

        <BottomNavigation
          justifyContent={"center"}
          margin={"40px 0 0 0"}
          minHeight={"35px"}
          backgroundColor={"#0070b9"}
        >
          <Button
            onClick={handleClose}
            style={{
              cursor: "pointer",
              background: "#0070b9",
              borderStyle: "none",
            }}
          >
            <Text>OK</Text>
          </Button>
        </BottomNavigation>
      </div>
    </Dialog>
  );
};
export default ErrorHandle;
