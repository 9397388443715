import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";

export const RequestCardContainer = styled.div`
  height: 400px;
  width: 420px;
  background: ${({ background }) => background || "#FFFFFF"};
  box-shadow: 0px 2px 4px rgba(20, 20, 20, 0.2);
  border-radius: 6px;
  margin: 12px;
  opacity: ;
`;
export const RequestCardHeader = styled.p`
  font-family: "OpenSans Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #596981;
  margin-left: 16px;
`;
export const RequestCheckBoxContainer = styled.div`
  display: "flex";
  flex-direction: "row";
  white-space: "nowrap";
`;

export const RequestCardCheckBox = styled(Checkbox)`
  height: 18px;
  width: 18px;
`;
export const RequestCheckboxlabel = styled.label`
  margin-left: 11px;
  font-family: "OpenSans Regular";
  opacity: 0.6;
  font-size: 16px;
  line-height: 28px;
  color: #3d515a;
`;
export const RequestPhotoText = styled.p`
  font-family: "OpenSans SemiBold";
  font-size: 14px;
  line-height: 18px;
  color: #596981;
  margin-left: 16px;
  margin-top: 44px;
`;
export const RequestPhotoSpan = styled.span`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 18px;
  color: #596981;
`;

export const RequestCameraCheckbox = styled(Checkbox)`
  height: 18px;
  width: 18px;
`;
export const RequestDescriptionLabel = styled.p`
  font-family: "OpenSans Regular";
  font-size: 9px;
  line-height: 12px;
  margin-left: 30px;
  color: #3d515a;
`;
export const RequestCardHelperText = styled.p`
  font-family: "OpenSans Regular";
  font-size: 12px;
  line-height: 12px;
  margin-left: 346px;
`;
