import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import PaginationItem from "@mui/material/PaginationItem";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SessionStorageService from "../../services/functional/accounts/login/storage";
import Loader from "../../shared/commonComponents/loader/loader";
import { Bottom, FootText } from "../../shared/commonComponents/footer/styled";
import Sort from "../../assets/images/keyboard-arrow-down.svg";
import {
  MainContainer,
  ReportingGrid,
  DownloadGrid,
  Header,
  SubHeaderContainer,
  SubHeaderDataGrid,
  SubHeaderDataName,
  SubHeaderDataNumber,
  DropdownMenuGrid,
  MonthYearGrid,
  AdjustorStatusGrid,
  MainHeaderContainer,
  HeaderTextContainer,
  BodyTextContainer,
  BodyGridContainer,
  SubHeaderText,
  SubHeaderTextContainer,
  SubHeader,
  HeaderTextContainerDesktop,
  HeaderTextContaineriPad,
  PageText,
  PaginationContainer,
  HeaderText,
} from "./index.styled";
import Download from "../../assets/images/download.svg";
import { isFooterReq } from "../../store/sharedComponents/footer/action";
import { headerType } from "../../store/sharedComponents/header/action";
import {
  downloadReport,
  getReportingData,
  reportHeaderData,
} from "../../store/reporting/action";
import { Pagination } from "@mui/material";
import { showLoader } from "../../store/sharedComponents/loader/action";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const Reporting = () => {
  const dispatch = useDispatch();
  const [adjudicatorName, setAdjudicatorName] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [status, setStatus] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [initialRow, setInitialRow] = useState(1);
  const [finalRow, setFinalRow] = useState(10);
  const [clientId, setClientId] = useState("");
  const [reverse, setReverse] = useState(false);
  const jwtTokenClientDetails = SessionStorageService.get("USER");
  const clientdetails = JSON.parse(jwtTokenClientDetails);
  useEffect(() => {
    dispatch(
      headerType({
        isSearch: true,
        isHeaderVi: false,
        isHamburger: true,
        isActiveClose: false,
        isLogo: true,
      })
    );
    dispatch(isFooterReq({ isFooterReq: true }));
  }, []);
  const searchText = useSelector(
    (state) => state.dashboardReducer.claimsReducer?.reportSearchData
  );
  const reportingData = useSelector(
    (state) => state.reportingReducer?.reportingData
  );
  const headerData = useSelector(
    (state) => state.reportingReducer?.reportHeaderData
  );
  useEffect(() => {
    if (clientdetails.roles === "super admin") {
      dispatch(reportHeaderData({ url: "header" }));
    } else {
      dispatch(reportHeaderData({ url: `header/${clientdetails.client_id}` }));
    }
  }, []);
  const getReportData = () => {
    dispatch(showLoader());
    if (clientdetails.roles === "super admin") {
      dispatch(
        getReportingData({
          clientId,
          Adjudicator: adjudicatorName,
          month,
          year,
          Status: status,
          searchString: searchText,
          pageNo,
          size: 10,
          reverse,
        })
      );
    } else {
      dispatch(
        getReportingData({
          clientId: clientdetails.client_id,
          Adjudicator: adjudicatorName,
          month,
          year,
          Status: status,
          searchString: searchText,
          pageNo,
          size: 10,
          reverse,
        })
      );
    }
  };
  useEffect(() => {
    if (reportingData?.yearsData?.count === 0) {
      updateRows(0);
    } else if (searchText) {
      updateRows(1);
      setPageNo(1);
    } else {
      updateRows(pageNo);
    }
  }, [reportingData?.yearsData?.count]);
  useEffect(() => {
    if (month) {
      if (year) {
        getReportData();
      }
    } else {
      getReportData();
    }
  }, [
    year,
    adjudicatorName,
    month,
    status,
    pageNo,
    clientId,
    searchText,
    reverse,
  ]);

  const shopAverage = headerData?.averageshop?.split(" ");
  const adjudicatorAverage = headerData?.averageadjudicator?.split(" ");

  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const STATUS_DATA = [
    { value: "OPEN", name: "Open" },
    { value: "CLOSED", name: "Closed" },
  ];

  const MONTH_DATA = [
    { value: "01", name: "01" },
    { value: "02", name: "02" },
    { value: "03", name: "03" },
    { value: "04", name: "04" },
    { value: "05", name: "05" },
    { value: "06", name: "06" },
    { value: "07", name: "07" },
    { value: "08", name: "08" },
    { value: "09", name: "09" },
    { value: "10", name: "10" },
    { value: "11", name: "11" },
    { value: "12", name: "12" },
  ];
  const YEAR_DATA = [{ value: "2022", name: "2022" }];
  const sortedData = reportingData?.superAdjudicatorName
    ? reportingData?.superAdjudicatorName?.sort((a, b) => {
        return a.toLowerCase() === b.toLowerCase()
          ? 0
          : a.toLowerCase() < b.toLowerCase()
          ? -1
          : 1;
      })
    : reportingData?.clientAdjudicatorName?.sort((a, b) => {
        return a.toLowerCase() === b.toLowerCase()
          ? 0
          : a.toLowerCase() < b.toLowerCase()
          ? -1
          : 1;
      });

  const ADJUSTOR_DATA = sortedData?.map((data) => {
    return {
      value: data,
      name: data,
    };
  });
  const sortedHeaderData = headerData?.clientList?.sort((a, b) => {
    return a.name.toLowerCase() === b.name.toLowerCase()
      ? 0
      : a.name.toLowerCase() < b.name.toLowerCase()
      ? -1
      : 1;
  });
  const ClientData = sortedHeaderData?.map((data) => {
    return {
      value: data._id,
      name: data.name,
    };
  });

  const handleDateChange = (e) => {
    setYear(e.target.value);
    setPageNo(1);
  };
  const handleAdjudicatorChange = (e) => {
    setAdjudicatorName(e.target.value);
    setPageNo(1);
  };
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    setPageNo(1);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setPageNo(1);
  };
  const handleClientChange = (e) => {
    setClientId(e.target.value);
    setPageNo(1);
  };
  const updateRows = (value) => {
    if (value === 0) {
      setInitialRow(0);
      setFinalRow(0);
    } else {
      setInitialRow(10 * value - 9);
      if (reportingData?.yearsData?.count > 10 * value) {
        setFinalRow(10 * value);
      } else {
        setFinalRow(reportingData?.yearsData?.count);
      }
    }
  };
  const handlePageChange = (e, value) => {
    setPageNo(value);
    updateRows(value);
  };
  const handleSortChange = () => {
    setReverse(!reverse);
  };
  const handleDownload = async () => {
    if (clientdetails.roles === "super admin") {
      dispatch(
        downloadReport({
          clientId,
          Adjudicator: adjudicatorName,
          month,
          year,
          Status: status,
          searchString: searchText,
          superAdmin: true,
        })
      );
    } else {
      dispatch(
        downloadReport({
          clientId: clientdetails.client_id,
          Adjudicator: adjudicatorName,
          month,
          year,
          Status: status,
          searchString: searchText,
          superAdmin: false,
        })
      );
    }
  };

  return (
    <>
      <MainContainer>
        <Header container xs={12} sm={12} md={12} lg={12} xl={12} mobile={12}>
          <ReportingGrid item xs={6} sm={6} md={6} lg={6} xl={6} mobile={12}>
            Reporting
          </ReportingGrid>
          <DownloadGrid item xs={6} sm={6} md={6} lg={6} xl={6} mobile={12}>
            {reportingData?.yearsData?.yearsData.length > 0 ? (
              <span onClick={handleDownload} style={{ cursor: "pointer" }}>
                <img src={Download} />
              </span>
            ) : (
              <span style={{ cursor: "not-allowed" }}>
                <img src={Download} />
              </span>
            )}
          </DownloadGrid>
        </Header>
        <SubHeaderContainer
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
        >
          <div>
            <SubHeader
              className="recent"
              item
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              mobile={12}
            >
              <SubHeaderText className="recentText">
                RECENT INSPECTIONS
              </SubHeaderText>
            </SubHeader>
            <SubHeaderDataGrid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mobile={12}
              style={{
                marginRight: "8px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SubHeaderTextContainer className="reqContainer">
                <SubHeaderDataName className="request">
                  Requested
                </SubHeaderDataName>
                <SubHeaderDataNumber>
                  {headerData?.requestedCount}
                </SubHeaderDataNumber>
              </SubHeaderTextContainer>

              <SubHeaderTextContainer className="completeContainer">
                <SubHeaderDataName className="completed">
                  Completed
                </SubHeaderDataName>
                <SubHeaderDataNumber>
                  {headerData?.completedCount}
                </SubHeaderDataNumber>
              </SubHeaderTextContainer>

              <SubHeaderTextContainer className="closedContainer">
                <SubHeaderDataName className="closed">Closed</SubHeaderDataName>
                <SubHeaderDataNumber>
                  {" "}
                  {headerData?.closedCount}
                </SubHeaderDataNumber>
              </SubHeaderTextContainer>
            </SubHeaderDataGrid>
          </div>
          <div>
            <SubHeader
              className="shop"
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              mobile={12}
            >
              <SubHeaderText className="shopText">
                Shop Completed (AVG)
              </SubHeaderText>
            </SubHeader>
            <SubHeaderDataNumber className="shopNumber">
              {shopAverage?.length > 0
                ? shopAverage?.[1] === "min"
                  ? shopAverage?.[0] === "NaN"
                    ? "0 min"
                    : parseFloat(shopAverage?.[0]).toFixed(0) >= 1
                    ? parseFloat(shopAverage?.[0]).toFixed(0) +
                      " " +
                      shopAverage?.[1]
                    : "< 1" + " " + shopAverage?.[1]
                  : shopAverage?.[0] === "NaN"
                  ? "0 min"
                  : parseFloat(shopAverage?.[0]).toFixed(1) >= 1
                  ? parseFloat(shopAverage?.[0]).toFixed(1) +
                    " " +
                    shopAverage?.[1]
                  : "< 1" + " " + shopAverage?.[1]
                : null}
            </SubHeaderDataNumber>
          </div>
          <div>
            <SubHeader
              className="review"
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              mobile={12}
            >
              <SubHeaderText className="reviewText">
                Adjudicator Review (AVG)
              </SubHeaderText>
            </SubHeader>
            <SubHeaderDataNumber className="adjudicatornumber">
              {adjudicatorAverage?.length > 0
                ? adjudicatorAverage?.[1] === "min"
                  ? adjudicatorAverage?.[0] === "NaN"
                    ? "0 min"
                    : parseFloat(adjudicatorAverage?.[0])?.toFixed(0) >= 1
                    ? parseFloat(adjudicatorAverage?.[0])?.toFixed(0) +
                      " " +
                      adjudicatorAverage?.[1]
                    : "< 1" + " " + adjudicatorAverage?.[1]
                  : adjudicatorAverage?.[0] === "NaN"
                  ? "0 min"
                  : parseFloat(adjudicatorAverage?.[0])?.toFixed(1) >= 1
                  ? parseFloat(adjudicatorAverage?.[0])?.toFixed(1) +
                    " " +
                    adjudicatorAverage?.[1]
                  : "< 1" + " " + adjudicatorAverage?.[1]
                : null}
            </SubHeaderDataNumber>
          </div>
        </SubHeaderContainer>
        <DropdownMenuGrid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          justifyContent={"space-between"}
        >
          {clientdetails.roles === "super admin" ? (
            <MonthYearGrid item xs={4} sm={4} md={4} lg={4} xl={4} mobile={12}>
              <FormControl>
                <InputLabel>
                  <span
                    style={{
                      fontFamily: "OpenSans Regular",
                      fontSize: "10px",
                    }}
                  >
                    Company{" "}
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  style={{ width: "130px", marginRight: "33px" }}
                  onChange={(e) => handleClientChange(e)}
                  value={clientId}
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                >
                  <MenuItem value={""}>{"All"}</MenuItem>
                  {ClientData?.map((data) => (
                    <MenuItem key={data.name} value={data.value}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>
                  <span
                    style={{
                      fontFamily: "OpenSans Regular",
                      fontSize: "10px",
                    }}
                  >
                    Month{" "}
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  style={{ width: "130px", marginRight: "33px" }}
                  onChange={(e) => handleMonthChange(e)}
                  value={month}
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                >
                  <MenuItem value={""}>{"All"}</MenuItem>
                  {MONTH_DATA.map(({ value, name }, index) => (
                    <MenuItem key={index} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>
                  <span
                    style={{
                      fontFamily: "OpenSans Regular",
                      fontSize: "10px",
                    }}
                  >
                    Year{" "}
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  style={{ width: "130px", marginRight: "33px" }}
                  onChange={(e) => handleDateChange(e)}
                  value={year}
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                >
                  <MenuItem value={""}>{"All"}</MenuItem>
                  {YEAR_DATA.map(({ value, name }, index) => (
                    <MenuItem key={index} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MonthYearGrid>
          ) : (
            <MonthYearGrid item xs={6} sm={6} md={6} lg={6} xl={6} mobile={12}>
              <FormControl>
                <InputLabel>
                  <span
                    style={{
                      fontFamily: "OpenSans Regular",
                      fontSize: "10px",
                    }}
                  >
                    Month{" "}
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  style={{ width: "130px", marginRight: "33px" }}
                  onChange={(e) => handleMonthChange(e)}
                  value={month}
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                >
                  <MenuItem value={""}>{"All"}</MenuItem>
                  {MONTH_DATA.map(({ value, name }, index) => (
                    <MenuItem key={index} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>
                  <span
                    style={{
                      fontFamily: "OpenSans Regular",
                      fontSize: "10px",
                    }}
                  >
                    Year{" "}
                  </span>
                </InputLabel>
                <Select
                  variant="standard"
                  style={{ width: "130px", marginRight: "33px" }}
                  onChange={(e) => handleDateChange(e)}
                  value={year}
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                >
                  <MenuItem value={""}>{"All"}</MenuItem>
                  {YEAR_DATA.map(({ value, name }, index) => (
                    <MenuItem key={index} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MonthYearGrid>
          )}
          <AdjustorStatusGrid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            mobile={12}
          >
            <FormControl>
              <InputLabel>
                <span
                  style={{ fontFamily: "OpenSans Regular", fontSize: "10px" }}
                >
                  Adjudicator{" "}
                </span>
              </InputLabel>
              <Select
                variant="standard"
                style={{ width: "130px", marginRight: "33px" }}
                onChange={(e) => handleAdjudicatorChange(e)}
                value={adjudicatorName}
                IconComponent={KeyboardArrowDownOutlinedIcon}
              >
                <MenuItem value={""}>{"All"}</MenuItem>
                {ADJUSTOR_DATA?.map(({ value, name }, index) => (
                  <MenuItem key={index} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>
                <span
                  style={{ fontFamily: "OpenSans Regular", fontSize: "10px" }}
                >
                  Status{" "}
                </span>
              </InputLabel>
              <Select
                variant="standard"
                style={{ width: "130px", marginRight: "33px" }}
                onChange={(e) => handleStatusChange(e)}
                value={status}
                IconComponent={KeyboardArrowDownOutlinedIcon}
              >
                <MenuItem value={""}>{"All"}</MenuItem>
                {STATUS_DATA.map(({ value, name }, index) => (
                  <MenuItem key={index} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AdjustorStatusGrid>
        </DropdownMenuGrid>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <MainHeaderContainer container direction="row">
              <HeaderTextContainer
                className="requestdate"
                item
                xs={1.4}
                sm={1.4}
                md={1.4}
                lg={1.4}
                xl={1.4}
                mobile={12}
                style={{ display: "flex" }}
              >
                <HeaderText style={{ marginLeft: "10px" }}>
                  Request Date
                </HeaderText>
                <div style={{ marginLeft: "9px", marginTop: "4px" }}>
                  {" "}
                  {reverse ? (
                    <img
                      src={Sort}
                      style={{ transform: "scale(1)", width: "6px" }}
                      onClick={handleSortChange}
                    />
                  ) : (
                    <img
                      src={Sort}
                      style={{ transform: "scale(-1)", width: "6px" }}
                      onClick={handleSortChange}
                    />
                  )}
                </div>
              </HeaderTextContainer>

              <HeaderTextContainer
                className="claim"
                item
                xs={1.3}
                sm={1.3}
                md={1.3}
                lg={1.3}
                xl={1.3}
                mobile={12}
              >
                <HeaderText>Claim #</HeaderText>
              </HeaderTextContainer>
              <HeaderTextContainer
                className="ro"
                item
                xs={1.3}
                sm={1.3}
                md={1.3}
                lg={1.3}
                xl={1.3}
                mobile={12}
              >
                <HeaderText>Ro #</HeaderText>
              </HeaderTextContainer>
              <HeaderTextContainer
                className="shop"
                item
                xs={2.5}
                sm={2.5}
                md={2.5}
                lg={2.5}
                xl={2.5}
                mobile={12}
              >
                <HeaderText>Shop Name</HeaderText>
              </HeaderTextContainer>
              <HeaderTextContainerDesktop
                className="completionfirst"
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                mobile={12}
              >
                <HeaderText>Completion</HeaderText>
              </HeaderTextContainerDesktop>
              <HeaderTextContaineriPad
                className="shopCompletion"
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                mobile={12}
              >
                <HeaderText>
                  Comp- <br />
                  letion
                </HeaderText>
              </HeaderTextContaineriPad>
              <HeaderTextContainer
                className="newadjudicator"
                item
                xs={2.5}
                sm={2.5}
                md={2.5}
                lg={2.5}
                xl={2.5}
                mobile={12}
              >
                <HeaderText>Adjudicator Name</HeaderText>
              </HeaderTextContainer>
              <HeaderTextContainerDesktop
                className="newname"
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                mobile={12}
              >
                <HeaderText>Completion</HeaderText>
              </HeaderTextContainerDesktop>
              <HeaderTextContaineriPad
                className="newname"
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                mobile={12}
              >
                <HeaderText>
                  Comp- <br />
                  letion
                </HeaderText>
              </HeaderTextContaineriPad>

              <HeaderTextContainer
                className="status"
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                mobile={12}
              >
                <HeaderText>Status</HeaderText>
              </HeaderTextContainer>
            </MainHeaderContainer>
            <BodyGridContainer container direction="row">
              {reportingData?.yearsData?.yearsData.length > 0 ? (
                reportingData?.yearsData?.yearsData?.map((data) => (
                  <>
                    <BodyTextContainer
                      className="requestData"
                      item
                      xs={1.4}
                      sm={1.4}
                      md={1.4}
                      lg={1.4}
                      xl={1.4}
                      mobile={12}
                    >
                      {data.requestedDate}
                    </BodyTextContainer>
                    <BodyTextContainer
                      className="claimdata"
                      item
                      xs={1.3}
                      sm={1.3}
                      md={1.3}
                      lg={1.3}
                      xl={1.3}
                      mobile={12}
                    >
                      {data.claim_number}
                    </BodyTextContainer>

                    <BodyTextContainer
                      className="rodata"
                      item
                      xs={1.3}
                      sm={1.3}
                      md={1.3}
                      lg={1.3}
                      xl={1.3}
                      mobile={12}
                    >
                      {data.ro_number}
                    </BodyTextContainer>
                    <BodyTextContainer
                      className="shop"
                      item
                      xs={2.5}
                      sm={2.5}
                      md={2.5}
                      lg={2.5}
                      xl={2.5}
                      mobile={12}
                    >
                      {data.shopName}
                    </BodyTextContainer>
                    <BodyTextContainer
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      mobile={12}
                      style={{ paddingLeft: "3px" }}
                    >
                      {data.shopCompletionTime}
                    </BodyTextContainer>
                    <BodyTextContainer
                      className="adjudictator"
                      item
                      xs={2.5}
                      sm={2.5}
                      md={2.5}
                      lg={2.5}
                      xl={2.5}
                      mobile={12}
                    >
                      {data.adjudicator}
                    </BodyTextContainer>
                    <BodyTextContainer
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      mobile={12}
                    >
                      {data.adjudicatorCompletionTime}
                    </BodyTextContainer>
                    <BodyTextContainer
                      className="statusdata"
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      xl={1}
                      mobile={12}
                    >
                      {data.status}
                    </BodyTextContainer>
                  </>
                ))
              ) : (
                <BodyTextContainer
                  className="requestData"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  mobile={12}
                >
                  No results found!
                </BodyTextContainer>
              )}
            </BodyGridContainer>
          </>
        )}
        {reportingData?.yearsData?.yearsData.length > 0 ? (
          <PaginationContainer>
            {!isLoading ? (
              <>
                <PageText>
                  Showing results {initialRow} -{finalRow} from{" "}
                  {reportingData?.yearsData?.count}
                </PageText>
                <Pagination
                  count={Math.ceil(reportingData?.yearsData?.count / 10)}
                  showFirstButton
                  showLastButton
                  page={pageNo}
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        first: KeyboardDoubleArrowLeftIcon,
                        last: KeyboardDoubleArrowRightIcon,
                      }}
                      {...item}
                    />
                  )}
                  onChange={(e, value) => handlePageChange(e, value)}
                ></Pagination>
              </>
            ) : null}
          </PaginationContainer>
        ) : null}
      </MainContainer>
      <div
        style={{
          position:
            reportingData?.yearsData?.yearsData.length <= 5 || isLoading
              ? "fixed"
              : "relative",
          bottom: 0,
          width: "100%",
        }}
      >
        <Bottom>
          <FootText>© 2022. Powered by Assurant. All rights reserved.</FootText>
        </Bottom>
      </div>
    </>
  );
};
export default Reporting;
