import React, { useEffect } from "react";
import { Dialog } from "@mui/material";
import {
  Heading,
  Content,
  Header,
  Text,
} from "./inspectionStartedDialogStyled.jsx";
import close from "../../../assets/images/close.svg";
import Button from "../../../shared/commonComponents/button/button";
import BottomNavigation from "../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import { useDispatch, useSelector } from "react-redux";
import { setInspectionStarted } from "../../../store/claimInspection/action";
import { useNavigate } from "react-router-dom";

const InspectionStarted = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.claimInspectionReducer?.inspectionStarted);
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );
  const handleContinue = () => {
    dispatch(setInspectionStarted(false));
    window.location =
      window.location.origin + `/inspection/edit/${currentClaim}`;
  };
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"xs"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            backgroundColor: "transparent !important",
            boxShadow: "none",
            borderRadius: ".5",
          },
        },
      }}
    >
      <Header>
        <img
          src={close}
          onClick={handleContinue}
          style={{
            cursor: "pointer",
          }}
        />
      </Header>
      <div style={{ padding: "30px", backgroundColor: "#fff" }}>
        <Heading>Inspection Started</Heading>
        <Content style={{ paddingTop: "20px" }}>
        Inspection in-progress. Edits cannot be requested
        until the inspection is returned.
        </Content>
        <BottomNavigation
          justifyContent={"center"}
          margin={"40px 0 0 0"}
          minHeight={"35px"}
        >
          <Button
            show={true}
            width={"148px"}
            height={"34px"}
            clicked={handleContinue}
            primary={true}
          >
            <Text>Continue</Text>
          </Button>
        </BottomNavigation>
      </div>
    </Dialog>
  );
};
export default InspectionStarted;
