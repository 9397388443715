import * as actionTypes from "./types";

const initialState = {
  isLoading: null,
  isLoadingComplete: false,
};

const loader = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADER:
      return {
        isLoading: true,
      };
    case actionTypes.HIDE_LOADER:
      return {
        isLoading: false,
      };
    case actionTypes.SHOW_CLOSED_LOADER:
      return {
        isLoadingComplete: false,
      };
    case actionTypes.HIDE_CLOSED_LOADER:
      return {
        isLoadingComplete: true,
      };
    default:
      return state;
  }
};
export default loader;
