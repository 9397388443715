import styled, { css } from "styled-components";
import media from "../../../styles/media.styled";

export const BottomNavigationContainer = styled.div`
  min-height: ${(props) => (props.minHeight ? props.minHeight : "60px")};
  display: flex;
  left: ${(props) => (props.left ? props.left : "0")};
  position: ${(props) => (props.position ? props.position : "sticky")};
  width: ${(props) => (props.width.standard ? props.width.standard : "100%")};
  bottom: ${(props) => (props.bottom ? props.bottom : "0")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  right: ${(props) => (props.right ? props.right : "0")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#ffffff"};
  justify-content: ${(props) =>
    props.desktopJustifyContent
      ? props.desktopJustifyContent
      : "space-between"};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "")};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "")};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : ""};

  ${media.portraitDevice`
          width: ${(props) =>
            props.width.portraitDevice ? props.width.portraitDevice : "100%"};
    `}

  ${media.smalDevice`
        width: ${(props) =>
          props.width.smallDevice ? props.width.smallDevice : "100%"};
            position: ${({ buttonsPosition }) =>
              buttonsPosition ? buttonsPosition : undefined} ;
            justify-content: ${(props) =>
              props.smalDeviceJustifyContent && props.smalDeviceJustifyContent};
            height: ${(props) => props.height && props.height};
    `}

    @media screen and (min-width: 830px) and (max-width: 840px) and (orientation: portrait) {
    height: ${(props) => props.height && props.height};
    justify-content: ${(props) =>
      props.smalDeviceJustifyContent && props.smalDeviceJustifyContent};
    position: ${(props) => props.portraitposition && props.portraitposition};
  }
  @media screen and (min-width: 768px) and (max-width: 780px) and (orientation: portrait) {
    position: ${(props) => props.portraitposition && props.portraitposition};
  }

  ${media.mobileDevice`
        margin-top:-2px;
        position: ${(props) =>
          props.mbPosition ? props.mbPosition : "sticky"};
        justify-content: ${(props) =>
          props.moblieJustifyContent ? props.moblieJustifyContent : ""};
        z-index:${(props) => (props.zIndex ? props.zIndex : "2000")};   
        padding: ${(props) => (props.mbPadding ? props.mbPadding : "")};
    `}
`;
