import { put, call, takeEvery, all, takeLatest } from "redux-saga/effects";
import { loginServices } from "../../../services/functional";
import SessionStorageService from "../../../services/functional/accounts/login/storage";
import * as types from "./types";

export function* getJWTToken({ payload }) {
  try {
    const response = yield call(loginServices.getJWTTokenService, payload);
    SessionStorageService.set("JWT_Token", response.data.data.user.jwt_token);
    SessionStorageService.set(
      "CLIENT_DETAILS",
      JSON.stringify(response.data.data.clientDetails)
    );
    SessionStorageService.set("USER", JSON.stringify(response.data.data.user));
    yield put({ type: types.GET_JWT_TOKEN_SUCCESS, payload: response });
  } catch (error) {
    SessionStorageService.clearAll();
    yield put({
      type: types.GET_JWT_TOKEN_FAILURE,
      error: error.response.data,
    });
  }
}
export function* activateUser({ payload }) {
  try {
    const response = yield call(loginServices.activateUserService, payload);
    yield put({ type: types.ACTIVATE_USER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ACTIVATE_USER_FAILURE, error });
  }
}
export function* resetmail({ payload }) {
  try {
    const response = yield call(loginServices.resetMailService, payload);
    yield put({ type: types.SEND_RESET_MAIL_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: types.SEND_RESET_MAIL_SUCCESS,
      payload: error.response.data,
    });
  }
}
export function* resetPassword({ payload }) {
  try {
    const response = yield call(loginServices.resetPasswordService, payload);
    yield put({ type: types.RESET_PASSWORD_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_FAILURE, error });
  }
}
export function* supportRequest({ payload }) {
  try {
    const response = yield call(loginServices.supportRequestService, payload);
    yield put({ type: types.SUPPORT_REQUEST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.SUPPORT_REQUEST_FAILURE, error });
  }
}

export function* watchGetJWTToken() {
  yield takeEvery(types.GET_JWT_TOKEN, getJWTToken);
}
export function* watchActivateUser() {
  yield takeEvery(types.ACTIVATE_USER, activateUser);
}
export function* watchSendResetMail() {
  yield takeEvery(types.SEND_RESET_MAIL, resetmail);
}
export function* watchResetPassword() {
  yield takeEvery(types.RESET_PASSWORD, resetPassword);
}
export function* watchSupportRequest() {
  yield takeEvery(types.SUPPORT_REQUEST, supportRequest);
}

export function* mainAccountSaga() {
  yield all([
    watchGetJWTToken(),
    watchActivateUser(),
    watchSendResetMail(),
    watchResetPassword(),
    watchSupportRequest(),
  ]);
}
