import { request } from "../../../services/core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getApiKeyDataService = (payload) => {
  return request(METHOD_TYPES.GET, `${ENDPOINTS.API_KEY}/${payload}/key`);
};

export const getPdfDataService = async (payload) => {
  return await request(
    METHOD_TYPES.GET,
    `${ENDPOINTS.API_KEY}/document/${payload}`,
    {},
    {
      responseType: "arraybuffer",
    }
  );
};
