import styled from "styled-components";
import media from "../../styles/media.styled";

export const Heading = styled.div`
  font-family: "OpenSans SemiBold";
  font-size: 18px;
  line-height: 27px;
  color: #13223c;
`;

export const SubHeading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 20px;
  padding-top: 5px;
  color: #68758b;
`;
export const Image = styled.image`
  padding-top: 20px;
  display: block;
  margin: 0 auto;
  width: 260px;
  height: 55px;
`;

export const Header = styled.div`
  direction: rtl;
  background-color: transparent !important;
  color: white !important;
  width: 100%;
`;

export const SubHeader = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 28px;
  color: #2972c8;

  &.heading {
    font-family: "OpenSans Regular";
    font-size: 14px;
    line-height: 24px;
    color: #68758b;
  }
`;

export const Text = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 22px;
`;

export const SaveButton = styled.button`
  width: 158px;
  height: 41px;

  background: #252525;
  background: ${({ background }) => (background ? background : "#2972C8")};
  border: 1px solid rgba(20, 20, 20, 0.3);
  border-radius: 20px;
  color: #ffffff;
  ${media.smalDevice`
     width: 120px;   
    `}

  &.mainSave {
    margin-left: 20px;
    margin-bottom: 6px;
  }
`;
export const SaveText = styled.div`
  font-family: OpenSans Regular;
  font-size: 16px;
  color: ${({ color }) => (color ? color : "#FFFFFF")};
  cursor: pointer;
`;
