import styled from "styled-components";
import { Grid } from "@mui/material";
import media from "../../styles/media.styled";

export const Dashboard = styled(Grid)`
  margin: 40px 10px 40px 30px;
  justify-content: space-evenly;
  width: 98%;
`;

export const Shop = styled(Grid)`
  font-family: OpenSans Bold;
`;

export const DashboardGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DashboardTextContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(100, 128, 200, 0.1);
  border-radius: 8px;
  height: 154px;
  padding-left: 13px;
  width: 330px;
  display: flex;
  align-items: center;
`;

export const Container = styled(Grid)`
  height: 133px;
  padding-left: 13px;
`;

export const BodyGridContainer = styled(Grid)``;

export const BodyTextContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Box = styled(Grid)`
  display: block;
  postion: absolute;
  top: 206px;
  left: 206px;
`;

export const DashboardColumn = styled.div`
  display: block;
  text-align: left;
  margin-left: 15px;
  margin-right: 10px;
  ${media.mediumDevice`
  margin-right: 0px;
  `}
`;
export const Admin = styled.h3`
  font-size: 24px;
  line-height: 38px;
  color: #13223c;
  font-weight: 700;
  font-family: OpenSans Bold;
`;

export const DashboardText = styled.div`
  margin-top: -1rem;
  font-size: 14px;
  color: #3d515a;
  font-weight: 400;
  margin-right: -2px;
  font-style: normal;
  font-family: OpenSans Regular;
  line-height: 21px;
  width: 201px;
`;

export const Support = styled(Grid)`
  display: flex;
  color: #2972c8;
  margin-left: ${(marginLeft) => marginLeft || ""};
  justify-content: ${(justifyContent) => justifyContent || ""};
  font-size: 16px;
  font-family: OpenSans Bold;
  a:link {
    text-decoration: none;
    color: #2972c8;
  }
`;

export const FooterContainer = styled.div`
  background-color: #303340;
  padding-left: 40px;
  padding-top: 18px;
  padding-bottom: 18px;
`;

export const FooterText = styled.div`
  font-family: OpenSans Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
`;

export const MainHeaderText = styled.h4`
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  font-family: OpenSans Regular;
  margin-bottom: 0px;
  color: #13223c;
`;
export const HeaderText = styled.h4`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  font-family: OpenSans Bold;
  margin-bottom: 0px;
  color: #3d515a;
`;
export const ReminderHeader = styled.div`
  margin-left: 13px;
`;

export const Description = styled.h4`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  font-family: OpenSans Regular;
  margin-top: 0px;
  color: #68758b;
`;

export const ReminderContainer = styled(Grid)`
  background: #ffffff;
  padding-left: 13px;
`;

export const Adjudicator = styled(Grid)`
  text-align: left;
  font-family: OpenSans Bold;
`;

export const Remindertext = styled.div`
  margin-top: 15px;
  font-family: OpenSans Regular;
  color: #3d515a;
  font-size: 14px;
`;

export const Input = styled.input`
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;

  color: #3d515a; ;
`;

export const Interval = styled.div`
  color: #3d515a;
  margin-left: 30px;
`;

export const Hours = styled.select`
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0.5px;
  color: #3d515a;
`;
export const IntervalText = styled.div`
  font-family: OpenSans Regular;
  font-size: 9px;
  color: #3d515a;
`;

export const ToggleText = styled.p`
  margin-top: 0px;
  margin-left: 5px;
  font-family: OpenSans Bold;
  font-size: 14px;
  color: #3d515a;
`;

export const ToggleButton = styled.div`
  display: flex;
`;

export const Line = styled.hr`
  border: 0.5px solid rgba(20, 20, 20, 0.2);
`;

export const DashboardBox = styled.div`
  padding-right: 60px;
`;
