import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import Loader from "../../../../shared/commonComponents/loader/loader";
import animation from "../../../../assets/images/animation.png";
import { useSelector, useDispatch } from "react-redux";
import {
  sendInvites,
  closeClientDialog,
  closeShopDialog,
  CloseResendDialogForShop,
  CloseResendDialogForClient,
} from "../../../../store/userManagment/action";
import { SentInvitesDiv, Heading } from "./styled";

const SendInvite = (props) => {
  const dispatch = useDispatch();

  const [isComplete, setComplete] = useState(false);

  const viTypeStatus = useSelector(
    (state) => state.userManagmentReducer?.viTypeStatus
  );
  const ClientInviteResponse = useSelector(
    (state) => state.userManagmentReducer?.sentInviteClient
  );
  const ShopInviteResponse = useSelector(
    (state) => state.userManagmentReducer?.sentInviteShop
  );

  const ClientResendInviteResponse = useSelector(
    (state) => state.userManagmentReducer?.resendInviteClient
  );

  const ShopResendInviteResponse = useSelector(
    (state) => state.userManagmentReducer?.resendInvite
  );

  useEffect(() => {
    if (props.fromClient === "client") {
      if (ClientInviteResponse === "SUCCESS") {
        setComplete(true);
      }
    } else if (props.fromShop === "shop") {
      if (ShopInviteResponse === "SUCCESS") {
        setComplete(true);
      }
    } else if (props.fromResendInvite === "resendInvite") {
      if (props.clientData && props.shopData) {
        if (ShopResendInviteResponse === "SUCCESS") {
          setComplete(true);
        }
      } else if (props.clientData && !props.shopData) {
        if (ClientResendInviteResponse === "SUCCESS") {
          setComplete(true);
        }
      }
    }
  }, [
    ClientInviteResponse,
    ShopInviteResponse,
    ClientResendInviteResponse,
    ShopResendInviteResponse,
  ]);
  if (isComplete) {
    setTimeout(() => {
      if (props.fromClient === "client") {
        dispatch(closeClientDialog("COMPLETE"));
      } else if (props.fromShop === "shop") {
        dispatch(closeShopDialog("COMPLETE"));
      } else if (props.fromResendInvite === "resendInvite") {
        if (props.clientData && props.shopData) {
          dispatch(CloseResendDialogForShop("COMPLETE"));
        } else if (props.clientData && !props.shopData) {
          dispatch(CloseResendDialogForClient("COMPLETE"));
        }
      }
      setComplete(false);

      dispatch(sendInvites(false));
    }, 1500);
  }

  const [dialogOpen, setDialogOpen] = useState(true);
  const [dialogClose, setDialogClose] = useState(false);

  return (
    <div>
      <Dialog
        open={dialogOpen}
        fullWidth
        minWidth={"lg"}
        onClose={dialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minWidth: "445px",
              minHeight: "270px",
              backgroundColor: "#ffffff",
              flex: 1,
              flexDirection: "column-reverse",
            },
          },
        }}
      >
        {isComplete ? (
          <>
            <SentInvitesDiv>
              <div
                style={{
                  margin: "38px 0 18px 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={animation} alt="complete" />
              </div>
              <Heading className="invites">Complete!</Heading>
            </SentInvitesDiv>
          </>
        ) : (
          <>
            <SentInvitesDiv>
              <div
                style={{
                  margin: "38px 0 18px 0",
                }}
              >
                <Loader />
              </div>
              <Heading className="invites">
                {viTypeStatus === 1 ||
                props.fromResendInvite === "resendInvite" ||
                props.fromClient === "client"
                  ? "Sending Invites..."
                  : "Users Added Successfully."}
              </Heading>
            </SentInvitesDiv>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default SendInvite;
