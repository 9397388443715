import styled from "styled-components";
import { Grid } from "@mui/material";
import media from "../../../styles/media.styled";

export const HeaderContainer = styled(Grid)`
  margin-bottom: 15px;
`;

export const HeaderTextContainer = styled(Grid)`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 19px;
  color: #13223c;
  text-transform: uppercase;
`;

export const BodyGridContainer = styled(Grid)``;

export const BodyTextContainer = styled(Grid)`
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(20, 20, 20, 0.2);
  padding-top: 15px;
  height: 50px;
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 18px;
  color: #3d515a;
`;

export const MainContainer = styled(Grid)`
  filter: drop-shadow(0px 3px 14px rgba(100, 128, 200, 0.1));
  left: 0;
  position: "fixed";
  top: "0";
  width: "100%";
  height: "80px";
  zindex: "1000";
`;

export const ClaimContainer = styled(Grid)`
  font-family: "OpenSans Regular";
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1.5px;
  color: #13223c;
  margin-bottom: 25px;
`;

export const MainGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const SubHeader = styled(Grid)`
  display: flex;
`;

export const HeaderGrid = styled(Grid)`
  margin-bottom: 25px !important;
  border-radius: 0px;
  background: #ffffff;
  display: flex;
`;

export const Pagination = styled(Grid)`
  height: 28px;
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  margin-top: 20px;
  color: #3d515a;
`;

export const SearchBoxContainer = styled.div`
  display: flex;
  margin-left: 3rem;
  height: 35px;
  border-bottom: 1px solid #d3d3d3;
  font-family: ${({ fontFamily }) => fontFamily || "OpenSans Regular"};
  text-align: left;
  color: #141414;
  align-items: center;
  background: ${({ background }) => background || "#FFFFFF"};
  width: ${({ width }) => width || "20%"};

  ${media.mobileDevice`
        width: ${({ mobileWidth }) => mobileWidth || "100%"};
        height: ${({ mobileHeight }) => mobileHeight || "40px"};
        border-radius: 20px;
        margin: 10px 0 5px 0;                  
    `};
`;

export const StyledInput = styled.input`
  margin-left: 16px;
  font-size: 16px !important;
  outline: none;
  border: none;
  width: ${({ width }) => width || "80%"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-family: OpenSans Regular;
  line-height: 32px;
  border-radius: ${({ borderRadius }) => borderRadius || "20px"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #44464b;
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  ::placeholder {
    /* Firefox */
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  ::-webkit-input-placeholder {
    /* Safari */
    font-family: "OpenSans Regular";
    opacity: 1;
  }

  ${media.smalDevice`
        width: 100%;
        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        ::placeholder {
            /* Firefox */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        ::-webkit-input-placeholder {
            /* Safari */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
    `}

  ${media.mobileDevice`
         font-size: 14px;
    `}
`;
export const IconContainer = styled.div`
  width: ${({ width }) => width || "30px"};
  height: ${({ height }) => height || "20px"};
  opacity: 1;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d515a;
  ${media.smalDevice`
        width:  20px;
        height: : 20px;
        margin: 0 0 0 5px;
    `}

  ${media.mobileDevice`
        width: ${({ mobileWidth }) => mobileWidth || "30px"};
        height: ${({ mobileHeight }) => mobileHeight || "20px"};
        opacity:1;
        margin: 0 0 0 10px;
    `}
`;

export const Status = styled.div`
  margin-left: 50px;
  .toggle--button {
    background-color: #767676;
  }
  .toggle--close {
    background-color: #13223c;
  }
`;
