import React, { useEffect, useState } from "react";
import {
  ReminderContainer,
  DashboardColumn,
  DashboardTextContainer,
  DashboardGrid,
  Admin,
  DashboardText,
  BodyTextContainer,
  Support,
  DashboardBox,
} from "./styled";
import analytics from "../../../src/assets/images/analytics.svg";
import usergroup from "../../../src/assets/images/user-group.svg";
import chatbubbles from "../../../src/assets/images/chat-bubbles.svg";
import API from "../../../src/assets/images/API.svg";
import divider from "../../../src/assets/images/divider.svg";
import Vector from "../../../src/assets/images/Vector.svg";
import mail from "../../../src/assets/images/mail.svg";
import videocam from "../../../src/assets/images/videocam.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { headerType } from "../../store/sharedComponents/header/action";
import { isFooterReq } from "../../store/sharedComponents/footer/action";
import ApiKey from "../apiKey/apiKey";
import ContactSupport from "../contactSupport/contactSupport";

const Administration = () => {
  useEffect(() => {
    dispatch(
      headerType({
        isSearch: false,
        isHeaderVi: false,
        isHamburger: true,
        isActiveClose: false,
        isLogo: true,
      })
    );
    dispatch(isFooterReq({ isFooterReq: true }));
  }, []);

  const navigateToUserMang = () => {
    navigate("/setup");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToReminder = () => {
    navigate("/reminder");
  };

  const navigateToReporting = () => {
    navigate("/reporting");
  };
  const [showApiKey, setshowApiKey] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const handleClickOpen = () => {
    setshowApiKey(true);
  };

  const handleClose = () => {
    setshowApiKey(false);
  };

  const handleClickOpenSupport = () => {
    setShowSupport(true);
  };

  const handleCloseSupport = () => {
    setShowSupport(false);
  };

  useEffect(() => {
    if (
      location.state &&
      location.state.message === "RedirectFromBannerAlert"
    ) {
      setshowApiKey(true);
    }
  }, []);
  return (
    <>
      <>
        <ReminderContainer
          container
          justifyContent={"center"}
          direction="row"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            margin: "45px auto",
          }}
        >
          <DashboardGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mobile={12}
          >
            <DashboardBox>
              <DashboardTextContainer>
                <BodyTextContainer onClick={navigateToUserMang}>
                  <img
                    src={usergroup}
                    alt="user-group"
                    style={{ width: "20%" }}
                  ></img>
                  <DashboardColumn>
                    <Admin>Administration</Admin>
                    <DashboardText>
                      Setup/ manage repair shops who will use the mobile app or
                      web inspections.
                    </DashboardText>
                  </DashboardColumn>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 0 20px 10px",
                    }}
                  >
                    <div>
                      <img
                        src={divider}
                        alt="divider"
                        style={{
                          color: "#DBE1E2",
                          padding: "0 10px 0 5px",
                          height: "110px",
                        }}
                      ></img>
                    </div>
                    <div>
                      <img
                        src={Vector}
                        alt="Vector"
                        style={{
                          color: "#3C4D6A",
                          paddingRight: "16px",
                        }}
                      ></img>
                    </div>
                  </div>
                </BodyTextContainer>
              </DashboardTextContainer>
            </DashboardBox>
            <DashboardBox>
              <DashboardTextContainer>
                <BodyTextContainer onClick={navigateToReporting}>
                  <img
                    src={analytics}
                    alt="analytics"
                    style={{ width: "20%" }}
                  ></img>

                  <DashboardColumn>
                    <Admin>Reporting</Admin>
                    <DashboardText>
                      Track the number of completed VIs for billing and team
                      usage.
                    </DashboardText>
                  </DashboardColumn>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 0 20px 10px",
                    }}
                  >
                    <div>
                      <img
                        src={divider}
                        alt="divider"
                        style={{
                          color: "#DBE1E2",
                          padding: "0 10px 0 5px",
                          height: "110px",
                        }}
                      ></img>
                    </div>
                    <div>
                      <img
                        src={Vector}
                        alt="Vector"
                        style={{
                          color: "#3C4D6A",
                          paddingRight: "16px",
                        }}
                      ></img>
                    </div>
                  </div>
                </BodyTextContainer>
              </DashboardTextContainer>
            </DashboardBox>
          </DashboardGrid>
        </ReminderContainer>
        <ReminderContainer
          container
          justifyContent={"center"}
          direction="row"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            margin: "45px auto",
          }}
        >
          <DashboardGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mobile={12}
          >
            <DashboardBox>
              <DashboardTextContainer>
                <BodyTextContainer>
                  <img src={API} alt="API" style={{ width: "20%" }}></img>

                  <DashboardColumn onClick={handleClickOpen}>
                    <Admin>API Keys</Admin>
                    <DashboardText>
                      Input and access API keys and documentation.
                    </DashboardText>
                  </DashboardColumn>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 0 20px 10px",
                    }}
                  >
                    <div>
                      <img
                        src={divider}
                        alt="divider"
                        style={{
                          color: "#DBE1E2",
                          padding: "0 10px 0 5px",
                          height: "110px",
                        }}
                      ></img>
                    </div>
                    <div>
                      <img
                        src={Vector}
                        alt="Vector"
                        style={{
                          color: "#3C4D6A",
                          paddingRight: "16px",
                        }}
                      ></img>
                    </div>
                  </div>
                </BodyTextContainer>
              </DashboardTextContainer>
            </DashboardBox>
            <DashboardBox>
              <DashboardTextContainer>
                <BodyTextContainer onClick={navigateToReminder}>
                  <img
                    src={chatbubbles}
                    alt="chat-bubbles"
                    style={{ width: "20%" }}
                  ></img>

                  <DashboardColumn>
                    <Admin>Reminders</Admin>
                    <DashboardText>
                      Configure iterations for when notifications should be
                      sent.
                    </DashboardText>
                  </DashboardColumn>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 0 20px 10px",
                    }}
                  >
                    <div>
                      <img
                        src={divider}
                        alt="divider"
                        style={{
                          color: "#DBE1E2",
                          padding: "0 10px 0 5px",
                          height: "110px",
                        }}
                      ></img>
                    </div>
                    <div>
                      <img
                        src={Vector}
                        alt="Vector"
                        style={{
                          color: "#3C4D6A",
                          paddingRight: "16px",
                        }}
                      ></img>
                    </div>
                  </div>
                </BodyTextContainer>
              </DashboardTextContainer>
            </DashboardBox>
          </DashboardGrid>
        </ReminderContainer>
        <ReminderContainer
          container
          justifyContent={"center"}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Support
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            mobile={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ paddingRight: "65px" }}>
              <a
                href="https://vimeo.com/user/37009752/folder/15581212"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#2972C8", display: "flex", cursor: "pointer" }}
              >
                <img
                  src={videocam}
                  alt="videocam"
                  style={{
                    width: "18px",
                    marginRight: "10px",
                  }}
                ></img>
                Training Videos
              </a>
            </div>
          </Support>
          <Support
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            mobile={12}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{ cursor: "pointer", display: "flex" }}
              onClick={handleClickOpenSupport}
            >
              <img
                src={mail}
                alt="mail"
                style={{ width: "21px", marginRight: "10px" }}
              ></img>
              Contact Support
            </div>
          </Support>
        </ReminderContainer>
      </>
      {showApiKey && <ApiKey open={handleClickOpen} onClose={handleClose} />}
      {showSupport && (
        <ContactSupport
          open={handleClickOpenSupport}
          onClose={handleCloseSupport}
        />
      )}
    </>
  );
};

export default Administration;
