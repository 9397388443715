import * as types from "./types";

export const getActiveClaims = (payload) => {
  return {
    type: types.GET_CLAIMS_DATA,
    payload,
  };
};

export const getTokenValidity = (payload) => {
  return {
    type: types.GET_TOKEN_VALIDITY,
    payload,
  };
};

export const getClosedClaims = (payload) => {
  return {
    type: types.GET_CLOSED_CLAIMS_DATA,
    payload,
  };
};

export const getActiveSearchClaims = (payload) => {
  return {
    type: types.GET_ACTIVE_SEARCHED_CLAIM_DATA,
    payload,
  };
};

export const getClosedSearchClaims = (payload) => {
  return {
    type: types.GET_CLOSED_SEARCHED_CLAIM_DATA,
    payload,
  };
};

export const getClosedDropdownData = (payload) => {
  return {
    type: types.GET_CLOSED_DROPDOWN_DATA,
    payload,
  };
};

export const activeSearch = (payload) => ({
  type: types.GET_ACTIVE_SEARCHED_CLAIM_DATA,
  payload,
});
export const closedMenuSearch = (payload) => ({
  type: types.GET_CLOSED_MENU_SEARCH_DATA,
  payload,
});
export const reportSearchData = (payload) => ({
  type: types.GET_REPORT_SEARCH_DATA,
  payload,
});
export const setShowClosedScreen = (payload) => ({
  type: types.SHOW_CLOSED_SCREEN,
  payload,
});
