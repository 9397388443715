import React from "react";
import Header from "./Header";
import Footer from "../../../shared/commonComponents/footer/footer";
import EditInspectionRequest from "./editInspectionRequest";

const EditInspection = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          overflowX: "hidden",
        }}
      >
        <Header />
        <EditInspectionRequest />
        <Footer />
      </div>
    </>
  );
};

export default EditInspection;
