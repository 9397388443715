import { all } from "redux-saga/effects";
import { mainDashboardSaga } from "./inspection/dashboard/saga/dashboardSaga";
import { mainUserManagmentSaga } from "./userManagment/saga";
import { mainReminderSaga } from "./reminders/saga";
import { mainClaimInspectionSaga } from "./claimInspection/saga";
import { mainAccountSaga } from "./accounts/login/saga";
import { mainReportingSaga } from "./reporting/saga";
import { mainApiKeySaga } from "./apiKey/saga";

export function* mainSaga() {
  yield all([
    mainDashboardSaga(),
    mainUserManagmentSaga(),
    mainReminderSaga(),
    mainClaimInspectionSaga(),
    mainAccountSaga(),
    mainReportingSaga(),
    mainApiKeySaga(),
  ]);
}
