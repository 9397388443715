import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "./login-okta.css";

const OktaSignInWidget = ({ onSuccess, onError, logIn }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }
    const loginPrefix = "VIA__";
    const config = {
      widget: {
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
        redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
        issuer: process.env.REACT_APP_OKTA_ISSUER,
        scopes: ["openid", "profile", "email", "groups"],
        useInteractionCodeFlow: false,
        i18n: {
          en: {
            "primaryauth.title": "Virtual Inspection",
            "password.forgot.email.or.username.placeholder": "Email Address",
            "password.forgot.email.or.username.tooltip": "Email Address",
            "password.forgot.emailSent.desc":
              "Email has been sent to {0} with password reset instructions.",
            "primaryauth.username.placeholder": "Email Address or User ID",
            "primaryauth.username.tooltip": "Email Address or User ID",
            "model.validation.field.blank": "This field cannot be left blank",
          },
        },
        features: {
          rememberMe: true,
          multiOptionalFactorEnroll: true,
          showPasswordToggleOnSignInPage: true,
          passwordlessAuth: true,
        },
        transformUsername:
          logIn === "client"
            ? function (username, operation) {
                return username.indexOf(loginPrefix) > -1 ||
                  !username.includes("@")
                  ? username
                  : loginPrefix + username;
              }
            : function (username, operation) {
                return username.indexOf(loginPrefix) > -1 ||
                  !username.includes("@assurant")
                  ? username.replace("@", "")
                  : username;
              },
      },
    };
    const widget = new OktaSignIn(config.widget);
    const searchParams = new URL(window.location.href).searchParams;
    widget.otp = searchParams.get("otp");
    widget.state = searchParams.get("state");
    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return <div className="okta-widget" ref={widgetRef} />;
};

export default OktaSignInWidget;
