import styled from "styled-components";
import { Grid } from "@mui/material";
import media from "../../../../styles/media.styled";

export const HeaderContainer = styled(Grid)`
  margin-bottom: 15px;
`;

export const HeaderTextContainer = styled(Grid)`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 19px;
  color: #13223c;
  text-transform: uppercase;
  cursor: pointer;

  &.headerStatus {
    display: flex;
  }

  &.headerClaim {
    padding-left: 3.5%;
    ${media.smalDevice`
  padding-left: 3%;
    `} ${media.mediumDevice`
    padding-left: 2.3%;
      `};
  }

  &.totaltime {
    padding-left: 70px;
    ${media.smalDevice`
 padding-left: 25px;
    `}
    ${media.mediumDevice`
    padding-left: 42px;
       `}
  }
`;

export const PaginationContainer = styled(Grid)`
  display: flex;
  justify-content: end;
  padding-top: 20px;
`;

export const PageText = styled.div`
  padding-top: 4px;
  color: #3d515a;
  font-family: "OpenSans Regular";
`;

export const BodyGridContainer = styled(Grid)``;

export const BodyTextContainer = styled(Grid)`
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(20, 20, 20, 0.2);
  padding-top: 15px;
  height: 50px;
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 18px;
  color: #3d515a;
  cursor: pointer;

  &.bodyClaim {
    padding-left: 3.5%;
    ${media.smalDevice`
    padding-left: 3%;
      `} ${media.mediumDevice`
    padding-left: 2.3%;
      `};
  }

  &.bodyTime {
    padding-left: 70px;
    ${media.smalDevice`
 padding-left: 25px;
    `}
    ${media.mediumDevice`
    padding-left: 42px;
       `}
  }

  &.bodyStatus {
    ${media.smalDevice`
   letter-spacing: -1px;
      `}
  }
`;

export const ImageContainer = styled.div`
  &.dropdownImage {
    margin-left: 49px;
    ${media.smalDevice`
  margin-left: 34px;
    `}
  }
  &.sortImage {
    padding-left: 15px;
    ${media.smalDevice`
 padding-left: 7px;
    `}
  }
`;

export const MainContainer = styled(Grid)`
  margin: 20px;
  ${media.smalDevice`
  margin: 10px 10px 0 10px;
    `}
`;

export const ClaimContainer = styled(Grid)`
  font-family: "OpenSans Bold";
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1.5px;
  color: #13223c;
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const Header = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const DropdownGrid = styled(Grid)`
  display: flex;
  justify-content: end;
`;
