import * as types from "./types";

export const showLoader = (payload) => {
  return {
    type: types.SHOW_LOADER,
    payload,
  };
};

export const hideLoader = (payload) => {
  return {
    type: types.HIDE_LOADER,
    payload,
  };
};
export const showClosedLoader = (payload) => {
  return {
    type: types.SHOW_CLOSED_LOADER,
    payload,
  };
};

export const hideClosedLoader = (payload) => {
  return {
    type: types.HIDE_CLOSED_LOADER,
    payload,
  };
};
