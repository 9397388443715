import * as types from "./types";

const initialState = {
  reportingData: [],
  error: null,
  filteredReportingData: [],
  enableFilter: false,
  reportDownload: null,
  reportHeaderData: null,
};

export const ReportingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORTING_DATA_SUCCESS:
      return {
        ...state,
        reportingData: action.payload,
      };
    case types.GET_REPORTING_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.GET_FILTERED_REPORTING_DATA_SUCCESS:
      return {
        ...state,
        filteredReportingData: action.payload,
      };
    case types.GET_FILTERED_REPORTING_DATA_FAILURE:
      return {
        ...state,
        filteredReportingData: [],
        error: action.error,
      };
    case types.SET_FILTER_ENABLE:
      return {
        ...state,
        enableFilter: true,
      };
      case types.DOWNLOAD_REPORT_SUCCESS:
        return {
          ...state,
          reportDownload: action.payload,
        };
        case types.DOWNLOAD_REPORT_FALIURE:
          return {
            ...state,
            error: action.error,
            reportDownload: null,
          };
          case types.GET_REPORTING_HEADER_DATA_SUCCESS:
            return {
              ...state,
              reportHeaderData: action.payload,
            };
            case types.GET_REPORTING_HEADER_DATA_FAILURE:
              return {
                ...state,
                error: action.error,
                reportHeaderData: null,
              };
    default:
      return state;
  }
};
