import FormattedMesssage from "../commonComponents/formattedMessage";

export const constants = {
  "primaryauth.title": "Virtual Inspection",
  "password.forgot.email.or.username.placeholder": "Email Address",
  "password.forgot.email.or.username.tooltip": "Email Address",
  "password.forgot.emailSent.desc":
    "Email has been sent to {0} with password reset instructions.",
  "primaryauth.username.placeholder": "Email Address",
  "primaryauth.username.tooltip": "Email Address",
  "error.username.required": "Please enter a username",
  "error.password.required": "Please enter a password",
  "oie.password.incorrect.message": "Password is incorrect",
  "okta.errors.E0000004": "Sign in failed!",
  "okta.errors.E0000007": " Not found: {0}",
  help: "Need support?",
  loginHelp: "Login support?",
  create_account_button: "Create Account",
};

export const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const categoryType = [
  { name: "Dealers", type: "dealer" },
  { name: "Repair Shops", type: "repairshop" },
  { name: "Credit Union", type: "creditunion" },
];

export const preferancesType = [
  { name: "Preferred", type: "preferred" },
  { name: "All", type: "all" },
  { name: "Sort", type: "sort" },
];

export const timeIntervel = {
  oneSecond: 1000,
  twoSecond: 2000,
};
export const periodMenu = ["per day", "per week", "per month", "per year "];
export const sortBy = [
  {
    name: "Distance",
    type: "distance",
  },
  { name: "A-Z", type: "az" },
];
export const defaultPos = { lat: Number(30.3380574), lng: Number(-97.7052795) };

export const weekdayOrder = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const FACTORY_WARRANTY_LABEL = "Factory Warranty";
export const POLICY_LABEL_MAPPER = {
  evp: "Extended Vehicle Protection",
  gap: "GAP",
  windSheild: "External Wind Sheild Protection",
  war: "WAR",
  lw: "Limited Warranty",
  mechanical: "Mechanical Repair Coverage",
  ppm: "Pre-Paid Maintenance",
  theft: "Theft Protection",
  factoryWarranty: "Factory Warranty",
};

export const PRODUCT_TYPES = {
  EXTENDED_VEHICLE_PROTECTION: "EXTENDED VEHICLE PROTECTION",
  MECHANICAL_REPAIR_COVERAGE: "MECHANICAL REPAIR COVERAGE",
  PRE_PAID_MAINTENANCE: "PRE-PAID MAINTENANCE",
  APPEARANCE_PROTECTION: "APPEARANCE PROTECTION",
  PAINTLESS_DENT_REPAIR: "PAINTLESS DENT REPAIR",
  TIRE_AND_WHEEL_PROTECTION: "TIRE AND WHEEL PROTECTION",
  THREE_FOR_ONE_PROTECTION: "THREE FOR ONE PROTECTION",
  PAINT_FABRIC_LTHER_VINYL: "PAINT,FABRIC,LTHER&VINYL",
  LIMITED_WARRANTY: "LIMITED WARRANTY",
  GAP: "GAP",
};

export const USA_STATES = [
  { label: "AL", value: "AL" },
  { label: "AK", value: "AK" },
  { label: "AR", value: "AR" },
  { label: "AZ", value: "AZ" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "HI", value: "HI" },
  { label: "IA", value: "IA" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "MA", value: "MA" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "NC", value: "NC" },
  { label: "NE", value: "NE" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NV", value: "NV" },
  { label: "NY", value: "NY" },
  { label: "ND", value: "ND" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VT", value: "VT" },
  { label: "VA", value: "VA" },
  { label: "WA", value: "WA" },
  { label: "WI", value: "WI" },
  { label: "WV", value: "WV" },
  { label: "WY", value: "WY" },
];

export const CLAIMS_GRID_HEADER = [
  {
    fieldName: "createdOn",
    heading: <FormattedMesssage id="MY_CONTRACTS.GRID_HEADERS.DATE_OPENED" />,
    sortable: true,
  },
  {
    fieldName: "contractNumber",
    heading: (
      <FormattedMesssage id="MY_CONTRACTS.GRID_HEADERS.CONTRACT_NUMBER" />
    ),
  },
  {
    fieldName: "claimNumber",
    heading: <FormattedMesssage id="MY_CONTRACTS.GRID_HEADERS.CLAIM_NUMBER" />,
  },
  {
    fieldName: "status",
    heading: <FormattedMesssage id="MY_CONTRACTS.GRID_HEADERS.STATUS" />,
  },
  {
    fieldName: "shop",
    heading: <FormattedMesssage id="MY_CONTRACTS.GRID_HEADERS.SHOP" />,
  },
  { fieldName: "", heading: "" },
];
export const claimSupportMobileNumber = "(800) 621-2130";
export const faqs = [
  {
    question: "When does my agreement/service contract expire?",
    answer:
      "Tap 'Summary' on the main contract page next to 'FAQs' (you may need to return to the previous screen first).  Once on 'Summary' page, this can be found under “End Date”.",
  },
  {
    question: "Do I have a deductible?",
    answer:
      "To see if you have a deductible, tap ‘Summary’ next to 'FAQs' (you may need to return to the previous screen first).  \n\nDepending on product you purchased, a higher deductible may exist if repairs are completed are completed by an out-of-network repair facility.  The full terms and conditions (T&Cs) will go into further detail, and can be found on the 'Summary' page by scrolling to the bottom and viewing the full contract.",
  },
  {
    question: "Can I update my contact information online?",
    answer:
      "Yes, you can.  Please follow the steps below. \n\n1. Go to MRClaims.net\n2. Enter Agreement Number and Your Last Name\n3. Click ‘Search agreement. Now’\n4. Click ‘Update Your Information’\n5. Enter your updated information in the appropriate fields\n6. Click on ‘Submit’\n\nYour agreement number can be found by tapping ‘Summary’ next to 'FAQs' (you may need to return to the previous screen first).",
  },
];

export const externalURLs = {
  ASSURANT_GAP: "https://www.assurantgap.com",
};

export const BANNER_ALERT_LABELS = {
  BLUE_COLOR: "blue",
  ORANGE_COLOR: "orange",
  RED_COLOR: "red",
  MSG_15DAYS: "API Key Expiring in 15 Days",
  MSG_7DAYS: "API Key Expiring in 7 Days",
  MSG_1DAY: "API Key Expiring Today!",
  TAP_TO_UPDATE: "Tap to update",
};
