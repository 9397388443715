import React, { useEffect, useState, useRef } from "react";
import Header from "../../../shared/commonComponents/header/header";
import Footer from "../../../shared/commonComponents/footer/footer";
import { useLocation, useParams } from "react-router-dom";
import {
  ClaimContainer,
  ClaimHeader,
  ClaimText,
  ClaimDetails,
  SearchBoxContainer,
  StyledInput,
  IconContainer,
  ClaimComments,
  UploadContainer,
  UploadCount,
  UploadHeader,
  UploadImage,
  UploadText,
  UploadTextName,
  UploadContent,
  UploadStatus,
  Line,
  ClaimInspectionDetails,
  ClaimRequest,
} from "./editInspectionRequest.styled";
import { Button } from "../../../styles/inspectionHeader.styled";
import { useDispatch, useSelector } from "react-redux";
import { headerType } from "../../../store/sharedComponents/header/action";
import Search from "../../../assets/images/search.svg";
import downloadLogo from "../../../assets/images/download-bottom 1.svg";
import camera from "../../../assets/images/camera-1.svg";
import { isFooterReq } from "../../../store/sharedComponents/footer/action";
import leftSwiperControl from "../../../assets/images/leftSwiper.svg";
import rightSwiperControl from "../../../assets/images/rightSwiper.svg";
import {
  getClaimInspectionDetails,
  searchPart,
  setPart,
  getAttachmentRequestDetails,
  enableEdit,
  handleError,
} from "../../../store/claimInspection/action";
import useDebounce from "../dashboard/closed/useDebounce";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import video from "../../../assets/images/video.svg";
import { List, ListItemText, Popover, ListItemButton } from "@mui/material";
import AttachementRequestCard from "../../../shared/functionalComponents/atttachement-request/attachmentRequestCard";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./swipert.css";
import InspectionStarted from "./inspectionStartedDialog";
const EditInspectionRequest = (props) => {
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [indexOverlay, setIndexOverlay] = useState(2);
  const dispatch = useDispatch();
  const location = useLocation();
  const swiperRef = useRef(null);
  const { InspectionId } = useParams();
  const claimInspectionDetails = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );
  const addedParts = useSelector(
    (state) => state?.claimInspectionReducer.inspectionRequest
  );
  const partList = useSelector(
    (state) => state?.claimInspectionReducer?.searchPartList
  );
  const searchFailure = useSelector(
    (state) => state.claimInspectionReducer?.searchlistFailure
  );
  const edit = useSelector((state) => state.claimInspectionReducer?.enableEdit);

  const debounceSearch = useDebounce(searchText, 1000);
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );
  const isError = useSelector((state) => state.claimInspectionReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.claimInspectionReducer?.error?.message
  );
  const isInspectionStarted = useSelector(
    (state) => state.claimInspectionReducer?.inspectionStarted
  );
  const handleErrorState = () => {
    dispatch(handleError());
  };
  useEffect(() => {
    if (edit) {
      dispatch(enableEdit(false));
    }
  }, []);
  useEffect(() => {
    if (debounceSearch) {
      dispatch(searchPart(debounceSearch));
    }
  }, [debounceSearch]);
  const handlePartSearch = (event) => {
    if (event.target.value.length === 0) {
      setAnchorEl(event.currentTarget);
      setSearchText(event.target.value);
    } else {
      if (event.target.value.split(" ").join("").length > 0) {
        setAnchorEl(event.currentTarget);
        setSearchText(event.target.value);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePartSeleceted = (event, data) => {
    setAnchorEl(null);
    const val = addedParts.findIndex((element) => {
      return element.name === data;
    });
    if (val === -1) {
      const newRequest = {
        name: data,
        noise: false,
        leak: false,
        other_failure: false,
        min_image: 0,
        min_video: 0,
        instructions: "",
        attachment_type: "",
      };
      dispatch(setPart(newRequest));
    }
  };
  useEffect(() => {
    dispatch(getClaimInspectionDetails(InspectionId));
    dispatch(isFooterReq({ isFooterReq: true }));
  }, []);
  useEffect(() => {
    dispatch(getAttachmentRequestDetails(InspectionId));
  }, []);
  const handleAdd = () => {
    const val = addedParts.findIndex((element) => {
      return (
        element.name.split(" ").join("").toLowerCase() ===
        searchText.split(" ").join("").toLowerCase()
      );
    });
    if (val === -1) {
      const newRequest = {
        name: searchText,
        noise: false,
        leak: false,
        other_failure: false,
        min_image: 0,
        min_video: 0,
        image: false,
        video: false,
        instructions: "",
        attachment_type: "",
      };
      dispatch(setPart(newRequest));
    }
  };
  const handleSlideNext = () => {
    swiperRef.current.swiper.slideNext();
    if (
      swiperRef.current.swiper.activeIndex + 2 ===
      swiperRef.current.swiper.slides.length
    ) {
      setIndexOverlay(swiperRef.current.swiper.slides.length);
    } else {
      setIndexOverlay(indexOverlay + 1);
    }
  };
  const handleSlidePrev = () => {
    swiperRef.current.swiper.slidePrev();
    if (swiperRef.current.swiper.activeIndex === 0) {
      setIndexOverlay(2);
    } else {
      setIndexOverlay(indexOverlay - 1);
    }
  };
  return (
    <>
      <ClaimContainer
        container
        justifyContent={"space-around"}
        direction="row"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        mobile={12}
      >
        <div
          style={{
            height: "auto",
            display: "flex",
            width: "100%",
          }}
        >
          <ClaimHeader item xs={12} sm={3} md={3} lg={3} xl={3} mobile={12}>
            <ClaimDetails style={{ marginTop: "0px" }} ipadWidth={"280px"}>
              <ClaimText
                fontFamily={"OpenSans Bold"}
                fontSize={"18px"}
                lineHeight={"33px"}
              >
                Claim #{" "}
                <span>
                  {claimInspectionDetails.claimNumber &&
                    claimInspectionDetails.claimNumber.replace(/CLAIM-/, "")}
                </span>
              </ClaimText>
            </ClaimDetails>
            <ClaimDetails>
              RO: <span>{claimInspectionDetails.RO}</span>
            </ClaimDetails>
            <ClaimDetails>
              VIN:{" "}
              <span>
                {"***"}
                {claimInspectionDetails?.vin?.slice(
                  claimInspectionDetails.vin.length - 6
                )}
              </span>
            </ClaimDetails>
            <ClaimDetails>
              Shop: <span>{claimInspectionDetails.shop}</span>
            </ClaimDetails>
            <ClaimDetails>
              Inspector: <span>{claimInspectionDetails.Inspector}</span>
            </ClaimDetails>
            <ClaimDetails>
              Request type: <span>{claimInspectionDetails.RequestType}</span>
            </ClaimDetails>
            <ClaimDetails>
              Customer: <span>{claimInspectionDetails.Customer}</span>
            </ClaimDetails>
            <ClaimDetails>
              Vehicle:{" "}
              <span>
                {claimInspectionDetails?.vehicle_year +
                  " " +
                  claimInspectionDetails?.vehicle_make +
                  " " +
                  claimInspectionDetails?.Vehicle}
              </span>
            </ClaimDetails>
            <ClaimDetails>
              VI Completion: <span>{claimInspectionDetails.VICompletion}</span>
            </ClaimDetails>
            {edit ? null : (
              <>
                <ClaimComments>
                  Inspector Comments
                  <ClaimDetails style={{ marginTop: "0px", color: "#68758b" }}>
                    None
                  </ClaimDetails>
                </ClaimComments>

                <ClaimComments>
                  Uploads (0)
                  <img
                    style={{
                      marginLeft: "10px",
                      marginTop: "3px",
                      width: "16px",
                    }}
                    src={downloadLogo}
                    alt="downloadLogo"
                  />
                </ClaimComments>
                <UploadContainer>
                  {claimInspectionDetails.attachment_requests_attributes &&
                  claimInspectionDetails.attachment_requests_attributes.length >
                    0
                    ? claimInspectionDetails.attachment_requests_attributes.map(
                        (data) => {
                          return (
                            <>
                              <UploadContent>
                                <UploadHeader height={"38px"}>
                                  <UploadTextName marginTop={"8px"}>
                                    {data.name}
                                  </UploadTextName>
                                  {data?.min_video > 0 && (
                                    <UploadImage>
                                      {" "}
                                      <img
                                        src={video}
                                        alt="camera"
                                        style={{
                                          width: "20px",
                                          marginRight: "6px",
                                          marginBottom: "0px",
                                        }}
                                      />
                                      <UploadCount>
                                        {data?.min_video}
                                      </UploadCount>
                                    </UploadImage>
                                  )}
                                  {data?.min_image > 0 && (
                                    <UploadImage>
                                      {" "}
                                      <img
                                        src={camera}
                                        alt="camera"
                                        style={{
                                          width: "20px",
                                          marginRight: "6px",
                                          marginBottom: "0px",
                                        }}
                                      />
                                      <UploadCount>
                                        {data?.min_image}
                                      </UploadCount>
                                    </UploadImage>
                                  )}
                                </UploadHeader>
                              </UploadContent>
                              <UploadStatus>
                                Pending inspector upload...
                              </UploadStatus>
                            </>
                          );
                        }
                      )
                    : null}
                </UploadContainer>
              </>
            )}
          </ClaimHeader>
          {edit ? (
            <ClaimHeader item xs={12} sm={9} md={9} lg={9} xl={9} mobile={12}>
              <ClaimRequest marginLeft={"5rem"}>
                <ClaimText>Edit Requests</ClaimText>
                <div style={{ display: "flex" }}>
                  <SearchBoxContainer width={"240px"}>
                    <IconContainer
                      width={"19px"}
                      height={"19px"}
                      mobileWidth={"19px"}
                      mobileHeight={"19px"}
                    >
                      <img
                        src={Search}
                        alt="Search Icon"
                        width={"22px"}
                        height={"22px"}
                      />
                    </IconContainer>
                    <StyledInput
                      id="search"
                      type="text"
                      searchImg={Search}
                      placeholder={"Search..."}
                      autoComplete="off"
                      value={searchText}
                      onChange={(e) => handlePartSearch(e)}
                    />
                    {partList.length > 0 ? (
                      <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        disableAutoFocus={true}
                        disableEnforceFocus={true}
                        sx={{ height: "200px" }}
                      >
                        <List>
                          {partList?.map((data, index) => {
                            return (
                              <ListItemButton
                                key={index}
                                onClick={(e) => handlePartSeleceted(e, data)}
                              >
                                <ListItemText
                                  primary={`${data}`}
                                ></ListItemText>
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Popover>
                    ) : null}
                  </SearchBoxContainer>

                  <Button
                    marginTop={"0px"}
                    style={{ marginLeft: "18px" }}
                    width={"16%"}
                    padding={"6px 24px"}
                    backgroundColor={
                      partList.length > 0 || !searchText.length > 0
                        ? "#FFFFFF"
                        : "black"
                    }
                    color={
                      partList.length > 0 || !searchText.length > 0
                        ? "black"
                        : "white"
                    }
                    onClick={handleAdd}
                    disabled={searchText.length > 0 ? false : true}
                  >
                    Add
                  </Button>
                </div>
                <div
                  style={{
                    marginLeft: "25px",
                    fontSize: "11px",
                    fontFamily: "OpenSans Regular",
                    color: "#3D515A",
                    marginTop: "4px",
                    lineHeight: "12px",
                    fontWeight: "400",
                  }}
                >
                  {searchText.length > 0 && !partList.length > 0
                    ? "Component not found. Try another term or click Add to continue."
                    : "Use common terms for parts or components"}
                </div>
                <ClaimInspectionDetails style={{ marginTop: "2rem" }}>
                  <div
                    style={{
                      width: "139%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Requested:</div>
                    <div
                      style={{
                        float: "right",
                        marginBottom: "-14px",
                        display:
                           addedParts.length > 2
                            ? "flex"
                            : "none",
                      }}
                    >
                      <img
                        src={leftSwiperControl}
                        style={{ cursor: "pointer" }}
                        onClick={handleSlidePrev}
                      ></img>
                      <img
                        src={rightSwiperControl}
                        style={{ cursor: "pointer" }}
                        onClick={handleSlideNext}
                      ></img>
                    </div>
                  </div>
                  <Line></Line>
                  <Swiper
                    ref={swiperRef}
                    modules={[Navigation, Pagination]}
                    style={{ width: "139%" }}
                    pagination={{ clickable: true }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 34,
                      },
                      1024: {
                        slidesPerView: 2,
                        spaceBetween: 34,
                      },
                      1280: {
                        slidesPerView: 2.3,
                        spaceBetween: 34,
                      },
                    }}
                  >
                    {addedParts.length > 0
                      ? addedParts.map((data, index) => {
                          return (
                            <SwiperSlide key={data.name}>
                              <AttachementRequestCard
                                header={data.name}
                                imageCountValue={data.min_image}
                                videoCountValue={data.min_video}
                                description={data.instructions}
                                leak={data.leak}
                                noise={data.noise}
                                other={data.other_failure}
                                image={
                                  data.attachment_type === "photo" ||
                                  data.attachment_type === "video/photo"
                                    ? true
                                    : false
                                }
                                video={
                                  data.attachment_type === "video" ||
                                  data.attachment_type === "video/photo"
                                    ? true
                                    : false
                                }
                                blur={
                                  index >= 2 && index === indexOverlay
                                    ? true
                                    : false
                                }
                                loc="edit"
                              />
                            </SwiperSlide>
                          );
                        })
                      : null}
                  </Swiper>
                </ClaimInspectionDetails>
                <ClaimInspectionDetails style={{ padding: "0px" }}>
                  Required:
                  <Line></Line>
                  <div style={{ display: "flex" }}>
                    <UploadContent>
                      <UploadHeader height={"35px"} width={"251px"}>
                        <UploadText marginTop={"5px"}>VIN</UploadText>
                        <UploadImage>
                          {" "}
                          <img
                            src={camera}
                            alt="camera"
                            style={{ width: "20px", marginRight: "6px" }}
                          />
                          <UploadCount marginTop={"5px"}>1</UploadCount>
                        </UploadImage>
                      </UploadHeader>
                    </UploadContent>
                    <UploadContent>
                      <UploadHeader
                        height={"35px"}
                        marginLeft={"14px"}
                        width={"251px"}
                      >
                        <UploadText marginTop={"5px"}>Odometer</UploadText>
                        <UploadImage>
                          {" "}
                          <img
                            src={camera}
                            alt="camera"
                            style={{ width: "20px", marginRight: "6px" }}
                          />
                          <UploadCount marginTop={"5px"}>1</UploadCount>
                        </UploadImage>
                      </UploadHeader>
                    </UploadContent>
                  </div>
                </ClaimInspectionDetails>
              </ClaimRequest>
            </ClaimHeader>
          ) : null}
        </div>
      </ClaimContainer>
      {isInspectionStarted && <InspectionStarted />}
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
    </>
  );
};

export default EditInspectionRequest;
