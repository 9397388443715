import { ShopOutlined } from "@mui/icons-material";
import * as types from "./types";

const initialSate = {
  adminName: [{}],
  generalName: [{}],
  generalEmail: [{}],
  adminEmail: [{}],
  adminUser: [],
  generalUser: [],
  clientList: [],
  shopList: [],
  shopListAssurant: [],
  admin: false,
  general: false,
  addNewUserToClient: [],
  addNewShop: [],
  addNewUsersToShop: [],
  shopListOfClient: [],
  clientUsers: [],
  shopAllUsers: [],
  clientValues: {},
  sentInviteShop: null,
  sentInviteClient: null,
  deleteUser: [],
  deleteClientUser: [],
  resendInvite: null,
  resendInviteClient: null,
  clientLogo: null,
  clientLogoSuccess: null,
  shopLogo: null,
  shopLogoSuccess: null,
  updateShopUser: false,
  updateClientUser: false,
  fileUpload: [],
  fileFetch: [],
  displayImage: null,
  addedNewShop: false,
  fileFetchHeader: [],
  shopUserMail: [],
  clientUserMail: [],
  shopEmailError: null,
  clientEmailError: null,
  searchShopFilter: [],
  searchClientFilter: [],
  sortingUsers: "",
  sortingShopUsers: "",
  data: [],
  isError: false,
  error: null,
  totalCount: null,
  searchKeyword: "",
  sendInvites: false,
  viTypeStatus: null,
  editClientUsers: null,
  editUsers: null,
  deleteShopUser: [],
};

export const UserManagmentReducer = (state = initialSate, action) => {
  switch (action.type) {
    case types.GET_CLIENT_LIST:
      return {
        ...state,
        clientList: [],
      };
    case types.GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientList: action.payload,
      };

    case types.GET_CLIENT_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.GET_SHOP_LIST_CLIENT:
      return {
        ...state,
        shopListOfClient: [],
      };
    case types.GET_SHOP_LIST_CLIENT_SUCCESS:
      return {
        ...state,
        shopListOfClient: action.payload,
      };

    case types.GET_SHOP_LIST_CLIENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    case types.GET_SHOP_LIST:
      return {
        ...state,
        shopList: [],
      };
    case types.GET_SHOP_LIST_SUCCESS:
      return {
        ...state,
        shopList: action.payload,
      };

    case types.GET_SHOP_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    case types.GET_SHOP_LIST_ASSURANT:
      return {
        ...state,
        shopListAssurant: [],
      };
    case types.GET_SHOP_LIST_ASSURANT_SUCCESS:
      return {
        ...state,
        shopListAssurant: action.payload,
      };

    case types.GET_SHOP_LIST_ASSURANT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.SET_ADMIN_USERNAME_MAIL:
      return {
        ...state,
        adminUser: [...state.adminUser, action.payload],
      };
    case types.ADD_NEW_USERS_TO_CLIENT:
      return {
        ...state,
        addNewUserToClient: [],
      };
    case types.ADD_NEW_USERS_TO_CLIENT_SUCCESS:
      return {
        ...state,
        addNewUserToClient: action.payload,
      };
    case types.ADD_NEW_USERS_TO_CLIENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.ADD_NEW_SHOP:
      return {
        ...state,
        addNewShop: [],
      };
    case types.ADD_NEW_SHOP_SUCCESS:
      return {
        ...state,
        addNewShop: action.payload,
        addedNewShop: true,
      };
    case types.ADD_NEW_SHOP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.ADD_NEW_USERS_TO_SHOP:
      return {
        ...state,
        addNewUserToShop: [],
      };
    case types.ADD_NEW_USERS_TO_SHOP_SUCCESS:
      return {
        ...state,
        addNewUserToShop: action.payload,
      };
    case types.ADD_NEW_USERS_TO_SHOP_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case types.GET_CLIENT_USERS:
      return {
        ...state,
        clientUsers: [],
        updateClientUser: false,
        updateShopUser: false,
      };
    case types.GET_CLIENT_USERS_SUCCESS:
      return {
        ...state,
        clientUsers: action.payload.sorted,
        totalCount: action.payload.count,
      };
    case types.GET_CLIENT_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    case types.GET_SHOP_USERS:
      return {
        ...state,
        shopAllUsers: action.data,
        updateClientUser: false,
        updateShopUser: false,
      };
    case types.GET_SHOP_USERS_SUCCESS:
      return {
        ...state,
        shopAllUsers: action.payload.sortShopUser,
        totalCount: action.payload.count,
      };
    case types.GET_SHOP_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    case types.SENT_INVITE_SHOP_SUCCESS:
      return {
        ...state,
        sentInviteShop: action.payload,
        adminUser: [],
        generalUser: [],
      };
    case types.SENT_INVITE_SHOP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    case types.SENT_INVITE_CLIENT_SUCCESS:
      return {
        ...state,
        sentInviteClient: action.payload,
        adminUser: [],
        generalUser: [],
      };
    case types.SENT_INVITE_CLIENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    case types.SET_GENERAL_USERNAME_MAIL:
      return {
        ...state,
        generalUser: [...state.generalUser, action.payload],
      };
    case types.SET_SHOPUSERS:
      return {
        ...state,
        shopAllUsers: [...state.shopAllUsers, action.payload],
      };

    case types.DELETE_USER:
      return {
        ...state,
        deleteUser: [],
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        shopAllUsers: state.shopAllUsers.filter(
          (data) => data._id !== action.payload._id
        ),
      };
    case types.DELETE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.DELETE_CLIENT_USER:
      return {
        ...state,
        deleteClientUser: [],
      };
    case types.DELETE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUsers: state.clientUsers.filter(
          (data) => data._id !== action.payload._id
        ),
      };
    case types.DELETE_CLIENT_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        resendInvite: action.payload,
      };
    case types.RESEND_INVITE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.RESEND_INVITE_CLIENT_SUCCESS:
      return {
        ...state,
        resendInviteClient: action.payload,
      };
    case types.RESEND_INVITE_CLIENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.EDIT_USER:
      return {
        ...state,
        editUsers: null,
      };
    case types.EDIT_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        editUsers: action.payload,
      };
    case types.EDIT_CLIENT_USER:
      return {
        ...state,
        editClientUsers: null,
      };
    case types.EDIT_CLIENT_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.EDIT_CLIENT_USER_SUCCESS:
      return {
        ...state,
        editClientUsers: action.payload,
      };
    case types.CLIENT_LOGO:
      return {
        ...state,
        clientLogo: null,
      };
    case types.CLIENT_LOGO_SUCCESS:
      return {
        ...state,
        clientLogoSuccess: action.payload,
        fileUpload: [],
      };
    case types.CLIENT_LOGO_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.SHOP_LOGO:
      return {
        ...state,
        shopLogo: null,
      };
    case types.SHOP_LOGO_SUCCESS:
      return {
        ...state,
        shopLogoSuccess: action.payload,
        fileUpload: [],
      };
    case types.SHOP_LOGO_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.FILE_UPLOAD:
      return {
        ...state,
        fileUpload: [],
      };
    case types.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        fileUpload: action.payload,
      };
    case types.FILE_UPLOAD_FAILURE:
      return {
        ...state,
        fileUpload: action.error,
        error: action.payload,
        isError: action.payload,
      };
    case types.FILE_FETCH_SUCCESS:
      return {
        ...state,
        fileFetch: action.payload,
        fileUpload: [],
      };
    case types.FILE_FETCH_FAILURE:
      return {
        ...state,
        fileFetch: action.error,
        error: action.payload,
        isError: action.payload,
      };
    case types.FILE_FETCH_HEADER_SUCCESS:
      return {
        ...state,
        fileFetchHeader: action.payload,
        fileUpload: [],
      };
    case types.FILE_FETCH_HEADER_FAILURE:
      return {
        ...state,
        fileFetchHeader: action.error,
        error: action.payload,
        isError: action.payload,
      };

    case types.UPDATE_SHOP_USER:
      return {
        ...state,
        updateShopUser: action.payload,
      };
    case types.UPDATE_CLIENT_USER:
      return {
        ...state,
        updateClientUser: action.payload,
      };

    case types.DISPLAY_IMAGE:
      return {
        ...state,
        displayImage: action.payload,
      };

    case types.DELETE:
      return {
        ...state,
        generalUser: state.generalUser.filter((data) => {
          return data.name !== action.payload;
        }),
        adminUser: state.adminUser.filter((data) => {
          return data.name !== action.payload;
        }),
      };
    case types.CHECK_SHOP_USER_MAIL:
      return {
        ...state,
        shopUserMail: [],
      };
    case types.CHECK_SHOP_USER_MAIL_SUCCESS:
      return {
        ...state,
        shopUserMail: action.payload,
      };
    case types.CHECK_SHOP_USER_MAIL_FAILURE:
      return {
        ...state,
        shopEmailError: action.payload,
      };
    case types.CHECK_CLIENT_USER_MAIL:
      return {
        ...state,
        clientUserMail: [],
      };
    case types.CHECK_CLIENT_USER_MAIL_SUCCESS:
      return {
        ...state,
        clientUserMail: action.payload,
      };
    case types.CHECK_CLIENT_USER_MAIL_FAILURE:
      return {
        ...state,
        clientEmailError: action.payload,
      };

    case types.SET_CHECK_CLIENT_MAIL:
      return {
        ...state,
        clientEmailError: action.payload,
      };
    case types.SET_CHECK_SHOP_MAIL:
      return {
        ...state,
        shopEmailError: action.payload,
      };
    case types.SEARCH_SHOP_FILTER:
      return {
        ...state,
        searchShopFilter: [],
      };
    case types.SEARCH_SHOP_FILTER_SUCCESS:
      return {
        ...state,
        shopAllUsers: action.payload.res,
        totalCount: action.payload.count,
      };
    case types.SEARCH_SHOP_FILTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    case types.SEARCH_CLIENT_FILTER:
      return {
        ...state,
        searchClientFilter: [],
      };
    case types.SEARCH_CLIENT_FILTER_SUCCESS:
      return {
        ...state,
        clientUsers: action.payload.res,
        totalCount: action.payload.count,
      };
    case types.SEARCH_CLIENT_FILTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };
    case types.DATA:
      return {
        ...state,
        data: {
          clientId: action.payload?.cli,
          shopId: action.payload?.sho,
          page: action.payload?.isPage,
          size: action.payload?.isSize,
          sortType: action.payload?.isSort,
        },
      };
    case types.EMPTY_DATA: {
      return {
        ...state,
        adminUser: [],
        generalUser: [],
      };
    }
    case types.HANDLE_ERROR: {
      return {
        ...state,
        isError: false,
        error: null,
      };
    }

    case types.SEARCH_KEYWORD: {
      return {
        ...state,
        searchKeyword: action.payload,
      };
    }

    case types.SEND_INVITES: {
      return {
        ...state,
        sendInvites: action.payload,
      };
    }

    case types.CLIENT_VALUES: {
      return {
        ...state,
        clientValues: action.payload,
      };
    }

    case types.VI_TYPE_STATUS: {
      return {
        ...state,
        viTypeStatus: action.payload,
      };
    }
    case types.DELETE_SHOP_USER:
      return {
        ...state,
        deleteShopUser: action.payload,
      };

    case types.DELETE_SHOP_USER_SUCCESS:
      return {
        ...state,
        shopAllUsers: state.shopAllUsers.filter(
          (data) => data._id !== action.payload._id
        ),
      };

    case types.DELETE_SHOP_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        isError: action.payload,
      };

    default:
      return state;
  }
};
