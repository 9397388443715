import React, { useEffect, useState } from "react";
import {
  MainContainer,
  HeaderGrid,
  Grid3,
  Grid2,
  Button,
} from "../../../styles/inspectionHeader.styled";
import assurantLogo from "../../../assets/images/assurant-logo.svg";
import { fileFetchHeader } from "../../../store/userManagment/action";
import { useNavigate } from "react-router-dom";
import { createNewInpectionRequest } from "../../../store/claimInspection/action";
import SessionStorageService from "../../../services/functional/accounts/login/storage";

import { useDispatch, useSelector } from "react-redux";
const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [preview, setPreview] = useState();
  const [disabled, setDisabled] = useState(false);

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const clientdetails = SessionStorageService.get("CLIENT_DETAILS");
  const parseClientDetails = JSON.parse(clientdetails);

  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer?.currentClaim
  );
  const enbaleRequest = useSelector(
    (state) => state.claimInspectionReducer?.enableNewInspection
  );
  const isLogo = useSelector((state) => state.headerReducer.condition?.isLogo);

  const ClaimRequestedDetails = useSelector(
    (state) => state.claimInspectionReducer?.inspectionRequest
  );
  const reset = useSelector((state) => state?.claimInspectionReducer?.reset);

  useEffect(() => {
    if (!userDetails.roles.toLowerCase().includes("super") && !fetchedUrl) {
      dispatch(fileFetchHeader({ link: parseClientDetails?.image_url }));
    }
  }, []);

  const fetchedUrl = useSelector(
    (state) => state.userManagmentReducer?.fileFetchHeader?.data
  );

  const displayImageUrl = () => {
    if (fetchedUrl) {
      const asset = fetchedUrl.asset;
      const assetValue = "data:image/png;base64," + asset;
      setPreview(assetValue);
    } else {
      setPreview(null);
    }
  };

  useEffect(() => {
    displayImageUrl();
  }, [fetchedUrl]);

  const createNewInspection = () => {
    if (disabled) {
      return null;
    } else {
      setDisabled(true);
      dispatch(
        createNewInpectionRequest({
          status: "REQUESTED",
          claimNo: currentClaim,
          support_doc_array: ClaimRequestedDetails,
          step: 1,
        })
      );
    }
  };
  useEffect(() => {
    if (reset) {
      navigate("/dashboard", { replace: true });
    }
  }, [reset]);

  return (
    <>
      <MainContainer
        style={{ position: "fixed", top: "0", width: "100%", zIndex: 3 }}
      >
        <HeaderGrid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} mobile={12}>
            {isLogo && (
              <img
                src={preview ? preview : assurantLogo}
                alt="ClientLogo"
                width={"130px"}
                height={"35px"}
                style={{ margin: "15px 0 16px 35px", cursor: "pointer" }}
                onClick={() =>
                  (window.location = window.location.origin + "/dashboard")
                }
              ></img>
            )}
          </Grid2>
          <Grid3
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            mobile={12}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Button
              disabled={enbaleRequest ? false : true}
              backgroundColor={enbaleRequest ? "black" : "#F1F1F1"}
              onClick={() => createNewInspection()}
              color={enbaleRequest ? "#ffffff" : "#666666"}
              mediumWidth={"50%"}
              ipadWidth={"68%"}
              width="261px"
              marginTop="0px"
            >
              Request New Inspection{" "}
            </Button>
          </Grid3>
        </HeaderGrid>
      </MainContainer>
    </>
  );
};
export default Header;
