import * as types from "./types";

export const getClaimInspectionDetails = (payload) => {
  return {
    type: types.GET_CLAIM_INSPECTION_DETAILS,
    payload,
  };
};

export const createNewInpectionRequest = (payload) => {
  return {
    type: types.CREATE_NEW_INPECTION_REQUEST,
    payload,
  };
};

export const resendInspection = (payload) => {
  return {
    type: types.RESEND_INSPECTION,
    payload,
  };
};

export const searchPart = (payload) => ({
  type: types.GET_SEARCH_PART_LIST,
  payload,
});
export const setPart = (payload) => ({
  type: types.SET_PART,
  payload,
});
export const setRequestedImageCount = (payload) => ({
  type: types.SET_REQUESTED_IMAGE_COUNT,
  payload,
});
export const setRequestedVideoCount = (payload) => ({
  type: types.SET_REQUESTED_VIDEO_COUNT,
  payload,
});
export const setRequestedNoise = (payload) => ({
  type: types.SET_REQUESTED_NOISE,
  payload,
});
export const setRequestedLeak = (payload) => ({
  type: types.SET_REQUESTED_LEAK,
  payload,
});
export const setRequestedOther = (payload) => ({
  type: types.SET_REQUESTED_OTHER,
  payload,
});
export const setRequestedDescription = (payload) => ({
  type: types.SET_REQUESTED_DESCRIPTION,
  payload,
});
export const deleteRequestedPart = (payload) => ({
  type: types.DELETE_REQUESTED_PART,
  payload,
});
export const setVideoPhotoFlag = (payload) => ({
  type: types.SET_VIDEO_PHOTO_FLAG,
  payload,
});
export const setRequestNewInspection = (payload) => ({
  type: types.ENABLE_REQUEST_NEW_INSPECTION,
  payload,
});
export const enableEdit = (payload) => ({
  type: types.ENABLE_EDIT,
  payload,
});
export const getAttachmentRequestDetails = (payload) => ({
  type: types.GET_ATTACHMENT_REQUEST_DETAILS,
  payload,
});

export const downloadClaimsFiles = (payload) => {
  return {
    type: types.DOWNLOAD_CLAIMS_FILES,
    payload,
  };
};

export const updateIsComplete = (payload) => {
  return {
    type: types.UPDATE_IS_COMPLETED,
    payload,
  };
};

export const updateAllPreviewImages = (downloadFiles) => {
  return {
    type: types.UPDATE_ALL_PREVIEW_IMAGES,
    payload: downloadFiles,
  };
};

export const setRequested = (payload) => ({
  type: types.SET_REQUESTED,
  payload,
});
export const editAttachmentDetails = (payload) => ({
  type: types.EDIT_REQUEST_ATTACHMENT,
  payload,
});

export const acceptInspection = (payload) => ({
  type: types.ACCEPT_INSPECTION,
  payload,
});
export const retakeRequest = (payload) => ({
  type: types.RETAKE_REQUEST,
  payload,
});
export const getRetakeRequestComment = (payload) => ({
  type: types.GET_RETAKE_REQUEST_COMMENT,
  payload,
});
export const setMoreDetails = (payload) => ({
  type: types.SET_MORE_DETAILS,
  payload,
});
export const setNavigate = (payload) => ({
  type: types.SET_NAVIGATE,
  payload,
});
export const setAccept = (payload) => ({
  type: types.SET_ACCEPT,
  payload,
});
export const resetClaimDetails = (payload) => ({
  type: types.RESET_CLAIM_DETAILS,
  payload,
});
export const setRetakeRequest = (payload) => ({
  type: types.SET_RETAKE_REQUEST,
  payload,
});
export const retakeSuccess = (payload) => ({
  type: types.RETAKE_REQUEST_SEND,
  payload,
});
export const setUpdate = (payload) => ({
  type: types.SET_UPDATE_COMPELTE,
  payload,
});
export const handleError = (payload) => {
  return {
    type: types.HANDLE_ERROR,
    payload,
  };
};
export const retakeErrorMessage = (payload) => {
  return {
    type: types.RETAKE_REQUEST_FAILURE,
    payload: null,
  };
};
export const setInspectionStarted = (payload) => ({
  type: types.SET_INSPECTION_STARTED,
  payload,
});
export const resetRequestMore = (payload) => ({
  type: types.RESET_REQUEST_MORE_INSPECTION,
  payload,
});
export const enableAcceptInspection = (payload) => ({
  type: types.ENABLE_ACCEPT_INSPECTION,
  payload,
});
export const setInspectionValue = (payload) => ({
  type: types.CLOSE_INSPECTION_VALUES,
  payload,
});
export const setCloseInpsectionSuccess = (payload) => ({
  type: types.CLOSE_INSPECTION_VALUES_SUCCESS,
  payload,
});
export const setClearAddedNewCard = (payload) => ({
  type: types.ENABLE_REQUEST_NEW_INSPECTION_SUCCESS,
  payload,
});
