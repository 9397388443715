// vendor
import axios from "axios";

// services
import { authHelper } from "../app";
import SessionStorageService from "../../../src/services/functional/accounts/login/storage";

// constants
import { METHOD_TYPES } from "../../shared/constants/method-types";
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = authHelper.getStoredAuthToken();

    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response Interceptor to handle the 401 http status code.
instance.interceptors.response.use(
  (response) => {
    // Return the success response without any changes.
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/"
    ) {
      // if user get 401, Redirecting them into login screen.
      // Used Browser api to redirect to login page. React router not used.
      window.localStorage.clear();
      SessionStorageService.clearAll();
      window.location.href = "/login";
    } else if (
      (error.response.status === 400 || error.response.status === 404) &&
      window.location.pathname !== "/login"
    ) {
      window.sessionStorage.clear();
    } else if (error.response.status >= 500 && error.response.status <= 599) {
      window.sessionStorage.clear();
      window.location.href = "/servererror";
    }
    return Promise.reject(error);
  }
);

export const request = (method, url, payload, headers) => {
  switch (method) {
    case METHOD_TYPES.GET:
      return instance.get(url, headers);

    case METHOD_TYPES.POST:
      return instance.post(url, payload, headers);

    case METHOD_TYPES.PUT:
      return instance.put(url, payload, { headers });

    case METHOD_TYPES.PATCH:
      return instance.patch(url, payload, { headers });

    case METHOD_TYPES.DELETE:
      return instance.delete(url, {
        headers: {},
        payload,
      });
    default:
      return null;
  }
};

export default instance;
