import React from "react";

import Administration from "./admin";
import Header from "../../shared/commonComponents/header/header";
import { Bottom, FootText } from "../../shared/commonComponents/footer/styled";

const MainAdministration = (props) => {
  return (
    <>
      <Header />
      <Administration />
      <div
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Bottom>
          <FootText>© 2022. Powered by Assurant. All rights reserved.</FootText>
        </Bottom>
      </div>
      {/* </div> */}
    </>
  );
};

export default MainAdministration;
