import {
  GET_REMINDER_DETAILS,
  UPDATE_REMINDER_DETAILS,
  IS_REMAINDER_UPDATE,
  RESTORE_REMINDER_DETAILS,
  REMINDER_DATA_CHANGE,
  OPEN_REMINDER_POPUP,
} from "./types";

export const getRemindersDetails = (payload) => {
  return {
    type: GET_REMINDER_DETAILS,
    payload,
  };
};

export const checkDataSaved = (payload) => {
  return {
    type: REMINDER_DATA_CHANGE,
    payload,
  };
};

export const updateReminderDetails = (payload, call, clientid) => {
  return {
    type: UPDATE_REMINDER_DETAILS,
    payload,
    call,
    clientid,
  };
};
export const isRemainderUpdate = (payload) => {
  return {
    type: IS_REMAINDER_UPDATE,
    payload,
  };
};

export const restoreReminderDetails = (payload) => {
  return {
    type: RESTORE_REMINDER_DETAILS,
    payload,
  };
};
export const openSaveReminderPopUp = (payload) => {
  return {
    type: OPEN_REMINDER_POPUP,
    payload,
  };
};
