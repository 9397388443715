import styled from "styled-components";
import { Grid } from "@mui/material";

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  &.user {
    font-size: 18px;
    color: #13223c;
    line-height: 25px;
  }
  &.choose {
    font-size: 12px;
    line-height: 18px;
    color: #68758b;
    margin: 6px 22px 42px 0;
  }
  &.invite {
    font-size: 14px;
    color: #3d515a;
    line-height: 19px;
    margin: 10px 0 6px 0;
  }
  &.users {
    font-family: "OpenSans Bold";
    font-size: 12px;
    line-height: 18px;
    color: #3d515a;
  }
  &.added {
    font-size: 14px;
    line-height: 19px;
    color: #68758b;
    margin-top: 10px;
    height: 120px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  &.invites {
    font-size: 16px;
    line-height: 30px;
    color: #666666;
    text-align: center;
    margin: 24px 0 25px 0;
  }
  &.errorMessage {
    color: #fe4a4a;
    font-size: 14px;
    line-height: 19px;
    margin: 0 18px 0 0;
  }
`;
export const Line = styled(Grid)`
  border-left: 1px solid #141414;
  opacity: 0.1;
`;

export const Back = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  color: #0066cc;
  align-item: center;
  line-height: 30px;
  text-transform: uppercase;
  justify-content: space-around;
  display: flex;
  width: 74px;
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  direction: rtl;
  background-color: transparent !important;
`;

export const Box = styled(Grid)`
  display: flex;
`;
export const Box1 = styled(Grid)`
  display: flex;
`;

export const SentInvitesDiv = styled(Grid)`
  display: "grid";
  justify-content: "center";
  align-items: "center";
`;

export const MainContainer = styled(Grid)``;
