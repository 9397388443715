import { request } from "../../../core/networkRequests";
import { METHOD_TYPES } from "../../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../../shared/constants/endpoints";

export const getActiveClaims = (payload) => {
  return request(METHOD_TYPES.POST, ENDPOINTS.CLAIMS, payload);
};

export const getClosedClaims = (payload) => {
  return request(METHOD_TYPES.POST, `${ENDPOINTS.CLOSED_CLAIMS}`, payload);
};

export const getClosedDropdownDataService = (payload) => {
  const url = "v1/api/inspections/claims/adjudicator/list";
  return request(METHOD_TYPES.GET, url);
};

export const getTokenValidity = (payload) => {
  return request(
    METHOD_TYPES.GET,
    `${ENDPOINTS.API_KEY}/${payload}/token_validity`
  );
};
