import React from "react";
import {
  Bottom,
  FootText,
} from "../../../shared/commonComponents/footer/styled";
import ReviewInspectionRequest from "./reviewInspectionRequest";
import Header from "./Header";

const ReviewInspection = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
        }}
      >
        <Header />
        <ReviewInspectionRequest />
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: 1000,
          }}
        >
          <Bottom>
            <FootText>
              © 2022. Powered by Assurant. All rights reserved.
            </FootText>
          </Bottom>
        </div>
      </div>
    </>
  );
};

export default ReviewInspection;
