import { request } from "../../../services/core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getReminderServices = (payload) => {
  return request(
    METHOD_TYPES.GET,
    `${ENDPOINTS.GET_REMINDER_DETAILS}/${payload}`
  );
};
export const updateReminderServices = (payload, call, clientid) => {
  let params = "";

  let callType;
  if (call === "put") {
    params = "v1/api/reminders/" + clientid;
    callType = METHOD_TYPES.PUT;
  } else if (call === "post") {
    params = "v1/api/reminders/";
    callType = METHOD_TYPES.POST;
  }
  return request(callType, params, payload);
};

const data = {
  shop: {
    first_reminder: {
      toggleValue: false,

      time: 10,

      type: "days",
    },

    final_reminder: {
      toggleValue: true,

      time: 2,

      type: "days",
    },
  },

  adjudicator: {
    readyForReview: true,

    first_reminder: {
      toggleValue: false,

      time: 2,

      type: "hours",
    },

    second_reminder: {
      toggleValue: true,

      time: 2,

      type: "days",
    },

    shop_late: {
      toggleValue: true,

      time: 3,

      type: "days",
    },
  },
};
