export const GET_REMINDER_DETAILS = "GET_REMINDER_DETAILS";
export const GET_REMINDER_DETAILS_SUCCESS = "GET_REMINDER_DETAILS_SUCCESS";
export const GET_REMINDER_DETAILS_FAILURE = "GET_REMINDER_DETAILS_FAILURE";
export const UPDATE_REMINDER_DETAILS = "UPDATE_REMINDER_DETAILS";
export const UPDATE_REMINDER_DETAILS_SUCCESS =
  "UPDATE_REMINDER_DETAILS_SUCCESS";
export const UPDATE_REMINDER_DETAILS_FAILURE =
  "UPDATE_REMINDER_DETAILS_FAILURE";
export const IS_REMAINDER_UPDATE = "IS_REMAINDER_UPDATE";
export const RESTORE_REMINDER_DETAILS = "RESTORE_REMINDER_DETAILS";
export const REMINDER_DATA_CHANGE = "REMINDER_DATA_CHANGE";
export const OPEN_REMINDER_POPUP = "OPEN_REMINDER_POPUP";
