import styled from "styled-components";
import { Grid } from "@mui/material";
import media from "../../../styles/media.styled";

export const ClaimContainer = styled(Grid)`
  margin-top: 7%;
  margin-left: 20px;

  ${media.mediumDevice`
  margin-top: ${({ ipadMarginTop }) => ipadMarginTop || "10%"};
  `}

  ${media.smalDevice`
margin-top: ${({ ipadMarginTop }) => ipadMarginTop || "10%"};
  `}

  ${media.mobileDevice`
  margin-top: ${({ ipadMarginTop }) => ipadMarginTop || "10%"};             
  `};
`;

export const ClaimHeader = styled(Grid)``;

export const ClaimText = styled.div`
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "OpenSans SemiBold"};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "27px")};
  color: #13223c;
`;
export const ClaimDetails = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 20px;
  color: #3d515a;
  width: 300px;
  margin-top: 10px;
  ${media.mediumDevice`
  width: ${({ ipadWidth }) => (ipadWidth ? ipadWidth : "280px")};
`};
`;
export const SearchBoxContainer = styled.div`
  display: flex;
  margin-left: 0.5rem;
  height: 50px;
  border-bottom: 1px solid #d3d3d3;
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "OpenSans Regular"};
  text-align: left;
  color: #141414;
  align-items: center;
  background: ${({ background }) => (background ? background : "#FFFFFF")};

  width: ${({ width }) => (width ? width : "20%")};

  ${media.mediumDevice`
       
    `}

  ${media.smalDevice`
        
    `}

    ${media.mobileDevice`
        width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : "100%")};
        height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : "40px")};
        border-radius: 20px;
        margin: 10px 0 5px 0;                  
    `};
`;

export const StyledInput = styled.input`
  margin-left: 16px;
  font-size: 16px !important;
  outline: none;
  border: none;
  width: ${({ width }) => (width ? width : "80%")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  font-family: OpenSans Regular;
  line-height: 20px;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "20px"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #44464b;
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  ::placeholder {
    /* Firefox */
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  ::-webkit-input-placeholder {
    /* Safari */
    font-family: "OpenSans Regular";
    opacity: 1;
  }

  ${media.mediumDevice`
      
    `}

  ${media.smalDevice`
        width: 100%;
        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        ::placeholder {
            /* Firefox */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        ::-webkit-input-placeholder {
            /* Safari */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
    `}

    ${media.mobileDevice`
        //border-radius: 5px;
         font-size: 14px;
    `}
`;
export const IconContainer = styled.div`
  width: ${({ width }) => (width ? width : "30px")};
  height: ${({ height }) => (height ? height : "20px")};
  opacity: 1;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d515a;
  ${media.smalDevice`
        width:  20px;
        height: : 20px;
        margin: 0 0 0 5px;
    `}

  ${media.mobileDevice`
        width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : "30px")};
        height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : "20px")};
        opacity:1;
        margin: 0 0 0 10px;
    `}
`;

export const ClaimCommentsRequestMore = styled.div`
  font-family: "OpenSans Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #13223c;
  width: 157px;
  margin-top: 67px;
`;

export const UploadContainerRequestMore = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 330px;
`;

export const UploadHeaderRequestMore = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  width: 251px;
  height: ${({ height }) => (height ? height : "35px")};
  margin-top: 0px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : " ")};
`;

export const UploadTextRequestMore = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "2px")};
  margin-left: 12px;
  font-family: "OpenSans Regular";
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #52636b;
`;
export const UploadImageRequestMore = styled.div`
  display: flex;
  margin-right: 14px;
`;

export const UploadCountRequestMore = styled.div`
  margin-top: 5px;
  margin-left: 2px;
`;
export const UploadStatusRequestMore = styled(Grid)`
  width: 265px;
  height: auto;
  background: #ffffff;
  font-family: "OpenSans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #68758b;
`;
export const Line = styled.hr`
  opacity: ${({ opacity }) => (opacity ? opacity : "0.5")};
  margin-top: 5px;
  width: 137%;
`;

export const UploadContentRequestMore = styled.div`
  height: auto;
`;

export const ClaimInspectionDetails = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "9rem")};
  font-family: "OpenSans SemiBold";
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #68758b;
`;

export const ClaimRequest = styled.div`
  width: 70%;

  ${media.mediumDevice`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "5rem")};
    `}

  ${media.smalDevice`
        
    `}

    ${media.mobileDevice`
        margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "5rem")};
    `};
`;
