import React, { useEffect, useState } from "react";
import {
  MainContainer,
  HeaderGrid,
  Grid3,
  Grid2,
  Button,
} from "../../../styles/inspectionHeader.styled";
import assurantLogo from "../../../assets/images/assurant-logo.svg";
import { useNavigate } from "react-router-dom";
import {
  resendInspection,
  enableEdit,
  getAttachmentRequestDetails,
  editAttachmentDetails,
} from "../../../store/claimInspection/action";
import { fileFetchHeader } from "../../../store/userManagment/action";
import SessionStorageService from "../../../services/functional/accounts/login/storage";
import CloseInspectionDialog from "./closeInspectionDialog";
import { useDispatch, useSelector } from "react-redux";

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [preview, setPreview] = useState();
  const [disabled, setDisabled] = useState(false);
  const [openInspectionClosedDialog, setOpenInspectionClosedDialog] =
    useState(false);

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const clientdetails = SessionStorageService.get("CLIENT_DETAILS");
  const parseClientDetails = JSON.parse(clientdetails);
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );
  const claimInspectionDetails = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );
  const ClaimRequestedDetails = useSelector(
    (state) => state.claimInspectionReducer?.inspectionRequest
  );
  const isLogo = useSelector((state) => state.headerReducer.condition?.isLogo);
  const edit = useSelector((state) => state.claimInspectionReducer?.enableEdit);
  const reset = useSelector((state) => state?.claimInspectionReducer?.reset);
  const enbaleRequest = useSelector(
    (state) => state.claimInspectionReducer?.enableNewInspection
  );
  const InspectionDate = new Date(claimInspectionDetails?.Date);
  const resendInspectionHandler = () => {
    dispatch(resendInspection({ claimNo: currentClaim }));
  };
  const handleEdit = () => {
    dispatch(enableEdit(true));
    dispatch(getAttachmentRequestDetails(currentClaim));
  };

  useEffect(() => {
    if (!userDetails.roles.toLowerCase().includes("super") && !fetchedUrl) {
      dispatch(fileFetchHeader({ link: parseClientDetails?.image_url }));
    }
  }, []);

  const fetchedUrl = useSelector(
    (state) => state.userManagmentReducer?.fileFetchHeader?.data
  );

  const handleCloseInspectionDialogOpen = () => {
    setOpenInspectionClosedDialog(true);
  };

  const handleCloseInspectionDialogClosed = () => {
    setOpenInspectionClosedDialog(false);
  };

  const displayImageUrl = () => {
    if (fetchedUrl) {
      const asset = fetchedUrl.asset;
      const assetValue = "data:image/png;base64," + asset;
      setPreview(assetValue);
    } else {
      setPreview(null);
    }
  };

  useEffect(() => {
    displayImageUrl();
  }, [fetchedUrl]);

  const handleSave = () => {
    if (disabled) {
      return null;
    } else {
      setDisabled(true);
      dispatch(
        editAttachmentDetails({
          claimNo: currentClaim,
          support_doc_array: ClaimRequestedDetails,
        })
      );
    }
  };
  useEffect(() => {
    if (reset) {
      navigate("/dashboard");
    }
  }, [reset]);
  return (
    <>
      <MainContainer
        style={{ position: "fixed", top: "0", width: "100%", zIndex: 3 }}
      >
        <HeaderGrid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Grid2 item xs={6} sm={4} md={4} lg={4} xl={6} mobile={12}>
            {isLogo && (
              <img
                src={preview ? preview : assurantLogo}
                alt="ClientLogo"
                width={"130px"}
                height={"35px"}
                style={{ margin: "15px 0 16px 35px", cursor: "pointer" }}
                onClick={() =>
                  (window.location = window.location.origin + "/dashboard")
                }
              ></img>
            )}
          </Grid2>
          <Grid3
            item
            xs={6}
            sm={8}
            md={8}
            lg={8}
            xl={6}
            mobile={12}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {claimInspectionDetails.Status === "STARTED" ? (
              <div style={{ display: "flex" }}>
                <div style={{ textAlign: "right" }}>
                  <div
                    style={{
                      fontFamily: "OpenSans Bold",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#3D515A",
                    }}
                  >
                    Inspection in-progress
                  </div>
                  <div
                    style={{
                      fontFamily: "OpenSans Regular",
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#3D515A",
                    }}
                  >
                    {InspectionDate?.toLocaleString("default", {
                      month: "long",
                    }) +
                      " " +
                      +InspectionDate?.getDate() +
                      ", " +
                      InspectionDate?.getFullYear()}
                  </div>
                </div>
                <div style={{ marginTop: "8px" }}>
                  <Button
                    marginTop={"0px"}
                    style={{ marginLeft: "18px" }}
                    width={"70%"}
                    padding={"6px 40px"}
                    backgroundColor={"#F1F1F1"}
                    color={"#666666"}
                    disabled={true}
                    cursor={"not-allowed"}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                {edit ? null : (
                  <div style={{ textAlign: "right" }}>
                    <div
                      style={{
                        fontFamily: "OpenSans Bold",
                        fontSize: "16px",
                        lineHeight: "28px",
                        color: "#3D515A",
                      }}
                    >
                      Inspection Requested
                    </div>
                    <div
                      style={{
                        fontFamily: "OpenSans Regular",
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "#3D515A",
                      }}
                    >
                      {InspectionDate?.toLocaleString("default", {
                        month: "long",
                      }) +
                        " " +
                        +InspectionDate?.getDate() +
                        ", " +
                        InspectionDate?.getFullYear()}
                    </div>
                  </div>
                )}
                {claimInspectionDetails.Status === "REQUESTED" ? (
                  <div style={{ marginTop: "8px" }}>
                    <div
                      style={{
                        padding: "6px 40px",
                        color: "#2972C8",
                        width: "10%",
                        cursor: "pointer",
                        fontFamily: "OpenSans Regular",
                      }}
                      onClick={handleCloseInspectionDialogOpen}
                    >
                      Close
                    </div>
                  </div>
                ) : null}
                {edit ? (
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      marginTop={"0px"}
                      width={"30%"}
                      padding={"6px 40px"}
                      style={{ paddingRight: "70px" }}
                      onClick={handleSave}
                      disabled={enbaleRequest ? false : true}
                      backgroundColor={enbaleRequest ? "black" : "#F1F1F1"}
                      color={enbaleRequest ? "#ffffff" : "#666666"}
                    >
                      Save
                    </Button>
                  </div>
                ) : (
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      marginTop={"0px"}
                      style={{ marginLeft: "18px" }}
                      width={"39%"}
                      padding={"6px 24px"}
                      backgroundColor={"#FFFFFF"}
                      color={"#666666"}
                      onClick={() => resendInspectionHandler()}
                    >
                      Resend
                    </Button>
                    <Button
                      marginTop={"0px"}
                      width={"30%"}
                      padding={"6px 40px"}
                      style={{ paddingRight: "70px" }}
                      onClick={handleEdit}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Grid3>
        </HeaderGrid>
      </MainContainer>
      {openInspectionClosedDialog && (
        <CloseInspectionDialog
          open={handleCloseInspectionDialogOpen}
          close={handleCloseInspectionDialogClosed}
        />
      )}
    </>
  );
};
export default Header;
