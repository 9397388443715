import * as types from "./types";

const initialSate = {
  forgotPassword: false,
  support: false,
  isDefaultScreen: true,
  isAssurantUser: false,
  JWTToken: "",
  isLoggedIn: false,
  resetPassword: null,
  error: null,
  resetMail: null,
  activateUser: null,
  supportRequest: null,
  resetError: null,
  loginError: null,
};

const LoginReducer = (state = initialSate, action) => {
  switch (action.type) {
    case types.FORGOTPASSWORD:
      return {
        ...state,
        forgotPassword: action.payload,
      };
    case types.SUPPORT:
      return {
        ...state,
        support: action.payload,
      };
    case types.SETDEFAULTSCREEN:
      return {
        ...state,
        isDefaultScreen: action.payload,
      };
    case types.IS_ASSURANT_USER:
      return {
        ...state,
        isAssurantUser: action.payload,
      };
    case types.GET_JWT_TOKEN_SUCCESS:
      return {
        ...state,
        JWTToken: action.payload,
        isLoggedIn: true,
      };
    case types.GET_JWT_TOKEN_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        loginError: action.error,
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.payload,
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.SEND_RESET_MAIL_SUCCESS:
      return {
        ...state,
        resetMail: action.payload,
      };
    case types.SEND_RESET_MAIL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUser: action.payload,
      };
    case types.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.SUPPORT_REQUEST_SUCCESS:
      return {
        ...state,
        supportRequest: action.payload,
      };
    case types.SUPPORT_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.RESET_SUPPORT:
      return {
        ...state,
        supportRequest: null,
      };
    case types.RESET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload,
      };
    default:
      return state;
  }
};
export default LoginReducer;
