import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import Footer from "../../../shared/commonComponents/footer/footer";
import {
  ClaimContainer,
  ClaimHeader,
  ClaimText,
  ClaimDetails,
  SearchBoxContainer,
  StyledInput,
  IconContainer,
  ClaimComments,
  UploadContainer,
  UploadCount,
  UploadHeader,
  UploadImage,
  UploadText,
  UploadContent,
  UploadStatus,
  ClaimInspectionDetails,
  Box,
  Image,
  Video,
  UploadHeaderCount,
  UploadTotalCount,
  ClaimInfoDiv,
  UploadArea,
  SwiperArrowIcon,
} from "./reviewInspectionRequest.styled";
import { useDispatch, useSelector } from "react-redux";
import downloadLogo from "../../../assets/images/download-bottom 1.svg";
import camera from "../../../assets/images/camera-1.svg";
import video from "../../../assets/images/video.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";
import { isFooterReq } from "../../../store/sharedComponents/footer/action";
import "swiper/css";
import Grid from "@mui/material/Grid";
import {
  downloadClaimsFiles,
  getClaimInspectionDetails,
  updateAllPreviewImages,
  updateIsComplete,
  setRetakeRequest,
  setUpdate,
  handleError,
  enableAcceptInspection,
} from "../../../store/claimInspection/action";
import DialogConfirm from "./acceptInspectionDialog";
import Button from "@mui/material/Button";
import refreshIcon from "../../../assets/images/icon.svg";
import {
  showLoader,
  hideLoader,
} from "../../../store/sharedComponents/loader/action";
import Loader from "../../../shared/commonComponents/loader/loader";
import DialogRetake from "./dialogRetake";
import crossIcon from "../../../assets/images/remove-circle.svg";
import swiperArrow from "../../../assets/images/control.svg";
import playButton from "../../../assets/images/playButton.png";
import JSZip from "jszip";

const ReviewInspectionRequest = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { InspectionId } = useParams();
  const [preview, setPreview] = useState([]);
  const [currentRetakeName, setCurrentRetakeName] = useState("");
  const [currentRetakeFileName, setCurrentRetakeFileName] = useState("");
  const [showDiv, setShowDiv] = useState("1");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const isError = useSelector((state) => state.claimInspectionReducer?.isError);
  const swiperRef = useRef(null);

  const userAgentString = navigator.userAgent;
  let safariAgent = userAgentString.indexOf("Safari") > -1;
  const chromeAgent = userAgentString.indexOf("Chrome") > -1;
  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }

  const errorMessage = useSelector(
    (state) => state.claimInspectionReducer?.error?.message
  );
  const downloadFiles = useSelector(
    (state) => state.claimInspectionReducer.downloadFiles
  );
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const claimInspectionDetails = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );

  const isUpdateComplete = useSelector(
    (state) => state.claimInspectionReducer.isUpdateComplete
  );

  const openAccept = useSelector(
    (state) => state.claimInspectionReducer.accept
  );
  const handleErrorState = () => {
    dispatch(handleError());
  };
  useEffect(() => {
    if (downloadFiles?.length > 0) {
    } else {
      // dispatch(showLoader());
      dispatch(getClaimInspectionDetails(InspectionId));
    }
  }, []);
  useEffect(() => {
    dispatch(setUpdate(false));
    dispatch(isFooterReq({ isFooterReq: true }));
  }, []);
  useEffect(() => {
    if (claimInspectionDetails.Status === "UPDATED") {
      dispatch(enableAcceptInspection(true));
    } else {
      dispatch(enableAcceptInspection(false));
    }
  }, [claimInspectionDetails]);
  useEffect(() => {
    if (downloadFiles?.length > 0) {
      const allImages = [];
      dispatch(updateAllPreviewImages(downloadFiles));
      setPreview(allImages);
      dispatch(hideLoader());
    } else {
      setPreview([]);
    }
  }, [downloadFiles]);

  useEffect(() => {
    if (claimInspectionDetails?.attachment_requests_attributes?.length > 0) {
      dispatch(showLoader());
      dispatch(
        downloadClaimsFiles({
          attachmentRequest:
            claimInspectionDetails?.attachment_requests_attributes,
        })
      );
    }
  }, [claimInspectionDetails?.attachment_requests_attributes?.length]);

  const onDownloadClick = () => {
    const zip = new JSZip();
    if (downloadFiles) {
      let i = 0;
      while (i < downloadFiles.length > 0) {
        let j = 0;
        while (j < downloadFiles[i].assets.length > 0) {
          const byteCharacters = atob(downloadFiles[i].assets[j].asset);

          const byteNumbers = new Array(byteCharacters.length);
          let k = 0;
          while (k < byteCharacters.length) {
            byteNumbers[k] = byteCharacters.charCodeAt(k);
            k++;
          }

          const byteArray = new Uint8Array(byteNumbers);

          zip.file(downloadFiles[i].assets[j].filename, byteArray);
          j++;
        }
        i++;
      }
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${claimInspectionDetails.claimNumber}.zip`);
    });
  };

  const handleRetake = (attachmentName, fileName) => {
    setCurrentRetakeFileName(fileName);
    setCurrentRetakeName(attachmentName);
    dispatch(setRetakeRequest(true));
  };

  useEffect(() => {
    let timerId;
    if (isFullScreen) {
      timerId = setInterval(() => {
        if (!document.fullscreenElement || !document.webkitFullScreen) {
          setIsFullScreen(false);
          setShowDiv("1");
        }
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [isFullScreen]);

  const requestFullScreen = (e, index) => {
    const elem = document.getElementById(78988);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      setShowDiv("0");
      setIsFullScreen(true);
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
      setShowDiv("0");
      setIsFullScreen(true);
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen();
      setShowDiv("0");
      setIsFullScreen(true);
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
      setShowDiv("0");
      setIsFullScreen(true);
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
      setShowDiv("0");
      setIsFullScreen(true);
    }
  };
  const handleThumb = (e, index) => {
    swiperRef.current.swiper.slideTo(index, 1);
  };

  return (
    <>
      {
        <ClaimContainer
          container
          justifyContent={"space-around"}
          direction="row"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
        >
          <div
            style={{
              height: "auto",
              display: "flex",
              width: "100%",
            }}
          >
            <ClaimHeader item xs={12} sm={3} md={3} lg={3} xl={3} mobile={12}>
              <ClaimInfoDiv>
                <div>
                  <ClaimDetails
                    style={{ marginTop: "0px" }}
                    ipadWidth={"280px"}
                  >
                    <ClaimText
                      fontFamily={"OpenSans Bold"}
                      fontSize={"16px"}
                      lineHeight={"16px"}
                    >
                      Claim #{" "}
                      <span>
                        {claimInspectionDetails.claimNumber &&
                          claimInspectionDetails.claimNumber.replace(
                            /CLAIM-/,
                            ""
                          )}
                      </span>
                    </ClaimText>
                  </ClaimDetails>
                  <ClaimDetails>
                    RO: <span>{claimInspectionDetails.RO}</span>
                  </ClaimDetails>
                  <ClaimDetails>
                    VIN:{" "}
                    <span>
                      {"***"}
                      {claimInspectionDetails?.vin?.slice(
                        claimInspectionDetails.vin.length - 6
                      )}
                    </span>
                  </ClaimDetails>
                  <ClaimDetails>
                    Shop: <span>{claimInspectionDetails.shop}</span>
                  </ClaimDetails>
                  <ClaimDetails>
                    Inspector: <span>{claimInspectionDetails.Inspector}</span>
                  </ClaimDetails>
                  <ClaimDetails>
                    Request type:{" "}
                    <span>{claimInspectionDetails.RequestType}</span>
                  </ClaimDetails>
                  <ClaimDetails>
                    Customer: <span>{claimInspectionDetails.Customer}</span>
                  </ClaimDetails>
                  <ClaimDetails>
                    Vehicle:{" "}
                    <span>
                      {claimInspectionDetails?.vehicle_year +
                        " " +
                        claimInspectionDetails?.vehicle_make +
                        " " +
                        claimInspectionDetails?.Vehicle}
                    </span>
                  </ClaimDetails>
                  <ClaimDetails>
                    VI Completion:{" "}
                    <span>{claimInspectionDetails.VICompletion}</span>
                  </ClaimDetails>
                </div>
                <div>
                  <ClaimComments>
                    Inspector Comments
                    <ClaimDetails
                      style={{
                        marginTop: "0px",
                        color: "#68758b",
                        lineHeight: "16px",
                      }}
                    >
                      {claimInspectionDetails?.additional_doc?.comments
                        ? claimInspectionDetails.additional_doc?.comments
                        : "NONE"}
                    </ClaimDetails>
                  </ClaimComments>
                </div>
                <UploadArea>
                  <UploadTotalCount>
                    Uploads ({claimInspectionDetails?.totalAssets})
                    <img
                      style={{
                        marginLeft: "10px",
                        marginTop: "3px",
                        width: "16px",
                      }}
                      onClick={onDownloadClick}
                      src={downloadLogo}
                      alt="downloadLogo"
                    />
                  </UploadTotalCount>
                  <UploadContainer>
                    {claimInspectionDetails?.attachment_requests_attributes &&
                    claimInspectionDetails.attachment_requests_attributes
                      .length > 0
                      ? claimInspectionDetails.attachment_requests_attributes.map(
                          (data, index) => {
                            return (
                              <>
                                <UploadContent>
                                  <UploadHeader>
                                    <UploadText>{data.name}</UploadText>
                                    {data?.min_video > 0 && (
                                      <UploadImage>
                                        {" "}
                                        <img
                                          src={video}
                                          alt="camera"
                                          style={{
                                            width: "20px",
                                            marginRight: "6px",
                                            marginBottom: "10px",
                                          }}
                                        />
                                        <UploadCount>
                                          {data?.min_video}
                                        </UploadCount>
                                      </UploadImage>
                                    )}
                                    {data?.min_image > 0 && (
                                      <UploadImage>
                                        {" "}
                                        <img
                                          src={camera}
                                          alt="camera"
                                          style={{
                                            width: "20px",
                                            marginRight: "6px",
                                            marginBottom: "10px",
                                          }}
                                        />
                                        <UploadCount>
                                          {data?.min_image}
                                        </UploadCount>
                                      </UploadImage>
                                    )}
                                  </UploadHeader>
                                </UploadContent>
                                <UploadStatus container spacing={1}>
                                  {isUpdateComplete &&
                                    data.assets.map((y, index) => {
                                      return (
                                        <Grid
                                          item
                                          xs={4}
                                          key={index}
                                          id={index + 1232}
                                          style={{ overflowX: scroll }}
                                        >
                                          {y.type === "image/jpg" ||
                                          y.type === "image/png" ? (
                                            <img
                                              className="react-player fixed-bottom"
                                              src={
                                                "data:image/*;base64," + y.asset
                                              }
                                              width="90px"
                                              height="90px"
                                              key={index}
                                              onClick={(e) =>
                                                handleThumb(e, y.slideIndex)
                                              }
                                              style={{
                                                objectFit: "fill",
                                                marginRight: "11px",
                                              }}
                                            />
                                          ) : (
                                            <>
                                              <video
                                                className="react-player fixed-bottom"
                                                controls={false}
                                                poster={
                                                  safariAgent
                                                    ? playButton
                                                    : null
                                                }
                                                controlsList="nodownload noremoteplayback noplaybackrate"
                                                disablePictureInPicture={true}
                                                playsInline={true}
                                                width="90px"
                                                height="90px"
                                                key={index}
                                                style={{
                                                  objectFit: "fill",
                                                  marginRight: "11px",
                                                }}
                                                onClick={(e) =>
                                                  handleThumb(e, y.slideIndex)
                                                }
                                              >
                                                <source
                                                  src={
                                                    "data:video/quicktime;base64," +
                                                    y.asset
                                                  }
                                                  type="video/quicktime"
                                                ></source>
                                                <source
                                                  src={
                                                    "data:video/mp4;base64," +
                                                    y.asset
                                                  }
                                                  type="video/mp4"
                                                ></source>

                                                <source
                                                  src={
                                                    "data:video/mpeg4;base64," +
                                                    y.asset
                                                  }
                                                  type="video/mpeg4"
                                                ></source>

                                                <source
                                                  src={
                                                    "data:video/webm;base64," +
                                                    y.asset
                                                  }
                                                  type="video/webm"
                                                ></source>
                                              </video>
                                            </>
                                          )}
                                        </Grid>
                                      );
                                    })}
                                  {isUpdateComplete && data.oldassets
                                    ? data?.oldassets.map((y, index) => {
                                        return (
                                          <Grid key={index}>
                                            {y.type === "image/jpg" ||
                                            y.type === "image/png" ? (
                                              <div
                                                style={{
                                                  position: "relative",
                                                  filter: "grayscale(100%)",
                                                  marginTop: "8px",
                                                  marginLeft: "8px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    "data:image/*;base64," +
                                                    y.asset
                                                  }
                                                  controls={true}
                                                  key={index}
                                                  width={"90px"}
                                                  height={"90px"}
                                                  style={{
                                                    objectFit: "fill",
                                                    marginRight: "11px",
                                                  }}
                                                />
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    position: "absolute",
                                                    width: "100%",
                                                    top: "12px",
                                                    left: "0px",
                                                    opacity: "1",
                                                  }}
                                                >
                                                  <div>
                                                    <img
                                                      src={crossIcon}
                                                      alt="crossIcon"
                                                      style={{
                                                        marginTop: "15px",
                                                        width: "37px",
                                                        marginRight: "11px",
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    filter: "grayscale(100%)",
                                                    marginTop: "8px",
                                                    marginLeft: "8px",
                                                  }}
                                                >
                                                  <video
                                                    className="react-player fixed-bottom"
                                                    controls={false}
                                                    poster={
                                                      safariAgent
                                                        ? playButton
                                                        : null
                                                    }
                                                    controlsList="nodownload noremoteplayback noplaybackrate"
                                                    disablePictureInPicture={
                                                      true
                                                    }
                                                    playsInline={true}
                                                    width="90px"
                                                    height="90px"
                                                    key={index}
                                                    style={{
                                                      objectFit: "fill",
                                                      marginRight: "11px",
                                                    }}
                                                    onClick={(e) =>
                                                      handleThumb(
                                                        e,
                                                        y.slideIndex
                                                      )
                                                    }
                                                  >
                                                    <source
                                                      src={
                                                        "data:video/quicktime;base64," +
                                                        y.asset
                                                      }
                                                      type="video/quicktime"
                                                    ></source>
                                                    <source
                                                      src={
                                                        "data:video/mp4;base64," +
                                                        y.asset
                                                      }
                                                      type="video/mp4"
                                                    ></source>

                                                    <source
                                                      src={
                                                        "data:video/mpeg4;base64," +
                                                        y.asset
                                                      }
                                                      type="video/mpeg4"
                                                    ></source>

                                                    <source
                                                      src={
                                                        "data:video/webm;base64," +
                                                        y.asset
                                                      }
                                                      type="video/webm"
                                                    ></source>
                                                  </video>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      position: "absolute",
                                                      width: "100%",
                                                      top: "12px",
                                                      left: "0px",
                                                      opacity: "1",
                                                    }}
                                                  >
                                                    <div>
                                                      <img
                                                        src={crossIcon}
                                                        alt="crossIcon"
                                                        style={{
                                                          marginTop: "15px",
                                                          width: "37px",
                                                          marginRight: "11px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </Grid>
                                        );
                                      })
                                    : null}
                                </UploadStatus>
                              </>
                            );
                          }
                        )
                      : null}
                  </UploadContainer>
                </UploadArea>
              </ClaimInfoDiv>
            </ClaimHeader>
            {isLoading ? (
              <Loader />
            ) : (
              <ClaimHeader
                item
                xs={12}
                sm={9}
                md={9}
                lg={9}
                xl={9}
                mobile={12}
                style={{
                  display: "flex",
                  height: "auto",
                  width: "100%",
                  marginBottom: "60px",
                }}
              >
                <Swiper
                  id={78988}
                  ref={swiperRef}
                  modules={[Navigation, Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  style={{ width: "100%", height: "100%" }}
                >
                  {claimInspectionDetails.attachment_requests_attributes
                    ?.length > 0 && isUpdateComplete
                    ? claimInspectionDetails?.attachment_requests_attributes.map(
                        (data) => {
                          return (
                            <>
                              <Box className="img">
                                {data.assets.map((y, index) => {
                                  const date = new Date(y.dateTime);
                                  const Day = date.getDate();
                                  const Month = date.getMonth() + 1;
                                  let Hour = date.getHours();
                                  const Min = date.getMinutes();
                                  const amPm = Hour >= 12 ? "PM" : "AM";
                                  Hour = Hour % 12;
                                  Hour = Hour ? Hour : 12;
                                  return (
                                    <SwiperSlide key={index}>
                                      {y.type === "image/jpg" ||
                                      y.type === "image/png" ? (
                                        <div
                                          style={{
                                            position: "relative",
                                            backgroundColor: "#000000",
                                          }}
                                        >
                                          <Image
                                            src={
                                              "data:image/*;base64," + y.asset
                                            }
                                            controls={true}
                                            key={index}
                                            onClick={(e) =>
                                              requestFullScreen(e, index)
                                            }
                                          />

                                          <SwiperArrowIcon>
                                            <img
                                              src={swiperArrow}
                                              alt="swiperArrow"
                                              style={{
                                                width: "39px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                swiperRef.current.swiper.slidePrev()
                                              }
                                            ></img>
                                            <img
                                              src={swiperArrow}
                                              alt="swiperArrow"
                                              style={{
                                                width: "39px",
                                                transform: "scaleX(-1)",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                swiperRef.current.swiper.slideNext()
                                              }
                                            ></img>
                                          </SwiperArrowIcon>

                                          {showDiv === "1" ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                position: "absolute",
                                                width: "100%",
                                                top: "45px",
                                                background: "#303340",
                                                left: "0px",
                                                opacity: "0.5",
                                              }}
                                              id="request_retake"
                                            >
                                              <div
                                                style={{
                                                  color: "#FFFFFF",
                                                  fontSize: "14px",
                                                  fontFamily: "OpenSans Bold",
                                                  lineHeight: "24px",
                                                  fontWeight: "600",
                                                  marginLeft: "11px",
                                                }}
                                              >
                                                {y.name}
                                                <span
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {Month +
                                                    "-" +
                                                    Day +
                                                    ", " +
                                                    Hour +
                                                    ":" +
                                                    Min +
                                                    " " +
                                                    amPm}
                                                </span>
                                              </div>
                                              {claimInspectionDetails.Status !==
                                              "COMPLETED" ? (
                                                claimInspectionDetails.Status ===
                                                "UPDATED" ? (
                                                  <div>
                                                    <img
                                                      src={refreshIcon}
                                                      alt="refreshIcon"
                                                      style={{
                                                        marginRight: "2px",
                                                        marginTop: "5px",
                                                        width: "18.75px",
                                                      }}
                                                    />
                                                    <Button
                                                      style={{
                                                        padding: "0px",
                                                        color: "#FFFFFF",
                                                        fontFamily:
                                                          "OpenSans Regular",
                                                        marginRight: "40px",
                                                      }}
                                                      disabled={
                                                        claimInspectionDetails.Status ===
                                                        "UPDATED"
                                                          ? true
                                                          : false
                                                      }
                                                      cursor={
                                                        claimInspectionDetails.Status ===
                                                        "UPDATED"
                                                          ? "not-allowed"
                                                          : "pointer"
                                                      }
                                                    >
                                                      REQUEST RETAKE
                                                    </Button>
                                                  </div>
                                                ) : (
                                                  <div
                                                    onClick={() =>
                                                      handleRetake(
                                                        y.name,
                                                        y.filename
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={refreshIcon}
                                                      alt="refreshIcon"
                                                      style={{
                                                        marginRight: "2px",
                                                        marginTop: "5px",
                                                        width: "18.75px",
                                                      }}
                                                      onClick={() =>
                                                        handleRetake(
                                                          y.name,
                                                          y.filename
                                                        )
                                                      }
                                                    />
                                                    <Button
                                                      style={{
                                                        padding: "0px",
                                                        color: "#FFFFFF",
                                                        fontFamily:
                                                          "OpenSans Regular",
                                                        marginRight: "40px",
                                                      }}
                                                      onClick={() =>
                                                        handleRetake(
                                                          y.name,
                                                          y.filename
                                                        )
                                                      }
                                                    >
                                                      REQUEST RETAKE
                                                    </Button>
                                                  </div>
                                                )
                                              ) : (
                                                " "
                                              )}
                                            </div>
                                          ) : (
                                            <SwiperArrowIcon>
                                              <img
                                                src={swiperArrow}
                                                alt="swiperArrow"
                                                style={{
                                                  width: "39px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  swiperRef.current.swiper.slidePrev()
                                                }
                                              ></img>
                                              <img
                                                src={swiperArrow}
                                                alt="swiperArrow"
                                                style={{
                                                  width: "39px",
                                                  transform: "scaleX(-1)",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  swiperRef.current.swiper.slideNext()
                                                }
                                              ></img>
                                            </SwiperArrowIcon>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              position: "relative",
                                              backgroundColor: "#000000",
                                            }}
                                          >
                                            <Video
                                              className="react-player fixed-bottom"
                                              controls
                                              controlsList="nodownload noremoteplayback noplaybackrate"
                                              disablePictureInPicture={true}
                                              playsInline={true}
                                              key={index}
                                              style={{
                                                objectFit: "contain",
                                                marginRight: "11px",
                                              }}
                                              onClick={(e) =>
                                                handleThumb(e, y.slideIndex)
                                              }
                                            >
                                              <source
                                                src={
                                                  "data:video/quicktime;base64," +
                                                  y.asset
                                                }
                                                type="video/quicktime"
                                              ></source>
                                              <source
                                                src={
                                                  "data:video/mp4;base64," +
                                                  y.asset
                                                }
                                                type="video/mp4"
                                              ></source>

                                              <source
                                                src={
                                                  "data:video/mpeg4;base64," +
                                                  y.asset
                                                }
                                                type="video/mpeg4"
                                              ></source>

                                              <source
                                                src={
                                                  "data:video/webm;base64," +
                                                  y.asset
                                                }
                                                type="video/webm"
                                              ></source>
                                            </Video>
                                            <SwiperArrowIcon>
                                              <img
                                                src={swiperArrow}
                                                alt="swiperArrow"
                                                style={{
                                                  width: "39px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  swiperRef.current.swiper.slidePrev()
                                                }
                                              ></img>
                                              <img
                                                src={swiperArrow}
                                                alt="swiperArrow"
                                                style={{
                                                  width: "39px",
                                                  transform: "scaleX(-1)",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  swiperRef.current.swiper.slideNext()
                                                }
                                              ></img>
                                            </SwiperArrowIcon>

                                            {showDiv === "1" ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  position: "absolute",
                                                  width: "100%",
                                                  top: "45px",
                                                  background: "#303340",
                                                  left: "0px",
                                                  opacity: "0.5",
                                                }}
                                                id="request_retake"
                                              >
                                                <div
                                                  style={{
                                                    color: "#FFFFFF",
                                                    fontSize: "14px",
                                                    fontFamily: "OpenSans Bold",
                                                    lineHeight: "24px",
                                                    fontWeight: "600",
                                                    marginLeft: "11px",
                                                  }}
                                                >
                                                  {y.name}
                                                  <span
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    {Month +
                                                      "-" +
                                                      Day +
                                                      ", " +
                                                      Hour +
                                                      ":" +
                                                      Min +
                                                      " " +
                                                      amPm}
                                                  </span>
                                                </div>
                                                {claimInspectionDetails.Status !==
                                                "COMPLETED" ? (
                                                  claimInspectionDetails.Status ===
                                                  "UPDATED" ? (
                                                    <div>
                                                      <img
                                                        src={refreshIcon}
                                                        alt="refreshIcon"
                                                        style={{
                                                          marginRight: "2px",
                                                          marginTop: "5px",
                                                          width: "18.75px",
                                                        }}
                                                      />
                                                      <Button
                                                        style={{
                                                          padding: "0px",
                                                          color: "#FFFFFF",
                                                          fontFamily:
                                                            "OpenSans Regular",
                                                          marginRight: "40px",
                                                        }}
                                                        disabled={
                                                          claimInspectionDetails.Status ===
                                                          "UPDATED"
                                                            ? true
                                                            : false
                                                        }
                                                        cursor={
                                                          claimInspectionDetails.Status ===
                                                          "UPDATED"
                                                            ? "not-allowed"
                                                            : "pointer"
                                                        }
                                                      >
                                                        REQUEST RETAKE
                                                      </Button>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      onClick={() =>
                                                        handleRetake(
                                                          y.name,
                                                          y.filename
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={refreshIcon}
                                                        alt="refreshIcon"
                                                        style={{
                                                          marginRight: "2px",
                                                          marginTop: "5px",
                                                          width: "18.75px",
                                                        }}
                                                        onClick={() =>
                                                          handleRetake(
                                                            y.name,
                                                            y.filename
                                                          )
                                                        }
                                                      />
                                                      <Button
                                                        style={{
                                                          padding: "0px",
                                                          color: "#FFFFFF",
                                                          fontFamily:
                                                            "OpenSans Regular",
                                                          marginRight: "40px",
                                                        }}
                                                        onClick={() =>
                                                          handleRetake(
                                                            y.name,
                                                            y.filename
                                                          )
                                                        }
                                                      >
                                                        REQUEST RETAKE
                                                      </Button>
                                                    </div>
                                                  )
                                                ) : (
                                                  " "
                                                )}
                                              </div>
                                            ) : (
                                              <SwiperArrowIcon>
                                                <img
                                                  src={swiperArrow}
                                                  alt="swiperArrow"
                                                  style={{
                                                    width: "39px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    swiperRef.current.swiper.slidePrev()
                                                  }
                                                ></img>
                                                <img
                                                  src={swiperArrow}
                                                  alt="swiperArrow"
                                                  style={{
                                                    width: "39px",
                                                    transform: "scaleX(-1)",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    swiperRef.current.swiper.slideNext()
                                                  }
                                                ></img>
                                              </SwiperArrowIcon>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </SwiperSlide>
                                  );
                                })}
                              </Box>
                            </>
                          );
                        }
                      )
                    : null}
                </Swiper>
              </ClaimHeader>
            )}
            <DialogConfirm open={openAccept} />
            <DialogRetake
              fileName={currentRetakeFileName}
              attachmentName={currentRetakeName}
            />
          </div>
        </ClaimContainer>
      }
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
    </>
  );
};

export default ReviewInspectionRequest;
