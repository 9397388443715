export const data = {
  shop: {
    first_reminder: {
      toggleValue: true,

      time: 2,

      type: "hours",
    },

    final_reminder: {
      toggleValue: true,

      time: 2,

      type: "hours",
    },
  },

  adjudicator: {
    readyForReview: true,

    first_reminder: {
      toggleValue: true,

      time: 2,

      type: "hours",
    },

    second_reminder: {
      toggleValue: true,

      time: 2,

      type: "hours",
    },

    shop_late: {
      toggleValue: true,

      time: 3,

      type: "hours",
    },
  },
};
