import React from "react";
import loader from "../../../assets/images/loader-spinner.svg";
import "./loader.css";

const Loader = () => {
  return (
    <>
      <div id="loader-wrapper">
        <div id="loader"></div>
      </div>
    </>
  );
};
export default Loader;
