import React from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { Heading, SubHeading, Header } from "../review/dialogSent.styled";
import sent from "../../../assets/images/sent-tick.svg";
import close from "../../../assets/images/close.svg";
import { useDispatch } from "react-redux";
import { setCloseInpsectionSuccess } from "../../../store/claimInspection/action";
import { setShowClosedScreen } from "../../../store/inspection/dashboard/getClaimsData/action";

const DialogInspectionClosedSent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(setCloseInpsectionSuccess({}));
    navigate("/dashboard");
    dispatch(setShowClosedScreen(true));
  };

  return (
    <Dialog
      open={props.open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          props.close;
        }
      }}
      fullWidth
      maxWidth={"sm"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            backgroundColor: "transparent !important",
            boxShadow: "none",
            width: "445px",
            borderRadius: ".5",
          },
        },
      }}
    >
      <Header>
        <img
          src={close}
          onClick={handleClose}
          style={{
            cursor: "pointer",
          }}
        />
      </Header>
      <div
        style={{ padding: "10%", backgroundColor: "#fff", borderRadius: "2px" }}
      >
        <div style={{ marginLeft: "44%" }}>
          <img src={sent} width={"44px"} height={"30px"} />
        </div>
        <Heading>Inspection Closed</Heading>
        <SubHeading>Returning to your dashboard.</SubHeading>
      </div>
    </Dialog>
  );
};
export default DialogInspectionClosedSent;
