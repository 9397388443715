import { combineReducers } from "redux";
import loaderReducer from "./sharedComponents/loader/reducer";
import { dashboardReducer } from "./inspection/dashboard/reducer/dashboardReducer";
import HeaderReducer from "./sharedComponents/header/reducer";
import LoginReducer from "./accounts/login/reducer";
import FooterReducer from "./sharedComponents/footer/reducer";
import { UserManagmentReducer } from "./userManagment/reducer";
import { ReminderReducers } from "./reminders/reducers";
import { ClaimInspectionReducer } from "./claimInspection/reducer";
import { ReportingReducer } from "./reporting/reducer";
import { ApiKeyReducer } from "./apiKey/reducer";

export const mainReducer = combineReducers({
  loader: loaderReducer,
  dashboardReducer,
  headerReducer: HeaderReducer,
  loginReducer: LoginReducer,
  footerReducer: FooterReducer,
  userManagmentReducer: UserManagmentReducer,
  reminderReducers: ReminderReducers,
  claimInspectionReducer: ClaimInspectionReducer,
  reportingReducer: ReportingReducer,
  apiKeyReducer: ApiKeyReducer,
});
