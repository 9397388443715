import React, { useRef, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import OktaSignInWidget from "./oktaSignInWidget";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  showLoader,
} from "../../../store/sharedComponents/loader/action";
import Loader from "../../../shared/commonComponents/loader/loader";
import SessionStorageService from "../../../services/functional/accounts/login/storage";
import { Collapse, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  setDefault,
  setForgotPassword,
  setSupport,
  getJWTToken,
  resetLoginError,
} from "../../../store/accounts/login/action";

const Assurant = ({ loggedIn }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isNeedHelp, setNeedHelp] = useState(false);
  const [createInspection, setCreateInspection] = useState("");
  const [reviewInspection, setReviewInspection] = useState("");
  const [open, setOpen] = useState(true);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const onSuccess = (tokens) => {
    SessionStorageService.set("Authenticated", true);
    oktaAuth.handleLoginRedirect(tokens);
  };
  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);
  const jwtToken = SessionStorageService.get("JWT_Token");
  const Authenticated = SessionStorageService.get("Authenticated");
  const loginError = useSelector((state) => state?.loginReducer?.loginError);

  const onError = (err) => {
    console.log("Sign in error:", err);
  };
  const onClickOfNeedHelp = () => {
    setNeedHelp(true);
  };

  const onClickForgotPassword = () => {
    dispatch(setDefault(false));
    dispatch(setForgotPassword(true));
    dispatch(setSupport(false));
  };

  const onClickSupport = () => {
    dispatch(setDefault(false));
    dispatch(setSupport(true));
    dispatch(setForgotPassword(false));
  };
  const jwtTokens = useSelector(
    (state) => state.loginReducer.JWTToken?.data?.data?.user?.jwt_token
  );
  useEffect(() => {
    if (location?.state?.from?.pathname.includes("create")) {
      setCreateInspection(location?.state?.from?.pathname);
    }
    if (location?.state?.from?.pathname.includes("review")) {
      setReviewInspection(location?.state?.from?.pathname);
    }
    if (!userDetails) {
      if (Authenticated) {
        dispatch(showLoader());
        const email = authState?.idToken.claims.email.replace("-test", "");
        const accessToken = authState?.accessToken.accessToken;
        dispatch(
          getJWTToken({
            email,
            access_token: "Bearer " + accessToken,
          })
        );
      } else {
        navigate("/login");
      }
    } else if (jwtToken) {
      if (userDetails?.roles.toLowerCase().includes("super")) {
        navigate("/setup");
      } else if (createInspection) {
        const adjudicatorId = createInspection.split("/");
        if (userDetails?.roles.toLowerCase().includes("admin")) {
          navigate(createInspection);
        } else if (userDetails._id === adjudicatorId[4]) {
          navigate(createInspection);
        } else {
          navigate("/dashboard");
        }
      } else if (reviewInspection) {
        navigate(reviewInspection);
      } else {
        navigate("/dashboard");
      }
      dispatch(hideLoader());
    }
  }, [jwtToken, Authenticated]);
  useEffect(() => {
    if (loginError) {
      dispatch(hideLoader());
    }
    if (loginError) {
      setTimeout(() => {
        setOpen(false);
        dispatch(resetLoginError(null));
      }, 4000);
    }
  }, [loginError, open]);
  const closeError = () => {
    setOpen(false);
    dispatch(resetLoginError(null));
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }

  return (
    <>
      {isLoading ? (
        <div style={{ height: "25rem" }}>
          <Loader />
        </div>
      ) : (
        <>
          <OktaSignInWidget
            onSuccess={onSuccess}
            onError={onError}
            logIn={loggedIn}
          />
          {loginError ? (
            <div className="lower-bottom" style={{ width: "320px" }}>
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        closeError();
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                  sx={{
                    alignItems: "center",
                    height: "30px",
                    width: "100%",
                    mr: 3,
                    ml: 3,
                    mt: 3,
                    mb: 2,
                    fontSize: "12px",
                  }}
                  variant="outlined"
                  severity="error"
                >
                  {loginError ? loginError?.status?.message : null}
                </Alert>
              </Collapse>
            </div>
          ) : (
            <>
              <div className="lower-bottom">
                <p className="lower-border"></p>
                <p className="Or">OR</p>
                <p className="lower-border"></p>
              </div>
              <div className="MainDiv">
                {isNeedHelp ? (
                  <div className="t-c" style={{ paddingBottom: "25px" }}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={onClickForgotPassword}
                    >
                      Forgot password?
                    </span>
                    <br />
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={onClickSupport}
                    >
                      Support
                    </span>
                  </div>
                ) : (
                  <div
                    className="t-c"
                    style={{ height: "50px", lineHeight: "0px" }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={onClickOfNeedHelp}
                    >
                      {" "}
                      Need help signing in?
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Assurant;
