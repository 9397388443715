import { request } from "../../core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getClaimInspectionServices = (claimId) => {
  const url = ENDPOINTS.GET_CLAIM_INSPECTION_DETAILS + `${claimId}`;
  return request(METHOD_TYPES.GET, url);
};
export const createNewInspectionRequest = (payload) => {
  const data = {
    support_doc_array: payload.support_doc_array,
    status: payload.status,
    step: payload.step,
  };
  return request(
    METHOD_TYPES.PUT,
    `${ENDPOINTS.CREATE_NEW_INSPECTION}/${payload.claimNo}`,
    data
  );
};
export const resendInspectionServices = ({ payload }) => {
  const url = `${ENDPOINTS.INSPECTION}/${payload.claimNo}/rerequest`;
  return request(METHOD_TYPES.POST, url);
};
export const getSearchPartList = (payload) => {
  const url = `v1/api/inspections/attachment_types?filter=${payload}`;
  return request(METHOD_TYPES.GET, url);
};
export const getAttachmentDetails = (payload) => {
  const url = `/v1/api/inspections/${payload}/attachmentdetails`;
  return request(METHOD_TYPES.GET, url);
};

export const downloadFilesServices = async (payload) => {
  const url = "/v1/api/files/downloadIntegration";
  return await request(
    METHOD_TYPES.POST,
    ENDPOINTS.DOWNLOAD_ASSET_DETAILS,
    payload
  );
};

export const editAttachmentDetails = (payload) => {
  const data = { support_doc_array: payload.support_doc_array };
  const url = `${ENDPOINTS.CREATE_NEW_INSPECTION}/${payload.claimNo}/updateattachment`;
  return request(METHOD_TYPES.PUT, url, data);
};

export const acceptInspection = (payload) => {
  const url = `${ENDPOINTS.INSPECTION}/${payload}/accept`;
  return request(METHOD_TYPES.PUT, url);
};
export const retakeRequest = (payload) => {
  const url = `${ENDPOINTS.INSPECTION}/${payload.claimNo}/retake`;
  return request(METHOD_TYPES.PUT, url, payload.retakeData);
};
export const getRetakeRequestComment = (payload) => {
  // return request(
  //   METHOD_TYPES.GET,
  //   `${ENDPOINTS.INSPECTION}/${payload.claimNo}/attachmentname/${payload.attachmentName}`
  // );
  const url = `${ENDPOINTS.INSPECTION}/${payload.claimNo}/attachmentname?attachmentname=${payload.attachmentName}`;
  return request(METHOD_TYPES.GET, url);
};

export const closeInspection = (payload) => {
  return request(
    METHOD_TYPES.PUT,
    `${ENDPOINTS.INSPECTION}/${payload.claimId}/close`,
    payload
  );
};
