import * as types from "./types";

export const setForgotPassword = (payload) => {
  return {
    type: types.FORGOTPASSWORD,
    payload,
  };
};

export const setSupport = (payload) => {
  return {
    type: types.SUPPORT,
    payload,
  };
};

export const setDefault = (payload) => {
  return {
    type: types.SETDEFAULTSCREEN,
    payload,
  };
};

export const isAssurantUser = (payload) => {
  return {
    type: types.IS_ASSURANT_USER,
    payload,
  };
};
export const getJWTToken = (payload) => ({
  type: types.GET_JWT_TOKEN,
  payload,
});
export const setActivateUser = (payload) => ({
  type: types.SET_ACTIVATE_USER,
  payload,
});
export const activateUser = (payload) => ({
  type: types.ACTIVATE_USER,
  payload,
});
export const sendResetMail = (payload) => ({
  type: types.SEND_RESET_MAIL,
  payload,
});
export const resetPassword = (payload) => ({
  type: types.RESET_PASSWORD,
  payload,
});
export const supportRequest = (payload) => ({
  type: types.SUPPORT_REQUEST,
  payload,
});
export const resetSupport = (payload) => ({
  type: types.RESET_SUPPORT,
  payload,
});
export const resetLoginError = (payload) => ({
  type: types.RESET_LOGIN_ERROR,
  payload,
});
