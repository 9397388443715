import * as types from "./types";

export const getReportingData = (payload) => ({
  type: types.GET_REPORTING_DATA,
  payload,
});

export const getFilteredReportingData = (payload) => ({
  type: second,
  payload,
});
export const setFilterEnable = (payload) => ({
  type: types.SET_FILTER_ENABLE,
  payload,
});
export const downloadReport = (payload) => ({
  type: types.DOWNLOAD_REPORT,
  payload,
});
export const reportHeaderData = (payload) => ({
  type: types.GET_REPORTING_HEADER_DATA,
  payload,
});
