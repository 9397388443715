/**
 *
 * @author(Karan Dumbre)
 * @date(07-04-2022)
 *
 * The below component is using the withTranslation HOC.
 * The advantage of the below component is we dont have to use the withTranslation HOC in each and every component.
 * Pass the id to the below component, it will return the translated string
 *
 */
// vendor
import React from "react";
import DOMPurify from "dompurify";
import {
  withTranslation,
  useTranslation as i18useTranslation,
} from "react-i18next";

const defaultTranslations = "translations"; // translations is the default object created by react-i18next,
// we pass this to withTransaltion HOC to tell react-i18n where to see for the translation object
// For multiple transaltion files -> https://react.i18next.com/guides/multiple-translation-files

const FormattedMessage = (props) => {
  const { id, t, objName = {} } = props;
  const mySafeHTML = DOMPurify.sanitize(String(t(id, { ...objName })));
  return (
    <span
      data-sdet={id}
      dangerouslySetInnerHTML={{
        __html: mySafeHTML.replace(/href/g, "target='_blank' href"),
      }}
    />
  );
};

export const useTranslation = (id, sanitizeOptions) => {
  const { t } = i18useTranslation(defaultTranslations);
  return DOMPurify.sanitize(t(id, { ...sanitizeOptions }));
};

export default withTranslation(defaultTranslations)(FormattedMessage);
