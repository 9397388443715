import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, LinearProgress } from "@mui/material";
import { Box, Heading, ShopName, PlusLogo, Message } from "./styled";
import { Header } from "../users/create/styled";
import refresh from "../../../assets/images/refresh.svg";
import removeUpload from "../../../assets/images/removeUpload.svg";
import successImg from "../../../assets/images/success.svg";
import add from "../../../assets/images/add.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import close from "../../../assets/images/close.svg";
import Button from "../../../shared/commonComponents/button/button";
import BottomNavigation from "../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import {
  displayImage,
  getShopListOfClient,
  editClientLogo,
  editShopLogo,
  getClientList,
  fileFetch,
  fileUpload,
  handleError,
  fileFetchHeader,
  closeShopLogo,
  closeClientLogo,
  closeLogoImg,
} from "../../../store/userManagment/action";
import {
  showLoader,
  hideLoader,
} from "../../../store/sharedComponents/loader/action";
import Loader from "../../../shared/commonComponents/loader/loader";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";
const Logo = (props) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [imageName, setImageName] = useState("");
  const [isUploading, setUploading] = useState(false);

  const isLoading = useSelector((state) => state?.loader?.isLoading);

  const fileInput = useRef();

  const fileUploaded = useSelector(
    (state) => state.userManagmentReducer?.fileUpload
  );

  const shopLogoResponse = useSelector(
    (state) => state.userManagmentReducer?.shopLogoSuccess
  );

  const clientLogoResponse = useSelector(
    (state) => state.userManagmentReducer?.clientLogoSuccess
  );

  useEffect(() => {
    if (clientLogoResponse) {
      if (props.editing === "client") {
        dispatch(getClientList());
      }
      props.onClose(true);
    } else if (shopLogoResponse) {
      if (props.editing === "shop") {
        dispatch(getShopListOfClient(props.onClient._id));
      }
      props.onClose(true);
    }
    return () => {
      if (props.editing === "shop") {
        dispatch(closeShopLogo(null));
      } else if (props.editing === "client") {
        dispatch(closeClientLogo(null));
      }
    };
  }, [clientLogoResponse, shopLogoResponse]);

  const clientValues = useSelector(
    (state) => state.userManagmentReducer?.clientValues
  );

  const uploadImageProgress = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setMessage("Uploaded");
        }
        return Math.min(oldProgress + 15, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    if (props.editing === "client") {
      if (props.onClient?.image_url) {
        dispatch(showLoader());
        dispatch(fileFetch({ link: props.onClient?.image_url }));
      }
    } else {
      if (props.onShop?.image_url) {
        dispatch(showLoader());
        dispatch(fileFetch({ link: props.onShop?.image_url }));
      }
    }
    return () => {
      dispatch(closeLogoImg(null));
    };
  }, []);

  const fetchedUrl = useSelector(
    (state) => state.userManagmentReducer?.fileFetch?.data
  );

  const handleErrorState = () => {
    dispatch(handleError());
  };

  useEffect(() => {
    if (fetchedUrl?.asset) {
      if (props.editing === "client") {
        const byteCharacters = atob(fetchedUrl.asset);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/*" });
        const myFile = URL.createObjectURL(blob);
        const file = new File([blob], "client-logo", {
          type: "image/*",
          lastModified: Date.now(),
        });
        setImageName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
          dispatch(hideLoader());
        };
        reader.readAsDataURL(blob);
      } else {
        setPreview(null);
      }
      if (props.editing === "shop") {
        const byteCharacters = atob(fetchedUrl?.asset);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/*" });
        const myFile = URL.createObjectURL(blob);
        const file = new File([blob], "shop-logo", {
          type: "image/*",
          lastModified: Date.now(),
        });
        setImageName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
          dispatch(hideLoader());
        };
        reader.readAsDataURL(blob);
      } else {
        setPreview(null);
      }
    } else {
      setPreview(null);
    }
  }, [fetchedUrl]);
  const isError = useSelector((state) => state.userManagmentReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.userManagmentReducer?.error?.message
  );

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setProgress(0);
        setUploading(true);
        uploadImageProgress();
        const formData = new FormData();
        dispatch(displayImage(preview));
        formData.append("contas", "logo");
        formData.append("filetype", "images/*");
        formData.append("image", image);
        dispatch(fileUpload(formData));
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const onSave = () => {
    if (props.editing === "client") {
      const clientLogo = {
        client_id: props.onClient._id,
        image_url: fileUploaded.data?.path,
      };
      dispatch(editClientLogo(clientLogo));
      if (clientValues.name.toLowerCase().includes("assurant")) {
        dispatch(fileFetchHeader({ link: fileUploaded.data?.path }));
      }
    } else if (props.editing === "shop") {
      const shopLogo = {
        store_id: props.onShop._id,
        image_url: fileUploaded.data?.path,
      };
      dispatch(editShopLogo(shopLogo));
    }
  };

  const onDelete = () => {
    setPreview(null);
  };

  return (
    <>
      <Grid container>
        <>
          <Header>
            <img
              src={close}
              alt="close"
              onClick={props.onClose}
              style={{ cursor: "pointer" }}
            />
          </Header>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ backgroundColor: "#fff", height: "300px" }}
          >
            <div style={{ margin: "20px 30px 0 30px" }}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <Heading className="editlogo">Edit Logo</Heading>
                  <Heading className="logo-preview">Logo Preview</Heading>
                  <div style={{ display: "grid" }}>
                    <div>
                      <Box className="img">
                        {preview ? (
                          <img
                            src={preview}
                            alt="img"
                            style={{
                              width: "385px",
                              height: "80px",
                            }}
                          />
                        ) : null}
                      </Box>
                    </div>
                    <div style={{ display: "block" }}>
                      {preview ? (
                        <>
                          <div style={{ display: "flex" }}>
                            <ShopName>{imageName}</ShopName>
                            {fileUploaded.data?.path ? (
                              <>
                                <div
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                  }}
                                >
                                  {message === "Uploaded" ? (
                                    <>
                                      <div>
                                        <img
                                          src={successImg}
                                          alt="success"
                                          style={{ marginRight: "13px" }}
                                        />
                                      </div>
                                      <div>
                                        <img
                                          src={removeUpload}
                                          alt="delete"
                                          onClick={() => {
                                            setPreview(null);
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                }}
                              >
                                <div>
                                  <img
                                    src={refresh}
                                    alt="refresh"
                                    style={{ marginRight: "13px" }}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      fileInput.current.click();
                                    }}
                                  />
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={fileInput}
                                    accept="image/*"
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      if (
                                        file &&
                                        file.type.substring(0, 5) === "image"
                                      ) {
                                        setImage(file);
                                      } else {
                                        setImage(null);
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={deleteIcon}
                                    alt="delete"
                                    style={{ padding: "2px" }}
                                    onClick={onDelete}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {preview && isUploading && (
                            <div style={{ width: "70%" }}>
                              <div>
                                <LinearProgress
                                  variant="determinate"
                                  color="success"
                                  value={progress}
                                />
                              </div>
                              <Message>{message}</Message>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <PlusLogo>
                            <img
                              src={add}
                              alt="+logo"
                              style={{ padding: "2px" }}
                              onClick={(event) => {
                                event.preventDefault();
                                fileInput.current.click();
                              }}
                            />
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={fileInput}
                              accept="image/*"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (
                                  file &&
                                  file.type.substring(0, 5) === "image"
                                ) {
                                  setImage(file);
                                } else {
                                  setImage(null);
                                }
                              }}
                            />
                            <Heading className="logo">Logo</Heading>
                          </PlusLogo>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Grid>
          <BottomNavigation
            opacity={"1"}
            position={"sticky"}
            padding={"17px 30px 0 30px"}
            justifyContent={"space-between"}
            moblieJustifyContent={"space-around"}
            borderRadius={"0px 0px 4px 4px"}
            minHeight={"55px"}
            bottom={"0"}
            left={"0"}
          >
            <Button
              show={true}
              width={"120px"}
              height={"32px"}
              clicked={props.onClose}
              secondary={true}
            >
              Cancel
            </Button>

            <Button
              show={true}
              width={"120px"}
              height={"32px"}
              clicked={onSave}
              disabled={
                fileUploaded.data?.path && image && preview ? false : true
              }
              primary={
                fileUploaded.data?.path && image && preview ? true : false
              }
            >
              Save
            </Button>
          </BottomNavigation>
        </>
      </Grid>

      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
    </>
  );
};

export default Logo;
