import React, { useEffect, useState } from "react";
import {
  MainContainer,
  HeaderGrid,
  Grid3,
  Grid2,
  Button,
  ButtonArea,
} from "../../../styles/inspectionHeader.styled";
import { fileFetchHeader } from "../../../store/userManagment/action";
import assurantLogo from "../../../assets/images/assurant-logo.svg";
import { useNavigate } from "react-router-dom";
import { setAccept } from "../../../store/claimInspection/action";
import { useDispatch, useSelector } from "react-redux";
import SessionStorageService from "../../../services/functional/accounts/login/storage";
import { setShowClosedScreen } from "../../../store/inspection/dashboard/getClaimsData/action";

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [preview, setPreview] = useState();

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const clientdetails = SessionStorageService.get("CLIENT_DETAILS");
  const parseClientDetails = JSON.parse(clientdetails);

  const retakeRequest = useSelector(
    (state) => state.claimInspectionReducer.retakeSuccess
  );

  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );

  const isLogo = useSelector((state) => state.headerReducer.condition?.isLogo);
  const claimInspectionDetails = useSelector(
    (state) => state.claimInspectionReducer.claimInspectionDetails
  );

  const handleRequestMoreDetails = () => {
    navigate(`/inspection/request-more/${currentClaim}`);
  };

  useEffect(() => {
    if (!userDetails.roles.toLowerCase().includes("super") && !fetchedUrl) {
      dispatch(fileFetchHeader({ link: parseClientDetails?.image_url }));
    }
  }, []);

  const fetchedUrl = useSelector(
    (state) => state.userManagmentReducer?.fileFetchHeader?.data
  );
  const enableAccept = useSelector(
    (state) => state?.claimInspectionReducer?.enableAcceptInspection
  );

  const reset = useSelector((state) => state?.claimInspectionReducer?.reset);
  const handleAccept = () => {
    dispatch(setAccept(true));
  };

  const displayImageUrl = () => {
    if (fetchedUrl) {
      const asset = fetchedUrl.asset;
      const assetValue = "data:image/png;base64," + asset;
      setPreview(assetValue);
    } else {
      setPreview(null);
    }
  };
  const onLogoClick = () => {
    if (claimInspectionDetails.Status === "COMPLETED") {
      dispatch(setShowClosedScreen(true));
      navigate("/dashboard");
    } else {
      window.location = window.location.origin + "/dashboard";
    }
  };
  useEffect(() => {
    displayImageUrl();
  }, [fetchedUrl]);

  useEffect(() => {
    if (reset) {
      navigate("/dashboard", { replace: true });
    }
  }, [reset]);
  return (
    <>
      <MainContainer
        style={{ position: "fixed", top: "0", width: "100%", zIndex: "2" }}
      >
        <HeaderGrid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Grid2 item xs={6} sm={6} md={6} lg={6} xl={6} mobile={6}>
            {isLogo && (
              <img
                src={preview ? preview : assurantLogo}
                alt="Logo"
                width={"130px"}
                height={"35px"}
                style={{ margin: "15px 0 16px 35px", cursor: "pointer" }}
                onClick={() => onLogoClick()}
              ></img>
            )}
          </Grid2>
          <Grid3
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            mobile={6}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {claimInspectionDetails.Status !== "COMPLETED" ? (
              <ButtonArea mediumMarginLeft={"0rem"}>
                <Button
                  marginTop={"0px"}
                  width={"40%"}
                  ipadWidth={"68%"}
                  mediumWidth={"65%"}
                  padding={"6px 24px"}
                  backgroundColor={"#FFFFFF"}
                  color={"#666666"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={handleRequestMoreDetails}
                  height={"35px"}
                  disabled={
                    claimInspectionDetails.Status === "UPDATED" ? true : false
                  }
                  cursor={
                    claimInspectionDetails.Status === "UPDATED"
                      ? "not-allowed"
                      : "pointer"
                  }
                >
                  Request More Details
                </Button>
                <Button
                  marginTop={"0px"}
                  width={"40%"}
                  padding={"0px"}
                  onClick={handleAccept}
                  height={"35px"}
                  mediumWidth={"54%"}
                  backgroundColor={enableAccept ? "gray" : "black"}
                  disabled={enableAccept ? true : false}
                >
                  Accept Inspection
                </Button>
              </ButtonArea>
            ) : (
              ""
            )}
          </Grid3>
        </HeaderGrid>
      </MainContainer>
    </>
  );
};
export default Header;
