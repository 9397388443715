import styled from "styled-components";
import { Grid } from "@mui/material";

export const Header = styled.div`
  direction: rtl;
  width: 100%;
`;

export const MainContainer = styled(Grid)``;
export const DeleteHeader = styled.div`
  font-family: "OpenSans Regular";
  &.heading {
    font-size: 18px;
    line-height: 25px;
    color: #13223c;
    margin: 0 0 15px 0;
  }
  &.context {
    font-size: 14px;
    line-height: 26px;
    color: #3d515a;
    margin: 0 0 21px 0;
  }
`;
