import styled from "styled-components";
import { Grid } from "@mui/material";
import media from "../../../../styles/media.styled";

export const HeaderContainer = styled(Grid)``;

export const HeaderTextContainer = styled(Grid)`
  background: #ffffff;
  display: flex;
  box-shadow: inset 0px -1px 0px rgba(20, 20, 20, 0.2);
  padding-bottom: 15px;
  font-family: "OpenSans Bold";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #13223c;
  &.headerClaim {
    padding-left: 10px;
  }
`;

export const BodyGridContainer = styled(Grid)``;
export const CenterGrid = styled(Grid)``;

export const BodyTextContainer = styled(Grid)`
  background: #ffffff;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(20, 20, 20, 0.2);
  padding-top: 5px;
  height: 60px;
  font-family: "OpenSans Regular";
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #3d515a;
  word-break: break-word;
  width: 100px;
  display: flex;

  &.bodyClaim {
    padding-left: 10px;
  }

  &.bodyShop {
    ${media.smalDevice`
    padding-left: 0% ;
    `}
    word-break: break-word;
    width: 100px;
  }

  &.bodyAdjudicator {
    padding-left: 3%;
    ${media.smalDevice`
      padding-left: 0% ;
      `}
    word-break: break-word;
    width: 100px;
  }
  &.bodyTotal {
    ${media.smalDevice`
      padding-left: 0% ;
      `}
  }
  &.noClaim {
    box-shadow: none;
    padding-left: 10px;
  }
`;

export const MainContainer = styled(Grid)`
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MainGrid = styled(Grid)`
  margin: 3.375rem 0 0 0;
  display: flex;
  justify-content: space-around;
`;

export const LeftGrid = styled(Grid)``;

export const CardGrid1 = styled(Grid)`
  display: flex;
  justify-content: center;
  &.pendingReview {
    padding-right: 10%;
  }
  &.urgentMechanic {
    padding-left: 5%;
  }
  &.pendingMechanic {
    padding-right: 5%;
  }
  &.customerCare {
    padding-right: 5%;
  }
`;

export const SubCardGrid = styled.div`
  height: 70px;
  width: 140px;
  display: flex;
  margin-top: 54px;
  background: #ffffff;
  box-shadow: 0px 3px 14px rgba(100, 128, 200, 0.1);
  border-radius: 6px;
  ${media.smalDevice`
    width: 13%;
    `};
`;

export const CardGrid = styled(Grid)`
  display: flex;
  margin-bottom: 7px;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled(Grid)`
  border-radius: 100px;
  background: white;
  &.display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const NameCard = styled(Grid)`
  font-family: "OpenSans SemiBold";
  font-size: 14px;
  line-height: 18px;
  color: #e18801;
  padding-top: 15px;
  padding-left: 10px;
  ${media.smalDevice`
      padding-left: 2px ;
      `}

  &.totalActive {
    padding: 5px 0 0 0;
    color: #3d515a;
  }
`;

export const NumberCard = styled(Grid)`
  font-family: "OpenSans Bold";
  font-size: 40px;
  line-height: 54px;
  color: #3d515a;
  padding-top: 5px;
  padding-left: 15px;
  ${media.smalDevice`
      padding-left: 3px ;
      `}

  &.totalActiveNumber {
    font-family: "OpenSans Regular";
    font-size: 60px;
    line-height: 82px;
    text-align: center;
    padding: 0;
  }
`;

export const RightGrid = styled(Grid)``;

export const BannerAlert = styled.div`
  font-family: "OpenSans Regular";
  color: #3d515a;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 57px;
  width: 440px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: ${(props) =>
    props.color === "blue"
      ? "1px solid #2490EE"
      : (props.color === "orange"
          ? "1px solid #E18801"
          : "1px solid #B90000") || "1px solid #2490EE"};
  background: ${(props) =>
    props.color === "blue"
      ? "linear-gradient(0deg, rgba(36, 144, 238, 0.05), rgba(36, 144, 238, 0.05)), #FFFFFF"
      : (props.color === "orange"
          ? "linear-gradient(0deg, rgba(225, 109, 1, 0.05), rgba(225, 109, 1, 0.05)), #FFFFFF;"
          : "linear-gradient(0deg, rgba(185, 0, 0, 0.05), rgba(185, 0, 0, 0.05)), #FFFFFF;") ||
        "linear-gradient(0deg, rgba(36, 144, 238, 0.05), rgba(36, 144, 238, 0.05)), #FFFFFF"};
`;
