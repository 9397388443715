import styled from "styled-components";
import media from "../../../styles/media.styled";

export const ButtonContainer = styled.button.attrs(
  ({ onClick, disabled, onMouseDown }) => ({
    onclick: onClick,
    disabled,
    onmousedown: onMouseDown,
  })
)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) =>
    props.border
      ? props.mouseDown
        ? "2px solid lightblue"
        : props.border
      : props.mouseDown
      ? "2px solid lightblue"
      : "none"};
  border: ${(props) =>
    props.disabled && (props.border ? props.border : "none")};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "OpenSans Regular"};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "19px"};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.show ? "flex" : "none")};
  outline-style: none;
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.WhiteSpace};
  ${media.mobileDevice`
        width: ${(props) => props.mobileWidth};
        height:${(props) => props.mobileHeight};
        margin: ${(props) => props.mobileMargin};
    `};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "unset")};
`;
