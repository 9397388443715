export const getStoredAuthToken = () => {
  const token = localStorage.getItem("JWT_Token");
  if (token) {
    return token;
  }
};

export const storeAuthToken = (token) =>
  localStorage.setItem("authToken", token);

export const removeStoredAuthToken = () => localStorage.removeItem("authToken");

export default class Auth {
  /**
   * Function to check the auth token is exist or not.
   * @param  {} .
   * @return {boolean} return boolean values based on token availlability.
   */
  static isTokenValid() {
    const token = JSON.parse(localStorage.getItem("okta-token-storage"));
    if (token?.idToken?.expiresAt) {
      return true;
    }
    return false;
  }
}
