import React from "react";

import { BottomNavigationContainer } from "./styled";

const BottomNavigation = (props) => {
  return (
    <BottomNavigationContainer
      margin={props.margin}
      backgroundColor={props.backgroundColor}
      desktopJustifyContent={props.justifyContent}
      moblieJustifyContent={props.moblieJustifyContent}
      opacity={props.opacity}
      padding={props.padding}
      minHeight={props.minHeight}
      width={props.width || {}}
      position={props.position}
      right={props.right}
      mbPosition={props.mbPosition}
      zIndex={props.zIndex}
      buttonsPosition={props.buttonsPosition}
      smalDeviceJustifyContent={props.smalDeviceJustifyContent}
      mbPadding={props.mbPadding}
      isIPadPotrait={props.isIPadPotrait}
      iPadPortraitFooterPosition={props.iPadPortraitFooterPosition}
      iPadPortraitJustifyContent={props.iPadPortraitJustifyContent}
      bottom={props.bottom}
      boxShadow={props.boxShadow}
      borderRadius={props.borderRadius}
      exSmallDeviceSafeAreaBottom={props.exSmallDeviceSafeAreaBottom}
      smallDeviceInsetArea={props.smallDeviceInsetArea}
      flexDirection={props.flexDirection}
      height={props.height}
      left={props.left}
      portraitposition={props.portraitposition}
    >
      {props.children}
    </BottomNavigationContainer>
  );
};

export default BottomNavigation;
