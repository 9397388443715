import styled from "styled-components";
import { Grid } from "@mui/material";
import media from "../../styles/media.styled";

export const Box = styled(Grid)`
  display: inline-block;
  postion: absolute;
  top: 206px;
  left: 206px;
`;

export const MainHeaderText = styled.h4`
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  font-family: OpenSans Regular;
  margin-bottom: 0px;
  color: #13223c;
`;
export const HeaderText = styled.h4`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  font-family: OpenSans Bold;
  margin-bottom: 0px;
  color: #3d515a;
`;
export const ReminderHeader = styled.div`
  margin-left: 13px;
`;

export const Description = styled.h4`
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  font-family: OpenSans Regular;
  margin-top: 0px;
  color: #68758b;
`;

export const ReminderContainer = styled(Grid)`
  background: #ffffff;
  height: 133px;
  padding-left: 13px;
`;

export const Shop = styled(Grid)`
  text-align: left;
  font-family: OpenSans Bold;
`;
export const Adjudicator = styled(Grid)`
  text-align: left;
  font-family: OpenSans Bold;
`;

export const Remindertext = styled.div`
  font-family: OpenSans Regular;
  color: #3d515a;
  font-size: 14px;
`;

export const Input = styled.input`
  border: 0;
  outline: 0;
  opacity: 1;
  color: #3d515a;
  margin-top: 14px;
`;

export const Interval = styled.div`
  color: #3d515a;
  margin-left: 30px;
`;

export const Hours = styled.select`
  border: 0;
  outline: 0;
  margin-left: -4px;
  color: #3d515a;
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #3d515a;
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  ::placeholder {
    /* Firefox */
    font-family: "OpenSans Regular";
    opacity: 1;
    color: #3d515a;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "OpenSans Regular";
    opacity: 1;
    color: #3d515a;
  }
  ::-webkit-input-placeholder {
    /* Safari */
    font-family: "OpenSans Regular";
    opacity: 1;
    color: #3d515a;
  }
`;
export const IntervalText = styled.div`
  font-family: OpenSans Regular;
  font-size: 9px;
  color: #3d515a;
`;

export const ToggleText = styled.p`
  margin-top: 0px;
  margin-left: 5px;
  font-family: OpenSans Bold;
  font-size: 14px;
  color: #3d515a;
`;

export const ToggleButton = styled.div`
  display: flex;
`;

export const Line = styled.hr`
  opacity: 0.2;
  margin-top: 15px;
`;
export const IntervalContainer = styled.div`
width: 15%
  display: block;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "11px")};
  height: 43px;
  width: ${({ width }) => (width ? width : "")};
  border-bottom: 1px solid #EBECF0;
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "OpenSans Regular"};
  text-align: left;
  color: #141414;
  align-items: center;
  background: ${({ background }) => (background ? background : "#FFFFFF")};

  ${media.mediumDevice`
       
    `}

  ${media.smalDevice`
        
    `}

    ${media.mobileDevice`
        width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : "100%")};
        height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : "40px")};
        border-radius: 20px;
        margin: 10px 0 5px 0;                  
    `};
`;

export const MainContainer = styled(Grid)``;

export const SaveButton = styled.button`
  width: 140px;
  height: 34px;

  background: #252525;
  background: ${({ background }) => (background ? background : "#252525")};
  border: 1px solid rgba(20, 20, 20, 0.3);
  border-radius: 20px;
  color: #ffffff;
  ${media.smalDevice`
     width: 120px;   
    `}

  &.mainSave {
    margin-left: 20px;
  }
`;
export const SaveText = styled.div`
  font-family: OpenSans Regular;
  font-size: 16px;
  color: ${({ color }) => (color ? color : "#FFFFFF")};
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  font-family: OpenSans Regular;
  margin-left: 30px;
  margin-top: 13px;
  margin-bottom: 28px;
  margin-right: 74px;
`;

export const ButtonContainer = styled(Grid)`
  font-family: OpenSans Regular;
  margin-top: 36px;
`;

export const SaveHeader = styled.div`
  font-family: OpenSans Regular;
  font-size: 18px;
  line-height: 25px;
  color: #13223c;
`;

export const SaveDescription = styled.div`
  font-family: OpenSans Regular;
  font-size: 14px;
  line-height: 26px;
  margin-top: 15px;
  color: #3d515a;
`;

export const Head = styled.div``;

export const Header = styled.div`
  width: 97%;
  direction: rtl;
  padding: 6px; ;
`;
