import styled from "styled-components";

export const InputWrapper = styled.label`
  position: relative;
`;
export const Input = styled.input`
  position: absolute;
  left: -9999px;
  top: -9999px;

  &:checked + span {
    background-color: #00cc33;

    &:before {
      left: 17px;
    }
  }
`;
export const Slider = styled.span`
  display: flex;
  cursor: pointer;
  width: 35px;
  height: 19px;
  border-radius: 100px;
  background-color: #bfbfbf;
  position: relative;
  transition: background-color 0.2s;
  &:before {
    content: " ";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 17px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  }
`;
