import styled from "styled-components";
import { Grid } from "@mui/material";

export const MainContainer = styled(Grid)``;
export const Cancel = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  color: #0066cc;
  align-item: center;
  line-height: 30px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 18px;
  line-height: 25px;
  color: #13223c;
  &.new {
    margin-bottom: 13px;
  }
  &.details {
    font-family: "OpenSans SemiBold";
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
    color: #596981;
  }
  &.client-shop {
    font-size: 12px;
    line-height: 18px;
    color: #596981;
    font-family: "OpenSans semiBold";
  }
  &.logo-preview {
    font-size: 12px;
    margin-top: 14px;
    color: #52636b;
    line-height: 18px;
    font-family: "OpenSans semiBold";
  }
  &.logo {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: capitalize;
    color: #68758b;
  }
  &.mobileApp {
    margin: 24px 0 0 0;
    font-size: 16px;
    color: #3d515a;
    display: inline-flex;
    width: 272px;
    align-items: center;
  }
  &.assurant_shop {
    font-size: 16px;
    line-height: 28px;
    color: #3d515a;
  }
`;
export const Line = styled(Grid)`
  border-right: 1px solid #141414;
  opacity: 0.1;
`;

export const Box = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 12px 8px 40px 12px;
  width: 100%;
`;

export const PlusLogo = styled.div`
  background: #fafcff;
  border: 0.5px dashed #0066cc;
  width: 42px;
  height: 42px;
  size: 22px;
  border-radius: 50%;
  margin: auto;
`;

export const ShopName = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-family: "OpenSans Regular";
  color: #3d515a;
`;

export const Message = styled.div`
  margin: 10px 0 0 0;
  font-family: "OpenSans Regular";
  color: #047d26;
  line-height: 20px;
`;
