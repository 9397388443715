import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { mainSaga } from "./mainSaga";
import { mainReducer } from "./mainReducer";

const sagaMiddleware = createSagaMiddleware();

const store =
  process.env.NODE_ENV === "production"
    ? createStore(mainReducer, applyMiddleware(sagaMiddleware))
    : createStore(
        mainReducer,
        composeWithDevTools(applyMiddleware(sagaMiddleware, createLogger()))
      );

sagaMiddleware.run(mainSaga);

export default store;
