import React, { useState } from "react";
import close from "../../../../assets/images/close-banneralert.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import { MainContainer, Header, Table, BodyTextContainer } from "./styled";
import { Dialog, Grid } from "@mui/material";
import Delete from "../../../../shared/commonComponents/confirm/delete";
import { useDispatch, useSelector } from "react-redux";

const UserShops = (props) => {
  const [showDelete, setShowDelete] = useState(false);
  const [continueClick, setContinueClicked] = useState(false);
  const [deleteUserDetails, setDeleteUserDetails] = useState("");
  const [deleteUserShopDetails, setDeleteUserShopDetails] = useState("");
  const [deleteState, setDeleteState] = useState("");

  const openDelete = (data, shopname, param) => {
    setShowDelete(true);
    setDeleteUserDetails(data);
    setDeleteUserShopDetails(shopname);
    setDeleteState(param);
  };

  const closeDelete = () => {
    setShowDelete(false);
  };
  return (
    <>
      <Dialog
        open={props.open}
        minWidth={"sm"}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.close;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "25px",
            },
          },
        }}
      >
        <MainContainer>
          <Header>
            <img
              src={close}
              alt="close"
              onClick={props.close}
              style={{ cursor: "pointer" }}
            />
          </Header>
          {/* <Grid container justifyContent={"center"}>
            <Heading className="storeHeading" item xs={12} sm={12} md={12} lg={12} xl={12}>
              SHOPS
            </Heading>
          </Grid> */}
          <Grid container>
            <Table
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent={"spaceEvenly"}
            >
              <>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{ paddingLeft: "5px" }}
                >
                  SHOP NAME
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  style={{ paddingLeft: "5px" }}
                >
                  SHOP TYPE
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              </>
            </Table>

            {props.values.stores.map((store, index) => {
              return (
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  key={index}
                >
                  <BodyTextContainer
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    key={index}
                  >
                    {store.name}
                  </BodyTextContainer>
                  <BodyTextContainer item xs={2} sm={2} md={2} lg={2} xl={2}>
                    {store.vi_type === 0 ? "Web" : "Mobile"}
                  </BodyTextContainer>
                  <BodyTextContainer
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    style={{ paddingRight: "5px", textAlign: "right" }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => {
                        openDelete(props.values, store.name, "removeshop");
                      }}
                    />
                  </BodyTextContainer>
                </Grid>
              );
            })}
          </Grid>
          {showDelete && (
            <Delete
              open={showDelete}
              close={closeDelete}
              values={deleteUserDetails}
              shopName={deleteUserShopDetails}
              state={deleteState}
              closeUserShops={props.close}
            />
          )}
        </MainContainer>
      </Dialog>
    </>
  );
};

export default UserShops;
