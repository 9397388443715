import { request } from "../../../services/core/networkRequests";
import { METHOD_TYPES } from "../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../shared/constants/endpoints";

export const getReportingDataService = (payload) => {
  return request(METHOD_TYPES.POST, ENDPOINTS.REPORTING_DATA, payload);
};
export const downloadReport = async (payload) => {
  return await request(
    METHOD_TYPES.POST,
    `${ENDPOINTS.DOWNLOAD_REPORT}/download`,
    payload,
    { responseType: "arraybuffer" }
  );
};
export const reportHeaderData = (payload) => {
  return request(
    METHOD_TYPES.GET,
    `${ENDPOINTS.REPORTING_DATA}/${payload.url}`
  );
};
