import styled from "styled-components";
import { Grid } from "@mui/material";

export const Bottom = styled.div`
  height: 56px;
  background: #303340;
`;

export const FootText = styled(Grid)`
  font-family: "OpenSans SemiBold";
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  padding-top: 17px;
  padding-left: 2%;
`;
