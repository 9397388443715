import React, { useEffect, useState } from "react";
import "./login.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Heading,
  SubHeading,
  Header,
} from "../../inspection/review/dialogSent.styled";
import sent from "../../../assets/images/sent-tick.svg";
import close from "../../../assets/images/close.svg";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  TextField,
  Button,
  Stack,
  Autocomplete,
  Input,
  Collapse,
  Alert,
  IconButton,
  Dialog,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import SigninLogo from "../../../assets/images/user.svg";
import AssurantLogo from "../../../assets/images/assurant-logo.svg";
import Assurant from "./assurant";
import blackmail from "../../../assets/images/blackmail.svg";
import { needHelpOption } from "./needHelpOption";
import {
  sendResetMail,
  setDefault,
  setForgotPassword,
  setSupport,
  supportRequest,
  resetSupport,
} from "../../../store/accounts/login/action";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Login = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [checked, setChecked] = useState(false);
  const [username, setUsername] = useState("");
  const [isReset, setReset] = useState(false);
  const [mailAddressError, setMailAddressError] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [issue, setIssue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [nameError, setNameError] = useState(false);

  const isDefault = useSelector((state) => state.loginReducer.isDefaultScreen);
  const isForgot = useSelector((state) => state.loginReducer?.forgotPassword);
  const support = useSelector((state) => state.loginReducer?.support);
  const supportSuccess = useSelector(
    (state) => state?.loginReducer?.supportRequest
  );

  const resetMailSuccess = useSelector(
    (state) => state?.loginReducer?.resetMail
  );
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (resetMailSuccess) {
      setOpen(true);
    }
  }, [resetMailSuccess]);
  useEffect(() => {
    if (supportSuccess?.status?.type === "success") {
      setOpenDialog(true);
      dispatch(resetSupport(null));
    }
    if (support) {
      setUsername("");
      setEmail("");
      setDescription("");
      setIssue("");
    }
  }, [supportSuccess, support]);

  const handleChangeUsername = (event) => {
    const nameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
    setTimeout(() => {
      if (event.target.value.length <= 40) {
        if (nameRegex.test(event.target.value)) {
          setNameError(false);
        } else {
          setNameError(true);
        }
      }
    }, 2000);
    setUsername(event.target.value);
  };

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
  };
  const backToSign = () => {
    dispatch(setDefault(true));
    dispatch(setForgotPassword(false));
    dispatch(setSupport(false));
  };

  const Reset = () => {
    dispatch(sendResetMail(email));
    setReset(true);
    setForgotPassword(false);
    setDefault(true);
  };
  // dispatch action for support request with data
  const submit = () => {
    const data = {
      name: username,
      email,
      help_type: issue,
      help_details: description,
    };
    setNameError(false);
    setMailAddressError(false);
    dispatch(supportRequest(data));
  };

  const handleChangeEmail = (event) => {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    setTimeout(() => {
      if (emailRegex.test(event.target.value)) {
        setMailAddressError(false);
      } else {
        setMailAddressError(true);
      }
    }, 2000);

    setEmail(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleIssue = (event, value) => {
    setIssue(event.target.value);
  };
  const handleClose = () => {
    setOpenDialog(false);
    dispatch(resetSupport(null));
  };

  return (
    <div>
      <div className="login-main">
        {isDefault && (
          <>
            <div className="login-screen">
              <img className="assurant-logo" src={AssurantLogo} alt="logo" />
              <p className="title-name">Virtual Inspection</p>
              <div style={{ height: "auto" }}>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}
                  aria-label="disabled tabs example"
                  centered
                  style={{
                    justifyContent: "space-around",
                    padding: "0px 10px 0px 10px",
                  }}
                >
                  <Tab
                    style={{
                      width: "200px",
                      textTransform: "none",
                      fontSize: "16px",
                    }}
                    label="Clients"
                  />
                  <Tab
                    style={{
                      width: "200px",
                      textTransform: "none",
                      fontSize: "16px",
                    }}
                    label="Assurant"
                  />
                </Tabs>
                <p className="client-title">Valued partners login</p>
                <TabPanel value={value} index={0}>
                  <Assurant loggedIn={"client"} location={location.state} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Assurant loggedIn={"assurant"} location={location.state} />
                </TabPanel>
              </div>
            </div>
          </>
        )}
        {isForgot && (
          <>
            <div className="forgot-passowrd-screen">
              <img className="assurant-logo" src={AssurantLogo} alt="logo" />
              <p className="title-name">Change Your Password</p>

              <div className="MainDiv">
                <>
                  <div className="username-icon">
                    <img
                      className="signin-password"
                      src={SigninLogo}
                      alt="signIN"
                    />
                    <TextField
                      fullWidth={true}
                      variant={"standard"}
                      id="username"
                      label="Email or Username"
                      value={email}
                      onChange={handleChangeEmail}
                      style={{
                        fontFamily: "OpenSans Regular",
                      }}
                    />
                  </div>
                </>
                <div className="remember-me">
                  <Button
                    variant="contained"
                    style={{
                      width: "201px",
                      background: "#0066CC",
                      opacity: "0.6",
                      border: "0.5px solid #0047AD",
                      borderRadius: "20px",
                      textTransform: "none",
                      fontSize: "16px",
                      marginTop: "30px",
                    }}
                    color="primary"
                    className="sign-in"
                    onClick={Reset}
                    disabled={email.length === 0}
                  >
                    {"Reset via Email"}
                  </Button>
                </div>
                <div className="back-signin">
                  <span style={{ cursor: "pointer" }} onClick={backToSign}>
                    {" "}
                    Back to Sign In
                  </span>
                </div>
                {resetMailSuccess ? (
                  <div className="reset-error">
                    <Collapse in={open}>
                      <Alert
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        }
                        severity={
                          resetMailSuccess?.status?.code === 200
                            ? "success"
                            : "error"
                        }
                        variant="outlined"
                        sx={{ width: "90%" }}
                      >
                        {resetMailSuccess?.status?.code === 200
                          ? "Email Sent SuccessFully. Check your E-mail To Reset Password"
                          : resetMailSuccess.status.message}
                      </Alert>
                    </Collapse>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
        {support && (
          <>
            <div className="support-screen">
              <img className="assurant-logo" src={AssurantLogo} alt="logo" />
              <p className="title-name">Support</p>

              <div className="MainDiv">
                <div className="username-icon">
                  <img
                    className="signin-password"
                    src={SigninLogo}
                    alt="signIN"
                  />
                  <TextField
                    fullWidth={true}
                    variant={"standard"}
                    error={nameError}
                    id="username"
                    label="Name"
                    value={username}
                    helperText={nameError ? "Please Enter Valid Username" : ""}
                    onChange={handleChangeUsername}
                    style={{
                      fontFamily: "OpenSans Regular",
                    }}
                  />
                </div>
                <div className="password-icon">
                  <img className="signin-password" src={blackmail} alt="mai" />
                  <TextField
                    fullWidth={true}
                    id="Mail"
                    label="Email"
                    type="email"
                    value={email}
                    variant={"standard"}
                    error={mailAddressError}
                    helperText={
                      mailAddressError ? "Please Enter Valid Email Address" : ""
                    }
                    style={{
                      fontFamily: "OpenSans Regular",
                    }}
                    onChange={handleChangeEmail}
                  />
                </div>
                <div className="dropdown-helpWith">
                  <Stack spacing={1} sx={{ width: "100%" }}>
                    <FormControl>
                      <InputLabel>What do you need help with?</InputLabel>
                      <Select
                        label="What do you need help with?"
                        variant="standard"
                        value={issue}
                        onChange={(event, value) => handleIssue(event, value)}
                      >
                        {needHelpOption.map((data) => {
                          return (
                            <MenuItem value={data.title}>{data.title}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                </div>
                <div>
                  <TextField
                    className="select_Class"
                    fullWidth={true}
                    id="input"
                    label={`${"Please provide details (more is better)"}`}
                    variant={"standard"}
                    style={{
                      fontFamily: "OpenSans Regular",
                    }}
                    value={description}
                    onChange={handleDescription}
                  />
                </div>
                <div className="remember-me">
                  <Button
                    variant="contained"
                    style={{
                      width: "201px",
                      background: "#0066CC",
                      opacity: "0.6",
                      border: "0.5px solid #0047AD",
                      borderRadius: "20px",
                      margin: "50px 0 0 0",
                      textTransform: "none",
                      fontSize: "16px",
                      color:
                        email && issue && description && username
                          ? "white"
                          : "white",
                    }}
                    color="primary"
                    className="sign-in"
                    disabled={
                      email && issue && description && username ? false : true
                    }
                    onClick={submit}
                  >
                    Submit
                  </Button>
                  {openDialog ? (
                    <Dialog
                      open={openDialog}
                      fullWidth
                      maxWidth={"xs"}
                      sx={{
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            backgroundColor: "transparent !important",
                            boxShadow: "none",
                            borderRadius: ".5",
                          },
                        },
                      }}
                    >
                      <Header>
                        <img
                          src={close}
                          onClick={handleClose}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Header>
                      <div
                        style={{
                          padding: "10%",
                          backgroundColor: "#fff",
                          borderRadius: "2px",
                        }}
                      >
                        <div style={{ marginLeft: "44%" }}>
                          <img src={sent} width={"44px"} height={"30px"} />
                        </div>
                        <Heading>Request Sent</Heading>
                        <SubHeading>
                          Support Team has been notified of your request.
                        </SubHeading>
                      </div>
                    </Dialog>
                  ) : null}
                </div>
                <div className="back-signin">
                  <span style={{ cursor: "pointer" }} onClick={backToSign}>
                    {" "}
                    Back to Sign In
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        <div
          style={{
            position: "fixed",
            bottom: "10px",
            width: "100%",
            zIndex: 1000,
          }}
          className="bottom-footer"
        >
          © 2022 Assurant, Inc. All Rights Reserved
        </div>
      </div>
    </div>
  );
  // }
};

export default Login;
