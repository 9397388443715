import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import close from "../../../../assets/images/close.svg";
import {
  MainContainer,
  Header,
  Heading,
  ModalDiv,
  UserUpdate,
  EditUsers,
} from "./styled";
import {
  Dialog,
  Grid,
  FormControl,
  Select,
  TextField,
  MenuItem,
  Modal,
} from "@mui/material";
import BottomNavigation from "../../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import Button from "../../../../shared/commonComponents/button/button";
import check from "../../../../assets/images/check.svg";
import {
  editClientUser,
  editUser,
  handleError,
} from "../../../../store/userManagment/action";
import ErrorHandle from "../../../../shared/commonComponents/errorHandle/errorHandle";
const EditUser = (props) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(props.values.roles);
  const [admin, setAdmin] = useState(false);
  const [general, setGeneral] = useState(false);
  const [adminValue, setAdminValue] = useState("");
  const [generalValue, setGeneralValue] = useState("");
  const [isUpdated, setUpdated] = useState(false);
  const [value, setValue] = useState(props.values.name);

  const openModal = () => {
    setUpdated(true);
  };

  const closeUpdateModal = () => {
    setUpdated(false);
  };

  const handleErrorState = () => {
    dispatch(handleError());
  };

  const handleChange = (event) => {
    setOptions(event.target.value);
    if (event.target.value === "ADMIN") {
      setAdmin(true);
      setGeneral(false);
      setAdminValue("ADMIN");
    } else if (event.target.value === "GENERAL") {
      setAdmin(false);
      setGeneral(true);
      setGeneralValue("GENERAL");
    }
  };
  const isError = useSelector((state) => state.userManagmentReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.userManagmentReducer?.error?.message
  );
  const onSave = () => {
    setUpdated(true);
    const data = {
      roles: admin ? "ADMIN" : "GENERAL",
      name: value,
      user_email: props.values.user_email,
      user_id: props.values._id,
      shop_id: props.values.current_store_id,
      size: props.size,
      page: props.page,
    };

    const clientData = {
      roles: admin ? "ADMIN" : "GENERAL",
      name: value,
      user_email: props.values.user_email,
      user_id: props.values._id,
      client_id: props.values.client_id,
      size: props.size,
      page: props.page,
    };

    setTimeout(() => {
      setUpdated(false);
    }, 2000);

    if (props.state === "client") {
      dispatch(editClientUser(clientData));
    } else if (props.state === "shop") {
      dispatch(editUser(data));
    }
    props.close(true);
  };

  const editName = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <Dialog
        open={props.open}
        minWidth={"sm"}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.close;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflow: "hidden",
              backgroundColor: "transparent !important",
              boxShadow: "none",
            },
          },
        }}
      >
        <MainContainer>
          <Header>
            <img
              src={close}
              alt="close"
              onClick={props.close}
              style={{ cursor: "pointer" }}
            />
          </Header>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            padding={"30px"}
            direction="row"
            style={{
              display: "block",
              width: "445px",
              height: "449px",
              backgroundColor: "#fff",
            }}
          >
            <EditUsers>
              <div style={{ marginRight: "5px" }}>Edit</div>
              <div>
                <TextField
                  type={"name"}
                  variant="standard"
                  onChange={editName}
                  value={value}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "18px",
                      fontFamily: "OpenSans Regular",
                      lineHeight: "25px",
                      color: "#13223c",
                    },
                  }}
                />
              </div>
            </EditUsers>
            <FormControl style={{ width: "100%", margin: "27px 0 150px 0" }}>
              <Select
                name="Permission Level"
                style={{ width: "100%" }}
                variant="standard"
                value={options}
                onChange={handleChange}
                renderValue={(value) =>
                  options.toLowerCase().includes("inspector")
                    ? "GENERAL"
                    : options
                }
              >
                <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                <MenuItem value={"GENERAL"}>GENERAL</MenuItem>
              </Select>
              <Heading className="choose">
                <div>
                  <span
                    style={{
                      fontFamily: "OpenSans Bold",
                      fontSize: "12px",
                    }}
                  >
                    Admin
                  </span>
                  : Request and review inspections, access the Admin dashboard
                  and settings, invite and manage users, etc.
                </div>
                <div>
                  <span
                    style={{
                      fontFamily: "OpenSans Bold",
                      fontSize: "12px",
                    }}
                  >
                    General
                  </span>
                  : Complete and submit inspections.
                </div>
              </Heading>

              <div>
                <TextField
                  fullWidth={true}
                  type={"email"}
                  variant="standard"
                  value={props.values.user_email}
                />
              </div>
            </FormControl>
            {isUpdated ? (
              <Modal
                open={openModal}
                onClose={closeUpdateModal}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ModalDiv>
                  <div style={{ marginRight: "12px" }}>
                    <img src={check} alt="check" />
                  </div>{" "}
                  <UserUpdate>User Updated !</UserUpdate>
                </ModalDiv>
              </Modal>
            ) : null}
            <BottomNavigation
              opacity={"1"}
              position={"sticky"}
              padding={"17px 0px 0 0px"}
              justifyContent={"space-between"}
              moblieJustifyContent={"space-around"}
              borderRadius={"0px 0px 4px 4px"}
              minHeight={"55px"}
              bottom={"0"}
              left={"0"}
            >
              <Button
                show={true}
                width={"120px"}
                height={"32px"}
                clicked={props.close}
                secondary={true}
              >
                <div>Cancel</div>
              </Button>

              <Button
                show={true}
                width={"120px"}
                height={"32px"}
                clicked={onSave}
                disabled={
                  value === props.values.name && options === props.values.roles
                    ? true
                    : false
                }
                primary={
                  value === props.values.name && options === props.values.roles
                    ? false
                    : true
                }
              >
                Save
              </Button>
            </BottomNavigation>
          </Grid>
        </MainContainer>
      </Dialog>
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
    </>
  );
};

export default EditUser;
