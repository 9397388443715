import styled from "styled-components";
import { Grid } from "@mui/material";

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  &.choose {
    font-size: 12px;
    line-height: 18px;
    color: #68758b;
    margin: 6px 22px 42px 0;
  }
`;

export const Header = styled.div`
  direction: rtl;
  width: 100%;
  background-color: transparent !important;
`;

export const MainContainer = styled(Grid)``;

export const ModalDiv = styled.div`
  width: 280px;
  height: 38px;
  padding: 6px 16px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  align-items: center;
  display: flex;
`;

export const UserUpdate = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-family: "OpenSans Regular";
  color: #3d515a;
`;

export const EditUsers = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: #13223c;
  font-family: "OpenSans Regular";
  display: inline-flex;
  align-items: center;
`;
