import styled from "styled-components";

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 18px;
  line-height: 25px;
  color: #13223c;
`;

export const Content = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 26px;
  color: #3d515a;
`;

export const Header = styled.div`
  direction: rtl;
  color: #13223c;
  font-size: 18px;
`;

export const Text = styled.div`
  font-family: "OpenSans SemiBold";
  padding-top: 7px;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  color: black;
`;
