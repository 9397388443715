import React, { useEffect, useState } from "react";
import { ButtonContainer } from "./styled";
const Button = ({
  children,
  width,
  mobileWidth,
  height,
  primary,
  secondary,
  disabled,
  discard,
  show,
  fontSize,
  fontFamily,
  clicked,
  padding,
  margin,
  borderRadius,
  cancel,
  color,
  backgroundColor,
  border,
  opacity,
  mobileMargin,
  boxShadow,
  disabledSaveBtn,
  WhiteSpace,
  cursor,
}) => {
  const [backgroundColorUpdate, setBackgroundColor] = useState("");
  const [borderUpdate, setBorder] = useState("");
  const [colorUpdate, setColor] = useState("");
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    if (primary) {
      setBackgroundColor(backgroundColor ? backgroundColor : "black");
      setColor(color ? color : "white");
    } else if (secondary) {
      setBackgroundColor(backgroundColor ? backgroundColor : "#FFFFFF");
      setColor(color ? color : "#3D515A");
      setBorder(border ? border : "0.5px solid rgba(20, 20, 20, 0.3)");
    } else if (disabled) {
      setBackgroundColor(backgroundColor ? backgroundColor : "#9A9A9A");
      setColor(color ? color : "#FFFFFF");
      setBorder("none");
    } else if (cancel) {
      setBorder("1px solid #140F4B");
      setBackgroundColor(backgroundColor ? backgroundColor : "#FFFFFF");
      setColor(color ? color : "#140f4b");
    }
  }, [primary, secondary, cancel, discard, disabled]);

  const onMouseDown = () => {
    setMouseDown(true);
  };

  const onMouseUp = () => {
    setMouseDown(false);
  };

  return (
    <ButtonContainer
      show={show}
      width={width}
      mobileWidth={mobileWidth}
      height={height}
      backgroundColor={backgroundColorUpdate}
      border={borderUpdate}
      borderRadius={borderRadius}
      margin={margin}
      color={colorUpdate}
      padding={padding}
      fontSize={fontSize ? fontSize : undefined}
      fontFamily={fontFamily}
      onClick={clicked}
      disabled={disabled}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      mouseDown={mouseDown}
      boxShadow={boxShadow}
      opacity={opacity ? opacity : undefined}
      mobileMargin={mobileMargin}
      WhiteSpace={WhiteSpace}
      cursor={cursor}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
