import React, { useState, useEffect } from "react";
import {
  MainContainer,
  HeaderGrid,
  SubHeader,
  MainGrid,
  SearchBoxContainer,
  StyledInput,
  IconContainer,
  Status,
} from "./styled";
import assurantLogo from "../../../assets/images/assurant-logo.svg";
import { Button, Stack, MenuItem, Menu } from "@mui/material";

import Liner from "../../../assets/images/Liner.svg";
import Search from "../../../assets/images/search.svg";
import Hamburger from "../../../assets/images/Menu.svg";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  activeSearch,
  closedMenuSearch,
  reportSearchData,
} from "../../../store/inspection/dashboard/getClaimsData/action";
import useDebounce from "../../../pages/inspection/dashboard/closed/useDebounce";
import { openSaveReminderPopUp } from "../../../store/reminders/actions";
import {
  getClientUsers,
  getShopUsers,
  searchClientFilter,
  searchShopFilter,
  fileFetchHeader,
  searchKeyword,
  clearFileFetchHeader,
} from "../../../store/userManagment/action";
import { useOktaAuth } from "@okta/okta-react";
import SessionStorageService from "../../../services/functional/accounts/login/storage";

const Header = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [preview, setPreview] = useState();

  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const clientdetails = SessionStorageService.get("CLIENT_DETAILS");
  const parseClientDetails = JSON.parse(clientdetails);
  const Authenticated = SessionStorageService.get("Authenticated");
  useEffect(() => {
    if (!Authenticated) {
      navigate("/dashboard", { replace: true });
    }
  }, [Authenticated]);
  const remininderDataChanged = useSelector(
    (state) => state.reminderReducers.isDataChanged
  );
  const openReminderPopUp = useSelector(
    (state) => state.reminderReducers.openReminderPopUp
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigateToDashboard = () => {
    if (!remininderDataChanged) {
      navigate("/dashboard");
    } else {
      dispatch(openSaveReminderPopUp(true));
    }
  };
  const navigateToAdministration = () => {
    if (!remininderDataChanged) {
      navigate("/admin");
    } else {
      dispatch(openSaveReminderPopUp(true));
    }
  };

  const navigateToReporting = () => {
    if (!remininderDataChanged) {
      navigate("/reporting");
    } else {
      dispatch(openSaveReminderPopUp(true));
    }
  };

  const navigateToSetup = () => {
    if (!remininderDataChanged) {
      navigate("/setup");
    } else {
      dispatch(openSaveReminderPopUp(true));
    }
  };

  const navigateToSignIn = async () => {
    if (!remininderDataChanged) {
      dispatch(clearFileFetchHeader(null));
      SessionStorageService.clearAll();
      navigate("/");
    } else {
      dispatch(openSaveReminderPopUp(true));
    }
  };

  const clientValues = useSelector(
    (state) => state.userManagmentReducer?.clientValues
  );

  const data = useSelector((state) => state.userManagmentReducer?.data);

  const isSearch = useSelector(
    (state) => state.headerReducer.condition?.isSearch
  );
  const isHeaderVi = useSelector(
    (state) => state.headerReducer.condition?.isHeaderVi
  );
  const isHamburger = useSelector(
    (state) => state.headerReducer.condition?.isHamburger
  );
  const isDataChanged = useSelector(
    (state) => state.ReminderReducers?.isDataChanged
  );
  const isActiveClose = useSelector(
    (state) => state.headerReducer.condition?.isActiveClose
  );
  const isLogo = useSelector((state) => state.headerReducer.condition?.isLogo);

  useEffect(() => {
    if (!userDetails.roles.toLowerCase().includes("super") && !fetchedUrl) {
      dispatch(fileFetchHeader({ link: parseClientDetails?.image_url }));
    }
  }, []);

  const fetchedUrl = useSelector(
    (state) => state.userManagmentReducer?.fileFetchHeader?.data
  );

  const [name, setName] = useState("");
  const debounceSearch = useDebounce(name, 500);

  const filter = (e) => {
    const keyword = e.target.value;
    if (props.showActiveScreen) {
      dispatch(activeSearch(keyword));
    } else if (props.userManagementScreen) {
      dispatch(searchKeyword(keyword));
    } else if (props.reporting) {
      dispatch(searchKeyword(keyword));
    } else {
      setName(keyword);
    }

    setName(keyword);
  };
  useEffect(() => {
    setName("");
    dispatch(activeSearch(""));
  }, [props.showActiveScreen]);
  useEffect(() => {
    setName("");
    dispatch(closedMenuSearch(""));
  }, [props.showClosedScreen]);
  useEffect(() => {
    if (props.showClosedScreen) {
      dispatch(closedMenuSearch(debounceSearch));
    } else if (props.userManagementScreen && debounceSearch !== "") {
      if ((data.shopId === "" || !data.shopId) && data.clientId) {
        dispatch(
          searchClientFilter({
            search: debounceSearch,
            client_id: data?.clientId,
            page: 1,
            size: data?.size,
            sort: data?.sortType,
          })
        );
      } else if (data.shopId !== "" && data.shopId) {
        dispatch(
          searchShopFilter({
            search: debounceSearch,
            shop_id: data?.shopId,
            page: 1,
            size: data?.size,
            client_id: data?.clientId,
            sort: data?.sortType,
          })
        );
      }
    } else if (props.userManagementScreen && debounceSearch === "") {
      if ((!data.shopId || data.shopId === "") && data.clientId) {
        dispatch(
          getClientUsers({
            client_id: data?.clientId,
            page: data?.page,
            size: data?.size,
            sortType: data?.sortType,
          })
        );
      } else if (
        data.shopId &&
        data.shopId !== "" &&
        data.clientId &&
        data.size &&
        data.page
      ) {
        dispatch(
          getShopUsers({
            shop_id: data?.shopId,
            page: data?.page,
            size: data?.size,
            client_id: data?.clientId,
            sortType: data?.sortType,
          })
        );
      }
    } else if (props.reporting) {
      dispatch(reportSearchData(debounceSearch));
    }
  }, [debounceSearch]);

  useEffect(() => {
    if (openReminderPopUp) {
      setAnchorEl(false);
    }
  }, [openReminderPopUp]);

  const displayImageUrl = () => {
    if (fetchedUrl) {
      const asset = fetchedUrl.asset;
      const assetValue = "data:image/png;base64," + asset;
      setPreview(assetValue);
    } else {
      setPreview(null);
    }
  };

  useEffect(() => {
    if (Object.keys(clientValues).length !== 0) {
      if (
        userDetails.roles.toLowerCase().includes("super") &&
        clientValues.name.toLowerCase().includes("assurant")
      ) {
        displayImageUrl();
      } else {
        displayImageUrl();
      }
    } else {
      displayImageUrl();
    }
  }, [fetchedUrl]);

  return (
    <>
      <MainContainer>
        <HeaderGrid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mobile={12}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <MainGrid item xs={6} sm={4} md={4} lg={4} xl={6} mobile={12}>
            {isLogo && (
              <div
                style={{ margin: "15px 0 0px 35px", cursor: "pointer" }}
                onClick={() => navigateToDashboard()}
              >
                <img
                  src={preview ? preview : assurantLogo}
                  alt="ClientLogo"
                  width={"130px"}
                  height={"35px"}
                ></img>
              </div>
            )}

            {isHeaderVi && (
              <div style={{ display: "contents" }}>
                <img
                  src={Liner}
                  alt="Line 1"
                  style={{ marginLeft: "37px", border: "0px solid #7D898D" }}
                ></img>
                <label
                  style={{
                    fontFamily: "OpenSans Regular",
                    marginLeft: "19px",
                    fontSize: "18px",
                    color: "#13223C",
                  }}
                >
                  Virtual Inspection
                </label>
              </div>
            )}
          </MainGrid>
          <MainGrid item xs={6} sm={8} md={8} lg={8} xl={6} mobile={12}>
            <SubHeader
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              mobile={12}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {isActiveClose && (
                <Status style={{ marginLeft: "4rem" }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      style={{
                        borderRadius: "12px",
                        backgroundColor: props.showActiveScreen
                          ? "#252525"
                          : "#FFFFFF",

                        lineHeight: 0.75,
                        textTransform: "none",
                        height: "25px",
                        width: "80px",
                        border: "0.5px solid rgba(20, 20, 20, 0.3)",
                        color: props.showActiveScreen ? "#FFFFFF" : "#252525",
                        fontFamily: "OpenSans Regular",
                      }}
                      onClick={props.togglingActive}
                    >
                      Active{" "}
                    </Button>
                    <Button
                      variant="outlined"
                      style={{
                        borderRadius: "12px",
                        backgroundColor: props.showClosedScreen
                          ? "#252525"
                          : "#FFFFFF",
                        lineHeight: 0.75,
                        border: "0.5px solid rgba(20, 20, 20, 0.3)",
                        textTransform: "none",
                        height: "25px",
                        width: "80px",
                        color: props.showClosedScreen ? "#FFFFFF" : "#252525",
                        fontFamily: "OpenSans Regular",
                      }}
                      onClick={props.togglingClosed}
                    >
                      Closed{" "}
                    </Button>
                  </Stack>
                </Status>
              )}
            </SubHeader>
            <SubHeader
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              mobile={12}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              {isSearch && (
                <SearchBoxContainer width={"240px"}>
                  <IconContainer
                    width={"19px"}
                    height={"19px"}
                    mobileWidth={"19px"}
                    mobileHeight={"19px"}
                  >
                    <img
                      src={Search}
                      alt="Search Icon"
                      width={"22px"}
                      height={"22px"}
                    />
                  </IconContainer>
                  <StyledInput
                    id="search"
                    type="text"
                    searchImg={Search}
                    placeholder={"Search..."}
                    autoComplete="off"
                    value={name}
                    onChange={(e) => filter(e)}
                  />
                </SearchBoxContainer>
              )}

              {isHamburger && (
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <img src={Hamburger} alt={"hamburger"} />
                  </Button>

                  <Menu
                    keepMounted
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    open={Boolean(anchorEl)}
                  >
                    {userDetails?.roles === "ADMIN" ? (
                      <MenuItem onClick={navigateToDashboard}>
                        Dashboard
                      </MenuItem>
                    ) : null}
                    {userDetails?.roles === "ADMIN" ? (
                      <MenuItem onClick={navigateToAdministration}>
                        Administration
                      </MenuItem>
                    ) : userDetails?.roles.toLowerCase().includes("super") ? (
                      <>
                        {props.userManagementScreen ? (
                          <MenuItem onClick={navigateToReporting}>
                            Reporting
                          </MenuItem>
                        ) : (
                          <MenuItem onClick={navigateToSetup}>
                            Administration
                          </MenuItem>
                        )}
                      </>
                    ) : null}

                    <MenuItem onClick={navigateToSignIn}>Logout</MenuItem>
                  </Menu>
                </div>
              )}
            </SubHeader>
          </MainGrid>
        </HeaderGrid>
      </MainContainer>
    </>
  );
};
export default Header;
