import { request } from "../../../core/networkRequests";
import { METHOD_TYPES } from "../../../../shared/constants/method-types";
import { ENDPOINTS } from "../../../../shared/constants/endpoints";

export const getJWTTokenService = (payload) => {
  return request(METHOD_TYPES.POST, ENDPOINTS.LOGIN_VERFIY, payload);
};
export const activateUserService = (payload) => {
  return request(METHOD_TYPES.POST, ENDPOINTS.ACTIVATE_USER, payload);
};
export const resetMailService = (payload) => {
  return request(METHOD_TYPES.GET, `${ENDPOINTS.RESET_EMAIL}/${payload}`);
};
export const resetPasswordService = (payload) => {
  return request(
    METHOD_TYPES.POST,
    `${ENDPOINTS.RESET_PASSWORD}/${payload.id}`,
    payload.password,
    { headers: { Authorization: "Bearer " + payload.token.token } }
  );
};
export const supportRequestService = (payload) => {
  return request(METHOD_TYPES.POST, ENDPOINTS.SUPPORT_REQUEST, payload);
};
