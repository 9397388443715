export const GET_REPORTING_DATA = "GET_REPORTING_DATA";
export const GET_REPORTING_DATA_SUCCESS = "GET_REPORTING_DATA_SUCCESS";
export const GET_REPORTING_DATA_FAILURE = "GET_REPORTING_DATA_FAILURE";
export const GET_FILTERED_REPORTING_DATA = "GET_FILTERED_REPORTING_DATA";
export const GET_FILTERED_REPORTING_DATA_SUCCESS =
  "GET_FILTERED_REPORTING_DATA_SUCCESS";
export const GET_FILTERED_REPORTING_DATA_FAILURE =
  "GET_FILTERED_REPORTING_DATA_FAILURE";
export const SET_FILTER_ENABLE = "SET_FILTER_ENABLE";
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_FALIURE = "DOWNLOAD_REPORT_FALIURE";
export const GET_REPORTING_HEADER_DATA = "GET_REPORTING_HEADER_DATA";
export const GET_REPORTING_HEADER_DATA_SUCCESS =
  "GET_REPORTING_HEADER_DATA_SUCCESS";
export const GET_REPORTING_HEADER_DATA_FAILURE =
  "GET_REPORTING_HEADER_DATA_FAILURE";
