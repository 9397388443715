import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  Heading,
  Content,
  Header,
  Text,
  Comment,
  CommentTime,
} from "./dialogRetake.styled";
import close from "../../../assets/images/close.svg";
import Button from "../../../shared/commonComponents/button/button";
import BottomNavigation from "../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import DialogSent from "./dialogSent";
import { useDispatch, useSelector } from "react-redux";
import {
  getRetakeRequestComment,
  retakeRequest,
  setRetakeRequest,
  retakeErrorMessage,
} from "../../../store/claimInspection/action";
const DialogRetake = (props) => {
  const [value, setValue] = useState("");
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );
  const dispatch = useDispatch();
  const open = useSelector((state) => state.claimInspectionReducer?.retake);
  const retakeComment = useSelector(
    (state) => state.claimInspectionReducer?.retakeComment
  );

  const retakeError = useSelector(
    (state) => state.claimInspectionReducer?.retakeError
  );

  useEffect(() => {
    if (open) {
      dispatch(
        getRetakeRequestComment({
          claimNo: currentClaim,
          attachmentName: props.attachmentName,
        })
      );
    }
  }, [open]);
  const CHARACTER_LIMIT = 160;
  const text = (event) => {
    setValue(event.target.value);
  };
  const handleClose = () => {
    dispatch(setRetakeRequest(false));
    dispatch(retakeErrorMessage());
  };
  const handleSend = () => {
    const data = {
      claimNo: currentClaim,
      retakeData: {
        file_name: props.fileName,
        attachment_name: props.attachmentName,
        comment: value,
      },
    };
    dispatch(retakeRequest(data));
    setValue("");
  };
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        minWidth={"lg"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              backgroundColor: "transparent !important",
              boxShadow: "none",
              borderRadius: ".5",
            },
          },
        }}
      >
        <Header>
          <img
            src={close}
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </Header>
        <div style={{ padding: "30px", backgroundColor: "#fff" }}>
          <Heading>Retake Request</Heading>
          <Content>Describe the issue with what was provided</Content>
          {retakeComment.length === 0
            ? null
            : retakeComment.length > 0
            ? retakeComment?.map((data, index) => {
                const d = new Date(data.date_time);
                const date = d.getDate();
                const month = d.getMonth() + 1;
                const hours = d.getHours();
                const min = d.getMinutes();
                const amPm = hours >= 12 ? "PM" : "AM";
                return (
                  <>
                    <CommentTime>
                      {month +
                        "-" +
                        date +
                        ", " +
                        hours +
                        ":" +
                        min +
                        " " +
                        amPm}
                    </CommentTime>
                    <Comment>{data.comment}</Comment>
                  </>
                );
              })
            : null}

          <TextField
            value={value}
            inputProps={{
              maxLength: CHARACTER_LIMIT,
            }}
            onChange={text}
            helperText={`${value.length}/${CHARACTER_LIMIT}`}
            label="Describe the issue"
            multiline
            fullWidth
            variant="standard"
          />
          <Heading className="errorMessage">
            {retakeError ? retakeError : null}
          </Heading>
          <BottomNavigation minHeight={"35px"} justifyContent={"center"}>
            <Button
              show={true}
              width={"118px"}
              height={"34px"}
              primary={value.length > 0 ? true : false}
              clicked={handleSend}
              disabled={value.length > 0 || retakeError ? false : true}
            >
              <Text>Send</Text>
            </Button>
          </BottomNavigation>
        </div>
      </Dialog>
      <DialogSent></DialogSent>
    </>
  );
};
export default DialogRetake;
