import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, TextField, Stack, Button, Autocomplete } from "@mui/material";
import {
  resetSupport,
  supportRequest,
} from "../../store/accounts/login/action";
import "./contactSupport.css";
import sent from "../../assets/images/sent-tick.svg";
import { Heading, SubHeading, Header } from "./contactSupport.styled";
import close from "../../assets/images/close.svg";
import blackmail from "../../assets/images/blackmail.svg";
import SessionStorageService from "../../services/functional/accounts/login/storage";
import { needHelpOption } from "../accounts/login/needHelpOption";

const ContactSupport = (props) => {
  const dispatch = useDispatch();
  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);
  const [username, setUsername] = useState(userDetails.name);
  const [description, setDescription] = useState("");
  const [issue, setIssue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [mailAddressError, setMailAddressError] = useState(false);
  const [email, setEmail] = useState(userDetails.user_email);
  const support = useSelector((state) => state.loginReducer?.support);
  const supportSuccess = useSelector(
    (state) => state?.loginReducer?.supportRequest
  );

  const handleClose = () => {
    setOpenDialog(false);
    props.onClose();
    dispatch(resetSupport());
  };

  const handleChangeUsername = (event) => {
    const nameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
    setTimeout(() => {
      if (event.target.value.length <= 40) {
        if (nameRegex.test(event.target.value)) {
          setNameError(false);
        } else {
          setNameError(true);
        }
      }
    }, 2000);
    setUsername(event.target.value);
  };
  const handleChangeEmail = (event) => {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    setTimeout(() => {
      if (emailRegex.test(event.target.value)) {
        setMailAddressError(false);
      } else {
        setMailAddressError(true);
      }
    }, 2000);

    setEmail(event.target.value);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleIssue = (event, value) => {
    setIssue(value?.title);
  };
  useEffect(() => {
    if (supportSuccess?.status?.type === "success") {
      setOpenDialog(true);
    }
    if (support) {
      setUsername("");
      setDescription("");
      setIssue("");
    }
  }, [supportSuccess, support]);
  const defaultProps = {
    options: needHelpOption,
    getOptionLabel: (option) => option.title,
  };
  const submit = () => {
    const data = {
      name: username,
      email: userDetails.user_email,
      help_type: issue,
      help_details: description,
    };
    dispatch(supportRequest(data));
  };
  return (
    <Dialog
      open={true}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            backgroundColor: "transparent !important",
            boxShadow: "none",
            width: "445px",
            minHeight: "449px",
          },
        },
      }}
    >
      <Header>
        <img
          src={close}
          onClick={props.onClose}
          style={{
            cursor: "pointer",
          }}
        />
      </Header>
      <div
        style={{ padding: "4%", backgroundColor: "#fff", borderRadius: "2px" }}
      >
        <>
          <div>
            <p className="title-name">Support</p>
            <div className="MainDiv">
              <div className="username-icon">
                <TextField
                  fullWidth={true}
                  variant={"standard"}
                  id="username"
                  label="Name"
                  error={nameError}
                  helperText={nameError ? "Please Enter Valid Username" : ""}
                  value={username}
                  onChange={handleChangeUsername}
                  style={{
                    fontFamily: "OpenSans Regular",
                  }}
                />
              </div>
              <div className="password-icon">
                <TextField
                  fullWidth={true}
                  id="Mail"
                  label="Email"
                  type="email"
                  value={email}
                  variant={"standard"}
                  helperText={
                    mailAddressError ? "Please Enter Valid Email" : ""
                  }
                  error={mailAddressError}
                  style={{
                    fontFamily: "OpenSans Regular",
                  }}
                  onChange={handleChangeEmail}
                />
              </div>
              <div className="dropdown-helpWith">
                <Stack spacing={1} sx={{ width: "100%" }}>
                  <Autocomplete
                    onChange={(event, value) => handleIssue(event, value)}
                    noOptionsText={"No results found"}
                    {...defaultProps}
                    id="disable-close-on-select"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="What do you need help with?"
                        variant="standard"
                        value={issue}
                      />
                    )}
                  />
                </Stack>
              </div>
              <div>
                <TextField
                  fullWidth={true}
                  id="input"
                  label="Please provide details (more is better)"
                  variant={"standard"}
                  style={{
                    fontFamily: "OpenSans Regular",
                  }}
                  value={description}
                  onChange={handleDescription}
                />
              </div>

              <div className="remember-me">
                <Button
                  variant="contained"
                  style={{
                    width: "201px",
                    background: "#0066CC",
                    opacity: "0.6",
                    border: "0.5px solid #0047AD",
                    borderRadius: "20px",
                    margin: "28px 0 0 0",
                    textTransform: "none",
                    fontSize: "16px",
                    color: "white",
                  }}
                  color="primary"
                  className="sign-in"
                  disabled={
                    email && issue && description && username ? false : true
                  }
                  onClick={submit}
                >
                  Submit
                </Button>
                {openDialog ? (
                  <Dialog
                    open={openDialog}
                    fullWidth
                    maxWidth={"xs"}
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          backgroundColor: "transparent !important",
                          boxShadow: "none",
                          borderRadius: ".5",
                        },
                      },
                    }}
                  >
                    <Header>
                      <img
                        src={close}
                        onClick={handleClose}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Header>
                    <div
                      style={{
                        padding: "10%",
                        backgroundColor: "#fff",
                        borderRadius: "2px",
                      }}
                    >
                      <div style={{ marginLeft: "44%" }}>
                        <img src={sent} width={"44px"} height={"30px"} />
                      </div>
                      <Heading>Request Sent</Heading>
                      <SubHeading>
                        Support Team has been notified of your request.
                      </SubHeading>
                    </div>
                  </Dialog>
                ) : null}
              </div>
            </div>
          </div>
        </>
      </div>
    </Dialog>
  );
};
export default ContactSupport;
