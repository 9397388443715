import React, { useEffect } from "react";
import { Dialog } from "@mui/material";
import {
  Heading,
  Content,
  Header,
  Text,
} from "./acceptInspectionDialog.styled";
import close from "../../../assets/images/close.svg";
import Button from "../../../shared/commonComponents/button/button";
import BottomNavigation from "../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptInspection,
  setAccept,
} from "../../../store/claimInspection/action";
import ErrorHandle from "../../../shared/commonComponents/errorHandle/errorHandle";

const DialogConfirm = (props) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.claimInspectionReducer?.accept);
  const handleCancel = () => {
    dispatch(setAccept(false));
  };
  const currentClaim = useSelector(
    (state) => state.claimInspectionReducer.currentClaim
  );
  const acceptInspectionValue = useSelector(
    (state) => state.claimInspectionReducer?.acceptInspection?.message
  );

  const handleContinue = () => {
    dispatch(acceptInspection(currentClaim));
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"xs"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              backgroundColor: "transparent !important",
              boxShadow: "none",
              borderRadius: ".5",
            },
          },
        }}
      >
        <Header>
          <img
            src={close}
            onClick={handleCancel}
            style={{
              cursor: "pointer",
            }}
          />
        </Header>
        <div style={{ padding: "30px", backgroundColor: "#fff" }}>
          <Heading>Accept Inspection</Heading>
          <Content style={{ paddingTop: "20px" }}>
            Are you ready to accept this inspection?
          </Content>
          <BottomNavigation
            justifyContent={"space-between"}
            margin={"40px 0 0 0"}
            minHeight={"35px"}
          >
            <Button
              show={true}
              width={"148px"}
              height={"34px"}
              secondary={true}
              clicked={handleCancel}
            >
              <Text>Cancel</Text>
            </Button>

            <Button
              show={true}
              width={"148px"}
              height={"34px"}
              clicked={handleContinue}
              primary={true}
            >
              <Text>Continue</Text>
            </Button>
          </BottomNavigation>
        </div>
      </Dialog>
      {acceptInspectionValue && <ErrorHandle acceptValue={acceptInspectionValue} />}
    </>
  );
};
export default DialogConfirm;
