import styled from "styled-components";

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 18px;
  line-height: 25px;
  color: #13223c;
  &.editlogo {
    margin-bottom: 22px;
  }
  &.logo-preview {
    font-size: 12px;
    margin-top: 14px;
    color: #52636b;
    line-height: 18px;
    font-family: "OpenSans semiBold";
  }
  &.logo {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: capitalize;
    color: #68758b;
  }
`;

export const Box = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 12px 0 30px 0;
  width: 100%;
  margin: 0;
`;

export const PlusLogo = styled.div`
  background: #fafcff;
  border: 0.5px dashed #0066cc;
  width: 42px;
  height: 42px;
  size: 22px;
  border-radius: 50%;
  margin: auto;
`;

export const ShopName = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-family: "OpenSans Regular";
  color: #3d515a;
  width: 20rem;
`;

export const Message = styled.div`
  margin: 10px 0 0 0;
  font-family: "OpenSans Regular";
  color: #047d26;
  line-height: 20px;
`;
