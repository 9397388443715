import styled from "styled-components";
import { Grid } from "@mui/material";

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  &.choose {
    font-size: 12px;
    line-height: 18px;
    color: #68758b;
    margin: 6px 22px 42px 0;
  }
`;

export const Header = styled.div`
  direction: rtl;
  width: 100%;
`;

export const ResendHeader = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: "#13223C";
  font-family: "OpenSans Regular";
`;

export const MainContainer = styled(Grid)``;
