import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { put, call, takeEvery, all } from "redux-saga/effects";

import { claimInspectionServices } from "../../services/functional/index";

import * as types from "./types";

function* getClaimInspectionDetails({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.getClaimInspectionServices,
      payload
    );

    yield put({ type: types.SET_CURRENT_CLAIM, payload });
    if (
      response.data.Status === "SUBMITTED" ||
      response.data.Status === "UPDATED"
    ) {
      const { data } = response;
      let slideIndex = -1;
      for (
        let index = 0;
        index < data.attachment_requests_attributes.length;
        index++
      ) {
        for (
          let j = 0;
          j < data.attachment_requests_attributes[index].assets.length;
          j++
        ) {
          slideIndex = slideIndex + 1;
          data.attachment_requests_attributes[index].assets[j].slideIndex =
            slideIndex;
        }
      }
    }
    yield put({
      type: types.GET_CLAIM_INSPECTION_DETAILS_SUCCESS,
      payload: response.data,
    });
    yield put({ type: types.SET_NAVIGATE, payload: true });
  } catch (error) {
    yield put({
      type: types.GET_CLAIM_INSPECTION_DETAILS_FAILURE,
      payload: error,
    });
  }
}

function* createNewInspectionRequests({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.createNewInspectionRequest,
      payload
    );

    yield put({
      type: types.CREATE_NEW_INPECTION_REQUEST_SUCCESS,
      payload: response.data,
    });
    yield put({ type: types.RESET_CLAIM_DETAILS, payload: true });
  } catch (error) {
    yield put({
      type: types.CREATE_NEW_INPECTION_REQUEST_FAILURE,
      payload: error,
    });
  }
}
function* resendInspection(payload) {
  try {
    const response = yield call(
      claimInspectionServices.resendInspectionServices,
      payload
    );

    yield put({
      type: types.RESEND_INSPECTION_SUCCESS,

      payload: response.payload,
    });
    yield put({ type: types.RESET_CLAIM_DETAILS, payload: true });
  } catch (error) {
    yield put({ type: types.RESEND_INSPECTION_FAILURE, payload: error });
  }
}
function* getSearchPartList({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.getSearchPartList,
      payload
    );

    yield put({
      type: types.GET_SEARCH_PART_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_SEARCH_PART_LIST_FAILURE, payload: error });
  }
}

function* postDownloadFiles({ payload }) {
  try {
    const allProducts = [];
    const oldallProducts = [];

    const { attachmentRequest } = payload;

    for (let i = 0; i < attachmentRequest.length > 0; i++) {
      for (let j = 0; j < attachmentRequest[i].assets.length > 0; j++) {
        const postPayload = {
          link: attachmentRequest[i].assets[j].path,
          name: attachmentRequest[i].name,
          fileName: attachmentRequest[i].assets[j].filename,
        };

        const newAttachmentReqAsset = { ...attachmentRequest[i].assets[j] };

        const response = yield call(
          claimInspectionServices.downloadFilesServices,
          postPayload
        );
        // check video URL
        yield (newAttachmentReqAsset.asset = response.data?.data?.asset
          ? response.data.data.asset
          : null);
        yield (newAttachmentReqAsset.type = response.data?.data?.type
          ? response.data.data.type
          : null);
        yield (attachmentRequest[i].assets[j] = { ...newAttachmentReqAsset });

        yield allProducts.push(response.data.data);
      }
    }
    for (let i = 0; i < attachmentRequest.length; i++) {
      if (attachmentRequest[i].oldassets?.length > 0) {
        for (let j = 0; j < attachmentRequest[i].oldassets.length; j++) {
          const postPayload = {
            link: attachmentRequest[i].oldassets[j].path,
            name: attachmentRequest[i].name,
            fileName: attachmentRequest[i].oldassets[j].filename,
          };

          const newAttachmentReqAsset = {
            ...attachmentRequest[i].oldassets[j],
          };

          const response = yield call(
            claimInspectionServices.downloadFilesServices,
            postPayload
          );

          yield (newAttachmentReqAsset.asset = response.data?.data?.asset
            ? response.data.data.asset
            : null);
          yield (newAttachmentReqAsset.type = response.data?.data?.type
            ? response.data.data.type
            : null);
          yield (attachmentRequest[i].oldassets[j] = {
            ...newAttachmentReqAsset,
          });
          yield oldallProducts.push(response.data.data);
        }
      }
    }

    yield put({
      type: types.DOWNLOAD_CLAIMS_FILES_SUCCESS,
      payload: attachmentRequest,
    });
  } catch (error) {
    yield put({ type: types.DOWNLOAD_CLAIMS_FILES_FAILURE, payload: error });
  }
}
export function reArrangeResponse(payload) {
  const filtered = payload.filter(
    (data) => data.name !== "VIN" && data.name !== "Odometer"
  );
  const removedKeys = filtered.map((data) => {
    const resolve = {
      name: data.name,
      noise: data.noise,
      leak: data.leak,
      other_failure: data.other_failure,
      min_image: data.min_image,
      min_video: data.min_video,
      instructions: data.instructions,
      attachment_type:
        data.min_image > 0 && data.min_video > 0
          ? "video/photo"
          : data.min_image > 0
          ? "photo"
          : data.min_video > 0
          ? "video"
          : "",
    };
    return resolve;
  });
  return removedKeys;
}
export function* getAttachmentDetails({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.getAttachmentDetails,
      payload
    );
    const res = yield call(reArrangeResponse, response.data);
    yield put({ type: types.SET_REQUESTED, payload: res });
  } catch (error) {
    yield put({ type: types.SET_REQUESTED_FAILURE, payload: error });
  }
}
export function* editAttachmentDetails({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.editAttachmentDetails,
      payload
    );
    if (response.data.data === "Inspection cannot be editted anymore") {
      yield put({ type: types.SET_INSPECTION_STARTED, payload: true });
    } else {
      yield put({
        type: types.EDIT_REQUEST_ATTACHMENT_SUCCESSFUL,
        payload: response.data,
      });
      yield put({ type: types.RESET_CLAIM_DETAILS, payload: true });
    }
  } catch (error) {
    yield put({ type: types.EDIT_REQUEST_ATTACHMENT_FAILURE, payload: error });
  }
}

export function* acceptInspection({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.acceptInspection,
      payload
    );
    if (response?.data?.data?.logoff) {
      yield put({
        type: types.ACCEPT_INSPECTION_SUCCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: types.ACCEPT_INSPECTION_SUCCCESS,
        payload: "success",
      });
      yield put({ type: types.RESET_CLAIM_DETAILS, payload: true });
    }
  } catch (error) {
    yield put({ type: types.ACCEPT_INSPECTION_FAILURE, payload: error });
  }
}

export function* retakeRequest({ payload }) {
  try {
    const response = yield call(claimInspectionServices.retakeRequest, payload);
    yield put({ type: types.RETAKE_REQUEST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: types.RETAKE_REQUEST_FAILURE,
      payload: error.response.data.status.message,
    });
  }
}

export function* closingInspection({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.closeInspection,
      payload
    );
    yield put({
      type: types.CLOSE_INSPECTION_VALUES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: types.CLOSE_INSPECTION_VALUES_FAILURE,
      payload: error.response,
    });
  }
}

export function* getRetakeRequestComment({ payload }) {
  try {
    const response = yield call(
      claimInspectionServices.getRetakeRequestComment,
      payload
    );
    yield put({
      type: types.GET_RETAKE_REQUEST_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_RETAKE_REQUEST_COMMENT_FAILURE,
      payload: error,
    });
  }
}
export function* enableInspection({ payload }) {
  let RequestEnable = false;
  const enableArray = payload?.map((element) => {
    let enableInspectionRequest = false;
    if (element.noise) {
      if (
        (element.attachment_type === "video/photo" &&
          element.min_image > 0 &&
          element.min_video > 0) ||
        (element.attachment_type === "video" && element.min_video > 0)
      ) {
        enableInspectionRequest = true;
      } else {
        enableInspectionRequest = false;
      }
    } else {
      if (
        ((element.attachment_type === "photo" && element.min_image) ||
          (element.attachment_type === "video" && element.min_video) ||
          (element.attachment_type === "video/photo" &&
            element.min_image > 0 &&
            element.min_video > 0)) &&
        (element.leak || element.other_failure) &&
        (element.min_image > 0 || element.min_video > 0)
      ) {
        enableInspectionRequest = true;
      } else {
        enableInspectionRequest = false;
      }
    }
    return enableInspectionRequest;
  });
  if (enableArray.length > 0) {
    RequestEnable = enableArray.every((value) => value === true);
  }

  yield put({
    type: types.ENABLE_REQUEST_NEW_INSPECTION_SUCCESS,
    payload: RequestEnable,
  });
}

export function* watchGetClaimInspectiondetails() {
  yield takeEvery(
    types.GET_CLAIM_INSPECTION_DETAILS,
    getClaimInspectionDetails
  );
}

export function* watchCreateNewInspectionRequest() {
  yield takeEvery(
    types.CREATE_NEW_INPECTION_REQUEST,
    createNewInspectionRequests
  );
}

export function* watchResendInspectiondetails() {
  yield takeEvery(types.RESEND_INSPECTION, resendInspection);
}

export function* watchGetSearchPartList() {
  yield takeEvery(types.GET_SEARCH_PART_LIST, getSearchPartList);
}

export function* watchGetAttachementDetails() {
  yield takeEvery(types.GET_ATTACHMENT_REQUEST_DETAILS, getAttachmentDetails);
}

export function* watchPostDownloadFiles() {
  yield takeEvery(types.DOWNLOAD_CLAIMS_FILES, postDownloadFiles);
}

export function* watchEditAttachmentDetails() {
  yield takeEvery(types.EDIT_REQUEST_ATTACHMENT, editAttachmentDetails);
}

export function* watchAcceptInspection() {
  yield takeEvery(types.ACCEPT_INSPECTION, acceptInspection);
}

export function* watchRetakeRequest() {
  yield takeEvery(types.RETAKE_REQUEST, retakeRequest);
}
export function* watchGetRetakeRequestComment() {
  yield takeEvery(types.GET_RETAKE_REQUEST_COMMENT, getRetakeRequestComment);
}
export function* watchEnableInspection() {
  yield takeEvery(types.ENABLE_REQUEST_NEW_INSPECTION, enableInspection);
}
export function* watchCloseInspection() {
  yield takeEvery(types.CLOSE_INSPECTION_VALUES, closingInspection);
}
export function* mainClaimInspectionSaga() {
  yield all([
    watchEnableInspection(),
    watchGetClaimInspectiondetails(),
    watchCreateNewInspectionRequest(),
    watchResendInspectiondetails(),
    watchGetSearchPartList(),
    watchGetAttachementDetails(),
    watchPostDownloadFiles(),
    watchEditAttachmentDetails(),
    watchAcceptInspection(),
    watchRetakeRequest(),
    watchGetRetakeRequestComment(),
    watchCloseInspection(),
  ]);
}
