import * as types from "./types";

const initialState = {
  apiKeyData: [],
  error: null,
  pdfData: [],
};

export const ApiKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_API_KEY_SUCCESS:
      return { ...state, apiKeyData: action.payload };
    case types.GET_API_KEY_FAILURE:
      return { ...state, error: action.error };
    case types.GET_PDF_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.GET_PDF_SUCCESS:
      return {
        ...state,
        pdfData: action.payload,
      };
    default:
      return state;
  }
};
