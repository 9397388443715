import React, { useEffect } from "react";
import { Dialog } from "@mui/material";
import { MainContainer, Header, DeleteHeader } from "./styled";
import close from "../../../assets/images/close.svg";
import BottomNavigation from "../bottomNavigation/bottomNavigation";
import Button from "../button/button";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  deleteClientUser,
  deleteShopUser,
} from "../../../store/userManagment/action";

const Delete = (props) => {
  const dispatch = useDispatch();
  const onContinue = () => {
    if (props.state === "client") {
      dispatch(deleteClientUser(props.values));
    } else if (props.state === "removeshop") {
      dispatch(deleteShopUser(props.values, props.shopName));
      props.closeUserShops(true);
    } else {
      dispatch(deleteUser(props.values));
    }

    props.close(true);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.close;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              backgroundColor: "transparent !important",
              boxShadow: "none",
              width: "475px",
            },
          },
        }}
      >
        <MainContainer>
          <Header>
            <img
              src={close}
              alt="close"
              onClick={props.close}
              style={{
                cursor: "pointer",
              }}
            />
          </Header>
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "20px 30px 5px 30px",
            }}
          >
            <DeleteHeader className="heading">
              {" "}
              {props.state !== "removeshop"
                ? "Delete User"
                : "Delete " +
                  props.values.name +
                  " from " +
                  props.shopName +
                  "?"}{" "}
            </DeleteHeader>
            <DeleteHeader className="context">
              {props.state !== "removeshop"
                ? "Are you sure you want to delete?"
                : "This action can't be undone."}
            </DeleteHeader>
            <BottomNavigation
              opacity={"1"}
              position={"sticky"}
              padding={"17px 0 0 0"}
              justifyContent={"space-between"}
              moblieJustifyContent={"space-around"}
              borderRadius={"0px 0px 4px 4px"}
              minHeight={"55px"}
              bottom={"0"}
              left={"0"}
            >
              <Button
                show={true}
                width={"120px"}
                height={"32px"}
                secondary={true}
                clicked={props.close}
              >
                Cancel
              </Button>

              <Button
                show={true}
                width={"120px"}
                height={"32px"}
                primary={true}
                clicked={onContinue}
              >
                Continue
              </Button>
            </BottomNavigation>
          </div>
        </MainContainer>
      </Dialog>
    </>
  );
};

export default Delete;
