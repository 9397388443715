import styled from "styled-components";
import { Grid } from "@mui/material";
import media from "../../../styles/media.styled";

export const ClaimContainer = styled(Grid)`
  margin-top: 7%;
  position: fixed;

  ${media.mediumDevice`
  margin-top: ${({ ipadMarginTop }) => ipadMarginTop || "10%"};
  `}

  ${media.smalDevice`
margin-top: ${({ ipadMarginTop }) => ipadMarginTop || "10%"};
  `}

  ${media.mobileDevice`
  margin-top: ${({ ipadMarginTop }) => ipadMarginTop || "10%"};             
  `};
`;

export const ClaimHeader = styled(Grid)`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;

export const Box = styled.div`
  height: 80px;
  display: inline-flex;
  &.img {
    width: 100%;
    min-width: 60px;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0;
    background-color: #f1f1f1;
  }
`;

export const ClaimText = styled.div`
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "OpenSans SemiBold"};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "27px")};
  color: #13223c;
`;
export const ClaimDetails = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 20px;
  color: #3d515a;
  width: 300px;
  margin-top: 10px;
  ${media.mediumDevice`
  width: ${({ ipadWidth }) => (ipadWidth ? ipadWidth : "280px")};
`};
`;
export const SearchBoxContainer = styled.div`
  display: flex;
  margin-left: 0.5rem;
  height: 50px;
  border-bottom: 1px solid #d3d3d3;
  font-family: ${({ fontFamily }) =>
    fontFamily ? fontFamily : "OpenSans Regular"};
  text-align: left;
  color: #141414;
  align-items: center;
  background: ${({ background }) => (background ? background : "#FFFFFF")};

  width: ${({ width }) => (width ? width : "20%")};

  ${media.mediumDevice`
       
    `}

  ${media.smalDevice`
        
    `}

    ${media.mobileDevice`
        width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : "100%")};
        height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : "40px")};
        border-radius: 20px;
        margin: 10px 0 5px 0;                  
    `};
`;

export const StyledInput = styled.input`
  margin-left: 16px;
  font-size: 16px !important;
  outline: none;
  border: none;
  width: ${({ width }) => (width ? width : "80%")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  font-family: OpenSans Regular;
  line-height: 20px;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "20px"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #44464b;
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  ::placeholder {
    /* Firefox */
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "OpenSans Regular";
    opacity: 1;
  }
  ::-webkit-input-placeholder {
    /* Safari */
    font-family: "OpenSans Regular";
    opacity: 1;
  }

  ${media.mediumDevice`
      
    `}

  ${media.smalDevice`
        width: 100%;
        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        ::placeholder {
            /* Firefox */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
        ::-webkit-input-placeholder {
            /* Safari */
            color: #767676;
            font-family: 'AvenirNextWorld Regular';
        }
    `}

    ${media.mobileDevice`
        //border-radius: 5px;
         font-size: 14px;
    `}
`;
export const IconContainer = styled.div`
  width: ${({ width }) => (width ? width : "30px")};
  height: ${({ height }) => (height ? height : "20px")};
  opacity: 1;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d515a;
  ${media.smalDevice`
        width:  20px;
        height: : 20px;
        margin: 0 0 0 5px;
    `}

  ${media.mobileDevice`
        width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : "30px")};
        height: ${({ mobileHeight }) => (mobileHeight ? mobileHeight : "20px")};
        opacity:1;
        margin: 0 0 0 10px;
    `}
`;

export const ClaimComments = styled.div`
  font-family: "OpenSans Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #13223c;
  width: 157px;
  @media only screen and (max-width: 1366px) and (max-height: 1024px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 768px) {
    margin-top: 10px;
  }
  @media only screen and (max-width: 1518px) and (max-height: 768px) {
    margin-top: 10px;
  }
`;
export const UploadArea = styled.div`
  margin-top: 10px;
`;

export const UploadHeaderCount = styled.div`
  font-family: "OpenSans Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #13223c;
  width: 157px;
  height: 23%;
  margin-top: 60px;
`;

export const UploadTotalCount = styled.div`
  font-family: "OpenSans Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #13223c;
  width: 157px;
`;

export const UploadContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;

  @media only screen and (max-height: 1000px) {
    height: 220px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 1024px) {
    height: 390px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 800px) {
    height: 260px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 768px) {
    height: 125px;
  }
  @media only screen and (max-height: 690px) {
    height: 152px;
  }
  @media only screen and (max-width: 1245px) and (max-height: 600px) {
    height: 108px;
  }
`;

export const UploadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  width: 96%;
  height: ${({ height }) => (height ? height : "35px")};
  margin-top: 0px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : " ")};
`;

export const UploadText = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "2px")};
  margin-left: 12px;
  font-family: "OpenSans Regular";
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #52636b;
`;
export const UploadImage = styled.div`
  display: flex;
  margin-right: 14px;
`;

export const UploadCount = styled.div`
  margin-top: 5px;
  margin-left: 2px;
`;
export const UploadStatus = styled(Grid)`
  width: 100%;
  min-width: 60px;
  height: auto;
  background: #ffffff;
  font-family: "OpenSans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #68758b;
`;
export const Line = styled.hr`
  opacity: ${({ opacity }) => (opacity ? opacity : "0.1")};
  margin-top: 5px;
  width: 137%;
`;

export const UploadContent = styled.div`
  height: auto;
`;

export const ClaimInspectionDetails = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "9rem")};
  font-family: "OpenSans SemiBold";
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #68758b;
`;

export const ClaimRequest = styled.div`
  width: 70%;

  ${media.mediumDevice`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "5rem")};
    `}

  ${media.smalDevice`
        
    `}

    ${media.mobileDevice`
        margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "5rem")};
    `};
`;

export const Video = styled.video`
  object-fit: contain;
  width: 100%;
  aspect-ratio: 16/9;
  @media only screen and (max-width: 1520px) and (max-height: 730px) {
    height: 560px;
  }
  @media only screen and (max-width: 1425px) and (max-height: 680px) {
    height: 500px;
  }
  media only screen and (min-width: 1368px) and (max-height: 940px) {
    height: 560px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 1024px) {
    height: 840px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 800px) {
    height: 630px;
  }

  @media only screen and (max-width: 1366px) and (max-height: 768px) {
    height: 500px;
  }
  @media only screen and (max-width: 1280px) and (max-height: 610px) {
    height: 450px;
  }
  @media only screen and (max-width: 1170px) and (max-height: 550px) {
    height: 410px;
  }
  @media all and (display-mode: fullscreen) {
    width: 100%;
    height: 745px;
  }
`;

export const Image = styled.img`
  object-fit: contain;
  width: 100%;
  aspect-ratio: 16/9;
  @media only screen and (max-width: 1520px) and (max-height: 730px) {
    height: 560px;
  }
  @media only screen and (max-width: 1425px) and (max-height: 680px) {
    height: 500px;
  }
  @media only screen and (max-width: 1366px) and (max-height: 1024px) {
    height: 840px;
  }
  media only screen and (min-width: 1368px) and (max-height: 940px) {
    height: 560px;
  }

  @media only screen and (max-width: 1366px) and (max-height: 800px) {
    height: 630px;
  }

  @media only screen and (max-width: 1366px) and (max-height: 768px) {
    height: 500px;
  }
  @media only screen and (max-width: 1280px) and (max-height: 610px) {
    height: 450px;
  }
  @media only screen and (max-width: 1170px) and (max-height: 550px) {
    height: 410px;
  }

  @media all and (display-mode: fullscreen) {
    width: 100%;
    height: 100%;
  }
`;
export const SwiperArrowIcon = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  width: 100%;
  left: 0px;
  opacity: 1;
  top: 50%;
`;

export const ClaimInfoDiv = styled.div`
  width: 95%;
  margin: 0 auto;
  height: auto;
  flexdirection: column;
  justifycontent: space-between;
`;
