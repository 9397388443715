import { all } from "redux-saga/effects";
import {
  watchGetClaims,
  watchGetClosedClaims,
  watchGetClosedDropDownData,
  watchGetTokenValidity,
} from "../getClaimsData/saga";

export function* mainDashboardSaga() {
  yield all([
    watchGetClaims(),
    watchGetClosedClaims(),
    watchGetClosedDropDownData(),
    watchGetTokenValidity(),
  ]);
}
