import React, { useState, useEffect } from "react";
import {
  RequestCameraCheckbox,
  RequestCardCheckBox,
  RequestCardContainer,
  RequestCardHeader,
  RequestCardHelperText,
  RequestCheckboxlabel,
  RequestDescriptionLabel,
  RequestPhotoSpan,
  RequestPhotoText,
} from "./attachementRequestCard.styled";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import camera from "../../../assets/images/camera.svg";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import videoimg from "../../../assets/images/video.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setRequestedImageCount,
  setRequestedLeak,
  setRequestedNoise,
  setRequestedOther,
  setRequestedVideoCount,
  setRequestedDescription,
  deleteRequestedPart,
  setVideoPhotoFlag,
  setRequestNewInspection,
  setClearAddedNewCard,
} from "../../../store/claimInspection/action";
import { Backdrop, IconButton } from "@mui/material";

const AttachmentRequestCard = ({
  header,
  imageCountValue,
  videoCountValue,
  image,
  video,
  description,
  leak,
  noise,
  other,
  color,
  disable,
  blur,
  loc,
}) => {
  const [imageChecked, setImageChecked] = useState(image);
  const [videoChecked, setVideoChecked] = useState(video);

  const dispatch = useDispatch();
  const addedParts = useSelector(
    (state) => state?.claimInspectionReducer.inspectionRequest
  );
  const RequestMoreDetails = useSelector(
    (state) => state.claimInspectionReducer?.requestMoreDetails
  );
  const handleDescriptionChange = (e) => {
    dispatch(
      setRequestedDescription({ name: header, description: e.target.value })
    );
  };
  const handleCheckboxChange = (e, name) => {
    if (name === "leak") {
      dispatch(setRequestedLeak({ name: header, leak: e.target.checked }));
    } else if (name === "noise") {
      dispatch(setRequestedNoise({ name: header, noise: e.target.checked }));
    } else if (name === "other") {
      dispatch(setRequestedOther({ name: header, other: e.target.checked }));
    } else if (name === "camera") {
      setImageChecked(e.target.checked);
      e.target.checked
        ? dispatch(setRequestedImageCount({ name: header, image_Count: 1 }))
        : dispatch(setRequestedImageCount({ name: header, image_Count: 0 }));
    } else if (name === "video") {
      setVideoChecked(e.target.checked);
      e.target.checked
        ? dispatch(
            setRequestedVideoCount({
              name: header,
              video_Count: 1,
            })
          )
        : dispatch(
            setRequestedVideoCount({
              name: header,
              video_Count: 0,
            })
          );
    }
  };
  useEffect(() => {
    let attachments = "";
    if (videoChecked && imageChecked) {
      attachments = "video/photo";
    } else if (videoChecked) {
      attachments = "video";
    } else if (imageChecked) {
      attachments = "photo";
    }
    dispatch(setVideoPhotoFlag({ name: header, attachment: attachments }));
  }, [videoChecked, imageChecked]);
  useEffect(() => {
    if (loc === "create" || loc === "edit") {
      dispatch(setRequestNewInspection(addedParts));
    } else {
      dispatch(setRequestNewInspection(RequestMoreDetails));
    }
  }, [video, image, noise, leak, other, imageCountValue, videoCountValue]);

  const handleInputChange = (e, name) => {
    if (name === "image") {
      dispatch(
        setRequestedImageCount({ name: header, image_Count: e.target.value })
      );
      e.target.value >= 1 ? setImageChecked(true) : null;
    } else if (name === "video") {
      dispatch(
        setRequestedVideoCount({ name: header, video_Count: e.target.value })
      );
      e.target.value >= 1 ? setVideoChecked(true) : null;
    }
  };
  const handleClose = () => {
    dispatch(deleteRequestedPart(header));
    if (loc === "create" || loc === "edit") {
      dispatch(setRequestNewInspection(addedParts));
    } else if (RequestMoreDetails.length > 1) {
      dispatch(setRequestNewInspection(RequestMoreDetails));
    } else {
      dispatch(setClearAddedNewCard(false));
    }
  };

  return (
    <RequestCardContainer background={color}>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        mobile={12}
        direction={"row"}
      >
        <Grid xs={11} sm={11} md={11} lg={11} xl={11} mobile={11} item>
          <RequestCardHeader>{header}</RequestCardHeader>
        </Grid>
        <Grid xs={1} sm={1} md={1} lg={1} xl={1} mobile={1}>
          <IconButton
            sx={{
              height: "25px",
              widht: "25px",
              paddingTop: "25px",
            }}
            onClick={handleClose}
            disabled={disable}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </Grid>
      </Grid>
      <div style={{ display: "flex", alignItems: "center" }}>
        <RequestCardCheckBox
          type="checkbox"
          id="leak"
          style={{ marginLeft: "16px" }}
          onChange={(e) => handleCheckboxChange(e, "leak")}
          checked={leak}
          disabled={disable}
        />
        <RequestCheckboxlabel htmlFor="leak">Leak</RequestCheckboxlabel>
        <RequestCardCheckBox
          type="checkbox"
          style={{ marginLeft: "53px" }}
          checked={noise}
          onChange={(e) => handleCheckboxChange(e, "noise")}
          disabled={disable}
        />
        <RequestCheckboxlabel>Noise</RequestCheckboxlabel>
        <RequestCardCheckBox
          type="checkbox"
          style={{ marginLeft: "40px" }}
          checked={other}
          onChange={(e) => handleCheckboxChange(e, "other")}
          disabled={disable}
        />
        <RequestCheckboxlabel>Other Failure</RequestCheckboxlabel>
      </div>
      <RequestPhotoText>
        Photos & Videos{" "}
        <RequestPhotoSpan>
          (edit count and instructions as needed)
        </RequestPhotoSpan>
      </RequestPhotoText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "27px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "48px",
          }}
        >
          <RequestCameraCheckbox
            type="checkbox"
            checked={image}
            onChange={(e) => handleCheckboxChange(e, "camera")}
            disabled={disable}
            sx={{
              borderRadius: "16px",
            }}
          />
          <img src={camera} alt="camera" style={{ marginLeft: "11px" }} />
          <div style={{ marginLeft: "14px", width: "65px" }}>
            <InputLabel htmlFor="imageCount">Count</InputLabel>
            <Input
              type="number"
              id="imageCount"
              aria-describedby="component-helper-text"
              value={imageCountValue}
              onChange={(e) => handleInputChange(e, "image")}
              disabled={disable}
              inputProps={{ min: 0 }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "46px",
          }}
        >
          <RequestCameraCheckbox
            type="checkbox"
            checked={video}
            onChange={(e) => handleCheckboxChange(e, "video")}
            disabled={disable}
          />
          <img src={videoimg} alt="video" style={{ marginLeft: "11px" }} />
          <div style={{ marginLeft: "15px", width: "65px" }}>
            <InputLabel htmlFor="videoCount">Count</InputLabel>
            <Input
              type="number"
              id="VideoCount"
              aria-describedby="component-helper-text"
              value={videoCountValue}
              onChange={(e) => handleInputChange(e, "video")}
              disabled={disable}
              inputProps={{ min: 0 }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "42.5px" }}>
        <RequestDescriptionLabel>Instructions to shop</RequestDescriptionLabel>
        <Input
          sx={{ marginLeft: "29px", width: "340px" }}
          inputProps={{
            maxLength: 160,
          }}
          value={description}
          onChange={(e) => handleDescriptionChange(e)}
          id="imageCount"
          aria-describedby="component-helper-text"
          placeholder="Add..."
          disabled={disable}
        />
        <RequestCardHelperText>{description.length}/160</RequestCardHelperText>
        {blur ? (
          <Backdrop
            sx={{
              height: "375px",
              width: "385px",
              margin: "12px",
              borderRadius: "6px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(1px)",
            }}
            open={true}
          />
        ) : null}
      </div>
    </RequestCardContainer>
  );
};
AttachmentRequestCard.defaultProps = {
  header: "",
  imageCountValue: 0,
  videoCountValue: 0,
  leak: false,
  noise: false,
  other: false,
  description: "",
  image: false,
  video: false,
  color: "white",
  disable: false,
};

export default AttachmentRequestCard;
