import React, { useEffect, useState } from "react";
import "./login.css";
import AssurantLogo from "../../../assets/images/assurant-logo.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  Collapse,
  Alert,
} from "@mui/material";
import lockLogo from "../../../assets/images/lock.svg";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useDispatch, useSelector } from "react-redux";
import { activateUser } from "../../../store/accounts/login/action";
import { useParams, useNavigate } from "react-router-dom";

const ActivateUser = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recoveryQuestion, setRecoveryQuestion] = useState("");
  const [recoveryAnswer, setRecoveryAnswer] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [recoveryAnswerError, setRecoveryAnswerError] = useState("");
  const [recoveryQuestionError, setRecoveryQuestionError] = useState("");
  const [enableSignUp, setEnableSignUp] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activationToken } = useParams();
  const activateUserSuccess = useSelector(
    (state) => state?.loginReducer?.activateUser
  );
  useEffect(() => {
    if (password && confirmPassword && recoveryQuestion && recoveryAnswer) {
      if (
        !passwordError.char &&
        !passwordError.number &&
        !passwordError.special &&
        !password.lowercase &&
        !recoveryAnswerError.length > 0 &&
        !confirmPasswordError.length > 0
      ) {
        setEnableSignUp(false);
      } else {
        setEnableSignUp(true);
      }
    }
  }, [
    passwordError,
    recoveryAnswerError,
    confirmPasswordError,
    recoveryAnswer,
    recoveryQuestion,
    password,
    confirmPassword,
    recoveryQuestionError,
  ]);
  const questions = [
    "What is the food you least liked as a child?",
    "What did you earn your first medal or award for?",
    "What is the name of your first stuffed animal?",
    "What is your favorite security question?",
    "What is the toy/stuffed animal you liked the most as a kid?",
    "What was the first computer game you played?",
    "What is your favorite movie quote?",
    "What was the mascot of the first sports team you played on?",
    "What music album or song did you first purchase?",
    "What is your favorite piece of art?",
    "What was your grandmother's favorite dessert?",
    "What was the first thing you learned to cook?",
    "What was your dream job as a child?",
    "Where did you meet your spouse/significant other?",
    "Where did you go for your favorite vacation?",
    "Where were you on New Year's Eve in the year 2000?",
    "Who is your favorite speaker/orator?",
    "Who is your favorite book/movie character?",
    "Who is your favorite sports player?",
  ];

  const handleUserId = (event) => {
    const { id, value } = event.target;
    switch (id) {
      case "password":
        setPassword(value);
        validatePassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        validateConfirmPassword(value);
        break;
      case "recoveryAnswer":
        setRecoveryAnswer(value);
        validateRecoveryAnswer(value);
        break;

      default:
        break;
    }
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 5,
        width: 400,
      },
    },
  };
  const handleRecoveryQuestion = (event) => {
    const { id, value } = event.target;
    setRecoveryQuestion(value);
  };
  const handleClickShowPassword = (value) => {
    if (value === "password") {
      setShowPassword(!showPassword);
    }
    if (value === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const checkOverallValidity = (value) => {
    if (value && confirmPassword.length > 0) {
      if (value === confirmPassword) {
        setConfirmPasswordError("");
      } else {
        setConfirmPasswordError("Passwords do not match");
      }
    }
  };
  const validatePassword = (value) => {
    const validationMessages = {
      char: false,
      number: false,
      special: false,
      lowerCase: false,
      requirements: "Password requirements were not met.",
    };
    const passwordPolicies = [
      {
        validationMessage: "special",
        regex: /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]+/,
      },
      {
        validationMessage: "lowerCase",
        regex: /[A-Z]+/,
      },
      {
        validationMessage: "lowerCase",
        regex: /[a-z]+/,
      },
      {
        validationMessage: "number",
        regex: /[0-9]+/,
      },
    ];
    const tests = passwordPolicies.map((policy) => {
      return {
        validationMessage: policy.validationMessage,
        isValid: policy.regex.test(value),
      };
    });
    const anyFailures = tests.filter((test) => !test.isValid);
    if (anyFailures.length > 0) {
      anyFailures.forEach(
        (failure) => (validationMessages[failure.validationMessage] = true)
      );
    }
    if (password.length < 8) {
      validationMessages.char = true;
    }
    checkOverallValidity(value);
    setPasswordError(validationMessages);
    setPasswordCheck(true);
  };
  const validateConfirmPassword = (value) => {
    if (value !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };
  const validateRecoveryAnswer = (value) => {
    if (!value) {
      setRecoveryAnswerError("Answer required");
      setEnableSignUp(true);
    } else if (value.length < 4) {
      setRecoveryAnswerError("Answer must be 4 characters");
      setEnableSignUp(true);
    } else {
      setRecoveryAnswerError("");
    }
  };
  const handleClick = () => {
    const data = {
      password,
      question: recoveryQuestion,
      token: activationToken,
      answer: recoveryAnswer,
    };
    dispatch(activateUser(data));
  };
  const backToSign = () => {
    navigate(window.location.origin + "/login");
  };
  return (
    <div>
      <div className="login-main">
        <div className="activate-screen">
          <div className="login-title">
            <img className="assurant-logo" src={AssurantLogo} alt="logo" />
            <p className="title-name">Virtual Inspection</p>
            <p className="title-password">Set Your Password</p>
          </div>
          {activateUserSuccess ? (
            <div className="reset-error">
              <Collapse in={open}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                  severity="success"
                  variant="outlined"
                  sx={{ width: "100%", alignItems: "center" }}
                >
                  User Activated SuccessFully!
                  <br />
                  Go back to Sign In
                </Alert>
              </Collapse>
            </div>
          ) : (
            <div className="MainDiv">
              {passwordError.char ||
              passwordError.number ||
              passwordError.special ||
              password.lowercase ||
              confirmPasswordError.length > 0 ||
              recoveryAnswerError.length > 0 ? (
                <Collapse in={open}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    sx={{
                      height: "30px",
                      width: "80%",
                      mr: 3,
                      ml: 3,
                      fontSize: "14px",
                      alignItems: "center",
                    }}
                    variant="outlined"
                    severity="error"
                  >
                    {passwordError.char ||
                    passwordError.number ||
                    passwordError.special ||
                    password.lowercase
                      ? passwordError.requirements
                      : null}
                    {(passwordError.char ||
                      passwordError.number ||
                      passwordError.special ||
                      password.lowercase) &&
                    confirmPasswordError.length > 0 ? (
                      <br />
                    ) : null}
                    {confirmPasswordError.length > 0
                      ? confirmPasswordError
                      : null}
                    {recoveryAnswerError.length > 0
                      ? recoveryAnswerError
                      : null}
                  </Alert>
                </Collapse>
              ) : null}
              {passwordCheck ? (
                <>
                  <div
                    style={{
                      margin: "5px 30px 0px 30px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    {passwordError.char ? (
                      <CloseIcon
                        fontSize="small"
                        style={{
                          height: "12px",
                          color: "#B90000",
                          marginLeft: "30px",
                        }}
                      />
                    ) : (
                      <DoneOutlinedIcon
                        fontSize="small"
                        style={{
                          height: "12px",
                          color: "#00B034",
                          marginLeft: "30px",
                        }}
                      />
                    )}
                    <p className="password-error">8+characters</p>
                    {passwordError.lowerCase ? (
                      <CloseIcon
                        fontSize="small"
                        style={{
                          marginLeft: "80px",
                          height: "12px",
                          color: "#B90000",
                        }}
                      />
                    ) : (
                      <DoneOutlinedIcon
                        fontSize="small"
                        style={{
                          height: "12px",
                          color: "#00B034",
                          marginLeft: "80px",
                        }}
                      />
                    )}
                    <p className="password-error">upper & lowercase letters</p>
                  </div>
                  <div
                    style={{
                      margin: "-15px 30px 0px 30px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    {passwordError.number ? (
                      <CloseIcon
                        fontSize="small"
                        style={{
                          height: "12px",
                          color: "#B90000",
                          marginLeft: "30px",
                        }}
                      />
                    ) : (
                      <DoneOutlinedIcon
                        fontSize="small"
                        style={{
                          height: "12px",
                          color: "#00B034",
                          marginLeft: "30px",
                        }}
                      />
                    )}
                    <p className="password-error">number</p>
                    {passwordError.special ? (
                      <CloseIcon
                        fontSize="small"
                        style={{
                          marginLeft: "100px",
                          height: "12px",
                          color: "#B90000",
                        }}
                      />
                    ) : (
                      <DoneOutlinedIcon
                        fontSize="small"
                        style={{
                          height: "12px",
                          color: "#00B034",
                          marginLeft: "100px",
                        }}
                      />
                    )}
                    <p className="password-error">special character</p>
                  </div>
                </>
              ) : null}
              <div style={{ margin: "20px 30px 0 30px" }}>
                <div className="password-icon">
                  <img className="signin-password" src={lockLogo} alt="lock" />
                  <TextField
                    fullWidth={true}
                    id="password"
                    label=" New Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    variant={"standard"}
                    onChange={handleUserId}
                    sx={{
                      fontFamily: "Open Sans Regular",
                      fontSize: "16px",
                      color: "#7D898D",
                      lineHeight: "20px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </div>
              </div>
              <div style={{ margin: "20px 30px 0 30px" }}>
                <div className="password-icon">
                  <img className="signin-password" src={lockLogo} alt="lock" />
                  <TextField
                    fullWidth={true}
                    id="confirmPassword"
                    label="Verify New Password"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    variant={"standard"}
                    onChange={handleUserId}
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#7D898D",
                      lineHeight: "20px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("confirmPassword")
                              }
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </div>
              </div>
              <div style={{ margin: "20px 30px 0 30px" }}>
                <FormControl fullWidth>
                  <InputLabel id="recoveryQuestion-label">
                    Choose a forgot password question
                  </InputLabel>
                  <Select
                    variant={"standard"}
                    fullWidth
                    value={recoveryQuestion}
                    labelId="recoveryQuestion-label"
                    id="recoveryQuestion"
                    onChange={handleRecoveryQuestion}
                    label="Recovery Question"
                    style={{
                      fontFamily: "OpenSans Regular",
                      fontSize: "16px",
                      color: "#7D898D",
                    }}
                    MenuProps={MenuProps}
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                  >
                    {questions.map((data, index) => {
                      return (
                        <MenuItem key={index + "que"} value={data}>
                          {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={{ margin: "20px 30px 0px 30px" }}>
                <TextField
                  fullWidth={true}
                  variant={"standard"}
                  value={recoveryAnswer}
                  id="recoveryAnswer"
                  label="Answer"
                  style={{
                    fontFamily: "OpenSans Regular",
                    fontSize: "16px",
                    color: "#7D898D",
                    lineHeight: "20px",
                  }}
                  onChange={handleUserId}
                  error={recoveryAnswerError.length > 0}
                />
              </div>
            </div>
          )}
          {activateUserSuccess ? null : (
            <div className="sign-up" style={{ marginTop: "25px" }}>
              <Button
                variant="contained"
                style={{
                  width: "186px",
                  height: "34px",
                  backgroundColor: "#0066CC",
                  opacity:
                    !passwordError.char &&
                    !passwordError.number &&
                    !passwordError.special &&
                    !password.lowercase &&
                    !recoveryAnswerError.length > 0 &&
                    !confirmPasswordError.length > 0 &&
                    password &&
                    confirmPassword &&
                    recoveryAnswer &&
                    recoveryQuestion
                      ? "1"
                      : "0.6",
                  border: "0.5px solid #0047AD",
                  borderRadius: "20px",
                  textTransform: "none",
                  fontSize: "16px",
                  color: "white",
                }}
                className="sign-in"
                onClick={handleClick}
                disabled={enableSignUp}
              >
                Set Password
              </Button>
            </div>
          )}
          <div className="back-signin" style={{ marginTop: "6px" }}>
            <span style={{ cursor: "pointer" }} onClick={backToSign}>
              {" "}
              Back to Sign In
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          zIndex: 1000,
        }}
        className="bottom-footer"
      >
        © 2022 Assurant, Inc. All Rights Reserved
      </div>
    </div>
  );
  // }
};

export default ActivateUser;
