import * as types from "./types";

export const setAdminUsernameMail = (payload) => {
  return {
    type: types.SET_ADMIN_USERNAME_MAIL,
    payload,
  };
};

export const setGeneralUsernameMail = (payload) => {
  return {
    type: types.SET_GENERAL_USERNAME_MAIL,
    payload,
  };
};

export const getClientList = (payload) => {
  return {
    type: types.GET_CLIENT_LIST,
    payload,
  };
};

export const getShopListOfClient = (payload) => {
  return {
    type: types.GET_SHOP_LIST_CLIENT,
    payload,
  };
};

export const getShopList = (payload) => {
  return {
    type: types.GET_SHOP_LIST,
    payload,
  };
};

export const getShopListAssurant = (payload) => {
  return {
    type: types.GET_SHOP_LIST_ASSURANT,
    payload,
  };
};

export const addNewUsersToClient = (payload) => {
  return {
    type: types.ADD_NEW_USERS_TO_CLIENT,
    payload,
  };
};

export const addNewShop = (payload) => {
  return {
    type: types.ADD_NEW_SHOP,
    payload,
  };
};

export const addNewUsersToShop = (payload) => {
  return {
    type: types.ADD_NEW_USERS_TO_SHOP,
    payload,
  };
};

export const getClientUsers = (payload) => {
  return {
    type: types.GET_CLIENT_USERS,
    payload,
  };
};

export const getShopUsers = (payload) => {
  return {
    type: types.GET_SHOP_USERS,
    payload,
  };
};

export const sentInviteShop = (payload) => {
  return {
    type: types.SENT_INVITE_SHOP,
    payload,
  };
};

export const sentInviteClient = (payload) => {
  return {
    type: types.SENT_INVITE_CLIENT,
    payload,
  };
};

export const setShopUsers = (payload) => {
  return {
    type: types.SET_SHOPUSERS,
    payload,
  };
};

export const resendInvite = (payload) => {
  return {
    type: types.RESEND_INVITE,
    payload,
  };
};

export const resendInviteClient = (payload) => {
  return {
    type: types.RESEND_INVITE_CLIENT,
    payload,
  };
};

export const deleteUser = (payload) => {
  return {
    type: types.DELETE_USER,
    payload,
  };
};

export const deleteClientUser = (payload) => {
  return {
    type: types.DELETE_CLIENT_USER,
    payload,
  };
};

export const editUser = (payload) => {
  return {
    type: types.EDIT_USER,
    payload,
  };
};

export const editClientUser = (payload) => {
  return {
    type: types.EDIT_CLIENT_USER,
    payload,
  };
};

export const editClientLogo = (payload) => {
  return {
    type: types.CLIENT_LOGO,
    payload,
  };
};

export const editShopLogo = (payload) => {
  return {
    type: types.SHOP_LOGO,
    payload,
  };
};

export const updateShopUser = (payload) => {
  return {
    type: types.UPDATE_SHOP_USER,
    payload,
  };
};

export const updateClientUser = (payload) => {
  return {
    type: types.UPDATE_CLIENT_USER,
    payload,
  };
};

export const fileUpload = (payload) => {
  return {
    type: types.FILE_UPLOAD,
    payload,
  };
};

export const fileFetch = (payload) => {
  return {
    type: types.FILE_FETCH,
    payload,
  };
};

export const fileFetchHeader = (payload) => {
  return {
    type: types.FILE_FETCH_HEADER,
    payload,
  };
};

export const displayImage = (payload) => {
  return {
    type: types.DISPLAY_IMAGE,
    payload,
  };
};

export const deleteUsers = (payload) => {
  return {
    type: types.DELETE,
    payload,
  };
};

export const checkShopUserMail = (payload) => {
  return {
    type: types.CHECK_SHOP_USER_MAIL,
    payload,
  };
};

export const checkClientUserMail = (payload) => {
  return {
    type: types.CHECK_CLIENT_USER_MAIL,
    payload,
  };
};

export const setCheckClientMail = (payload) => {
  return {
    type: types.SET_CHECK_CLIENT_MAIL,
    payload,
  };
};

export const setCheckShopMail = (payload) => {
  return {
    type: types.SET_CHECK_SHOP_MAIL,
    payload,
  };
};

export const searchShopFilter = (payload) => {
  return {
    type: types.SEARCH_SHOP_FILTER,
    payload,
  };
};

export const searchClientFilter = (payload) => {
  return {
    type: types.SEARCH_CLIENT_FILTER,
    payload,
  };
};

export const sortingUser = (payload) => {
  return {
    type: types.SORTING_USER,
    payload,
  };
};

export const sortingShopUser = (payload) => {
  return {
    type: types.SORTING_SHOP_USER,
    payload,
  };
};

export const data = (payload) => {
  return {
    type: types.DATA,
    payload,
  };
};

export const emptyData = (payload) => {
  return {
    type: types.EMPTY_DATA,
    payload,
  };
};

export const handleError = (payload) => {
  return {
    type: types.HANDLE_ERROR,
    payload,
  };
};

export const searchKeyword = (payload) => {
  return {
    type: types.SEARCH_KEYWORD,
    payload,
  };
};

export const sendInvites = (payload) => {
  return {
    type: types.SEND_INVITES,
    payload,
  };
};

export const closeClientDialog = (payload) => {
  return {
    type: types.SENT_INVITE_CLIENT_SUCCESS,
    payload,
  };
};

export const closeShopDialog = (payload) => {
  return {
    type: types.SENT_INVITE_SHOP_SUCCESS,
    payload,
  };
};

export const clientValues = (payload) => {
  return {
    type: types.CLIENT_VALUES,
    payload,
  };
};

export const viTypeStatus = (payload) => {
  return {
    type: types.VI_TYPE_STATUS,
    payload,
  };
};

export const CloseResendDialogForShop = (payload) => {
  return {
    type: types.RESEND_INVITE_SUCCESS,
    payload,
  };
};
export const CloseResendDialogForClient = (payload) => {
  return {
    type: types.RESEND_INVITE_CLIENT_SUCCESS,
    payload,
  };
};

export const closeLogoImg = (payload) => {
  return {
    type: types.FILE_FETCH_SUCCESS,
    payload,
  };
};

export const closeShopLogo = (payload) => {
  return {
    type: types.SHOP_LOGO_SUCCESS,
    payload,
  };
};

export const closeClientLogo = (payload) => {
  return {
    type: types.CLIENT_LOGO_SUCCESS,
    payload,
  };
};
export const clearFileFetchHeader = (payload) => {
  return {
    type: types.FILE_FETCH_HEADER_SUCCESS,
    payload: null,
  };
};
export const clearUserStoreID = (payload) => {
  return {
    type: types.ADD_NEW_SHOP_SUCCESS,
    payload: null,
  };
};
export const deleteShopUser = (payload, shopName) => {
  return {
    type: types.DELETE_SHOP_USER,
    payload,
    shopName,
  };
};
