import React from "react";

import Reminder from "./reminder";
import Header from "../../shared/commonComponents/header/header";
import { Bottom, FootText } from "../../shared/commonComponents/footer/styled";

const ReminderScreen = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
        }}
      >
        <Header />
        <Reminder navigate={props.navigate} />
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: 1000,
          }}
        >
          <Bottom>
            <FootText>
              © 2022. Powered by Assurant. All rights reserved.
            </FootText>
          </Bottom>
        </div>
      </div>
    </>
  );
};

export default ReminderScreen;
