import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Select,
  TextField,
  MenuItem,
  FormControl,
  Dialog,
} from "@mui/material";
import BottomNavigation from "../../../../shared/commonComponents/bottomNavigation/bottomNavigation";
import {
  Heading,
  Line,
  Back,
  Header,
  Box,
  Box1,
  MainContainer,
} from "./styled";
import Button from "../../../../shared/commonComponents/button/button";
import close from "../../../../assets/images/close.svg";
import smClose from "../../../../assets/images/sm_close_black.svg";
import backblueIcon from "../../../../assets/images/back-blueArrow.svg";
import progress2Client from "../../../../assets/images/progress2Client.svg";
import {
  deleteUsers,
  sentInviteClient,
  checkClientUserMail,
  setCheckClientMail,
  emptyData,
  handleError,
  sendInvites,
  closeClientDialog,
} from "../../../../store/userManagment/action";
import ErrorHandle from "../../../../shared/commonComponents/errorHandle/errorHandle";
import SendInvite from "./sendInvite";
import SessionStorageService from "../../../../services/functional/accounts/login/storage";
const AddClientUser = (props) => {
  const [admin, setAdmin] = useState(false);
  const [general, setGeneral] = useState(false);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [options, setOptions] = useState("");
  const [adminValue, setAdminValue] = useState("");
  const [generalValue, setGeneralValue] = useState("");
  const [mailError, setMailError] = useState(false);
  const [mailAddressError, setMailAddressError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const dispatch = useDispatch();

  const jwtTokenUserDetails = SessionStorageService.get("USER");
  const userDetails = JSON.parse(jwtTokenUserDetails);

  const clientMailError = useSelector(
    (state) => state.userManagmentReducer.clientEmailError
  );

  const isError = useSelector((state) => state.userManagmentReducer?.isError);

  const errorMessage = useSelector(
    (state) => state.userManagmentReducer?.error?.message
  );

  const sendInvitesUser = useSelector(
    (state) => state.userManagmentReducer?.sendInvites
  );

  const handleErrorState = () => {
    dispatch(handleError());
  };

  const getAdminUser = useSelector(
    (state) => state.userManagmentReducer.adminUser
  );
  const getGeneralUser = useSelector(
    (state) => state.userManagmentReducer.generalUser
  );

  const clientUser = useSelector(
    (state) => state.userManagmentReducer?.clientUsers
  );

  const userRole = clientUser.filter((data) => data.roles === "ADMIN");

  const handleChange = (event) => {
    setOptions(event.target.value);

    if (event.target.value === 10) {
      setAdmin(true);
      setGeneral(false);
      setAdminValue("ADMIN");
    } else {
      setAdmin(false);
      setGeneral(true);
      setGeneralValue("GENERAL");
    }

    dispatch(setCheckClientMail(null));
  };

  const nameChange = (event) => {
    const nameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
    setTimeout(() => {
      if (event.target.value.length <= 40) {
        if (nameRegex.test(event.target.value)) {
          setNameError(false);
        } else if (!event.target.value) {
          setNameError(false);
        } else {
          setNameError(true);
        }
      } else {
        setNameError(true);
      }
    }, 2000);

    setName(event.target.value);
  };

  const emailChange = (event) => {
    setTimeout(() => {
      if (props.addClient.name.toLowerCase().includes("assurant")) {
        const assurantEmailRegex = /^[a-z0-9._%+-]+@assurant+\.[a-z]{2,4}$/;
        if (assurantEmailRegex.test(event.target.value)) {
          setMailAddressError(false);
        } else if (!event.target.value) {
          setMailAddressError(false);
        } else {
          setMailAddressError(true);
        }
      } else {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (emailRegex.test(event.target.value)) {
          setMailAddressError(false);
        } else if (!event.target.value) {
          setMailAddressError(false);
        } else {
          setMailAddressError(true);
        }
      }
    }, 2000);

    setEmail(event.target.value);
    dispatch(setCheckClientMail(null));
  };

  const onCloseClick = () => {
    dispatch(setCheckClientMail(null));
    dispatch(emptyData());
    props.close(true);
  };
  const onSave = () => {
    const adminInviteArray = getAdminUser.map((data) => {
      const obj = {
        roles: "ADMIN",
        admin_id: userDetails._id,
        name: data.name.trim(),
        user_email: data.user_email.trim(),
        client_id: props.addClient._id,
        image_url: props.addClient.image_url,
      };
      return obj;
    });

    const generalInviteArray = getGeneralUser.map((data) => {
      const obj = {
        roles: "GENERAL",
        admin_id: userDetails._id,
        name: data.name.trim(),
        user_email: data.user_email.trim(),
        client_id: props.addClient._id,
        image_url: props.addClient.image_url,
      };
      return obj;
    });

    const clientData = adminInviteArray.concat(generalInviteArray);

    dispatch(
      sentInviteClient({
        inviteUsersArray: clientData,
        client_id: props.addClient._id,
        page: props.page,
        size: props.size,
        sortType: props.sort,
      })
    );
    dispatch(sendInvites(true));
  };

  const InviteResponse = useSelector(
    (state) => state.userManagmentReducer?.sentInviteClient
  );

  useEffect(() => {
    if (InviteResponse === "COMPLETE") {
      props.close(true);
      dispatch(closeClientDialog(null));
    }
  }, [InviteResponse]);

  const deleteItem = (name) => {
    dispatch(deleteUsers(name));
  };

  const AddNameEmail = () => {
    const checkAdminEmail = getAdminUser.some((item) => {
      return item.user_email === Email;
    });

    const checkGeneralEmail = getGeneralUser.some((item) => {
      return item.user_email === Email;
    });
    if (checkAdminEmail || checkGeneralEmail) {
      setMailError(true);
    } else {
      const data = {
        user_email: Email,
        name: Name,
        level: admin ? "ADMIN" : "GENERAL",
      };

      dispatch(checkClientUserMail(data));
    }
  };

  useEffect(() => {
    if (
      clientMailError === "Email already exists as an adjudicator" ||
      clientMailError === "This User is not available in okta." ||
      clientMailError === "Email already exists as an inspector"
    ) {
      setMailError(true);
    } else if (clientMailError === "success") {
      setName("");
      setEmail("");
      setOptions("");
      setMailError(false);
      setMailAddressError(false);
    }
  }, [clientMailError]);

  return (
    <>
      <Dialog
        open={props.open}
        fullWidth
        minWidth={"lg"}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.close;
          }
        }}
        className="client-shop-add"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "875px",
              maxHeight: "590px",
              backgroundColor: "transparent !important",
              boxShadow: "none",
            },
          },
        }}
      >
        <MainContainer container>
          <Header>
            <img
              src={close}
              alt="close"
              onClick={onCloseClick}
              style={{ cursor: "pointer" }}
            />
          </Header>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            padding={"30px"}
            direction="row"
            style={{ backgroundColor: "#fff" }}
          >
            <Box item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  display: "block",
                  maxWidth: "330px",
                  marginRight: "16px",
                }}
              >
                <Heading className="user">{props.addClient.name}</Heading>
                <div style={{ margin: "10px 0 0 0 " }}>
                  <img src={progress2Client} alt="progress2Client" />
                </div>
                <FormControl>
                  <Select
                    label="Permission Level"
                    style={{ width: "100%" }}
                    variant="standard"
                    value={options}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>ADMIN</MenuItem>
                    <MenuItem value={20}>GENERAL</MenuItem>
                  </Select>
                  <Heading className="choose">
                    <div>
                      <span
                        style={{
                          fontFamily: "OpenSans Bold",
                          fontSize: "12px",
                        }}
                      >
                        Admin
                      </span>
                      : Request and review inspections, access the Admin
                      dashboard and settings, invite and manage users, etc.
                    </div>
                    <div>
                      <span
                        style={{
                          fontFamily: "OpenSans Bold",
                          fontSize: "12px",
                        }}
                      >
                        General
                      </span>
                      : Complete and submit inspections.
                    </div>
                  </Heading>

                  <div style={{ marginBottom: "52px" }}>
                    <TextField
                      fullWidth={true}
                      error={nameError}
                      placeholder={"Name"}
                      variant="standard"
                      onChange={nameChange}
                      value={Name}
                      helperText={
                        nameError ? "Please Enter Valid Username" : ""
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      fullWidth={true}
                      error={mailAddressError}
                      placeholder={"Email"}
                      variant="standard"
                      onChange={emailChange}
                      value={Email}
                      helperText={
                        mailAddressError
                          ? "Please Enter Valid Email Address"
                          : ""
                      }
                    />
                  </div>

                  <div
                    style={{
                      margin: "10px 0 0 0",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Heading className="errorMessage">
                      {mailError ? clientMailError : ""}
                    </Heading>
                    <Button
                      show={true}
                      width="120px"
                      height="34px"
                      background="#FFFFFF"
                      border="0.5px solid rgba(20, 20, 20, 0.3)"
                      borderRadius="20px"
                      disabled={
                        options &&
                        Name &&
                        Email &&
                        !nameError &&
                        !mailAddressError
                          ? false
                          : true
                      }
                      primary={
                        options &&
                        Name &&
                        Email &&
                        !nameError &&
                        !mailAddressError
                          ? true
                          : false
                      }
                      clicked={AddNameEmail}
                    >
                      Add
                    </Button>
                  </div>
                </FormControl>
              </div>
            </Box>
            <Line item xs={12} sm={1} md={1} lg={1} xl={1} />
            <Box1 item xs={12} sm={5} md={5} lg={5} xl={5}>
              <div style={{ width: "100%" }}>
                <Heading className="invite">
                  An invite will be emailed to added users.
                </Heading>
                <div style={{ justifyContent: "space-between" }}>
                  <div>
                    <Heading className="users">
                      Admin users ({getAdminUser.length})
                    </Heading>
                    <Heading className="added">
                      {getAdminUser.length === 0
                        ? "None Added"
                        : getAdminUser.map((item, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 10px 0 0",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {index + 1} {". "}
                                  {item.name} {item.user_email}
                                </div>
                                <div>
                                  <img
                                    src={smClose}
                                    alt="close"
                                    onClick={() => {
                                      deleteItem(item.name);
                                    }}
                                    style={{ marginLeft: "15px" }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                    </Heading>
                  </div>
                  <div style={{ marginTop: "50px" }}>
                    <Heading className="users">
                      General Users ({getGeneralUser.length})
                    </Heading>
                    <Heading className="added">
                      {getGeneralUser.length === 0
                        ? "None Added"
                        : getGeneralUser.map((item, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 10px 0 0",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {index + 1} {". "}
                                  {item.name} {item.user_email}
                                </div>
                                <div>
                                  <img
                                    src={smClose}
                                    alt="close"
                                    onClick={() => {
                                      deleteItem(item.name);
                                    }}
                                    style={{ marginLeft: "15px" }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                    </Heading>
                  </div>
                </div>
              </div>
            </Box1>
          </Grid>
          <BottomNavigation
            opacity={"1"}
            position={"sticky"}
            padding={"17px 30px 0 30px"}
            justifyContent={"space-between"}
            moblieJustifyContent={"space-around"}
            borderRadius={"0px 0px 4px 4px"}
            minHeight={"55px"}
            bottom={"0"}
            left={"0"}
          >
            <Back onClick={onCloseClick}>
              <div>
                <img src={backblueIcon} alt="arrow" />
              </div>
              <div>Back</div>
            </Back>

            <div style={{ display: "inline-flex", alignItems: "baseline" }}>
              {userRole.length === 0 &&
              getAdminUser.length === 0 &&
              getGeneralUser.length > 0 ? (
                <Heading className="errorMessage">
                  You must add at least one admin user
                </Heading>
              ) : null}

              <Button
                show={true}
                width={"120px"}
                height={"32px"}
                clicked={onSave}
                disabled={
                  userRole.length > 0
                    ? getAdminUser.length > 0 || getGeneralUser.length > 0
                      ? false
                      : true
                    : getAdminUser.length > 0
                    ? false
                    : true
                }
                primary={
                  userRole.length > 0
                    ? getAdminUser.length > 0 || getGeneralUser.length > 0
                      ? true
                      : false
                    : getAdminUser.length > 0
                    ? true
                    : false
                }
                borderRadius={"20px"}
              >
                Save
              </Button>
            </div>
          </BottomNavigation>
        </MainContainer>
      </Dialog>
      {isError && (
        <ErrorHandle message={errorMessage} errorState={handleErrorState} />
      )}
      {sendInvitesUser && <SendInvite fromClient={"client"} />}
    </>
  );
};

export default AddClientUser;
