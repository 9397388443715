import { WatchLater } from "@mui/icons-material";
import { put, call, takeEvery, all, takeLatest } from "redux-saga/effects";
import { reportingServices } from "../../services/functional";
import * as types from "./types";
import { HIDE_LOADER } from "../sharedComponents/loader/types";
import { saveAs } from "file-saver";
function* getReportingData({ payload }) {
  try {
    const response = yield call(
      reportingServices.getReportingDataService,
      payload
    );
    yield put({
      type: types.GET_REPORTING_DATA_SUCCESS,
      payload: response.data,
    });
    yield put({ type: HIDE_LOADER });
  } catch (error) {
    yield put({ type: types.GET_REPORTING_DATA_FAILURE, error });
  }
}
function* downloadReportData({ payload }) {
  try {
    const response = yield call(reportingServices.downloadReport, payload);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = date.getHours();
    const min = date.getMinutes();
    saveAs(
      blob,
      "report_" +
        day +
        "_" +
        month +
        "_" +
        year +
        "_" +
        time +
        "_" +
        min +
        ".pdf"
    );
    yield put({ type: types.DOWNLOAD_REPORT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.DOWNLOAD_REPORT_FALIURE, error });
  }
}
export function* getReportHeaderData({ payload }) {
  try {
    const response = yield call(reportingServices.reportHeaderData, payload);
    yield put({
      type: types.GET_REPORTING_HEADER_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_REPORTING_HEADER_DATA_FAILURE, error });
  }
}

export function* watchGetReportingData() {
  yield takeEvery(types.GET_REPORTING_DATA, getReportingData);
}
export function* WatchDownloadReportData() {
  yield takeLatest(types.DOWNLOAD_REPORT, downloadReportData);
}
export function* WatchGetReportHeaderData() {
  yield takeLatest(types.GET_REPORTING_HEADER_DATA, getReportHeaderData);
}
export function* mainReportingSaga() {
  yield all([
    watchGetReportingData(),
    WatchDownloadReportData(),
    WatchGetReportHeaderData(),
  ]);
}
