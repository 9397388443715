import React from "react";

import CreateInspectionRequest from "./createInspectionRequest";
import Header from "./Header";
import Footer from "../../../shared/commonComponents/footer/footer";

const createInspection = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          overflowX: "hidden",
        }}
      >
        <Header />
        <CreateInspectionRequest />
        <Footer />
      </div>
    </>
  );
};

export default createInspection;
