export const FORGOTPASSWORD = "FORGOTPASSWORD";
export const SUPPORT = "SUPPORT";
export const SETDEFAULTSCREEN = "SETDEFAULTSCREEN";
export const IS_ASSURANT_USER = "IS_ASSURANT_USER";
export const GET_JWT_TOKEN = "GET_JWT_TOKEN";
export const GET_JWT_TOKEN_SUCCESS = "GET_JWT_TOKEN_SUCCESS";
export const GET_JWT_TOKEN_FAILURE = "GET_JWT_TOKEN_FAILURE";
export const SET_ACTIVATE_USER = "SET_ACTIVATE_USER";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAILURE = "ACTIVATE_USER_FAILURE";
export const SEND_RESET_MAIL = "SEND_RESET_MAIL";
export const SEND_RESET_MAIL_SUCCESS = "SEND_RESET_MAIL_SUCCESS";
export const SEND_RESET_MAIL_FAILURE = "SEND_RESET_MAIL_FAILURE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const SUPPORT_REQUEST = "SUPPORT_REQUEST";
export const SUPPORT_REQUEST_SUCCESS = "SUPPORT_REQUEST_SUCCESS";
export const SUPPORT_REQUEST_FAILURE = "SUPPORT_REQUEST_FAILURE";
export const RESET_SUPPORT = "RESET_SUPPORT";
export const RESET_LOGIN_ERROR = "RESET_LOGIN_ERROR";
