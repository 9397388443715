export const GET_CLAIMS_DATA = "GET_CLAIMS_DATA";
export const GET_CLAIMS_DATA_SUCCESS = "GET_CLAIMS_DATA_SUCCESS";
export const GET_CLAIMS_DATA_FAILURE = "GET_CLAIMS_DATA_FAILURE";
export const GET_CLOSED_CLAIMS_DATA = "GET_CLOSED_CLAIMS_DATA";
export const GET_CLOSED_CLAIMS_DATA_SUCCESS = "GET_CLOSED_CLAIMS_DATA_SUCCESS";
export const GET_CLOSED_CLAIMS_DATA_FAILURE = "GET_CLOSED_CLAIMS_DATA_FAILURE";
export const GET_ACTIVE_SEARCHED_CLAIM_DATA = "GET_SEARCHED_CLAIM_DATA";
export const GET_CLOSED_SEARCHED_CLAIM_DATA = "GET_CLOSED_SEARCHED_CLAIM_DATA";
export const GET_CLOSED_DROPDOWN_DATA = "GET_CLOSED_DROPDOWN_DATA";
export const GET_CLOSED_DROPDOWN_DATA_SUCCESS =
  "GET_CLOSED_DROPDOWN_DATA_SUCCESS";
export const GET_CLOSED_DROPDOWN_DATA_FAILURE =
  "GET_CLOSED_DROPDOWN_DATA_FAILURE";
export const GET_CLOSED_MENU_SEARCH_DATA = "GET_CLOSED_MENU_SEARCH_DATA";
export const GET_CLOSED_MENU_SEARCH_SUCCESS = "GET_CLOSED_MENU_SEARCH_SUCCESS";
export const GET_CLOSED_MENU_SEARCH_FAILURE = "GET_CLOSED_MENU_SEARCH_FAILURE";
export const GET_REPORT_SEARCH_DATA = "GET_REPORT_SEARCH_DATA";
export const SHOW_CLOSED_SCREEN = "SHOW_CLOSED_SCREEN";
export const GET_TOKEN_VALIDITY = "GET_TOKEN_VALIDITY";
export const GET_TOKEN_VALIDITY_SUCCESS = "GET_TOKEN_VALIDITY_SUCCESS";
export const GET_TOKEN_VALIDITY_FAILURE = "GET_TOKEN_VALIDITY_FAILURE";
