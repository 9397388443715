import styled from "styled-components";

export const Heading = styled.div`
  font-family: "OpenSans Regular";
  font-size: 18px;
  line-height: 25px;
  color: #13223c;
`;

export const Content = styled.div`
  font-family: "OpenSans Regular";
  font-size: 14px;
  line-height: 26px;
  color: #3d515a;
`;

export const Text = styled.div`
  font-family: "OpenSans Regular";
  font-size: 16px;
  line-height: 22px;
`;

export const Header = styled.div`
direction: rtl;
background-color: transparent !important;
color: white !important;
width: 100%;
`;
