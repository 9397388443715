import { Navigate, Outlet, useLocation } from "react-router-dom";
import SessionStorageService from "./services/functional/accounts/login/storage";

const PrivateRoutes = () => {
  const location = useLocation();
  const jwtToken = SessionStorageService.get("JWT_Token");
  const Authenticated = SessionStorageService.get("Authenticated");
  return jwtToken && Authenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};
export default PrivateRoutes;
