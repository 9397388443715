import styled from "styled-components";
import { Grid } from "@mui/material";

import media from "../../styles/media.styled";

export const MainContainer = styled(Grid)`
  margin: 0 20px 58px 20px;
`;

export const ReportingGrid = styled(Grid)`
  font-family: "OpenSans Bold";
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1.5px;
  color: #13223c;
`;

export const DownloadGrid = styled(Grid)`
  text-align: end;
`;

export const Header = styled(Grid)`
  display: flex;
`;

export const SubHeaderContainer = styled(Grid)`
  display: flex;
  padding-bottom: 8px;
`;

export const SubHeaderDataGrid = styled(Grid)`
  display: flex;
`;

export const SubHeaderDataNumber = styled(Grid)`
  font-family: "OpenSans Bold";
  font-size: 24px;
  line-height: 33px;
  color: #13223c;

  &.adjudicatornumber {
    padding-left: 32%;
    padding-top: 18px;
    ${media.mediumDevice`
padding-left: 11%;
`}
  }
  &.shopNumber {
    padding-left: 30%;
    padding-top: 18px;
    ${media.mediumDevice`
padding-left: 25%;
`}
  }
`;

export const SubHeaderDataName = styled.div`
  &.request {
    font-family: "OpenSans Regular";
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #3d515a;
  }

  &.completed {
    font-family: "OpenSans Regular";
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #3d515a;
  }

  &.closed {
    font-family: "OpenSans Regular";
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #3d515a;
  }
`;

export const DropdownMenuGrid = styled(Grid)`
  display: flex;
  padding-bottom: 40px;
`;

export const MonthYearGrid = styled(Grid)`
  display: flex;
`;

export const AdjustorStatusGrid = styled(Grid)`
  display: flex;
  justify-content: end;
`;

export const MainHeaderContainer = styled(Grid)``;

export const HeaderTextContainer = styled(Grid)`
  font-family: "OpenSans SemiBold";
  font-size: 14px;
  line-height: 19px;
  color: #13223c;
  text-transform: uppercase;
  padding-top: 14px;
  height: 56px;
  background: #fbfbfb;
  box-shadow: inset 0px -1px 0px #e0e0e0;

  &.new {
    height: 56px;
    background: #a4a4a4;
    box-shadow: inset 0px -1px 0px #e0e0e0;
  }

  &.completion {
    height: 56px;
    background: #a4a4a4;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    ${media.smalDevice`
padding-left: 29px;
`}
  }

  &.shop {
    height: 56px;
    background: #a4a4a4;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    padding-left: 15px;
    color: white;
    ${media.smalDevice`
padding-left: 5px;
`};
  }

  &.newadjudicator {
    height: 56px;
    padding-left: 15px;
    background: #666666;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    color: white;
    ${media.smalDevice`
padding-left: 7px;
`}
  }

  &.status {
    padding-left: 26px;
    ${media.smalDevice`
padding-left: 16px;
`}
  }

  &.claim {
    padding-left: 1rem;
    ${media.smalDevice`
    padding-left: 19px;
    `}
  }

  &.ro {
    ${media.smalDevice`
    text-align: left;
    `}
  }
`;

export const BodyGridContainer = styled(Grid)`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const BodyTextContainer = styled(Grid)`
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(20, 20, 20, 0.2);
  padding-top: 15px;
  padding-bottom: 10px;
  height: 60px;
  font-family: "OpenSans Regular";
  font-size: 13px;
  line-height: 18px;
  color: #3d515a;

  &.requestData {
    padding-left: 10px;
  }

  &.statusdata {
    padding-left: 26px;
    ${media.smalDevice`
padding-left: 16px;
`}
  }

  &.claimdata {
    padding-left: 0.8rem;
    ${media.smalDevice`
    padding-left: 10px;
    `}
    word-break: break-word;
    width: 100px;
  }

  &.rodata {
    word-break: break-word;
    width: 100px;
    ${media.smalDevice`
    text-align: left;
    `}
  }

  &.rodata {
    ${media.smalDevice`
    text-align: start;
    `}
  }

  &.shop {
    padding-left: 15px;
    ${media.smalDevice`
    padding-left: 0px;
    `}
  }

  &.adjudictator {
    padding-left: 15px;
    ${media.smalDevice`
    padding-left: 7px;
    `}
  }
`;

export const SubHeaderText = styled(Grid)`
  &.recentText {
    font-family: "OpenSans Regular";
    font-size: 14px;
    margin-bottom: 2px;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
  }

  &.shopText {
    font-family: "OpenSans Regular";
    font-size: 14px;
    margin-bottom: 2px;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    ${media.smalDevice`
        padding-left: 3px;
        `}
  }

  &.reviewText {
    font-family: "OpenSans Regular";
    font-size: 14px;
    margin-bottom: 2px;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    ${media.smalDevice`
        padding-left: 4px;
        `}
  }
`;

export const SubHeaderTextContainer = styled.div`
  &.reqContainer {
    padding-left: 3%;
    ${media.smalDevice`
    padding-left: 0%;
    `}
  }

  &.completeContainer {
    padding-left: 18%;
    ${media.smalDevice`
        padding-left: 7%;
        `}
  }

  &.closedContainer {
    padding-left: 20%;
    ${media.smalDevice`
        padding-left: 6%;
        `}
  }
`;

export const SubHeader = styled(Grid)`
  &.recent {
    width: 535px;
    background: #252525;
    ${media.smalDevice`
        width: 324px;
        `}
  }
  &.shop {
    width: 199px;
    background: #252525;
    margin-left: 20px !important;
    color: white;
    ${media.smalDevice`
            width: 168px;
            `}
  }

  &.review {
    width: 200px;
    margin-left: 20px !important;
    background: #252525;
    ${media.smalDevice`
            width: 195px;
            `}
  }
`;

export const HeaderTextContainerDesktop = styled(Grid)`
font-family: "OpenSans SemiBold";
  font-size: 13px;
  line-height: 19px;
  color: #13223c;
  text-transform: uppercase;
  padding-top: 14px;
  height: 56px;
    background: #a4a4a4;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    color: white;

    &.newname {
      background: #666666;
      color: white;
    }
    ${media.smalDevice`
display: none;
`}
  }

`;

export const HeaderTextContaineriPad = styled(Grid)`
  display: none;
  ${media.smalDevice`
display: block;
font-family: "OpenSans SemiBold";
  font-size: 14px;
  line-height: 19px;
  color: #13223c;
  text-transform: uppercase;
  padding-top: 14px;
    height: 56px;
    background: #a4a4a4;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &.newname {
      background: #666666;
      padding-left: 0.6rem;
    }

    &.shopCompletion {
      padding-left: 4.5rem;
    }

`}
`;
export const PaginationContainer = styled(Grid)`
  display: flex;
  justify-content: end;
  margin: 20px 0;
`;

export const PageText = styled.div`
  padding-top: 4px;
  color: #3d515a;
  font-family: "OpenSans Regular";
`;

export const HeaderText = styled.div`
  margin-top: 3px;
`;
